import { useEffect, useRef, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import FirebaseInterface from '../../services/firebase';
import firebase from 'firebase/compat/app';


/**
 * Most of this is boiler plate code that I don't quite understand yet.
 */

const LoginButton = () => {
    const [userSignedIn, setUserSignedIn] = useState(false);
    const elementRef = useRef(null);

    const [firebaseInterface] = useState<FirebaseInterface>(new FirebaseInterface());

    const [uiConfig] = useState<firebaseui.auth.Config>( {
        // Your FirebaseUI Web UI configuration options
        signInFlow: 'popup', // Example configuration option
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID, // Email and password authentication
          firebase.auth.GoogleAuthProvider.PROVIDER_ID, // Google authentication
        ],
        signInSuccessUrl: '/userDataFetchinScreen',
        // ...
      })

    useEffect(() => {
        // Get or Create a firebaseUI instance.
        const firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseInterface.auth);
        if (uiConfig.signInFlow === 'popup')
            firebaseUiWidget.reset();

        // We track the auth state to reset firebaseUi if the user signs out.
        const unregisterAuthObserver = onAuthStateChanged(firebaseInterface.auth, (user) => {
            if (!user && userSignedIn)
                firebaseUiWidget.reset();
            setUserSignedIn(!!user);
        });

        // Trigger the callback if any was set.
        //if (uiCallback)
         //   uiCallback(firebaseUiWidget);

        // Render the firebaseUi Widget.
        // @ts-ignore
        firebaseUiWidget.start(elementRef.current, uiConfig);

        return () => {
            unregisterAuthObserver();
            firebaseUiWidget.reset();
        };
    }, [ uiConfig, firebaseInterface.auth, userSignedIn]);

    return <div className={"loginbutton"} ref={elementRef} />;
};

export default LoginButton; 