import { AddIcon } from "@chakra-ui/icons";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, Input, Text, useBreakpointValue } from "@chakra-ui/react";
import { t } from "i18next";
import { useRef, useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";

interface AddRememberedMessageAlertDialogProps {
  handleAddMessage: (message: string) => void;
}

function AddRememberedMessageAlertDialogButton(props: AddRememberedMessageAlertDialogProps) {
    const buttonText = useBreakpointValue({ base: t('AddRememberedMessageButton_text_short'), sm: t('AddRememberedMessageButton_text_long'), md:t('AddRememberedMessageButton_text_long')});
  const [theme] = useState<Theme>(lavenderStyle);

  const [isOpen, setIsOpen] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const handleMessageInputChange = (event: any) => {
    const input = event.target.value;
    if (input.length <= 50) {
      setMessageInput(input);
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }
  };

  const handleAddMessage = () => {
    if (messageInput.length === 0) {
      return;
    }
    // Add your logic to handle the message here
    // console.log("Message:", messageInput);
    props.handleAddMessage(messageInput);
    // Clear the input field
    setMessageInput("");
    setShowWarning(false);
    // Close the dialog
    onClose();
  };

  const cancelRef = useRef(null);

  return (
    <Flex width='100%' justifyContent={'center'}>
      <Button
        colorScheme={theme.colorScheme}
        variant="outline"
        overflow="hidden" textOverflow="ellipsis"
        size="md"
        mt="20px"
        mb="20px"
        onClick={onOpen}
        leftIcon={<AddIcon />}
      >
        {buttonText}
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{t('AddRememberedMessageButton_AlertDialogHeader')}</AlertDialogHeader>
            <AlertDialogBody>
              <Text fontSize={"sm"} mb="5" fontStyle={"italic"} fontWeight="light">
              {t('AddRememberedMessageButton_AlertDialogBody')}
              </Text>
              <Input
                value={messageInput}
                onChange={handleMessageInputChange}
                placeholder={t('AddRememberedMessageButton_placeholder') as string}
              />
              
              {showWarning && <Flex width={'100%'} justifyContent='center'> <Text  color="red" fontSize="sm">{t('AddRememberedMessageButton_warning')}</Text></Flex>}
            </AlertDialogBody>
            <AlertDialogFooter justifyContent={"center"}>
              <Button colorScheme={theme.colorScheme} mr={3} onClick={handleAddMessage}>
              {t('AddRememberedMessageButton_add')}
              </Button>
              <Button colorScheme={theme.colorScheme} variant={'outline'} ref={cancelRef} onClick={onClose}>
              {t('AddRememberedMessageButton_cancel')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default AddRememberedMessageAlertDialogButton;
