import { useContext, useEffect, useState } from "react";
import {
    Divider,
    Flex, Heading, Text
} from '@chakra-ui/react'
import { Spinner, Image } from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import { Mood } from "../../types/types";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { t } from "i18next";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import Calendar from "react-calendar";
import './Calendar.css';
import MoodtrackerIcon from "../../components/moodtracker/MoodTrackerIcon";
import logo from '../../assets/general/PsyScribeLogo.svg';
import MainNavigationRow from "../../components/sharedComponents/mainNavigationRow/MainNavigationRow";
import NotificationsButton from "../../components/sharedComponents/topNavigationRow/NotificationsButton";
import RememberedMessagesButton from "../../components/sharedComponents/topNavigationRow/RememberedMessageButton";
import ProfileButton from "../../components/sharedComponents/topNavigationRow/ProfileButton";
import ChatDoesNotExistAlert from "../../components/alerts/ChatDoesNotExistAlert";
function CalendarScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const { currentUser } = useContext(UserProfileContext);
    const firebaseInterface = useContext(FirebaseContext);
    const [dayIdToMoodsMap, setDayIdToMoodsMap] = useState<Map<string, Mood[]>>(new Map<string, Mood[]>())
    const [isFetchingMoods, setIsFetchingMoods] = useState<boolean>(true)
    const [showChatDoesNotExistAlert, setShowChatDoesNotExistAlert] = useState<boolean>(false)
    const navigate = useNavigate();
    useEffect(() => {
        if (!currentUser) {
            return;
        }
        setIsFetchingMoods(true)
        firebaseInterface.fetchAllMoods(currentUser.uid).then((moods) => {
            setDayIdToMoodsMap(moods)
       
            setIsFetchingMoods(false)

        })
    }, [currentUser,firebaseInterface])

    function getMoodTrackingIcon({ activeStartDate, date, view }: any) {
 
        if (view === 'month') {
            const id = firebaseInterface.dateToFirebaseDateId(date)
            const moods = dayIdToMoodsMap.get(id)
            
            if (moods) {
                // calculate average mood

                let sum = 0;
              
                moods.forEach((mood) => {
              
                    sum += mood.moodRating
                })
    
                const averageMood = sum / moods.length

                const avg = Number((averageMood).toFixed(1))


                return <MoodtrackerIcon moodRating={avg} />
            }
            //random number betweeen 0 and 10
            //const randomNumber = Math.floor(Math.random() * 10);
            return <MoodtrackerIcon moodRating={0} />
        }
    }

    async function clickOnDate(value : any, event : any) {
        if(!currentUser){
            return;
        }
        console.log(value)

        const firebaseDayId = firebaseInterface.dateToFirebaseDateId(value)
        
        const exists = dayIdToMoodsMap.has(firebaseDayId)
        //alert('New date is: ' + value)

        if (exists) {
            navigate(`/insightsScreen/${firebaseInterface.dateToFirebaseDateId(value)}`);
        }
        else{
            setShowChatDoesNotExistAlert(true)

        }
    }



    return <Flex flexDirection='column' height={isMobile ? "88vh" : "99vh"} width="96%" alignItems="center" marginLeft="auto"
        marginRight="auto"   >

        <Flex width="100%" mt="2" zIndex={'overlay'}   >
            <Image ml='2' width='150px' height='50px' src={logo} alt="My Logo" />
            <Flex width="100%" justifyContent={"flex-end"} columnGap='1' >
                <RememberedMessagesButton />
                <NotificationsButton />
                <ProfileButton />
            </Flex>
        </Flex>

        <Heading color={theme.primaryColor}> {t('CalendarScreen_title')}</Heading>
        <Divider borderColor={theme.thirdColor} maxWidth='800px' mt='5' ></Divider>
        {showChatDoesNotExistAlert && <ChatDoesNotExistAlert onClose={()=> setShowChatDoesNotExistAlert(false)} />}
     
        {isFetchingMoods ? <Spinner size="xl" color={theme.thirdColor} mt='20px' mb='20px' /> :
            <Flex mt='20px' flexDir={'column'} maxWidth='350px'>
                <Calendar tileContent={getMoodTrackingIcon} onChange={clickOnDate} />
            </Flex>
        }
        <Flex maxWidth={'1000px'} justifyContent='center'  alignItems={'center'}>
        <Text width='80%' mt='20px' pb='100px' fontStyle={'italic'} > {t('CalendarScreen_description')}</Text>

        </Flex>


        <MainNavigationRow />



    </Flex>
}


export default CalendarScreen