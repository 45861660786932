import { Language, Region } from "../types/types";

class InsightServer {
    //URL= 'http://192.168.0.233:5800'
    //URL= 'http://192.168.0.233:4001/insights'
    URL = 'https://insightsusa.psyscribe.com/'

    
    constructor(region: Region) {
        if (region === "us") {
            this.URL = 'https://insightsusa.psyscribe.com/'
        }
        
    }


    async requestInsights(userId: string, chatId: string, language: Language) {
        const url = `${this.URL}`
        const params = new URLSearchParams()

        params.append('userid', userId); // Replace with the actual userid
        params.append('chatId', chatId); // Replace with the actual chatId
        params.append('language', language); // Replace with the actual language
        const a = await fetch(`${url}?${params}`)
        return a.json()
    }

}

export default InsightServer;