import { Flex, Heading, ListItem, UnorderedList,Text } from "@chakra-ui/react";
import { t } from "i18next";
import {  useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";
import AvatarsOverview from "./AvatarsOverview";
import PersonalitiesOverview from "./PersonalitiesOverview";
import TherapyStylesOverview from "./TherapyStylesOverview";

interface LandingPagePersonalizationsProps {
    isMobile: boolean
}



function LandingPagePersonalizations(props: LandingPagePersonalizationsProps) {
    const [theme] = useState<Theme>(lavenderStyle)
 

    return (
        <Flex width={'99%'} flexDir='column' alignItems={'center'} mt='100px' shadow={'lg'} p='2'>


            <Flex width='96%' justifyContent={'center'} >
                <Heading size={props.isMobile? 'xl':'3xl'} color={theme.primaryColor} >
                    {t('LandingPagePersonalizations_title')}
                </Heading>
            </Flex>

            <Text width={'95%'} maxWidth='1000px' mt='30px' fontSize={props.isMobile? 'sm':'lg'} fontWeight={'bold'} color={"blackAlpha.700"}> 
            {t('LandingPagePersonalizations_description')}
            </Text>





            <AvatarsOverview isMobile={props.isMobile} />




            <Heading as="h1" size={props.isMobile? 'md':'xl'} marginBottom={'10px'} marginTop={'80px'} color={'blackAlpha.800'}  ml='10px' >
                {t('LandingPagePersonalizations_therapy_style_title')}
            </Heading>
            <Flex justifyContent='center'  mt='40px' >

                <TherapyStylesOverview theme={theme} />

            </Flex>


            <Heading as="h1" size={props.isMobile? 'md':'xl'} marginBottom={'10px'} marginTop={'80px'} color={'blackAlpha.800'}  ml='10px' >
            {t('LandingPagePersonalizations_personality_title')}
            </Heading>
            <Flex justifyContent='center'  mt='40px'  marginBottom={'40px'}  >

                <PersonalitiesOverview />

            </Flex>



            <Heading as="h1" size={props.isMobile? 'md':'xl'} marginBottom={'30px'} marginTop={'30px'} color={'blackAlpha.800'} >
            {t('LandingPagePersonalizations_more_title')}
            </Heading>

            <Flex height={'100%'} justifyContent='center' flexDir='column' ml='10px' mb='40px' >

                <UnorderedList >
                    <ListItem key='1' mb='10px' fontSize={props.isMobile?'md':'xl'} fontWeight={'bold'} color={"blackAlpha.700"}>         {t('LandingPagePersonalizations_more_name_title')}</ListItem>
                    <ListItem key='2' mb='10px' fontSize={props.isMobile?'md':'xl'}  fontWeight={'bold'} color={"blackAlpha.700"}> {t('LandingPagePersonalizations_your_name_title')}</ListItem>
                    <ListItem key='3' mb='10px'fontSize={props.isMobile?'md':'xl'}  fontWeight={'bold'} color={"blackAlpha.700"}>{t('LandingPagePersonalizations_typespeed_title')}</ListItem>
                    <ListItem key='4' mb='10px'  fontSize={props.isMobile?'md':'xl'}  fontWeight={'bold'} color={"blackAlpha.700"}>         {t('LandingPagePersonalizations_more_language_title')}</ListItem>
                </UnorderedList>

            </Flex>

        </Flex>
    )
}

export default LandingPagePersonalizations;