import { useState } from 'react';
import { Alert, AlertDescription, Button, CloseButton, Flex, Text,  useBreakpointValue, AlertTitle } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../../styles/styles';
import { t } from 'i18next';
import { Link as Routerlink } from "react-router-dom";


interface UsageLimitAlertProps {
  onClose: () => void;
}

const TryDifferentSettingsAlert = (props: UsageLimitAlertProps) => {
  const [theme] = useState<Theme>(lavenderStyle);
  const notificationWidth = useBreakpointValue({ base: "98%", sm: "95%", md: "700px" }); // Use useBreakpointValue hook to set different widths based on screen size

  





  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="9999"
      backgroundColor="rgba(0, 0, 0, 0.5)"
    >
      <Alert status={'info'} variant="subtle" width={notificationWidth} bgColor={theme.fifthColor} borderRadius="20px" borderColor={theme.secondaryColor} borderWidth={'1px'}>
        <AlertDescription width="100%">
          <Flex width="100%" justifyContent={"center"} alignItems="center" flexDir={"column"}>

            <AlertTitle mb='5px'>{t('TryDifferentSettingsAlert_title')}</AlertTitle>
            <Text>{t('TryDifferentSettingsAlert_description')}</Text>
            <Flex width={'100%'}  justifyContent={'center'} columnGap={'10px'}>
            <Button
            width={'100%'}
                mt='15px'
                colorScheme={theme.colorScheme}
                variant="outline"
                size="md"
                _hover={{ transform: "scale(1.05)" }}
                transition="transform 0.2s"
                _focus={{ boxShadow: "none" }}
                onClick={props.onClose}
              >
                {t('TryDifferentSettingsAlert_continue_button')}
              </Button>
            <Button
            width={'100%'}
                mt='15px'
                colorScheme={theme.colorScheme}
                variant="solid"
                size="md"
                _hover={{ transform: "scale(1.05)" }}
                transition="transform 0.2s"
                _focus={{ boxShadow: "none" }}
                as={Routerlink} to="/modifyPsychologistScreen"
              
              >
                  {t('TryDifferentSettingsAlert_modify_button')}
              </Button>
              </Flex>
          </Flex>

        </AlertDescription>
        <CloseButton color={theme.primaryColor} onClick={props.onClose} position="absolute" right="8px" top="8px" />
      </Alert>
    </Flex>
  );
};

export default TryDifferentSettingsAlert;
