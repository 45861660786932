import { useState } from 'react';
import { Flex, Heading, Text, VStack, Button, IconButton } from '@chakra-ui/react';
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { lavenderStyle, Theme } from '../../styles/styles';
import PlannedActionCard from './PlannedActionCard';
import CloseInsightCreatorButton from './CloseInsightCreatorButton';
import { t } from 'i18next';
import PreviousScreenButton from '../sharedComponents/navigation/PreviousScreenButton';

interface FlowStepProps {
    heading: string;
    category: string;
    text: string;
    actions: string[];
    handleStatementChange: (category: string, index: number, statement: string) => void;
    addNewStatement: (category: string) => void;
    saveReport: () => Promise<void>;
    goPreviousFlowStep: () => void;
}

function PlannedActionsStep({
    heading,
    category,
    text,
    actions,
    handleStatementChange,
    addNewStatement,
    saveReport,
    goPreviousFlowStep,
}: FlowStepProps) {
    const navigate = useNavigate();
    const [theme] = useState<Theme>(lavenderStyle)
    const path = window.location.pathname;

    const goNextFlowStep = async () => {
        await saveReport();
        const lastPart = path.split("/").pop();

        navigate('/insightsScreen/' + lastPart);
    };

    return (
        <Flex width="100%" height={"90vh"} flexDir="column">
            <Flex width="100%" justifyContent={"space-between"} mt="2" >

                <PreviousScreenButton onClick={goPreviousFlowStep} />
                <CloseInsightCreatorButton />

            </Flex>
            <Flex width="100%" alignItems="center" flexDir="column">

                <Heading size="lg" maxWidth="800px" paddingTop="5px" marginLeft="5px" color={theme.primaryColor}>
                    {heading}
                </Heading>

                <Flex width="100%" maxWidth="800px" pb="5" pt="5">
                    <Text fontStyle="italic" fontSize="md">
                        {text}
                    </Text>
                </Flex>
            </Flex>
            <Flex width="100%" height="100%" justifyContent="center" align='stretch'>
                <VStack spacing={4} align="stretch" width="95%">
                    {actions.map((feeling, index) => (
                        <PlannedActionCard
                            key={index}
                            category={category}
                            statement={feeling}
                            onStatementChange={(statement) => handleStatementChange(category, index, statement)}

                        />
                    ))}
                    <Flex width="100%" justifyContent="center" alignItems="center" flexDir="column">
                        <Text mb="5">{t('InsightsFlow_PlannedActionsStep_add_action')}</Text>
                        <IconButton
                            width="80%"
                            aria-label="Add"
                            pt="5"
                            pb="5"
                            variant={'outline'}
                            colorScheme={theme.colorScheme}
                            icon={<AddIcon />}
                            onClick={() => addNewStatement("plannedActions")}
                        />
                    </Flex>

                </VStack>
            </Flex>
            <Flex columnGap="2" pb="5" mt="10" width={"100%"} justifyContent="center">

                <Button width="60%" onClick={goNextFlowStep} colorScheme={theme.colorScheme} variant="solid">
                    {t('InsightsFlow_PlannedActionsStep_save')}
                </Button>
            </Flex>
        </Flex>
    );
}

export default PlannedActionsStep;
