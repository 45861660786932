
import {
    Button,
    Center,
    Divider,
    Flex,
    Image,
} from "@chakra-ui/react";

import logo from '../../assets/general/PsyScribeLogo.svg';
import { Link as Routerlink, useNavigate } from "react-router-dom";
import { useState } from "react";
import SmallScreenHeaderDrawer from "./SmallScreenHeaderDrawer";
import { lavenderStyle, Theme } from "../../styles/styles";
import FeaturesDrowDownMenu from "./FeaturesDrowDownMenu";
import { t } from "i18next";

interface LandingPageHeaderProps {
    isMobile: boolean
}


function LandingPageHeader(props: LandingPageHeaderProps) {

  
    const [theme] = useState<Theme>(lavenderStyle)

    const navigate = useNavigate();


    if (props.isMobile) return (<Flex
        width={'100%'}
        borderBottomColor={theme.thirdColor}
        borderBottomWidth='1px'
        bg='white'
        zIndex={10}
        p={3}
        alignItems='center'
        position={'fixed'}
    >
        <Image width='150px' height='50px' src={logo} alt="My Logo" cursor="pointer" transition="transform 0.2s" onClick={() => { navigate('/') }} />
        <Flex ml='5' columnGap={'7'} width='100%' justifyContent={'flex-end'}>


            <SmallScreenHeaderDrawer />
        </Flex>

    </Flex>
    );

    return (<Flex
        width={'100%'}
        borderBottomColor={theme.thirdColor}
        borderBottomWidth='1px'
        position={'fixed'}
        bg='white'
        zIndex={10}
        p={3}
        pl={7}
        pr={7}
        alignItems='center'

    >
        <Image width='150px' height='50px' src={logo} alt="My Logo" cursor="pointer" transition="transform 0.2s" onClick={() => { navigate('/') }} />

        <Flex columnGap={'7'} ml='50px'>
            <FeaturesDrowDownMenu />
            <Center   >
                <Divider borderColor='gray' orientation='vertical' />
            </Center>
            <Button color={theme.primaryColor} variant={'link'} as={Routerlink} to="/pricing">{t('LandingPageHeader_pricing_button')}</Button>
            <Button color={theme.primaryColor} variant={'link'} as={Routerlink} to="/contact">{t('LandingPageHeader_contact_button')}</Button>
        </Flex>

        <Flex ml='5' columnGap={'7'} width='100%' justifyContent={'flex-end'}>

            <Button colorScheme={theme.colorScheme} width='180px' as={Routerlink} to={"/login"}>{t('LandingPageHeader_sign_in_button')}</Button>

        </Flex>





    </Flex>
    );
}

export default LandingPageHeader;
