
import female_1 from '../../assets/earrings/ear-rings.svg';
class AvatarEarrings {
    type;
    img;
    earringsMap = {
        "female_1": female_1,
    }
    constructor(type) {
        this.gender = "female";
        this.type = type;
        this.img = new Image();
        this.img.src = this.earringsMap[this.getOptionsString()];
    }
    getOptionsString() {
        return this.gender + "_" + this.type;
    }
    renderOnCanvas(context, canvas_width, canvas_height) {

        return new Promise((resolve, reject) => {

            let x_scale = 10;
            let y_scale = 10;

            switch (this.type) {
                case "1":

                    if (this.img.complete) {
                        context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 6, 735, this.img.width * x_scale, this.img.height * y_scale);
                        resolve()
                    }
                    else {
                        this.img.onload = () => {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 6, 735, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        };
                    }
                    break;
                default:
                    resolve();
                    break;
            }

        });
    }


    renderAsOption(context, canvas_width, canvas_height) {
        if (this.type === "none") {
            context.strokeStyle = "gray";
            context.lineWidth = 5

            let margin = 50;
            // draw the first diagonal line
            context.beginPath();
            context.moveTo(margin, margin);
            context.lineTo(canvas_width - margin, canvas_height - margin);
            context.stroke();

            // draw the second diagonal line
            context.beginPath();
            context.moveTo(canvas_width - margin, margin);
            context.lineTo(margin, canvas_height - margin);
            context.stroke();

        }
        else {
            this.img.onload = () => {

                switch (this.type) {
                    case "1":
                        const scaleXMale = 2.5; // Scale factor for width
                        const scaleYMale = 2.5; // Scale factor for height
                        context.drawImage(this.img, 10, 70, this.img.width * scaleXMale, this.img.height * scaleYMale);
                        break;

                    default:
                        break;
                }

            }
        }
    }
}



export default AvatarEarrings