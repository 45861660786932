import {  Flex, Heading, Text } from "@chakra-ui/react";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";
import { MessageType } from "../../types/types";
import TestChatMessage from "./TestChatMessage";
import TestSavedMessage from "./TestSavedMessage";


import { t } from "i18next";

interface LandingPageSupportingFeaturesExplainedProps {
    isMobile: boolean
}

function LandingPageSupportingFeaturesExplained(props: LandingPageSupportingFeaturesExplainedProps) {

    const [theme] = useState<Theme>(lavenderStyle)

    const exampleChatMessages: MessageType[] = [{ 'message': t('LandingPageSupportingFeatures_long_term_memmory_brain_button_example_msg_bot'), 'animation': false, 'isSaved': false, 'sender': "psychologist", timestampInSeconds: 5 }, { 'message': t('LandingPageSupportingFeatures_long_term_memmory_brain_buttonexample_msg_user'), 'animation': false, 'isSaved': false, 'sender': "user", timestampInSeconds: 5 }]




    return (
        <Flex width={'95%'} flexDir='column' alignItems={'center'} mt='50px' >

            <Flex width='96%' justifyContent={'center'}  marginBottom={'10px'}>
                <Heading size={props.isMobile?'xl' :'3xl'} color={theme.primaryColor}>
                {t('LandingPageSupportingFeatures_long_term_memmory_title')}
                </Heading>
            </Flex>


      
            <Flex width='95%' justifyContent='center' maxWidth={'1000px'} mt='20px' mb='30px' >
                <Text fontSize={props.isMobile?'sm' :'lg'} fontWeight={'bold'} color={"blackAlpha.700"}>  {t('LandingPageSupportingFeatures_long_term_memmory_description_1')}</Text>
            </Flex>

            <Flex width="100%" maxWidth={'1000px'} height={'100%'} mb="5" borderWidth={"1px"} borderColor={theme.thirdColor} padding="4" borderRadius={"2xl"} >

                <Flex flexDir="column" width="100%" height={"100%"}   >

                    <Flex flexDir="column" width="100%" key={1} >
                        <TestSavedMessage message={{ 'message': t('LandingPageSupportingFeatures_long_term_memmory_remembered_msg_1'), 'animation': false, 'isSaved': false, 'sender': 'user', timestampInSeconds: 5 }} messageBackgroundColor={"#f5f6f8"} messageTextColor={"#000"} showCursor={false} typeSpeed={55} handleDeleteSavedMessage={(message) => { }} />
                    </Flex>
                    <Flex flexDir="column" width="100%" key={1} >
                        <TestSavedMessage message={{ 'message': t('LandingPageSupportingFeatures_long_term_memmory_remembered_msg_2'), 'animation': false, 'isSaved': false, 'sender': 'user', timestampInSeconds: 5 }} messageBackgroundColor={"#f5f6f8"} messageTextColor={"#000"} showCursor={false} typeSpeed={55} handleDeleteSavedMessage={(message) => { }} />
                    </Flex>
                    <Flex flexDir="column" width="100%" key={1} >
                        <TestSavedMessage message={{ 'message': t('LandingPageSupportingFeatures_long_term_memmory_remembered_msg_3'), 'animation': false, 'isSaved': false, 'sender': 'user', timestampInSeconds: 5 }} messageBackgroundColor={"#f5f6f8"} messageTextColor={"#000"} showCursor={false} typeSpeed={55} handleDeleteSavedMessage={(message) => { }} />
                    </Flex>

                </Flex>
            </Flex>

            <Flex width='95%' justifyContent='center' fontSize={props.isMobile?'sm' :'lg'} fontWeight={'bold'} color={"blackAlpha.700"} maxWidth={'900px'} mt='25px' mb='50px' alignItems={'center'} >
                <Text mr='3'>{t('LandingPageSupportingFeatures_long_term_memmory_description_2')} </Text>

                <FontAwesomeIcon icon={faBrain} size="lg" bounce={true} color={theme.brainButtonColor} />
            </Flex>

            <Flex
                ref={undefined}
                flexDirection="column"
                width="100%"
                height="100%"
                rowGap="12px"
                overflowY="auto"
                pb="40px"
                borderWidth={"1px"} borderColor={theme.thirdColor} padding="4" borderRadius={"2xl"}

                maxWidth={'1000px'}
            >
                {exampleChatMessages.map((message: MessageType, index: number) => (
                    <Flex flexDirection="column" key={index}>
                        {message.sender === 'psychologist' && (
                            <Flex width="100%" justifyContent="flex-start" columnGap="1px" paddingLeft="2px" >
                                <TestChatMessage message={message} theme={theme} typeSpeed={55} showCursor={false} />
                            </Flex>
                        )}
                        {message.sender === 'user' && (
                            <Flex width="100%"
                                justifyContent="flex-end"

                            >
                                <TestChatMessage message={message} theme={theme} typeSpeed={55} showCursor={false} />

                            </Flex>
                        )}
                        {((index === (exampleChatMessages.length - 1))) && (
                            <Flex width="100%" justifyContent="flex-start" columnGap="1px" paddingLeft="2px" >
                            </Flex>
                        )}
                    </Flex>
                ))}

            </Flex>




        </Flex>
    )
}
export default LandingPageSupportingFeaturesExplained;