
import {
    Flex,
    Heading,
    Text
} from "@chakra-ui/react";




import LandingPageHeader from "../../components/landingPageScreen/LandingPageHeader";
import LandingPageFooter from "../../components/landingPageScreen/LandingPageFooter";
import { email} from "../../data/contactInfo/contactInfo";
import { t } from "i18next";
import useScreenWidth from "../../utils/useScreenWidth";
import { useEffect, useState } from "react";


function LandingPageContactScreen() {

    const screenWidth = useScreenWidth();

    const [isMobile, setIsMobile] = useState<boolean>(false)
    useEffect(() => {
    
        if (screenWidth < 1000) {
           setIsMobile(true)
           }
        else {
            setIsMobile(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenWidth])
  




    return (<Flex
        flexDirection="column"
        alignItems="center"
        width="100%"
        height={'99vh'}>

        <LandingPageHeader isMobile={isMobile} />

        <Flex width='80%' flexDirection="column" height={'100%'}
            justifyContent={'center'}
            alignItems="center" mt='100px'>


            <Heading as="h1" size="xl" marginBottom={'10px'} marginTop={'50px'} color={'blackAlpha.800'} >
                {t('LandingPageContactScreen_title')}
            </Heading>


            <Flex alignItems='center' maxWidth={'800px'} flexDir='column' rowGap='10px' mt='50px'>
                <Text fontSize='md' fontWeight={'bold'} color={"blackAlpha.700"}>
                    {t('LandingPageContactScreen_description')}
                </Text>




            </Flex>

            <Flex alignItems='center' maxWidth={'800px'} flexDir='column' rowGap='10px' mt='50px' >
                <Text cursor="pointer"    onClick={(e) => {
                window.location.href = "mailto:" + email;
                e.preventDefault();
            }} fontSize='xl' fontWeight={'bold'} color={"blackAlpha.900"}>{email}</Text>



            </Flex>

            

        </Flex>
        <Flex height={'100%'} width='100%' justifyContent='flex-end' alignItems={'flex-end'}>
                <LandingPageFooter />
            </Flex>


    </Flex>
    );
}

export default LandingPageContactScreen;
