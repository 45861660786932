import { useContext, useEffect, useState } from "react";
import {
    Button, Divider, Flex, Heading, Input, Text
} from '@chakra-ui/react'
import { Link as Routerlink, useLocation } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { LinkedHumanTherapistData } from "../../types/types";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { Spinner, Image } from '@chakra-ui/react'
import logo from '../../assets/general/PsyScribeLogo.svg';
import RememberedMessagesButton from "../../components/sharedComponents/topNavigationRow/RememberedMessageButton";
import NotificationsButton from "../../components/sharedComponents/topNavigationRow/NotificationsButton";
import ProfileButton from "../../components/sharedComponents/topNavigationRow/ProfileButton";

function AddTherapistScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile, currentUser } = useContext(UserProfileContext);
    const location = useLocation();
    const [isFirstTime] = useState(!(location.state));
    const firebaseInterface = useContext(FirebaseContext);
    const [clientSignUpIdToken, setClientSignUpIdToken] = useState("");
    const [idTooLongError, setIdTooLongError] = useState(false);
    const [idTooShortError, setIdTooShortError] = useState(false);
    const [noTherapistsFoundError, setNoTherapistsFoundError] = useState(false);
    const [matchedTherapists, setMatchedTherapists] = useState<LinkedHumanTherapistData[]>([])
    const [isSearchingTherapist, setIsSearchingTherapist] = useState(false)
    const [isAddingTherapist, setIsAddingTherapist] = useState(false)
    const [isUnlinkingTherapist, setIsUnlinkingTherapist] = useState(false)

    const [isTherapistLinked, setIsTherapistLinked] = useState(false)

    useEffect(() => {
        if (userProfile.linkedHumanTherapist) {
            setIsTherapistLinked(true)
        }
    }, [userProfile.linkedHumanTherapist])

    const handleTokenChangen = (event: any) => {
        const input = event.target.value;
        setIdTooShortError(false);

        if (input.length <= 40) {
            setClientSignUpIdToken(event.target.value)

            setIdTooLongError(false);
        } else {
            setIdTooLongError(true);
        }
    };

    const getTherapistsLinkedToSignUpToken = async () => {
        if (!currentUser) {
            return;
        }
        setMatchedTherapists([])
        setNoTherapistsFoundError(false)
        if (clientSignUpIdToken.length === 0) {
            setIdTooShortError(true);
            return;
        }
        setIsSearchingTherapist(true)
        const response = await firebaseInterface.getTherapistsLinkedToSignUpToken(clientSignUpIdToken, currentUser.uid)
        setIsSearchingTherapist(false)

        const matchedTherapists = response.matchingTherapists
        if (matchedTherapists.length === 0) {
            setNoTherapistsFoundError(true);
            return;
        }
        setMatchedTherapists(matchedTherapists)
    }


    const linkClientWithTherapist = async (therapist: LinkedHumanTherapistData) => {
        if (!currentUser) {
            return;
        }
        setIsAddingTherapist(true)
        const response = await firebaseInterface.linkClientWithTherapist(clientSignUpIdToken, therapist.id, currentUser.uid)
        if (response.success) {
            // update user profile with therapist
            userProfile.linkedHumanTherapist = therapist
            firebaseInterface.updateLinkedHumanTherapist(currentUser.uid, therapist)
        }
        setMatchedTherapists([])
        setIsAddingTherapist(false)
    }

    const removeLinkedTherapist = async () => {
        if (!currentUser) {
            return;
        }
        if (!userProfile.linkedHumanTherapist) {
            return;
        }
        setIsUnlinkingTherapist(true)
        const response = await firebaseInterface.unlinkClientFromTherapist(userProfile.linkedHumanTherapist.id, currentUser.uid)
        if (response.success) {
            // update user profile with therapist
            userProfile.linkedHumanTherapist = null
            //firebaseInterface.updateLinkedHumanTherapist(currentUser.uid, null)
        }
        setIsTherapistLinked(false)
        setIsUnlinkingTherapist(false)
    }



    if (isTherapistLinked) {
        return (
            <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
                <Flex width="100%" mt="2" zIndex={'overlay'}   >
                    <Image ml='2' width='150px' height='50px' src={logo} alt="My Logo" />
                    <Flex width="100%" justifyContent={"flex-end"} columnGap='1' >
                        <RememberedMessagesButton />
                        <NotificationsButton />
                        <ProfileButton />
                    </Flex>
                </Flex>

                {isFirstTime ? <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/regionSettingScreen" /> </Flex> : <Flex width="100%" justifyContent={"flex-start"} mt="2"> </Flex>}

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='65px' marginLeft="5px">
                        {t('AddTherapistScreen_therapist_linked_title')}
                    </Heading>
                </Flex>

                <Flex width={"100%"} justifyContent="center" mt='10px' maxWidth={'800px'}>
                    <Text>
                        {t('AddTherapistScreen_therapist_linked_description')}
                    </Text>
                </Flex>
                <Divider maxWidth={'600px'} borderColor={theme.thirdColor} mt='20px' mb='20px'></Divider>
                <Flex flexDir="column" alignItems={"center"} p='3' boxShadow={'md'} borderRadius='10px' borderColor="blackAlpha.200" borderWidth={'1px'}>
                    <Flex>
                        <Text fontWeight={'bold'} mr='2'>{t('AddTherapistScreen_therapist_linked_name')} </Text>
                        <Text>{userProfile.linkedHumanTherapist?.name}</Text>
                    </Flex>
                    <Divider maxWidth={'600px'} borderColor={theme.thirdColor} mt='15px' mb='15px'></Divider>


                    <Button colorScheme={'red'} isLoading={isUnlinkingTherapist} onClick={() => removeLinkedTherapist()} >{t('AddTherapistScreen_therapist_linked_remove_button')}</Button>
                </Flex>

                <Divider maxWidth={'600px'} borderColor={theme.thirdColor} mt='20px' mb='20px'></Divider>

                <Flex justifyContent='center' height="100%" width="100%" flexDirection='row'>
                    <Button colorScheme={theme.colorScheme} size="lg" as={Routerlink} to={isFirstTime ? "/dataPrivacyScreen" : location.state.from} >{isFirstTime ? t('LanguageSettingScreen_therapist_linked_next') : t('LanguageSettingScreen_second_time_done')}  </Button>
                </Flex>

            </Flex >);
    }


    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            {isFirstTime ? <Flex> </Flex> :<Flex width="100%" mt="2" zIndex={'overlay'}   >
                <Image ml='2' width='150px' height='50px' src={logo} alt="My Logo" />
                <Flex width="100%" justifyContent={"flex-end"} columnGap='1' >
                    <RememberedMessagesButton />
                    <NotificationsButton />
                    <ProfileButton />
                </Flex>
            </Flex>
            }

            {isFirstTime ? <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/regionSettingScreen" /> </Flex> : <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen={location.state.from} />  </Flex>}

            <Flex width={"100%"} justifyContent="center">
                {isFirstTime ?
                    <Heading size='lg' paddingTop='15px' marginLeft="5px">
                        {t('AddTherapistScreen_title_first_time')}
                    </Heading>
                    :
                    <Heading size='lg' paddingTop='15px' marginLeft="5px">
                        {t('AddTherapistScreen_title')}
                    </Heading>
                }


            </Flex>


            <Flex width={"100%"} justifyContent="center" mt='20px' maxWidth={'800px'}>
                <Text>
                    {t('AddTherapistScreen_description')}
                </Text>
            </Flex>
            <Flex flexDir="column" width="100%" alignItems={"center"} marginTop="30px">
                <Input maxWidth={"300px"} placeholder={t('AddTherapistScreen_code_input_placeholder') as string}
                    _placeholder={{ opacity: 0.6, color: 'inherit' }} type="text" value={clientSignUpIdToken} onChange={handleTokenChangen} isRequired />
                {!idTooLongError ? (
                    <Flex></Flex>
                ) : (
                    <Text fontSize="sm" >  {t('AddTherapistScreen_code_too_long')} </Text>)}

                {!idTooShortError ? (
                    <Flex></Flex>
                ) : (
                    <Text fontSize="sm" >{t('AddTherapistScreen_code_too_short')} </Text>)}

            </Flex>
            <Button colorScheme={theme.colorScheme} isLoading={isSearchingTherapist} onClick={() => getTherapistsLinkedToSignUpToken()} mt='30px'>{t('AddTherapistScreen_search_therapists_button')} </Button>
            <Divider maxWidth={'400px'} borderColor={theme.thirdColor} mt='20px' ></Divider>

            <Flex flexDir="column" width="100%" alignItems={"center"} >
                {noTherapistsFoundError ? (

                    <Text fontSize="sm" >  {t('AddTherapistScreen_no_therapists_found_label')} </Text>) : (

                    <Flex></Flex>)

                }
                {matchedTherapists.map((therapist) => {
                    return (
                        <Flex flexDir="column" p='4' alignItems={"center"} marginTop="20px" boxShadow={'md'} borderRadius='10px' borderColor="blackAlpha.200" borderWidth={'1px'}>
                            <Text>{therapist.name}</Text>
                            <Divider borderColor={theme.fourthColor} mt='20px' mb='20px'></Divider>
                            <Button isLoading={isAddingTherapist} onClick={() => linkClientWithTherapist(therapist)}> {t('AddTherapistScreen_link_therapist_label')} </Button>
                        </Flex>
                    )
                })
                }
            </Flex>
            {isFirstTime &&
                <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' marginTop={"40px"} >
                    <Button variant={"outline"} colorScheme={theme.colorScheme} size="lg" as={Routerlink} to={"/usageRisksScreen"} >{"Skip "}  </Button>
                </Flex>
            }
        </Flex >);

}


export default AddTherapistScreen