
import { User } from "firebase/auth";
import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FirebaseInterface from "../services/firebase";
import { AvatarParams, TherapistSettings, UserProfile } from "../types/types";


type UserProfileContextType = {
  userProfile: UserProfile;
  setUserProfile: Dispatch<SetStateAction<UserProfile>>;
  profileFetchingComplete: boolean;
  currentUser: User | null
};

const defaultAvatarParams: AvatarParams = {
  chosenAge: "young",
  chosenClothesType: "1",
  chosenEarRingsType: "0",
  chosenFacialHairType: "none",
  chosenGender: "male",
  chosenGlassesType: "none",
  chosenHairColor: "black",
  chosenHairType: "1",
  chosenSkinColor: "cauca",
}

const defaultTherapistSettings: TherapistSettings = {
  therapyStyle: "solution-oriented",
  personality: "motivational",
  psychologistName: "",
  typeSpeed: 80,
  avatar: defaultAvatarParams,
  rememberedMessages: [],
}

const dafaultUserProfile: UserProfile = {
  username: "not_complete",
  language: "en",
  region: "us",
  therapistSettings: defaultTherapistSettings,
  dailyStats: {
    totalMessagesSent: 0,
    totalMessagesRemembered: 0,
    totalInsightReportsGenerated: 0,
    totalChatsCreated: 0,
  },
  totalStats: {
    totalMessagesSent: 0,
    totalMessagesRemembered: 0,
    totalInsightReportsGenerated: 0,
    totalChatsCreated: 0,
  },
  subscriptionId: "trial",
  notifications: [],
  todayMoods: [],
  promoCode: null,
  linkedHumanTherapist: null
}


// Create a new context
export const UserProfileContext = createContext<UserProfileContextType>({
  userProfile: dafaultUserProfile,
  setUserProfile: () => { },
  profileFetchingComplete: false,
  currentUser: null
});



// Create a context provider component
export const UserProfileContextProvider = ({ children }: { children: React.ReactNode }) => {
  // Define your shared state
  const [userProfile, setUserProfile] = useState<UserProfile>(dafaultUserProfile);
  const [firebase] = useState<FirebaseInterface>(new FirebaseInterface());
  const [profileFetchingComplete, setProfileFetchingComplete] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>();
  const { i18n } = useTranslation();
  //const path = window.location.pathname;


  const userProfileIsComplete = (userProfile: UserProfile) => {
    if (userProfile.username === "not_complete") {
      return false;
    }
    if (!userProfile.language) {
      return false;
    }
    if (!userProfile.therapistSettings) {
      return false;
    }
    if (!userProfile.therapistSettings.avatar) {
      return false;
    }
    if (!userProfile.therapistSettings.personality) {
      return false;
    }
    if (!userProfile.therapistSettings.typeSpeed) {
      return false;
    }
    if (!userProfile.therapistSettings.therapyStyle) {
      return false;
    }
    return true;
  }

  //const isCurrentlyOnLandingPage = () => {
  //  if (path === "/" || path === "/login" || path === "/pricing" || path === "/contact") {
  //    return true;
  //  }
  //}

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        firebase.checkIfUserExistsInFirestore(user.uid).then((exists: boolean) => {
          if (exists) {
            firebase.fetchProfile(user.uid).then((userProfile: UserProfile) => {
              // no  more completeness check
              if (userProfileIsComplete(userProfile)) {
                setUserProfile(userProfile);
              }

              if (userProfile.language === "nl") {
                i18n.changeLanguage("nl");
              }
              setProfileFetchingComplete(true)
            });
          } else {
            setProfileFetchingComplete(true)
          }
        }
        );
      }
      else {
        // user has loggged out
      }
      setCurrentUser(user);
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserProfileContext.Provider value={{ userProfile, setUserProfile, currentUser, profileFetchingComplete }}>
      {children}
    </UserProfileContext.Provider>
  );
};
