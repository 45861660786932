import React, { useEffect, useRef, useState } from 'react';
import { Flex, Divider,  Textarea } from '@chakra-ui/react';
import ImportanceSlider from './ImportanceSlider';
import { lavenderStyle, Theme } from '../../styles/styles';
import { t } from 'i18next';

interface InsightCardProps {
  statement: string;
  category: string;
  rating: number;
  onRatingChange: (rating: number) => void;
  onStatementChange: (statement: string) => void;
  ratingHeader: string;
  ratingLabels: string[];
}

function InsightCard(props: InsightCardProps) {
  const [editableStatement, setEditableStatement] = useState(props.statement);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [theme] = useState<Theme>(lavenderStyle);
  const isNewInsight = props.rating === 0;

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleStatementChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableStatement(event.target.value);
    adjustTextareaHeight();
    props.onStatementChange(event.target.value);
  };

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight();
    }
    setEditableStatement(props.statement);
  }, [props.statement]);

  return (
    <Flex
      width="100%"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="sm"
      position="relative"
      flexDir="column"
      borderColor={theme.thirdColor}
    >
      {isNewInsight && (
        <Flex
          position="absolute"
          top="-18px"
          right="10px"
          bg={theme.completementaryColor2}
          color="white"
          px={2}
          py={1}
          borderRadius="md"
          fontSize="12px"
          fontWeight="bold"
        >
          {t('InsightCard_new_insight_label')}
        </Flex>
      )}
      <Flex
        width="100%"
        pt={3}
        pl={3}
        pr={3}
        pb={3}
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        columnGap={"20px"}
      >
        <Textarea
          value={editableStatement}
          onChange={handleStatementChange}
          rows={editableStatement.split('\n').length || 1}
          style={{ resize: 'none', width: '100%' }}
          overflow="hidden"
          placeholder="Type here"
          ref={textareaRef}
        />
      </Flex>

      <Divider />

      <Flex p={4} flex={1} alignItems="center" justifyContent="center">
        <ImportanceSlider
          onRatingChange={props.onRatingChange}
          ratingHeader={props.ratingHeader}
          ratingLabels={props.ratingLabels}
          startRating={props.rating}
        />
      </Flex>
    </Flex>
  );
}

export default InsightCard;
