
import {
    Flex, Text, Button, Spinner, Switch, Heading, keyframes
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { lavenderStyle, Theme } from "../../styles/styles";
import { SubscriptionID } from "../../types/types";
import SubscriptionPlanSelector from "../../components/subscriptionScreens/SubscriptionPlanSelector";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { essentialPlan, starterPlan } from "../../data/subscriptions/subscriptionPlans";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import PromoCodeInput from "../../components/subscriptionScreens/PromoCodeInput";

function SubscriptionPlanOverviewScreen() {

    const { userProfile, currentUser} = useContext(UserProfileContext);
    const [theme] = useState<Theme>(lavenderStyle)
    const [currentUserSubscriptionPlan] = useState<SubscriptionID>(userProfile.subscriptionId)
    const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<SubscriptionID>(userProfile.subscriptionId)
    const [isEuroSelected, setIsEuroSelected] = useState<boolean>(userProfile.region === 'eu'); // Track the selected currency
    const firebaseInterface = useContext(FirebaseContext);
    const [stripeLoading, setStripeLoading] = useState<boolean>(false)
    const location = useLocation();
    const [isFirstTime] = useState(location.state && location.state.isFirstTime);
    const navigate = useNavigate();
    const promoCodeValid = userProfile.promoCode && userProfile.promoCode.endDate > new Date()
    const getSubscriptionPlanName = () =>{
        switch (selectedSubscriptionPlan) {
            case 'starter':
                return t('SubscriptionPlan_starter_name')
            case 'essential':
                return t('SubscriptionPlan_essential_name')
            default:
                return t('SubscriptionPlan_trial_name')
        }
    }

    const shineAnimation = keyframes`
    0% {
      background-position: -200%;
    }
    100% {
      background-position: 200%;
    }
  `;

    async function buySubscriptionPlan() {
        let productId = ''
        switch (selectedSubscriptionPlan) {
            case 'starter':
                if (isEuroSelected) {
                    productId = starterPlan.stripeProductIdEUR
                }
                else {
                    productId = starterPlan.stripeProductIdUSD
                }
                break;
            case 'essential':
                if (isEuroSelected) {
                    productId = essentialPlan.stripeProductIdEUR
                }
                else {
                    productId = essentialPlan.stripeProductIdUSD
                }
                break;
           
            default:
                return;
        }
        if (productId === '') {
            throw new Error("no product id")
        }

        if (!currentUser) {
            throw new Error("no current user")
        }
        
        firebaseInterface.createCheckoutSession(currentUser.uid, productId)
        setStripeLoading(true)
    }



    return (<Flex flexDirection='column' width="96%" alignItems="center" marginLeft="auto"
        marginRight="auto" >
        <Flex width="100%" justifyContent={"flex-start"} mt="2">
            {isFirstTime ? <PreviousScreenButton previousScreen="/manualScreen" /> : <PreviousScreenButton previousScreen={location.state? location.state.from : "homeScreen"} />}
        </Flex>

        <Flex width={"100%"} justifyContent="center" maxWidth={'1000px'}>
            <Heading size='lg' paddingTop='5px'  marginLeft="5px">
                {t('SubscriptionPlanOverviewScreen_title')}
            </Heading>
        </Flex>
        <Flex mt='5' width={"100%"} maxWidth={'800px'} justifyContent="center">
            {isFirstTime ? <Text fontStyle={'italic'} >                {t('SubscriptionPlanOverviewScreen_description_free_trail')}</Text>
                : <Text fontStyle={'italic'} >                {t('SubscriptionPlanOverviewScreen_description')}</Text>}
        </Flex>


        {!currentUserSubscriptionPlan || stripeLoading || !selectedSubscriptionPlan ?
            <Flex width="100%" height="100%" justifyContent={"center"} alignItems="center">
                <Spinner color={theme.thirdColor} size="xl" />
            </Flex>
            :


            <Flex width="100%" height="100%" alignItems={"center"} flexDirection='column'>
                <SubscriptionPlanSelector currentPlan={currentUserSubscriptionPlan} selectedPlan={selectedSubscriptionPlan} setSelectedPlan={setSelectedSubscriptionPlan} theme={theme} currency={isEuroSelected ? '€' : '$'} includeTrial={isFirstTime} />
                <Flex alignItems="center" mt="20px">
                    <Text mr="2">{t('SubscriptionPlanOverviewScreen_pay_in_label')} € {isEuroSelected}</Text>
                    <Switch
                        colorScheme={theme.colorScheme}
                        isChecked={isEuroSelected}
                        onChange={() => setIsEuroSelected(!isEuroSelected)}
                    />
                </Flex>
                <Flex justifyContent={'flex-end'} height="100%" alignItems={'center'} mt='20px' mb='20px' >
                    {isFirstTime ?
                        <Button _hover={{ transform: "scale(1.05)" }}
                            transition="transform 0.2s"
                            animation={` ${shineAnimation} 4s infinite linear`}
                            _focus={{ boxShadow: "none" }}
                            style={{
                                background: `linear-gradient(to right, rgba(139, 139, 182, 1),rgba(97, 97, 153, 1), rgba(182, 182, 211, 1), rgba(139, 139, 182, 1))`,
                                backgroundSize: "200% 100%",
                                backgroundPosition: "100%",
                            }}
                            mb='10px'
                            size="lg" colorScheme={theme.colorScheme}
                            onClick={selectedSubscriptionPlan === 'trial' ? () => navigate('/homeScreen') : () => buySubscriptionPlan()} > {selectedSubscriptionPlan === 'trial' ? "Start " + t('SubscriptionPlan_trial_name') : t('SubscriptionPlanOverviewScreen_change_plan_label') + getSubscriptionPlanName().toUpperCase() } </Button>

                        :
                        <Button size="lg" _hover={{ transform: "scale(1.05)" }}
                            transition="transform 0.2s"
                            animation={` ${shineAnimation} 4s infinite linear`}
                            _focus={{ boxShadow: "none" }}
                            mb='10px'
                            style={{
                                background: `linear-gradient(to right, rgba(139, 139, 182, 1),rgba(97, 97, 153, 1), rgba(182, 182, 211, 1), rgba(139, 139, 182, 1))`,
                                backgroundSize: "200% 100%",
                                backgroundPosition: "100%",
                            }} colorScheme={theme.colorScheme} isDisabled={selectedSubscriptionPlan === 'trial' || currentUserSubscriptionPlan === selectedSubscriptionPlan} onClick={() => buySubscriptionPlan()} > {t('SubscriptionPlanOverviewScreen_change_plan_label')}{getSubscriptionPlanName().toUpperCase()}</Button>
                    }
                </Flex>

                

            </Flex>

        }


{!promoCodeValid && !stripeLoading &&
    <Flex mb='20px'>
                <PromoCodeInput />
                </Flex>
            }



    </Flex>
    );
}

export default SubscriptionPlanOverviewScreen;
