import { useContext, useState } from "react";
import {
    IconButton, Menu, MenuButton, MenuItem, MenuList, useBreakpointValue, Text
} from '@chakra-ui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faQuestion, faUser } from "@fortawesome/free-solid-svg-icons";
import { lavenderStyle, Theme } from "../../../styles/styles";
import { Link as Routerlink, useLocation } from "react-router-dom";
import { AddIcon, SettingsIcon } from "@chakra-ui/icons";
import { t, use } from "i18next";
import { UserProfileContext } from "../../../contexts/userProfileContextProvider";





interface RememberedMessaggesButtonProps {
    isNotificationOn?: boolean
}

function ProfileButton(props: RememberedMessaggesButtonProps) {

    const [theme] = useState<Theme>(lavenderStyle)
    const buttonSize = useBreakpointValue({ base: "md", sm: "lg", md: "lg" }); // Use useBreakpointValue hook to set different widths based on screen size
    const location = useLocation();

    const { userProfile, currentUser } = useContext(UserProfileContext);


    return (
        <Menu  >
            <MenuButton
                as={IconButton}
                aria-label='Options'
                icon={<FontAwesomeIcon icon={faUser} size='lg' />}
                variant='ghost'
                size={buttonSize}

                colorScheme={theme.colorScheme}
            />
            <MenuList  >
                <MenuItem icon={<SettingsIcon ml='-0.5' />} as={Routerlink} to="/modifyPsychologistScreen" state={{ from: location.pathname }}>
                    <Text ml=''> {t('HomeScreen_SettingsHamburgerMenu_psychologist_settings_text')}</Text>
                </MenuItem>
                {!userProfile.linkedHumanTherapist &&
                    <MenuItem icon={<AddIcon ml='-0.9' />} as={Routerlink} to="/manageSubscriptionScreen" state={{ from: location.pathname }}>
                        <Text ml="">{t('HomeScreen_SettingsHamburgerMenu_subscription_text')}  </Text>
                    </MenuItem>
                }



                <MenuItem icon={<FontAwesomeIcon icon={faLink} />} as={Routerlink} to="/addTherapistScreen" state={{ from: location.pathname }}>
                    <Text ml="-1">{t('HomeScreen_SettingsHamburgerMenu_link_therapist_text')} </Text>
                </MenuItem>


                <MenuItem icon={<FontAwesomeIcon icon={faQuestion} />} as={Routerlink} to="/infoOverviewScreen" state={{ from: location.pathname }}>
                    <Text ml="1">{t('HomeScreen_SettingsHamburgerMenu_manual_text')} </Text>
                </MenuItem>



                <MenuItem icon={<FontAwesomeIcon icon={faUser} style={{ color: "#000000", }} />} as={Routerlink} to="/accountScreen" state={{ from: location.pathname }} >
                    <Text ml="0.5">{t('HomeScreen_SettingsHamburgerMenu_profile_text')}</Text>
                </MenuItem>
            </MenuList>
        </Menu>





    );
}

export default ProfileButton;




