import { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { Theme } from "../../styles/styles";
import { t } from "i18next";

interface Option {
  id: string;
  text: string;
}

interface Question {
  id: string;
  text: string;
  options: Option[];
}

interface Props {
  questions: Question[];
  onSubmit: (answers: { [key: string]: string }) => void;
  theme: Theme
}

function Questionnaire({ questions, onSubmit, theme}: Props) {
  const [answers, setAnswers] = useState<{ [key: string]: string }>({
    "question1": "option1",
    "question2": "option1",
    "question3": "option1",
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleOptionChange = (value: string) => {
    setAnswers({ ...answers, [currentQuestion.id]: value });
  };
  

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
  
  const handleFormSubmit = () => {

    onSubmit(answers);
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <Flex width="90%" maxWidth={'500px'} height="200px" margin="auto"  justifyContent={"flex-start"}  >
      <form onSubmit={handleFormSubmit} >
        <FormControl height="100%" >
          <FormLabel height={'60px'} fontSize="xl">{currentQuestion.text}</FormLabel>
          <RadioGroup
            onChange={handleOptionChange}
            value={answers[currentQuestion.id]}
            height="100%"
          >
            <Stack spacing={4} marginTop="10px">
              {currentQuestion.options.map((option) => (
                <Radio key={option.id} name={currentQuestion.id} value={option.id} colorScheme={theme.colorScheme}>
                  {option.text}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
        <Flex marginTop="10px" justifyContent={"flex-end"} alignItems={"center"} maxWidth={'500px'}  >
          {currentQuestionIndex > 0 && (
            <Button  onClick={handlePreviousQuestion} marginRight="10px" variant={'outline'}>
               {t("Questionnaire_therapystyles_previous_btn")}
            </Button>
          )}
          {currentQuestionIndex < questions.length - 1 ? (
            <Button colorScheme={theme.colorScheme} onClick={handleNextQuestion}>  {t("Questionnaire_therapystyles_next_btn")}</Button>
          ) : (
            <Button  colorScheme={theme.colorScheme} onClick={handleFormSubmit}>  {t("Questionnaire_therapystyles_ready_btn")}</Button>
          )}
        </Flex>
      </form>
    </Flex>
  );
}

export default Questionnaire;
