import { Button, keyframes , Text} from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";
import { Link as Routerlink} from "react-router-dom";

function GetStartedButton(){
    const [theme] = useState<Theme>(lavenderStyle)

    const shineAnimation = keyframes`
    0% {
      background-position: -200%;
    }
    100% {
      background-position: 200%;
    }
  `;

    return (
        <Button mt='50px' colorScheme={theme.colorScheme} fontSize='25px' p='36px' size={'lg'} borderRadius='xl'
        animation={` ${shineAnimation} 12s infinite linear`}
        _focus={{ boxShadow: "none" }}
      
        transition="transform 0.2s"
        style={{
            background: `linear-gradient(to right, rgba(139, 139, 182, 1),rgba(97, 97, 153, 1), rgba(182, 182, 211, 1), rgba(139, 139, 182, 1))`,
            backgroundSize: "200% 100%",
            backgroundPosition: "100%",
        }}
        as={Routerlink} to={"/login"}
        
        >
            <Text fontWeight={'bold'}> {t('LandingPageGetStarted_button')}</Text>
           
            
            </Button>

    )
}

export default GetStartedButton;