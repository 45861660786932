import {useState } from "react";
import {
    IconButton, useBreakpointValue,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { lavenderStyle, Theme } from "../../../styles/styles";
import { Link as Routerlink, useLocation } from "react-router-dom";


interface RememberedMessaggesButtonProps {
    isNotificationOn?: boolean
}

function RememberedMessagesButton(props: RememberedMessaggesButtonProps) {

    const [theme] = useState<Theme>(lavenderStyle)
    const buttonSize = useBreakpointValue({ base: "md", sm: "lg", md:"lg"}); // Use useBreakpointValue hook to set different widths based on screen size
    const location = useLocation();

    

    return (


        <IconButton aria-label="Go back"
            variant="ghost"
            colorScheme={props.isNotificationOn? theme.colorScheme2: theme.colorScheme}
            icon={<FontAwesomeIcon icon={faBrain} color={props.isNotificationOn? theme.completementaryColor1 : ""} size="lg" fade={props.isNotificationOn} />}
     
            size={buttonSize}
            state={{ from: location.pathname }}
             as={Routerlink} to={"/rememberedMessagesScreen"} borderRadius={theme.borderRadius} > </IconButton>


    );
}


export default RememberedMessagesButton;




