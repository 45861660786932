import { SubscriptionPlan } from "../../types/types";

//for things on which no real limit is set but we still want to check for spam
const dailyRememberedMessagesSpamLimit = 200;
const dailyCreatedChatsSpamLimit = 100;
const praticallyInfinite = 100000000

export const trialPlan : SubscriptionPlan = {
    id: "trial",
    displayName: "Trial Plan",
    displayNameNL: 'Proef ',
    limitTotalRememberedMessagesAtTheSameTime: 15,
    limitTotalInsightReportsAtTheSameTime: praticallyInfinite,
    limitTotalChatsAtTheSameTime: praticallyInfinite,
    limitTotalMessagesSentAllTime: 150,
    limitTotalCreatedInsightReportsAllTime: praticallyInfinite,
    limitDailyRememberedMessages: dailyRememberedMessagesSpamLimit,
    limitDailyCreatedChats: dailyCreatedChatsSpamLimit,
    limitDailyCreatedInsightReports: praticallyInfinite,
    limitDailyMessagesSent: 150,
    stripeProductIdEUR: "not purchasable",
    stripeProductIdUSD: "not purchasable",
    price: 0,
};

export const starterPlan : SubscriptionPlan =  {
    id: "starter",
    displayName: "Starter Plan",
    displayNameNL: 'Starter',
    limitTotalRememberedMessagesAtTheSameTime: 25,
    limitTotalInsightReportsAtTheSameTime: praticallyInfinite,
    limitTotalChatsAtTheSameTime: praticallyInfinite,
    limitTotalMessagesSentAllTime: praticallyInfinite,
    limitTotalCreatedInsightReportsAllTime: praticallyInfinite,
    limitDailyRememberedMessages: dailyRememberedMessagesSpamLimit,
    limitDailyCreatedChats: dailyCreatedChatsSpamLimit,
    limitDailyCreatedInsightReports: praticallyInfinite,
    limitDailyMessagesSent: 150,
    stripeProductIdEUR: "price_1NdXXtEC7qjZh8n0I76lahCt",
    stripeProductIdUSD: "price_1NdXXtEC7qjZh8n0BlWsbQGg",
    price: 1.99
}

export const essentialPlan: SubscriptionPlan  =  {
    id: "essential",
    displayName: 'Essential Plan',
    displayNameNL: 'Essentieel',
    limitTotalRememberedMessagesAtTheSameTime: praticallyInfinite,
    limitTotalInsightReportsAtTheSameTime: praticallyInfinite,
    limitTotalChatsAtTheSameTime: praticallyInfinite,
    limitTotalMessagesSentAllTime: praticallyInfinite,
    limitTotalCreatedInsightReportsAllTime: praticallyInfinite,
    limitDailyRememberedMessages: dailyRememberedMessagesSpamLimit,
    limitDailyCreatedChats: dailyCreatedChatsSpamLimit,
    limitDailyCreatedInsightReports: praticallyInfinite,
    limitDailyMessagesSent: 300,
    stripeProductIdEUR: "price_1NdXXjEC7qjZh8n0PNcVoTrP",
    stripeProductIdUSD: "price_1NdXXiEC7qjZh8n0KaRPHOGf",
    price: 4.99
}



