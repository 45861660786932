import {  useState } from "react";
import {
    Box, Button, Flex, Heading, Text
} from '@chakra-ui/react'

import { Link as Routerlink, } from "react-router-dom";

import { lavenderStyle, Theme, } from "../../styles/styles";
import { t } from "i18next";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { Image } from '@chakra-ui/react'
import mindfulnesspng from '../../assets/general/mindfulness.png'

function MindfulnessSettingScreen() {
    const [theme] = useState<Theme>(lavenderStyle)


    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            <Flex width="100%" justifyContent={"flex-start"} mt="2">   <PreviousScreenButton previousScreen="/therapyStyleSettingScreen" /></Flex>
            <Flex width={"100%"} flexDir={"column"} >

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px'  marginLeft="5px">
                        {t('MindfulnessSettingScreen_title')}
                    </Heading>
                </Flex>
            </Flex>

            <Flex alignItems="flexstart" justifyContent="center" marginTop="10px" >
                {[...Array(8)].map((_, index) => (
                    <Box
                        key={index}
                        w="2"
                        h="2"
                        borderRadius="full"
                        bg={4 === index ? theme.primaryColor : theme.secondaryColor}
                        mx="2"
                    />
                ))}
            </Flex>


            <Flex flexDir="column" alignItems="center" height={"100%"} width="100%" >



                <Flex width="100%" maxWidth={"250px"} justifyContent={"center"} marginTop="20px" marginBottom="10px">
                    <Image src={mindfulnesspng} alt='mindfulness' />
                </Flex>


                <Flex flexDirection={"column"} alignItems="center" width="100%" maxWidth={"600px"} marginTop="10px">
                    <Flex flexDirection={"column"} alignItems="center" width="90%" >
                        <Text fontStyle={"italic"} >    {t('MindfulnessSettingScreen_description')} </Text>
                    </Flex>
                    <Flex paddingTop="20px" paddingBottom="50px" flexDir="column" alignItems={"center"} width="100%">
                        <Text fontSize={"2xl"} color={theme.completementaryColor2} fontWeight={"extrabold"} > {t('MindfulnessSettingScreen_coming_soon')} </Text>

                    </Flex>
                </Flex>


                <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' >
                    <Button as={Routerlink} to="/typeSpeedSettingScreen" colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} > {t('MindfulnessSettingScreen_next_btn')}</Button>
                </Flex>
            </Flex>
        </Flex >
    )
}


export default MindfulnessSettingScreen