import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { GB, NL } from "country-flag-icons/react/3x2";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { lavenderStyle, Theme } from "../../styles/styles";

function LanguageMenu() {
    const [theme] = useState<Theme>(lavenderStyle)
    const { i18n } = useTranslation();

    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language)

        //refresh page
        window.location.reload();
    };


    return (
        <Menu>
            <MenuButton colorScheme={theme.colorScheme} as={Button} variant='ghost' rightIcon={<ChevronDownIcon />} >
                {i18n.language === "en" ?
                    <GB
                        style={{ width: "20px", height: "20px" }}
                    /> :
                    <NL
                        style={{ width: "20px", height: "20px" }}
                    />
                }

            </MenuButton>
            <MenuList>
                <MenuItem  onClick={() => setLanguage("en")}><GB
                    style={{ width: "20px", height: "20px" }}
                />
                    <Text ml='2'> English</Text>
                </MenuItem>
                <MenuItem  onClick={() => setLanguage("nl")}>

                    <NL
                        style={{ width: "20px", height: "20px" }}
                    />
                    <Text ml='2'> Nederlands</Text>

                </MenuItem>
            </MenuList>
        </Menu>
    )
}

export default LanguageMenu;