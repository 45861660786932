import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import { IconButton, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Flex, Button, useDisclosure } from "@chakra-ui/react";
import { t } from "i18next";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate, Link as Routerlink, } from "react-router-dom";
import { lavenderStyle } from "../../styles/styles";

// ... (rest of the code)

function SmallScreenHeaderDrawer() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef(null);
    const [theme] = useState(lavenderStyle);
    const [showFeatures, setShowFeatures] = useState(false);
    const navigate = useNavigate();

    const location = window.location.pathname;

    const scrollToHeading = (heading: string) => {

        if (location !== '/') {
            navigate('/');

        }

        const headingElement = document.getElementById(heading);

        if (headingElement) {
            headingElement.scrollIntoView({ behavior: 'smooth' });
        }
        setTimeout(() => {
            onClose();
        }, 900);

    };



    return (
        <>
            <IconButton ref={btnRef} onClick={onOpen} colorScheme={theme.colorScheme} variant='ghost' aria-label={""}>
                <HamburgerIcon boxSize='7' />
            </IconButton>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader></DrawerHeader>

                    <DrawerBody>
                        <Flex flexDir='column' rowGap={'3'}>
                            <Button onClick={() => { setShowFeatures(!showFeatures) }} colorScheme={theme.colorScheme} variant={'outline'} justifyContent={'space-between'} rightIcon={<ChevronDownIcon />}>{t('LandingPageHeader_features_button')}</Button>

                            {showFeatures && (
                                <Flex flexDir='column' rowGap={'20px'} ml='4'>
                                    <Button alignItems={'center'} justifyContent={'flex-start'} width='100%' color={theme.primaryColor} variant='link' onClick={() => scrollToHeading('aiTherapist')} >{t('LandingPageHeader_features_ai_therapist_button')}</Button>
                                    <Button alignItems={'center'} justifyContent={'flex-start'} width='100%' color={theme.primaryColor} variant='link' onClick={() => scrollToHeading('conversationInsights')}>{t('LandingPageHeader_features_conversation_insights_button')}</Button>

                                    <Button alignItems={'center'} justifyContent={'flex-start'} width='100%' color={theme.primaryColor} variant='link' onClick={() => scrollToHeading('landingPagePersonalizations')} >{t('LandingPageHeader_features_personalizable_button')}</Button>
                                    <Button alignItems={'center'} justifyContent={'flex-start'} width='100%' color={theme.primaryColor} variant='link' onClick={() => scrollToHeading('longTermMemory')}>{t('LandingPageHeader_features_longtermmemmory_button')}</Button>
                                </Flex>
                            )}
                            <Button colorScheme={theme.colorScheme} justifyContent={'flex-start'} variant={'outline'} as={Routerlink} to="/pricing">{t('LandingPageHeader_pricing_button')}</Button>
                            <Button colorScheme={theme.colorScheme} justifyContent={'flex-start'} variant={'outline'} as={Routerlink} to="/contact">{t('LandingPageHeader_contact_button')}</Button>
                            <Button colorScheme={theme.colorScheme} justifyContent={'flex-start'} variant={'solid'} as={Routerlink} to="/login">{"Login"}</Button>
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default SmallScreenHeaderDrawer;
