import { useContext, useState } from "react";
import {
    Button, Flex, Heading, Text
} from '@chakra-ui/react'
import { Link as Routerlink, useLocation } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import {  Region } from "../../types/types";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import RegionSelector from "../../components/settingScreens/RegionSelector";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";

function RegionSettingScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile, setUserProfile , currentUser } = useContext(UserProfileContext);

    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/accountScreen"));
    const firebaseInterface = useContext(FirebaseContext);

    const [selectedRegion, setSelectedRegion] = useState<Region>(isFirstTime? userProfile.language==='nl'? 'eu' : "us": userProfile.region);
 
    function updateRegion(region: Region) {
        //userProfile.language = language as Language
        if (currentUser) {
            firebaseInterface.updateRegion(currentUser.uid, region);
            setUserProfile({ ...userProfile, region: region })
        }
        else {
            throw new Error("User not logged in");
        }
    }
    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
                        {isFirstTime ? <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/languageSettingScreen" /> </Flex> : <Flex width="100%" justifyContent={"flex-start"} mt="2"> </Flex>}

            <Flex width={"100%"} justifyContent="center">
                <Heading size='lg' paddingTop={isFirstTime?'5px':'65px'} marginLeft="5px">
                {t('RegionSettingScreen_title')}
                </Heading>
            </Flex>
          
            <Flex width={"60%"} justifyContent="center">
                <Text fontStyle={"italic"} fontSize="md" textAlign="center" marginTop="20px">
                    {t('RegionSettingScreen_description')}
                </Text>
            </Flex>


            <Flex justifyContent="center" alignItems={"center"} height="100%" marginTop="40px">
                <RegionSelector selectedRegion={selectedRegion} setRegion={setSelectedRegion} />
            </Flex>
            <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' marginTop={"40px"} >
                <Button variant={"outline"} colorScheme={theme.colorScheme} size="lg" as={Routerlink} to= {isFirstTime ? "/addTherapistScreen": "/accountScreen" } onClick={() => updateRegion(selectedRegion)}>{isFirstTime ? t('LanguageSettingScreen_first_time_done'):  t('LanguageSettingScreen_second_time_done')}  </Button>
            </Flex>
        </Flex >);

}


export default RegionSettingScreen