
import {
    Flex, Heading, Text
} from '@chakra-ui/react'
import { t } from "i18next";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { email } from "../../data/contactInfo/contactInfo";
function ContactInformationScreen() {


    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/infoOverviewScreen" /> </Flex>


            <Flex width={"100%"} flexDir={"column"} maxWidth={"900px"}>

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px'  marginLeft="5px">
                        {t('ContactInformationScreen_title')}
                    </Heading>

                </Flex>
            </Flex>
            <Flex flexDir="column" mt="5" maxWidth={"900px"} justifyContent="center" alignItems={"center"}>
                <Flex flexDir="column">
                    <Text>
                        {t('ContactInformationScreen_description')}
                    </Text>
                </Flex>
                <Text mt='10px' cursor="pointer"    onClick={(e) => {
                window.location.href = "mailto:" + email;
                e.preventDefault();
            }} fontSize='xl' fontWeight={'bold'} color={"blackAlpha.900"}>{email}</Text>
            </Flex>
        </Flex >);
}



export default ContactInformationScreen