import { Flex,Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";
import CustomizationRadioGroup from "../settingScreens/CustomizationRadioGroup";

function PersonalitiesOverview() {

    const [personalityRadioValue, setPersonalityRadioValue] = useState('1')
    const [theme] = useState<Theme>(lavenderStyle)


    

    return(
        <Flex flexDirection={"column"}>
        <Flex flexDirection={"column"} justifyContent="flex-start" width="100%" maxWidth={"600px"} >
        <CustomizationRadioGroup theme={theme} heading={""} radioValues={[t('PersonalitySettingScreen_radiobutton1'), t('PersonalitySettingScreen_radiobutton2'), t('PersonalitySettingScreen_radiobutton3')]}
            onChange={setPersonalityRadioValue} value={personalityRadioValue}
        />
    </Flex>

    <Flex width="100%" justifyContent="center" height="300px" maxHeight="100px" maxWidth={"600px"} marginTop="20px" >
        {personalityRadioValue === '0' && <Text width="90%" as='em' marginLeft="5px">{t('PersonalitySettingScreen_radiobutton1_explanation')}</Text>}
        {personalityRadioValue === '1' && <Text width="90%" as='em' marginLeft="5px">{t('PersonalitySettingScreen_radiobutton2_explanation')}</Text>}
        {personalityRadioValue === '2' && <Text width="90%" as='em' marginLeft="5px">{t('PersonalitySettingScreen_radiobutton3_explanation')}</Text>}

    </Flex>
    </Flex>
    )
}

export default PersonalitiesOverview;