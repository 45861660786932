//facial hair
import male_1_black from '../../assets/facialhair/facial-1-black.svg';
import male_1_blond from '../../assets/facialhair/facial-1-blond.svg';
import male_1_brown from '../../assets/facialhair/facial-1-brown.svg';
import male_1_gray from '../../assets/facialhair/facial-1-gray.svg';
import male_2_black from '../../assets/facialhair/facial-2-black.svg';
import male_2_blond from '../../assets/facialhair/facial-2-blond.svg';
import male_2_brown from '../../assets/facialhair/facial-2-brown.svg';
import male_2_gray from '../../assets/facialhair/facial-2-gray.svg';


class AvatarFacialHair {

    gender;
    type;
    color;
    img;

    facialHairMap = {
        "male_1_black": male_1_black,
        "male_2_black": male_2_black,
        "male_1_blond": male_1_blond,
        "male_2_blond": male_2_blond,
        "male_1_brown": male_1_brown,
        "male_2_brown": male_2_brown,
        "male_1_gray": male_1_gray,
        "male_2_gray": male_2_gray,
    }

    constructor(gender, type, color) {
        this.gender = gender;
        this.type = type;
        this.color = color;
        this.img = new Image();
        this.img.src = this.facialHairMap[this.getOptionsString()];
    }

    getOptionsString() {
        return this.gender + "_" + this.type + "_" + this.color;
    }


    async renderOnCanvas(context, canvas_width, canvas_height) {

        return new Promise((resolve, reject) => {
            let x_scale = 10;
            let y_scale = 10;

            switch (this.type) {
                case "1":

                    if (this.img.complete) {
                        context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 5, canvas_height * 0.23 - 14, this.img.width * x_scale, this.img.height * y_scale);
                        resolve()
                    }
                    else {
                        this.img.onload = () => {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 5, canvas_height * 0.23 - 14, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        };
                    }

                    break;
                case "2":
                    if (this.img.complete) {
                        context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.34, this.img.width * x_scale, this.img.height * y_scale);
                        resolve()
                    }
                    else {
                        this.img.onload = () => {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.34, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        };
                    }
                    break;
                default:
                    resolve();
                    break;
            }

        })
            ;
    }


    renderAsOption(context, canvas_width, canvas_height) {

        if (this.type === "none") {
            context.strokeStyle = "gray";
            context.lineWidth = 5

            let margin = 50;
            // draw the first diagonal line
            context.beginPath();
            context.moveTo(margin, margin);
            context.lineTo(canvas_width - margin, canvas_height - margin);
            context.stroke();

            // draw the second diagonal line
            context.beginPath();
            context.moveTo(canvas_width - margin, margin);
            context.lineTo(margin, canvas_height - margin);
            context.stroke();

        }
        else {

            this.img.onload = () => {

                switch (this.type) {
                    case "1":
                        const scaleXMale = 3; // Scale factor for width
                        const scaleYMale = 3; // Scale factor for height
                        context.drawImage(this.img, 20, 20, this.img.width * scaleXMale, this.img.height * scaleYMale);
                        break;
                    case "2":
                        const scaleXMale2 = 5; // Scale factor for width
                        const scaleYMale2 = 5; // Scale factor for height
                        context.drawImage(this.img, 40, 80, this.img.width * scaleXMale2, this.img.height * scaleYMale2);
                        break;
                    default:

                        break;
                }


            };
        }

    }
}
export default AvatarFacialHair