// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { addDoc, collection, deleteDoc, doc, FieldValue, Firestore, getCountFromServer, getDoc, getDocs, getFirestore, increment, limit, onSnapshot, orderBy, query, serverTimestamp, setDoc, startAfter, Timestamp, updateDoc, where } from 'firebase/firestore';
import { getAuth, signOut, Auth, sendEmailVerification } from "firebase/auth";
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';

import { AvatarParams, Chat, Insights, Language, MessageType, Personality, TherapistSettings, TherapyStyle, TypeSpeed, UserProfile, InsightReport, firebaseStoredInsightReport, SubscriptionID, UsageStatistics, SubscriptionPlan, NotificationInferface, NotificationFetchedFromDatabase, FeedbackReport, Region, LinkedHumanTherapistData, Mood, PromoCode } from "../types/types";
import initializeStripe from "./stripe";
import { essentialPlan, starterPlan, trialPlan } from "../data/subscriptions/subscriptionPlans";


type FirebaseMessage = {
    id: string;
    text: string;
    sender: "user" | "psychologist";
    timestamp: FieldValue;
}

class FirebaseInterface {
    app: FirebaseApp;

    db: Firestore;

    auth: Auth;

    functions: Functions

    trialPlan: SubscriptionPlan = trialPlan
    starterPlan: SubscriptionPlan = starterPlan
    essentialPlan: SubscriptionPlan = essentialPlan


    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    //firebaseConfig = {
    //    apiKey: "AIzaSyAXkD7q-McJhxpJqcllaensyb-7jMWXKuY",
    //    authDomain: "psychobot-90921.firebaseapp.com",
    //    projectId: "psychobot-90921",
    //    storageBucket: "psychobot-90921.appspot.com",
    //    messagingSenderId: "953365192676",
    //    appId: "1:953365192676:web:a5ce2993a28dd2a37531f1",
    //    measurementId: "G-G1256GNREM"
    //};

    firebaseConfig = {
        apiKey: "AIzaSyAXkD7q-McJhxpJqcllaensyb-7jMWXKuY",
        authDomain: "auth.psyscribe.com",
        projectId: "psychobot-90921",
        storageBucket: "psychobot-90921.appspot.com",
        messagingSenderId: "953365192676",
        appId: "1:953365192676:web:a5ce2993a28dd2a37531f1",
        measurementId: "G-G1256GNREM"
    };
    // firebaseConfig = {
    //     apiKey: "AIzaSyDdeh9Lbb3JVZPYVtuEflMLUyl53xHHKKc",
    //     authDomain: "deckofcards-7eb6b.firebaseapp.com",
    //     databaseURL: "https://deckofcards-7eb6b.firebaseio.com",
    //     projectId: "deckofcards-7eb6b",
    //     storageBucket: "deckofcards-7eb6b.appspot.com",
    //    messagingSenderId: "815834337205",
    //     appId: "1:815834337205:web:987f0f60af221c3a5c13fc",
    //     measurementId: "G-PQ84EWB8EB"
    // };

    constructor() {
        this.app = initializeApp(this.firebaseConfig);
        this.db = getFirestore(this.app);
        this.auth = getAuth(this.app);
        this.functions = getFunctions(this.app, 'europe-west3');
    }

    logOut() {
        signOut(this.auth);
    }

    async getTherapistsLinkedToSignUpToken(clientToken: string, patientId: string): Promise<{ success: boolean, matchingTherapists: LinkedHumanTherapistData[] }> {
        const url = 'https://get-therapist-from-token-4gncbogdka-uc.a.run.app'
        const data = {
            client_token: clientToken,
            patient_id: patientId
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return response.json()

    }


    async linkClientWithTherapist(clientToken: string, therapistId: string, patientId: string) {
        const url = 'https://link-client-w-therapist-4gncbogdka-uc.a.run.app'
        const data = {
            client_token: clientToken,
            therapist_id: therapistId,
            patient_id: patientId
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return response.json()
        // TODO LINK CLIENT WITH THERAPIST van client zijn kant

    }


    async unlinkClientFromTherapist(therapistId: string, patientId: string) {
        const url = 'https://unlink-client-w-therapist-4gncbogdka-uc.a.run.app'
        const data = {
            patient_id: patientId,
            therapist_id: therapistId
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return response.json()

    }


    async logMood(userId: string, mood: Mood) {

        // check if mood document exists
        const moodRefDocCurrentDate = doc(this.db, "Users", userId, "moods", this.getCurrentDate(),);
        const moodDocSnap = await getDoc(moodRefDocCurrentDate);

        if (moodDocSnap.exists()) {
            // if mood document exists, check if mood document for this mood exists
            const moodRef = collection(this.db, "Users", userId, "moods", this.getCurrentDate(), "moods");
            await addDoc(moodRef, {
                mood: mood,
                timestamp: serverTimestamp(),
            });
        }
        else {
            // if mood document does not exist, create mood document and add mood
            const moodRef = doc(this.db, "Users", userId, "moods", this.getCurrentDate());
            await setDoc(moodRef, {});
            const moodRef2 = collection(this.db, "Users", userId, "moods", this.getCurrentDate(), "moods");
            await addDoc(moodRef2, {
                mood: mood,
                timestamp: serverTimestamp(),
            });
        }
    }


    async fetchMoodsFromDate(userId: string, date: Date) {
        const moodRef = collection(this.db, "Users", userId, "moods", this.dateToFirebaseDateId(date), "moods");
        const querySnapshot = await getDocs(
            query(
                moodRef,
                orderBy('timestamp', 'desc'),
            )
        );
        const moods: Mood[] = querySnapshot.docs.map((doc) => (
            doc.data().mood
        )).reverse();
        return moods;
    }




    async fetchAllMoods(userId: string) {

        const moodRef = collection(this.db, "Users", userId, "moods");
        // get all docs in this collection
        try {
            const querySnapshot = await getDocs(moodRef);
            const dayIdToMoodsMap: Map<string, Mood[]> = new Map();



            const docIds: string[] = []
            querySnapshot.forEach((doc) => {
                // Assuming your mood documents have some fields, you can access them like this:
                // log doc.id
                // fetch all moods from this doc
                docIds.push(doc.id)

            });
            for (let i = 0; i < docIds.length; i++) {
                let moods: Mood[] = []
                let moodRef2 = collection(this.db, "Users", userId, "moods", docIds[i], "moods");
                let querySnapshot2 = await getDocs(moodRef2);

                querySnapshot2.forEach((doc) => {
                    // Assuming your mood documents have some fields, you can access them like this:
                    // log doc.id
                    // fetch all moods from this doc
                    moods.push(doc.data().mood)

                });


                dayIdToMoodsMap.set(docIds[i], moods)
            }


            return dayIdToMoodsMap;
        } catch (error) {
            console.error("Error fetching moods: ", error);
            throw error;
        }

    }






    async fetchProfile(userId: string): Promise<any> {
        const docRef = doc(this.db, "Users", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            const therapistSettings: TherapistSettings = {
                therapyStyle: data.therapyStyle,
                personality: data.personality,
                psychologistName: data.psychologistName,
                typeSpeed: data.typeSpeed,
                avatar: data.avatar,
                rememberedMessages: await this.getRememberedMessages(userId)
            }
            const subscription = await this.getSubscriptionInfo(userId);
            const profile: UserProfile = {
                username: data.userName,
                language: data.language,
                region: data.region,
                therapistSettings: therapistSettings,
                dailyStats: await this.getDailyStats(userId),
                totalStats: await this.getTotalStats(userId),
                subscriptionId: data.linkedHumanTherapist || (data.promoCode && new Date(data.promoCode.endDate.seconds * 1000) > new Date()) ? 'essential' : subscription ? subscription.role : "trial",
                notifications: await this.fetchNotifications(userId),
                todayMoods: await this.fetchMoodsFromDate(userId, new Date()),
                promoCode: data.promoCode ? { code: data.promoCode.code, endDate: new Date(data.promoCode.endDate.seconds * 1000) } : null,
                linkedHumanTherapist: data.linkedHumanTherapist ? data.linkedHumanTherapist : null,
            }
            return profile;
        }
        return null;
    }



    async checkIfUserExistsInFirestore(userId: string): Promise<boolean> {
        const docRef = doc(this.db, "Users", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return true;
        }
        return false;
    }

    sendEmailVerification() {
        if (this.auth.currentUser) {
            sendEmailVerification(this.auth.currentUser)
                .then(() => {
                    // Email verification sent!
                    // ...
                });
        }
    }




    updateUserDescriptionDebug(userId: string, user_description: string) {
        const newUserDescription = {
            user_description: user_description,
            timestamp: serverTimestamp(),
        };

        const col = collection(this.db, "Users", userId, "user_descriptions");
        addDoc(col, newUserDescription)
    }

    updateChatSummary(uid: string, newSummary: string, chatId: string) {
        const newChatSummary = {
            chatSummary: newSummary,
            timestamp: serverTimestamp(),
        };
        const chat_summary_doc = doc(this.db, "Users", uid, "chats", chatId);
        setDoc(chat_summary_doc, newChatSummary, { merge: true })
    }

    async getChatSummary(uid: string, chatId: string) {
        // check if chat summary exists
        const chat_summary_doc = doc(this.db, "Users", uid, "chats", chatId);
        const chat_summary_doc_snap = await getDoc(chat_summary_doc);
        if (chat_summary_doc_snap.exists()) {
            return chat_summary_doc_snap.data().chatSummary;
        }
        return "";
    }




    async addRememberedMessage(uid: string, message: MessageType) {
        const newRememberedMessage = {
            message: message,
            sender: message.sender,
            timestamp: serverTimestamp(),
        };
        const col = collection(this.db, "Users", uid, "remembered_messages");
        addDoc(col, newRememberedMessage)

        this.logMessageRemembered(uid);
    }


    async addInsightReport(uid: string, chatId: string, report: InsightReport) {
        const newInsightReport = {
            report: report,
            chatId: chatId,
            timestamp: serverTimestamp(),
        };


        // delete old insight report with same chatId
        const oldInsightReportRef = doc(this.db, "Users", uid, "insight_reports", chatId);

        await deleteDoc(oldInsightReportRef);

        // add new insight report

        const docRef = doc(this.db, "Users", uid, "insight_reports", chatId);
        await setDoc(docRef, newInsightReport);



    }

    async updateInsights(uid: string, chatId: string, insights: Insights) {
        const chatRef = doc(this.db, "Users", uid, "chats", chatId);
        const new_insights = {
            insights: {
                insights: insights,
            },
        };
        await setDoc(chatRef, new_insights, { merge: true });
    }

    async getInsightReports(uid: string) {
        const insightReportsRef = collection(this.db, "Users", uid, "insight_reports");
        const querySnapshot = await getDocs(
            query(
                insightReportsRef,
                orderBy('timestamp', 'desc'),
            )
        );
        const firebaseStoredInsightReports: firebaseStoredInsightReport[] = querySnapshot.docs.map((doc) => (
            {
                report: doc.data().report,
                insightReportId: doc.id,
            }
        )).reverse();

        return firebaseStoredInsightReports;
    }


    async getInsightReportByChatId(uid: string, chatId: string) {
        const insightReportsRef = doc(this.db, "Users", uid, "insight_reports", chatId);
        const docSnap = await getDoc(insightReportsRef);
        if (docSnap.exists()) {
            return (

                {
                    report: docSnap.data().report,
                    insightReportId: docSnap.id,
                })

        }
        return null;
    }

    async getInsightReportsByChatId(uid: string, chatId: string) {
        const insightReportsRef = collection(this.db, "Users", uid, "insight_reports");
        const querySnapshot = await getDocs(
            query(
                insightReportsRef,
                where("chatId", "==", chatId),
                orderBy('timestamp', 'desc'),
            )
        );
        const insightReports: InsightReport[] = querySnapshot.docs.map((doc) => (

            doc.data().report
        )).reverse();
        return insightReports;
    }

    async deleteInsightReport(uid: string, insightReportId: string) {
        // delete document with id
        const insightReportDoc = doc(this.db, "Users", uid, "insight_reports", insightReportId);
        await deleteDoc(insightReportDoc);
    }



    async deleteRememberedMessage(uid: string, message: MessageType) {
        const col = collection(this.db, "Users", uid, "remembered_messages");
        const querySnapshot = await getDocs(col);
        querySnapshot.forEach((doc) => {
            if (doc.data().message.message === message.message) {
                deleteDoc(doc.ref);
            }
        });
    }

    async getRememberedMessages(uid: string) {
        const rememberedMessagesRef = collection(this.db, "Users", uid, "remembered_messages");
        const querySnapshot = await getDocs(
            query(
                rememberedMessagesRef,
                orderBy('timestamp', 'desc'),
                limit(100)
            )
        );
        const rememberedMessages: MessageType[] = querySnapshot.docs.map((doc) => (
            doc.data().message
        )).reverse();
        return rememberedMessages;

    }

    async addMessage(uid: string, chadId: string, message: MessageType) {
        const newMessage = {
            text: message.message,
            sender: message.sender,
            timestamp: serverTimestamp(),
        };
        const chatRef = doc(this.db, "Users", uid, "chats", chadId);
        const messagesRef = collection(chatRef, "messages");

        try {
            const chatDoc = await getDoc(chatRef);
            if (chatDoc.exists()) {
                await addDoc(messagesRef, newMessage);
            } else {
                await setDoc(chatRef, {});
                await addDoc(messagesRef, newMessage);
            }
        } catch (error) {
            console.error("Error adding message: ", error);
            throw error;
        }
    }


    async updateChatServerTimestamp(uid: string, chatId: string) {
        const chatRef = doc(this.db, "Users", uid, "chats", chatId);
        const newServerTimestamp = {
            serverTimestamp: serverTimestamp(),
        };
        await setDoc(chatRef, newServerTimestamp, { merge: true });
    }


    async checkIfChatExists(uid: string, chatId: string) {
        const dailyChatRef = doc(this.db, "Users", uid, "chats", chatId);
        const docSnap = await getDoc(dailyChatRef);
        if (docSnap.exists()) {
            return true;
        }
        return false;
    }

    async createDailyChat(uid: string, chatName: string) {
        const newChat = {
            metadata: {
                name: chatName,
            },
            serverTimestamp: serverTimestamp(),
        };
        const currentDate = this.getCurrentDate();
        const chatsRef = doc(this.db, "Users", uid, 'chats', currentDate);
        await setDoc(chatsRef, newChat);

        return currentDate;
    }



    async createChat(uid: string, chatName: string) {
        const newChat = {
            metadata: {
                name: chatName,
            },
            serverTimestamp: serverTimestamp(),
        };
        const chatsRef = collection(this.db, "Users", uid, 'chats');
        const docRef = await addDoc(chatsRef, newChat);

        const chat: Chat = {
            databaseId: docRef.id,
            name: new Date().getTime().toString(),
            lastMessageDateInMilliseconds: new Date().getTime(),
        }
        return chat
    }

    async getDailyStats(uid: string): Promise<UsageStatistics> {
        const dailyStatsRef = doc(this.db, "Users", uid, "daily_stats", this.getCurrentDate());
        const docSnap = await getDoc(dailyStatsRef);
        if (docSnap.exists()) {
            const dailyStats = docSnap.data() as UsageStatistics;
            return dailyStats;
        } else {
            return {
                totalMessagesSent: 0,
                totalMessagesRemembered: 0,
                totalInsightReportsGenerated: 0,
                totalChatsCreated: 0,
            };
        }
    }

    async getTotalStats(uid: string): Promise<UsageStatistics> {
        const totalStatsRef = doc(this.db, "Users", uid, "total_stats", "total_stats");
        const docSnap = await getDoc(totalStatsRef);
        if (docSnap.exists()) {
            const totalStats = docSnap.data() as UsageStatistics;
            return totalStats;
        } else {
            return {
                totalMessagesSent: 0,
                totalMessagesRemembered: 0,
                totalInsightReportsGenerated: 0,
                totalChatsCreated: 0,
            };
        }
    }


    async getDailyTotalGeneratedInsightReportsStats(uid: string) {
        const dailyStatsRef = doc(this.db, "Users", uid, "daily_stats", this.getCurrentDate());
        const docSnap = await getDoc(dailyStatsRef);
        if (docSnap.exists()) {
            const dailyStats = docSnap.data() as UsageStatistics;
            return dailyStats.totalInsightReportsGenerated;
        } else {
            return 0;
        }
    }

    async getDailyTotalCreatedChatsStats(uid: string) {
        const dailyStatsRef = doc(this.db, "Users", uid, "daily_stats", this.getCurrentDate());
        const docSnap = await getDoc(dailyStatsRef);
        if (docSnap.exists()) {
            const dailyStats = docSnap.data() as UsageStatistics;
            return dailyStats.totalChatsCreated;
        } else {
            return 0;
        }
    }

    async getDailyTotalSentMessagesStats(uid: string) {
        const dailyStatsRef = doc(this.db, "Users", uid, "daily_stats", this.getCurrentDate());
        const docSnap = await getDoc(dailyStatsRef);
        if (docSnap.exists()) {
            const dailyStats = docSnap.data() as UsageStatistics;
            return dailyStats.totalMessagesSent;
        } else {
            return 0;
        }
    }

    async getDailyTotalRememberedMessagesStats(uid: string) {
        const dailyStatsRef = doc(this.db, "Users", uid, "daily_stats", this.getCurrentDate());
        const docSnap = await getDoc(dailyStatsRef);
        if (docSnap.exists()) {
            const dailyStats = docSnap.data() as UsageStatistics;
            return dailyStats.totalMessagesRemembered;
        } else {
            return 0;
        }
    }


    async getTotalSentMessagesStats(uid: string) {
        const totalStatsRef = doc(this.db, "Users", uid, "total_stats", "total_stats");
        const docSnap = await getDoc(totalStatsRef);
        if (docSnap.exists()) {
            const totalStats = docSnap.data() as UsageStatistics;
            return totalStats.totalMessagesSent;
        } else {

            return 0;
        }
    }

    async addNotication(uid: string, notification: NotificationInferface) {
        const notificationsRef = collection(this.db, "Users", uid, "notifications");
        // add  with timestamp
        await addDoc(notificationsRef, {
            notification: notification,
            serverTimestamp: serverTimestamp(),
        });
    }

    async setNotificationAsRead(uid: string, notificationId: string) {
        const notificationRef = doc(this.db, "Users", uid, "notifications", notificationId);
        // set notification.isNew to false
        await setDoc(notificationRef, {
            notification: {
                isNew: false,
            },
        }, { merge: true });

    }


    async fetchNotifications(uid: string) {
        const notificationsRef = collection(this.db, "Users", uid, "notifications");

        const notifications: NotificationFetchedFromDatabase[] = [];

        // Get the latest two chats ordered by serverTimestamp
        const querySnapshot = await getDocs(
            query(notificationsRef, orderBy("serverTimestamp", "desc"))
        );

        querySnapshot.forEach((doc) => {
            let notification: NotificationFetchedFromDatabase = {
                notification: doc.data().notification as NotificationInferface,
                firebaseDocumentId: doc.id,
            }
            notifications.push(notification);
        });



        return notifications;
        // er moet een listener op de notifications collection komen in een context provider, maar dit moet ook al ggeteched wordenn bij het eerste fetchen van profiel

    }

    async deleteNotification(uid: string, notificationId: string) {
        const notificationRef = doc(this.db, "Users", uid, "notifications", notificationId);
        await deleteDoc(notificationRef);
    }


    // This is necessary because Date().toLocaleDateString() does not work as firebase Id:
    getCurrentDate() {
        const timestamp = Timestamp.now();
        const date = timestamp.toDate();
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        return formattedDate;
    }

    dateToFirebaseDateId(date: Date) {
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        return formattedDate;
    }

    dateFromFirebaseDateId(dateId: string) {
        const dateParts = dateId.split("-");
        const date = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
        return date;
    }

    async logInsightReportGenerated(uid: string, subscriptionID: SubscriptionID) {
        const totalStatsRef = doc(this.db, "Users", uid, "total_stats", "total_stats");
        // check if document exists
        const totalDocSnap = await getDoc(totalStatsRef);
        if (totalDocSnap.exists()) {
            await updateDoc(totalStatsRef, {
                totalInsightReportsGenerated: increment(1),
            });
        }
        else {
            const newTotalStats: UsageStatistics = {
                totalChatsCreated: 0,
                totalMessagesSent: 0,
                totalInsightReportsGenerated: 1,
                totalMessagesRemembered: 0,
            }
            await setDoc(totalStatsRef, newTotalStats);
        }
        if (subscriptionID === "trial") {
            return
        }
        const dailyStatsRef = doc(this.db, "Users", uid, "daily_stats", this.getCurrentDate());
        // check if document exists
        const docSnap = await getDoc(dailyStatsRef);
        if (docSnap.exists()) {
            await updateDoc(dailyStatsRef, {
                totalInsightReportsGenerated: increment(1),
            });
        } else {
            const newDailyStats: UsageStatistics = {
                totalChatsCreated: 0,
                totalMessagesSent: 0,
                totalInsightReportsGenerated: 1,
                totalMessagesRemembered: 0,
            }
            await setDoc(dailyStatsRef, newDailyStats);
        }
    }

    async logMessageSent(uid: string, subscriptionID: SubscriptionID) {
        const totalStatsRef = doc(this.db, "Users", uid, "total_stats", "total_stats");
        // check if document exists
        const totalDocSnap = await getDoc(totalStatsRef);
        if (totalDocSnap.exists()) {
            await updateDoc(totalStatsRef, {
                totalMessagesSent: increment(1),
            });
        }
        else {
            const newTotalStats: UsageStatistics = {
                totalChatsCreated: 0,
                totalMessagesSent: 1,
                totalInsightReportsGenerated: 0,
                totalMessagesRemembered: 0,
            }
            await setDoc(totalStatsRef, newTotalStats);
        }
        if (subscriptionID === "trial") {
            return
        }
        const dailyStatsRef = doc(this.db, "Users", uid, "daily_stats", this.getCurrentDate());
        // check if document exists
        const docSnap = await getDoc(dailyStatsRef);
        if (docSnap.exists()) {
            await updateDoc(dailyStatsRef, {
                totalMessagesSent: increment(1),
            });
        } else {
            const newDailyStats: UsageStatistics = {
                totalChatsCreated: 0,
                totalMessagesSent: 1,
                totalInsightReportsGenerated: 0,
                totalMessagesRemembered: 0,
            }
            await setDoc(dailyStatsRef, newDailyStats);
        }


    }


    async logChatCreated(uid: string) {
        const totalStatsRef = doc(this.db, "Users", uid, "total_stats", "total_stats");
        // check if document exists
        const totalDocSnap = await getDoc(totalStatsRef);
        if (totalDocSnap.exists()) {
            await updateDoc(totalStatsRef, {
                totalChatsCreated: increment(1),
            });
        }
        else {
            const newTotalStats: UsageStatistics = {
                totalChatsCreated: 1,
                totalMessagesSent: 0,
                totalInsightReportsGenerated: 0,
                totalMessagesRemembered: 0,
            }
            await setDoc(totalStatsRef, newTotalStats);
        }

        const dailyStatsRef = doc(this.db, "Users", uid, "daily_stats", this.getCurrentDate());
        // check if document exists
        const docSnap = await getDoc(dailyStatsRef);
        if (docSnap.exists()) {
            await updateDoc(dailyStatsRef, {
                totalChatsCreated: increment(1),
            });
        } else {
            const newDailyStats: UsageStatistics = {
                totalChatsCreated: 1,
                totalMessagesSent: 0,
                totalInsightReportsGenerated: 0,
                totalMessagesRemembered: 0,
            }
            await setDoc(dailyStatsRef, newDailyStats);
        }
    }

    async logMessageRemembered(uid: string) {
        const dailyStatsRef = doc(this.db, "Users", uid, "daily_stats", this.getCurrentDate());
        // check if document exists
        const docSnap = await getDoc(dailyStatsRef);
        if (docSnap.exists()) {
            await updateDoc(dailyStatsRef, {
                totalMessagesRemembered: increment(1),
            });
        } else {
            const newDailyStats: UsageStatistics = {
                totalChatsCreated: 0,
                totalMessagesSent: 0,
                totalInsightReportsGenerated: 0,
                totalMessagesRemembered: 1,
            }
            await setDoc(dailyStatsRef, newDailyStats);
        }

        const totalStatsRef = doc(this.db, "Users", uid, "total_stats", "total_stats");
        // check if document exists
        const totalDocSnap = await getDoc(totalStatsRef);
        if (totalDocSnap.exists()) {
            await updateDoc(totalStatsRef, {
                totalMessagesRemembered: increment(1),
            });
        }
        else {
            const newTotalStats: UsageStatistics = {
                totalChatsCreated: 0,
                totalMessagesSent: 0,
                totalInsightReportsGenerated: 0,
                totalMessagesRemembered: 1,
            }
            await setDoc(totalStatsRef, newTotalStats);
        }
    }


    async deleteChat(uid: string, chat: Chat) {

        const chatRef = doc(this.db, "Users", uid, "chats", chat.databaseId);
        const messageCollectionRef = collection(chatRef, "messages");
        // Delete all the documents in the message collection
        const messageQuerySnapshot = await getDocs(messageCollectionRef);
        messageQuerySnapshot.forEach((doc) => {
            deleteDoc(doc.ref);
        });

        await deleteDoc(chatRef);

    }

    async getChatData(uid: string, chatId: string) {
        try {
            const chatRef = doc(this.db, "Users", uid, "chats", chatId);
            const docSnap = await getDoc(chatRef);
            const data = docSnap.data();
            const chatName = data?.metadata.name;

            // get date of last message sent
            const messagesRef = collection(chatRef, "messages");
            const querySnapshot = await getDocs(
                query(
                    messagesRef,
                    orderBy('timestamp', 'desc'),
                    limit(1)
                )
            );
            const lastMessage = querySnapshot.docs.map((doc) => (
                doc.data()
            ))[0];
            const chatData: Chat = {
                name: chatName,
                lastMessageDateInMilliseconds: lastMessage ? lastMessage.timestamp.seconds * 1000 : new Date().getTime(),
                insights: data?.insights?.insights,
                databaseId: chatId,

            }
            return chatData;
        } catch (error) {
            throw new Error('error')
        }
    }

    async getChatName(uid: string, chatId: string) {
        try {
            const chatRef = doc(this.db, "Users", uid, "chats", chatId);
            const docSnap = await getDoc(chatRef);
            const data = docSnap.data();
            const chatName = data?.metadata.name;
            return chatName;
        } catch (error) {
            throw new Error('error')
        }
    }


    async getChatMetaData(uid: string, chadId: string) {
        try {
            const chatRef = doc(this.db, "Users", uid, "chats", chadId);
            const docSnap = await getDoc(chatRef);
            const data = docSnap.data();

            const chatName = data?.metadata.name;
            // get date of last message sent
            const messagesRef = collection(chatRef, "messages");
            const querySnapshot = await getDocs(
                query(
                    messagesRef,
                    orderBy('timestamp', 'desc'),
                    limit(1)
                )
            );
            const lastMessage = querySnapshot.docs.map((doc) => (
                doc.data()
            ))[0];
            const metadata = {
                name: chatName,
                lastMessageDateInMilliseconds: lastMessage ? lastMessage.timestamp.seconds * 1000 : new Date().getTime(),
                insights: data?.insights?.insights,
            }
            return metadata;
        }
        catch (error: any) {
            throw new Error(error)
        }
    }

    async getAllChats(uid: string) {
        const chatsRef = collection(this.db, "Users", uid, "chats");
        // Get the latest two chats ordered by serverTimestamp
        const querySnapshot = await getDocs(
            query(chatsRef, orderBy("serverTimestamp", "desc"))
        );

        const chatIds: string[] = [];
        querySnapshot.forEach((doc) => {
            chatIds.push(doc.id);
        });

        const chats: Chat[] = [];
        for (let chatId of chatIds) {
            try {
                let metadata = await this.getChatData(uid, chatId);
                let chat: Chat = {
                    databaseId: chatId,
                    name: metadata.name,
                    lastMessageDateInMilliseconds: metadata.lastMessageDateInMilliseconds,
                    insights: metadata?.insights,
                };
                chats.push(chat);
            } catch (error) {
                continue;
            }
        }
        return chats;
    }

    async getLimitedChats(uid: string) {
        const chatsRef = collection(this.db, "Users", uid, "chats");

        // Get the latest two chats ordered by serverTimestamp
        const querySnapshot = await getDocs(
            query(chatsRef, orderBy("serverTimestamp", "desc"), limit(10))
        );

        const chatIds: string[] = [];
        querySnapshot.forEach((doc) => {
            chatIds.push(doc.id);
        });

        const chats: Chat[] = [];
        for (let chatId of chatIds) {
            try {
                let metadata = await this.getChatData(uid, chatId);
                let chat: Chat = {
                    databaseId: chatId,
                    name: metadata.name,
                    lastMessageDateInMilliseconds: metadata.lastMessageDateInMilliseconds,
                    insights: metadata?.insights,
                };
                chats.push(chat);
            } catch (error) {
                continue;
            }
        }
        return chats;
    }



    async countTotalChatsCurrentlyAvailable(uid: string) {
        const coll = collection(this.db, "Users", uid, 'chats');
        const snapshot = await getCountFromServer(coll);
        return snapshot.data().count;
    }

    async countTotalInsightReportsCurrentlyAvailable(uid: string) {
        const coll = collection(this.db, "Users", uid, 'insight_reports');
        const snapshot = await getCountFromServer(coll);
        return snapshot.data().count;
    }

    async countTotalMessagesRememberedCurrentlyAvailable(uid: string) {
        const coll = collection(this.db, "Users", uid, 'remembered_messages');
        const snapshot = await getCountFromServer(coll);
        return snapshot.data().count;
    }



    async getMoreChats(uid: string, lastChat: Chat) {
        const chatsRef = collection(this.db, "Users", uid, 'chats');
        const lastChatId = lastChat.databaseId;
        const lastChatSnapshot = await getDoc(doc(chatsRef, lastChatId));

        // get all the chats after the last chat
        const querySnapshot = await getDocs(
            query(
                chatsRef,
                orderBy("serverTimestamp", "desc"),
                startAfter(lastChatSnapshot),
                limit(10)
            )
        );
        const chatIds: string[] = [];
        querySnapshot.forEach((doc) => {
            chatIds.push(doc.id);
        });

        const chats: Chat[] = [];
        for (let chatId of chatIds) {
            try {
                let metadata = await this.getChatData(uid, chatId);
                let chat: Chat = {
                    databaseId: chatId,
                    name: metadata.name,
                    lastMessageDateInMilliseconds: metadata.lastMessageDateInMilliseconds,
                    insights: metadata?.insights,
                };
                chats.push(chat);
            } catch (error) {
                continue;
            }
        }
        return chats;

    }








    async updateChatName(uid: string, chatId: string, chatName: string) {
        const chatRef = doc(this.db, "Users", uid, "chats", chatId);
        const chat = {
            metadata: {
                name: chatName,
            },
        };
        await setDoc(chatRef, chat, { merge: true });

    }

    async updateUserReportName(uid: string, reportid: string, reportName: string) {
        const reportRef = doc(this.db, "Users", uid, "insight_reports", reportid);
        const report = {
            report: {
                chatName: reportName,
            },
        };
        await setDoc(reportRef, report, { merge: true });
    }



    async getMessages(uid: string, chatId: string) {
        const querySnapshot = await getDocs(
            query(
                collection(this.db, "Users", uid, 'chats', chatId, 'messages'),
                orderBy('timestamp', 'desc'),
                limit(75)
            )
        );
        const messages: FirebaseMessage[] = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            text: doc.data().text,
            sender: doc.data().sender,
            timestamp: doc.data().timestamp,
        })).reverse();
        return messages;
    }



    async getMessageCount(uid: string, chatId: string) {
        const coll = collection(this.db, "Users", uid, 'chats', chatId, 'messages');
        const snapshot = await getCountFromServer(coll);
        return snapshot.data().count;
    }


    async updateAvatar(uid: string, avatar: AvatarParams) {
        const docRef = doc(this.db, "Users", uid);
        await setDoc(docRef, { avatar: avatar }, { merge: true });
    }


    async updateLanguage(uid: string, language: Language) {
        const docRef = doc(this.db, "Users", uid);
        await setDoc(docRef, { language: language }, { merge: true });

    }

    async updateRegion(uid: string, region: Region) {
        const docRef = doc(this.db, "Users", uid);
        await setDoc(docRef, { region: region }, { merge: true });

    }

    async updatePersonality(uid: string, personality: Personality) {
        const docRef = doc(this.db, "Users", uid);
        await setDoc(docRef, { personality: personality }, { merge: true });

    }
    async updatePromoCode(uid: string, promoCode: PromoCode) {
        const docRef = doc(this.db, "Users", uid);
        await setDoc(docRef, { promoCode: promoCode }, { merge: true });
    }

    async updatePsychologistName(uid: string, psychologistName: string) {
        const docRef = doc(this.db, "Users", uid);
        await setDoc(docRef, { psychologistName: psychologistName }, { merge: true });


    }

    async updateTherapyStyle(uid: string, therapyStyle: TherapyStyle) {
        const docRef = doc(this.db, "Users", uid);
        await setDoc(docRef, { therapyStyle: therapyStyle }, { merge: true });
    }

    async updateLinkedHumanTherapist(uid: string, therapist: LinkedHumanTherapistData | null) {
        const docRef = doc(this.db, "Users", uid);
        await setDoc(docRef, { linkedHumanTherapist: therapist }, { merge: true });
    }




    async updateTypeSpeed(uid: string, typeSpeed: TypeSpeed) {
        const docRef = doc(this.db, "Users", uid);
        await setDoc(docRef, { typeSpeed: typeSpeed }, { merge: true });
    }

    async updateUserName(uid: string, userName: string) {
        const docRef = doc(this.db, "Users", uid);
        await setDoc(docRef, { userName: userName }, { merge: true });
    }


    async createCheckoutSession(uid: string, priceId: string) {
        const checkoutSessionRef = await addDoc(collection(this.db, "customers", uid, 'checkout_sessions'), {
            price: priceId,
            success_url: 'https://www.psyscribe.com/subscriptionBoughtScreen',
            cancel_url: 'https://www.psyscribe.com/homeScreen',

        });
        // Wait for the CheckoutSession to get attached by the extension
        onSnapshot(doc(this.db, "customers", uid, 'checkout_sessions', checkoutSessionRef.id), async (snap) => {
            const data = snap.data();
            if (data) {
                const { error, sessionId } = data;
                if (sessionId) {
                    // We have a session, let's redirect to Checkout
                    // Init Stripe
                    const stripe = await initializeStripe();
                    stripe?.redirectToCheckout({ sessionId });
                } else if (error) {
                    // No session, let's display the error message
                    alert(`An error occured: ${error.message}`);
                }
            }
        });
    }
    async getCustomerPortal() {
        const functionRef = httpsCallable(this.functions, 'ext-firestore-stripe-payments-createPortalLink');
        //const functionRef = this.functions
        //    .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
        //    
        const { data } = await functionRef({
            returnUrl: 'https://www.psyscribe.com/homeScreen',
            locale: "auto", // Optional, defaults to "auto" // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
        });
        //@ts-ignore
        window.location.assign(data.url);
    }

    async addFeedbackReport(feedbackReport: FeedbackReport) {
        const colRef = collection(this.db, "feedback_reports");
        const feedbackRapportAndTimestamp = {
            feedbackReport: feedbackReport,
            serverTimestamp: serverTimestamp(),
        };

        addDoc(colRef,

            feedbackRapportAndTimestamp
        );
    }

    async getFeedbackReports() {
        // ordered  by serverTimestamp
        const querySnapshot = await getDocs(
            query(
                collection(this.db, "feedback_reports"),
                orderBy('serverTimestamp', 'desc'),
                limit(100)
            )
        );
        const feedbackReports: FeedbackReport[] = querySnapshot.docs.map((doc) => (
            doc.data().feedbackReport
        ));
        return feedbackReports;
    }






    /*
        async getSubscriptionID(): Promise<SubscriptionID> {
            await this.auth.currentUser?.getIdToken(true);
            const decodedToken = await this.auth.currentUser?.getIdTokenResult();
            const role = decodedToken?.claims?.stripeRole
            if (role) {
                return role;
            }
            return 'trial'
        }
        * */

    async getSubscriptionInfo(uid: string) {
        try {
            const collectionRef = collection(this.db, "customers", uid, 'subscriptions');
            const querySnapshot = await getDocs(
                query(
                    collectionRef,
                    where('status', 'in', ['trialing', 'active'])
                )
            );
            const subscriptions = querySnapshot.docs.map((doc) => (
                doc.data()

            ));


            let most_expensive_subscription: any = null;
            let most_expensive_subscription_role = ""
            subscriptions.forEach((subscription) => {
                let role = subscription.role
                // check if role starts with therapist  
                if (!role.startsWith("therapist")) {
                    if (most_expensive_subscription == null) {
                        most_expensive_subscription = subscription
                        most_expensive_subscription_role = role
                    }
                    else if (most_expensive_subscription_role === "trial") {
                        if (role === "starter" || role === "essential") {
                            most_expensive_subscription = subscription
                            most_expensive_subscription_role = role
                        }
                    }
                    else if (most_expensive_subscription_role === "starter") {
                        if (role === "essential") {
                            most_expensive_subscription = subscription
                            most_expensive_subscription_role = role
                        }
                    }
                }

            })


            return most_expensive_subscription;
        } catch (error) {
        }
    }
    /**
    async getSubscriptionInfo(uid: string) {
        try {
            const collectionRef = collection(this.db, "customers", uid, 'subscriptions');
            const querySnapshot = await getDocs(
                query(
                    collectionRef,
                    where('status', 'in', ['trialing', 'active']),
                    limit(1)
                )
            );
            const subscription = querySnapshot.docs.map((doc) => (
                doc.data()

            ));
            return subscription[0];
        } catch (error) {
        }
    }
     */


    async getTotalMessagesWithoutInsights(uid: string, chatId: string) {
        const count = await this.getMessageCount(uid, chatId)
        const chatRef = doc(this.db, "Users", uid, "chats", chatId);
        const docSnap = await getDoc(chatRef);
        const data = docSnap.data();
        if (data?.insights?.index_last_summarized_message) {
            return Math.floor((count - data.insights.index_last_summarized_message) / 2)
                ;
        }
        return Math.floor(count / 2);
    }


    async signOut() {
        this.auth.signOut();
    }

    async deleteAllChatData(uid: string) {
        const chatsRef = collection(this.db, "Users", uid, "chats");
        const querySnapshot = await getDocs(chatsRef);
        querySnapshot.forEach(async (doc) => {
            const messageCollectionRef = collection(doc.ref, "messages");
            // Delete all the documents in the message collection
            const messageQuerySnapshot = await getDocs(messageCollectionRef);
            messageQuerySnapshot.forEach((doc) => {
                deleteDoc(doc.ref);
            });
            deleteDoc(doc.ref);
        });
    }

    async deleteAllMoodData(uid: string) {
        const moodRef = collection(this.db, "Users", uid, "moods");
        const querySnapshot = await getDocs(moodRef);
        querySnapshot.forEach(async (doc) => {
            const messageCollectionRef = collection(doc.ref, "moods");
            // Delete all the documents in the message collection
            const messageQuerySnapshot = await getDocs(messageCollectionRef);
            messageQuerySnapshot.forEach((doc) => {
                deleteDoc(doc.ref);
            });
            deleteDoc(doc.ref);
        });

    }

    async deleteAllDailyStats(uid: string) {
        const dailyStatsRef = collection(this.db, "Users", uid, "daily_stats");
        const querySnapshot = await getDocs(dailyStatsRef);
        querySnapshot.forEach(async (doc) => {
            deleteDoc(doc.ref);
        });

    }


    async deleteAllTotalStats(uid: string) {
        const totalStatsRef = doc(this.db, "Users", uid, "total_stats", "total_stats");
        await deleteDoc(totalStatsRef);
    }

    async deleteAllNotifications(uid: string) {
        const notificationsRef = collection(this.db, "Users", uid, "notifications");
        const querySnapshot = await getDocs(notificationsRef);
        querySnapshot.forEach(async (doc) => {
            deleteDoc(doc.ref);
        });
    }


    async deleteAllInsightReports(uid: string) {
        const insightReportsRef = collection(this.db, "Users", uid, "insight_reports");
        const querySnapshot = await getDocs(insightReportsRef);
        querySnapshot.forEach(async (doc) => {
            deleteDoc(doc.ref);
        });
    }



    async deleteRememberedMessages(uid: string) {

        const rememberedMessagesRef = collection(this.db, "Users", uid, "remembered_messages");
        const querySnapshot = await getDocs(rememberedMessagesRef);
        querySnapshot.forEach(async (doc) => {
            deleteDoc(doc.ref);
        });
    }


    async deleteAccount() {

        // delete all user data
        const uid = this.auth.currentUser?.uid;
        if (uid) {
            const userRef = doc(this.db, "Users", uid);

            await this.deleteAllChatData(uid);

            await this.deleteAllMoodData(uid);


            await this.deleteAllDailyStats(uid);
            await this.deleteAllTotalStats(uid);
            await this.deleteAllNotifications(uid);
            await this.deleteAllInsightReports(uid);
            await this.deleteRememberedMessages(uid);

            await deleteDoc(userRef);

        }
        const user = this.auth.currentUser;
        if (user) {

            this.auth.signOut();
            await user.delete();
           
            
        }
        //this.auth.signOut();

    }
}

export default FirebaseInterface


