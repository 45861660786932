import { useContext, useEffect, useState } from "react";
import { Button, Flex,  Text } from "@chakra-ui/react";
import { lavenderStyle, Theme } from "../../../styles/styles";
import { NotificationFetchedFromDatabase } from "../../../types/types";
import { faBell, faLightbulb, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteButton from "../confirmationButtons/DeleteButton";
import CancelButton from "../confirmationButtons/CancelButton";
import { FirebaseContext } from "../../../contexts/firebaseProvider";
import { UserProfileContext } from "../../../contexts/userProfileContextProvider";
import { useNavigate } from "react-router-dom";

interface NotificationProps {
  notification: NotificationFetchedFromDatabase;
}

function Notification(props: NotificationProps) {
  const [theme] = useState<Theme>(lavenderStyle);
  const [icon, setIcon] = useState(faBell);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const firebaseInterface = useContext(FirebaseContext);
  const { setUserProfile , currentUser} = useContext(UserProfileContext);
  const navigate = useNavigate();




  useEffect(() => {
    switch (props.notification.notification.category) {
      case "insights":
        setIcon(faLightbulb);
        break;
      case "subscription":
        setIcon(faLightbulb);
        break;
      case "info":
        setIcon(faLightbulb);
        break;
      case "memory":
        setIcon(faLightbulb);
        break;
      default:
        setIcon(faBell);
        break;
    }
  }, [props.notification]);

  const handleConfirmDelete = () => {
    if (!currentUser) return;
    firebaseInterface.deleteNotification(currentUser.uid, props.notification.firebaseDocumentId);
    setUserProfile((oldProfile) => {
      return {
        ...oldProfile,
        notifications: oldProfile.notifications.filter((notification) => {
          return notification.firebaseDocumentId !== props.notification.firebaseDocumentId;
        }
        )
      }
    })
    setShowConfirmDelete(false);  
    // Handle the delete action here
  };


  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  };

  const handleDeleteClick = () => {
    setShowConfirmDelete(true);
  };

  const handleNotificationClicked = () => {
    const routeToNavigateTo = props.notification.notification.onClickNavigateTo;
    if(routeToNavigateTo){
      navigate(routeToNavigateTo)


    }  
  };


  return (
    <Flex width='100%' justifyContent={'center'} borderBottomColor={theme.secondaryColor} borderBottomWidth='1px'>
      {showConfirmDelete ? (
        <Flex p={2}>
          <DeleteButton onClick={handleConfirmDelete} size='sm' />
          <CancelButton onClick={handleCancelDelete} size='sm' />

        </Flex>
      ) :
        <Flex
          p={2}
          display="flex"
          justifyContent="flex-start"
          borderRadius="md"
          width={'100%'}
          transition="background-color 0.3s"
          cursor="pointer"
          onClick={handleNotificationClicked}
          _hover={{ bg: theme.fifthColor }}
        >
          <Flex align="center">
            <FontAwesomeIcon icon={icon} color={theme.primaryColor} />

            <Text
              ml={5}
              overflowWrap="break-word"
              wordBreak="break-word"
              maxWidth="100%"
            >
              {props.notification.notification.message}
            </Text>

          </Flex>

        </Flex>
      }
      {!showConfirmDelete && (
        <Button
          ml="auto"
          variant="ghost"
          colorScheme={theme.colorScheme}
          onClick={handleDeleteClick}
          size='sm'
        >
          <FontAwesomeIcon icon={faTimes} size='sm' />
        </Button>
      )}
    </Flex>
  );
}

export default Notification;
