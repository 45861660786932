import React, { useEffect, useRef, useState } from 'react';
import { Flex,  Textarea } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../../styles/styles';

interface PlannedActionProps {
  statement: string;
  category: string;
  onStatementChange: (statement: string) => void;

  
}

function PlannedActionCard(props: PlannedActionProps) {
  const [editableStatement, setEditableStatement] = useState(props.statement);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [theme] = useState<Theme>(lavenderStyle)


  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleStatementChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableStatement(event.target.value);
    adjustTextareaHeight();
    props.onStatementChange(event.target.value);
  };

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight();
    }
    setEditableStatement(props.statement);
  }, [props.statement]);

  return (
    <Flex
      width="100%"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="sm"
      position="relative"
      flexDir="column"

      borderColor={theme.thirdColor}
    >
      <Flex
        width="100%"
        pt={3}
        pl={3}
        pr={3}
        pb={3}
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        columnGap={"20px"}
        
      >
        <Textarea
          value={editableStatement}
          onChange={handleStatementChange}
          rows={editableStatement.split('\n').length || 1}
          style={{ resize: 'none', width: '100%' }}
          overflow="hidden"
          placeholder="Type here"
          ref={textareaRef}
        />
      </Flex>



   
    </Flex>
  );
}

export default PlannedActionCard;
