import { CheckIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import i18next, { t } from "i18next";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";
import { SubscriptionID, SubscriptionPlan } from "../../types/types";

interface SubscriptionPlanCardProps {
    plan: SubscriptionPlan
    isSelected: boolean
    setSelectedPlan: (value: SubscriptionID) => void;
    isCurrentPlan: boolean
    currency: '€' | '$'
}

function SubscriptionPlanCard(props: SubscriptionPlanCardProps) {
    const [theme] = useState<Theme>(lavenderStyle)
    const bg = useColorModeValue("white", "gray.900");
    const activeBg = theme.fourthColor
    const textColor = "black"

    return (

        <Flex
            p="4"
            flexDir='column'
            bg={props.isSelected ? activeBg : bg}
            color={textColor}
            borderRadius="md"
            borderColor={theme.thirdColor}
            borderWidth={props.isSelected ? "2px" : "1px"}
            cursor="pointer"
            transition="background-color 0.2s"
            shadow={"md"}
            width={"310px"}
            height={"250px"}
            onClick={() => {
                props.setSelectedPlan(
                    props.plan.id
                )
            }
            }
        >

            <Flex width={"100%"} height='60px' justifyContent={"center"} alignItems='center' flexDir="column" >
                {props.isCurrentPlan && (<Text mb="2" mr='2' casing={'uppercase'} >{t('SubscriptionPan_current_subscription_label')}</Text>)}
                <Text casing={'uppercase'} fontWeight="bold" fontSize={'xl'} color={theme.primaryColor}>
                    {i18next.language === 'nl' ? props.plan.displayNameNL : props.plan.displayName}
                </Text>
            </Flex>
           
                <>
                    <Flex  alignItems="center" mt="2">
                        <Box
                            as={CheckIcon}
                            color={
                                props.isSelected ? theme.completementaryColor1 : "gray.300"
                            }
                            mr="3"
                        />
                        <Text   width='40px'>{props.plan.limitDailyMessagesSent < 200? props.plan.limitDailyMessagesSent:t('SubscriptionPlanCard_unlimited_label')}</Text>
                        <Text >{props.plan.id !=='trial'? t('SubscriptionPlanCard_daily_messages_label') : t('SubscriptionPlanCard_free_messages_label')}</Text>
                    </Flex>
                
                  
                    <Flex  alignItems="center" mt="2">
                        <Box
                            as={CheckIcon}
                            color={
                                props.isSelected ? theme.completementaryColor1 : "gray.300"
                            }
                            mr="3"
                        />
                        <Text justifyContent={'center'} alignItems='center' width='40px'>{props.plan.limitTotalRememberedMessagesAtTheSameTime <100?props.plan.limitTotalRememberedMessagesAtTheSameTime:t('SubscriptionPlanCard_unlimited_label')}</Text>
                        <Text  ml={i18next.language==='nl'?'8px':'0px'}>{t('SubscriptionPlanCard_longtermmemory_slots_label')}</Text>
                    </Flex>
              
                </>
            
            <Flex mt="auto" justifyContent="center" alignItems="center">
                {props.plan.price === 0 ? (<Text color='blackAlpha.700' fontSize={'lg'} fontWeight="bold">{t('SubscriptionPan_free_label')}</Text>)
                    : <Text  fontSize={'lg'} color='blackAlpha.700' fontWeight="bold">{props.currency} {props.plan.price} / {t('SubscriptionPan_month_label')}</Text>
                }

            </Flex>
        </Flex>
    )
}
export default SubscriptionPlanCard;