import { Button } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../../styles/styles";

interface DeleteButtonProps {
    onClick?: any;
    size?: string
}

function DeleteButton(props: DeleteButtonProps) {
    const [theme] = useState<Theme>(lavenderStyle)

    return (
        <Button
            colorScheme={theme.colorScheme}
            variant={"solid"}
            onClick={props.onClick}
            marginRight="10px"
            size={props.size? props.size : "md"}
        >
                 {t('DeleteButton_text')}
        </Button>
    );
}

export default DeleteButton;