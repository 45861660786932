import { useRef, useEffect } from "react";
import { lavenderStyle } from "../../styles/styles";
import { AvatarCustomizationCategories } from "../../types/types";

import "./Avatar.css";
interface AvatarCustomizationOptionProps {
    avatarOptions: AvatarCustomizationCategories;
    isCurrentlySelected: boolean;
    onClick?: () => void;
}

function AvatarCustomizationOption(props: AvatarCustomizationOptionProps) {


    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        const canvas = canvasRef.current
        if (!canvas) return;
        const context = canvas.getContext('2d')
        
       
        canvas.width = 200
        canvas.height = 200

        if ((props.isCurrentlySelected)) {
            if (context != null) {
                drawAvatarSelected(context)
            }
        }
   
        if (context != null) {
            context.imageSmoothingEnabled = false;
            
            drawAvatar(context, props.avatarOptions, canvas.width, canvas.height)
        }
    })
    return <canvas ref={canvasRef} className="avatar_customization" onClick={props.onClick} />;
}
async function drawAvatar(context: CanvasRenderingContext2D, avatarOptions: AvatarCustomizationCategories, canvasWidth: number, canvasHeight: number) {
    //drawAvatarHead(context, avatar_options, cwidth, cheight)
    avatarOptions.customizationCategory.renderAsOption(context, canvasWidth, canvasHeight)
    
}
const theme = lavenderStyle

function drawAvatarSelected(context: CanvasRenderingContext2D) {
    context.globalAlpha = 0.3;
    context.strokeStyle = theme.primaryColor;
    context.lineWidth = 5;
    context.strokeRect(0, 0, 200, 200)
    context.globalAlpha = 1.0;
}

export default AvatarCustomizationOption