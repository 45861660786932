

class AvatarColor {

    colorCode;
    img;

    constructor(colorCode) {
        this.colorCode = colorCode;
    }

    colorMap = {
        "cauca_skin": "#FFDAD4",
        "brown_skin": "#EDB399",
        "black_skin": "#90554B",
        "black_hair": "#1A1A1A",
        "brown_hair": "#562501",
        "blond_hair": "#FFDB80",
        "gray_hair": "#C9C9C9",
        
    }


    renderAsOption(context, canvas_width, canvas_height) {

        let radius = Math.min(canvas_width, canvas_height) / 2;
        radius = radius * 0.6;
        // set the fill style to the color code
        context.fillStyle = this.colorMap[this.colorCode];

        // draw a circle with the specified radius
        context.beginPath();
        context.arc(canvas_width / 2, canvas_height / 2, radius, 0, 2 * Math.PI);
        context.fill();

    }
}



export default AvatarColor