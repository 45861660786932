import { useState, } from "react";
import { Button, VStack } from "@chakra-ui/react";
import { EU,  US } from 'country-flag-icons/react/3x2'
import { lavenderStyle, Theme } from "../../styles/styles";
import { Region } from "../../types/types";
import { t } from "i18next";

interface RegionSelectorProps {
  setRegion: (region: Region) => void
  selectedRegion: Region
}

const RegionSelector = (props:RegionSelectorProps) => {
  const [theme] = useState<Theme>(lavenderStyle)

 


  return (
    <VStack spacing={5} width="100%" borderWidth={"1px"} padding="4" borderRadius={"5"} borderColor={theme.fourthColor}>
      <Button
      colorScheme={theme.colorScheme}
        width="100%"
        variant={props.selectedRegion === "us" ? "solid" : "outline"}
        onClick={() => props.setRegion("us")}
        justifyContent={'space-between'}
      >
        {t('RegionSettingScreen_region_us')}
        <US
          style={{ marginLeft: "10px", width: "24px", height: "24px" }}
        />
      </Button>
      <Button
         colorScheme={theme.colorScheme}
         width="100%"
        variant={props.selectedRegion ===  "eu" ? "solid" : "outline"}
        onClick={() => props.setRegion("eu")}
        justifyContent={'space-between'}
      >
                {t('RegionSettingScreen_region_eu')}

        <EU
          style={{ marginLeft: "10px", width: "24px", height: "24px" }}
        />
      </Button>
      

    </VStack>
  );
};

export default RegionSelector;
