import {
   Text, Radio, RadioGroup, Stack, Heading,
    Flex, Box,
} from '@chakra-ui/react'
import { Theme } from "../../styles/styles";

interface CustomizationRadioGroupProps {
    heading: string;
    radioValues: string[];
    theme: Theme;
    value: string;
    onChange: (value: string) => void;
    isDisabled?: boolean;
}

function CustomizationRadioGroup(props: CustomizationRadioGroupProps) {
    const theme = props.theme;

    let radios = [];
    for (let i = 0; i < props.radioValues.length; i++) {
        radios.push(<Radio key={i} size='sm' colorScheme={theme.colorScheme} value={i.toString()}>{<Text fontSize='sm' fontWeight="light" >{props.radioValues[i]}</Text>}</Radio>);
    }
    return (
        <Flex justifyContent='center' rowGap='10px' direction='column' as="span" width="100%">
            <Flex justifyContent='center' direction='row' width="100%"   >
                <Box display='inline-block' mr={2}>
                    <Heading color={theme.text1} fontSize='lg' >{props.heading}</Heading>
                </Box>
            </Flex>

            <RadioGroup onChange={props.onChange} value={props.value} isDisabled={props.isDisabled}>
                <Stack justifyContent='space-evenly' direction='row'>
                    {radios.map((radio) => { return radio })}
                </Stack>
            </RadioGroup>
        </Flex>

    )

}

export default CustomizationRadioGroup