import { useState } from "react";
import {
    Button,  Flex, Heading, Text
} from '@chakra-ui/react'
import { Link as Routerlink, useLocation,  } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
function InfoOverviewScreen() {
    const [theme] = useState<Theme>(lavenderStyle)

    const location = useLocation();

    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/homeScreen" /> </Flex>
            <Flex width={"100%"} maxWidth="1000px" justifyContent="center" flexDir="column" alignItems={"center"}> 
                <Heading size='lg' paddingTop='5px'  marginLeft="5px">
                    {t('InfoOverviewScreen_title')}
                </Heading>

                <Text fontStyle="italic" mt="2" maxWidth="600px">
                {t('InfoOverviewScreen_description')}
                </Text>


            </Flex>

            <Flex>

                <Flex width="100%" maxWidth="900px" flexDir="column" mt="40px" rowGap="40px">


                <Button as={Routerlink}     colorScheme={theme.colorScheme}   variant={"outline"}  state={{ from: location.pathname }} to="/manualScreen" >
                {t('InfoOverviewScreen_manual_button')}
                </Button>

                <Button as={Routerlink} colorScheme={theme.colorScheme}   variant={"outline"}  state={{ from: location.pathname }} to="/gdprScreen" >
                {t('InfoOverviewScreen_data_privacy_button')}
                </Button>

                <Button as={Routerlink} colorScheme={theme.colorScheme}   variant={"outline"}  state={{ from: location.pathname }} to="/contactInformationScreen" >
                {t('InfoOverviewScreen_contact_button')}
                </Button>
                </Flex>
         
            </Flex>
     


        </Flex >);

}



export default InfoOverviewScreen