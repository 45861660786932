import { useContext, useEffect, useState } from "react";
import {
    Button,
    Divider,
    Flex, Heading,  Spinner, Switch, Text
} from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { SubscriptionPlan } from "../../types/types";
import { Link as Routerlink, useLocation } from "react-router-dom";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { essentialPlan, starterPlan, trialPlan } from "../../data/subscriptions/subscriptionPlans";
import ProgressBar from "../../components/accountScreen/ProgressBar";
import SubscriptionPlanSelector from "../../components/subscriptionScreens/SubscriptionPlanSelector";
import PromoCodeInput from "../../components/subscriptionScreens/PromoCodeInput";

function ManageSubscriptionScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    // const [userSubscriptionPlan, setUserSubscriptionPlan] = useState<SubscriptionID>()
    const { userProfile, currentUser } = useContext(UserProfileContext);
    const [isEuroSelected, setIsEuroSelected] = useState<boolean>(userProfile.region === 'eu'); // Track the selected currency

    const location = useLocation();
    const [stripeLoading, setStripeLoading] = useState<boolean>(false)
    const [nextPaymentDate, setNextPaymentDate] = useState<string>("")
    const [isCanceled, setIsCanceled] = useState<boolean>(false)
    const firebaseInterface = useContext(FirebaseContext);
    const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState<SubscriptionPlan | null>(null)
    console.log(userProfile)

    const promoCodeValid = userProfile.promoCode && userProfile.promoCode.endDate > new Date()
    useEffect(() => {
        if (currentUser) {
            if (promoCodeValid) {
                setCurrentSubscriptionPlan(essentialPlan)
            }
            else {
                if (!userProfile.linkedHumanTherapist) {
                    firebaseInterface.getSubscriptionInfo(currentUser.uid).then((subscriptionInfo) => {
                        if (subscriptionInfo) {
                            switch (subscriptionInfo.role) {
                                case 'starter':
                                    setCurrentSubscriptionPlan(starterPlan)
                                    break;

                                case 'essential':
                                    setCurrentSubscriptionPlan(essentialPlan)
                                    break;
                            }
                            const firebaseTimestamp = subscriptionInfo.current_period_end; // Replace with your Firebase timestamp
                            const timestampInMillis = firebaseTimestamp.seconds * 1000; // Convert seconds to milliseconds
                            const date = new Date(timestampInMillis); // Create a Date object using the milliseconds
                            setNextPaymentDate(date.toDateString())
                            const canceledAtPeriodEnd = subscriptionInfo.cancel_at_period_end; // Replace with your Firebase timestamp
                            setIsCanceled(canceledAtPeriodEnd)
                        }
                        else {
                            setCurrentSubscriptionPlan(trialPlan)
                        }
                    })
                }
                else {
                    setCurrentSubscriptionPlan(essentialPlan)
                }

            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])





    async function manageSubscriptionPlan() {
        if (!currentUser) {
            throw new Error("no current user")
        }
        setStripeLoading(true)
        firebaseInterface.getCustomerPortal()
    }


    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen={location.state ? location.state.from : '/homeScreen'} /> </Flex>


            <Flex width={"100%"} flexDir={"column"} maxWidth={"900px"}>

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px' marginLeft="5px">
                        {t('AccountScreen_subscription_plan_label')}
                    </Heading>
                </Flex>

            </Flex>
            <Flex flexDir="column" maxWidth={"900px"} rowGap="10px" justifyContent="center" alignItems={"flex-start"} width="100%">



                <Divider width="100%" mt='2' mb='2' />
                <Flex flexDir="column" width='80%' columnGap={'10px'} rowGap='15px' justifyContent={'flex-start'} alignItems='flex-start'>

                    <Flex columnGap={'5px'}>
                        <Text fontSize='lg'
                            casing={'uppercase'} color={theme.primaryColor}>
                            {currentSubscriptionPlan ? userProfile.language === 'en' ? currentSubscriptionPlan.displayName : currentSubscriptionPlan.displayNameNL : <Spinner size="xs" />}
                        </Text>
                        {promoCodeValid &&
                            <Text fontSize='lg'
                                casing={'uppercase'} color={theme.thirdColor}> Promo Code</Text>

                        }
                    </Flex>

                    {!promoCodeValid && currentSubscriptionPlan && currentSubscriptionPlan.id !== 'trial' &&
                        <Flex columnGap='10px' flexDir={'row'} rowGap='10px'>
                            <Button colorScheme={theme.colorScheme} variant='outline' size='sm' onClick={manageSubscriptionPlan} isLoading={stripeLoading}> {t('AccountScreen_subscription_manage_subscription_button')}</Button>
                        </Flex>
                    }

                    {!promoCodeValid && currentSubscriptionPlan && currentSubscriptionPlan.id === 'trial' &&
                        <Button colorScheme={theme.colorScheme} variant='outline' size='sm' as={Routerlink} to="/subscriptionPlanOverviewScreen" state={{ from: location.pathname }} > Upgrade</Button>

                    }




                    {currentSubscriptionPlan && currentSubscriptionPlan.id === 'trial' &&
                        <Flex flexDir='column' rowGap={'10px'}>
                            <Flex columnGap={'5px'}>
                                <Text
                                    fontWeight={'bold'}
                                    fontStyle={'italic'}
                                > {t('AccountScreen_subscription_limit_label')}</Text>
                            </Flex>
                            <Flex columnGap={'5px'}>

                                <Text
                                    fontStyle={'italic'} fontWeight='light'
                                > {t('AccountScreen_subscription_limit_trail_messages_label')}</Text>
                                <ProgressBar current={userProfile.totalStats.totalMessagesSent} total={currentSubscriptionPlan?.limitTotalMessagesSentAllTime} />


                            </Flex>





                            <Flex columnGap={'5px'}>
                                <Text
                                    fontStyle={'italic'} fontWeight='light'
                                > {t('AccountScreen_subscription_limit_longterm_memory_label')}:</Text>
                                <ProgressBar current={userProfile.therapistSettings.rememberedMessages.length} total={currentSubscriptionPlan?.limitTotalRememberedMessagesAtTheSameTime} />
                            </Flex>
                        </Flex>
                    }

                    {promoCodeValid &&
                        <Flex columnGap={'5px'}>
                            <Text
                                fontWeight={'bold'}
                                fontStyle={'italic'}
                            > Promo geldig tot:</Text>
                            <Text>
                                {userProfile.promoCode?.endDate.toLocaleDateString()}
                            </Text>
                        </Flex>
                    }


                    {currentSubscriptionPlan && currentSubscriptionPlan.id !== 'trial' &&
                        <Flex flexDir='column' rowGap={'10px'}>
                            <Flex columnGap={'5px'}>
                                <Text
                                    fontWeight={'bold'}
                                    fontStyle={'italic'}
                                > {t('AccountScreen_subscription_limit_label')}</Text>
                            </Flex>
                            <Flex columnGap={'5px'}>

                                <Text
                                    fontStyle={'italic'} fontWeight='light'
                                > {t('AccountScreen_subscription_limit_daily_messages_label')}:</Text>
                                <ProgressBar current={userProfile.dailyStats.totalMessagesSent} total={currentSubscriptionPlan?.limitDailyMessagesSent} />


                            </Flex>




                            <Flex columnGap={'5px'}>

                                <Text
                                    fontStyle={'italic'} fontWeight='light'
                                > {t('AccountScreen_subscription_limit_longterm_memory_label')}:</Text>

                                <ProgressBar current={userProfile.therapistSettings.rememberedMessages.length} total={currentSubscriptionPlan?.limitTotalRememberedMessagesAtTheSameTime} />




                            </Flex>

                        </Flex>
                    }



                    {!promoCodeValid && currentSubscriptionPlan && currentSubscriptionPlan.id !== 'trial' &&
                        <Flex columnGap={'5px'}>
                            {!isCanceled ?
                                <Text
                                    fontWeight={'bold'}
                                    fontStyle={'italic'}
                                > {t('AccountScreen_subscription_next_payment_date_label')}</Text>
                                :
                                <Text
                                    fontWeight={'bold'}
                                    fontStyle={'italic'}
                                > {t('AccountScreen_subscription_subscription_end_date')}:</Text>
                            }

                            <Text>
                                {nextPaymentDate}
                            </Text>
                        </Flex>
                    }



                </Flex>

            </Flex>
            {!promoCodeValid &&
                <Flex mt='5' width={"100%"} flexDir={"column"} justifyContent='center' alignItems={'center'} maxWidth={"1500px"} onClick={userProfile.subscriptionId !== 'trial' ? manageSubscriptionPlan : () => { }} >
                    <Heading size='md'>Plans</Heading>

                    {!stripeLoading ?
                        <>
                            <SubscriptionPlanSelector currentPlan={currentSubscriptionPlan?.id ? currentSubscriptionPlan.id : "trial"} selectedPlan={currentSubscriptionPlan?.id ? currentSubscriptionPlan.id : "trial"} setSelectedPlan={() => { }} theme={theme} currency={isEuroSelected ? '€' : '$'} includeTrial={false} />
                            <Flex alignItems="center" mb='20px' mt="20px">
                                <Text mr="2">€ {isEuroSelected}</Text>
                                <Switch
                                    colorScheme={theme.colorScheme}
                                    isChecked={isEuroSelected}
                                    onChange={() => setIsEuroSelected(!isEuroSelected)}
                                />
                            </Flex>
                        </> :
                        <Spinner mt='40px' color={theme.thirdColor} size="xl" />
                    }
                </Flex>

            }
            {!promoCodeValid && !stripeLoading &&
            <Flex pb='40px'>
                <PromoCodeInput />
                </Flex>
            }

        </Flex >);
}



export default ManageSubscriptionScreen