import { Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";

interface LandingPageFeaturesProps {
    isMobile: boolean
}

function LandingPageFeatures(props: LandingPageFeaturesProps) {
    const [theme] = useState<Theme>(lavenderStyle)

    return (
        <Flex width={'100%'} flexDir='column' alignItems={'center'} mt='40px' bgColor={'#f6f6ff'} borderRadius={'xl'}>
            <Flex width='96%' justifyContent={'center'} >
                <Heading size={props.isMobile ? '2xl' : '3xl'} color={theme.primaryColor} mt='20px' >
                    {t('LandingPageFeatures_title')}
                </Heading>
            </Flex>


            <Divider width='80%' borderColor={theme.thirdColor} mt='20px'></Divider>
            <Heading as="h1" size={props.isMobile ? 'lg' : 'xl'} marginBottom={'10px'} marginTop={'30px'} color={'blackAlpha.800'} >
                {t('LandingPageFeatures_mental_health_chatbot_title')}
            </Heading>
            <Flex justifyContent='center' flexDir='column' maxWidth={'1000px'} width='95%' >
                <Text fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}>  {t('LandingPageFeatures_mental_health_chatbot_description')}</Text>
                <Flex mt="2" >
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <Text fontSize="sm" color="gray.500" ml="2" mt="-0.5"> {t('LandingPageScreen_warning_1')} </Text>
                </Flex>
                <Flex mt="2" >
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <Text fontSize="sm" color="gray.500" ml="2" mt="-0.5"> {t('ManualScreen_chatbot_warning_1')} </Text>
                </Flex>
            </Flex>

            <Divider width='80%' borderColor={theme.thirdColor} mt='40px'></Divider>

            <Heading as="h1" size={props.isMobile ? 'lg' : 'xl'} marginBottom={'10px'} marginTop={'30px'} color={'blackAlpha.800'}>
            {t('LandingPageFeatures_conversation_insights_title')}
            </Heading>
            <Flex justifyContent='center' maxWidth={'1000px'} color={'blackAlpha.800'} width='95%' >
                <Text fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}>
                {t('LandingPageFeatures_conversation_insights_description')}
                     </Text>
            </Flex>

            <Divider width='80%' borderColor={theme.thirdColor} mt='40px'></Divider>

            <Heading as="h1" size={props.isMobile ? 'lg' : 'xl'} marginBottom={'10px'} marginTop={'30px'} color={'blackAlpha.800'}>
                {t('LandingPageFeatures_personalized_approach_title')}
            </Heading>
            <Flex justifyContent='center' maxWidth={'1000px'} color={'blackAlpha.800'} width='95%' >
                <Text fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}>{t('LandingPageFeatures_personalized_approach_description')}  </Text>
            </Flex>

            <Divider width='80%' borderColor={theme.thirdColor} mt='40px'></Divider>

            <Heading as="h1" size={props.isMobile ? 'lg' : 'xl'} marginBottom={'10px'} marginTop={'30px'} color={'blackAlpha.800'}>
                {t('LandingPageFeatures_anonymous_title')}
            </Heading>
            <Flex alignItems='flex-start' maxWidth={'1000px'} color={'blackAlpha.800'} width='95%' >
                <Text fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}>        {t('LandingPageFeatures_anonymous_description')} </Text>
            </Flex>


            <Divider width='80%' borderColor={theme.thirdColor} mt='40px'></Divider>
            <Heading as="h1" size={props.isMobile ? 'lg' : 'xl'} marginBottom={'10px'} marginTop={'30px'} color={'blackAlpha.800'}>
                {t('LandingPageFeatures_always_available_title')}
            </Heading>
            <Flex justifyContent='center' maxWidth={'1000px'} width='95%' mb='40px'  >
                <Text fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}>
                    {t('LandingPageFeatures_always_available_description')}</Text>
            </Flex>

          


        </Flex>
    )

}
export default LandingPageFeatures;