import  { useState } from "react";
import AvatarFacialHair from "../avatar/AvatarFacialHair";
import AvatarHair from "../avatar/AvatarHair";
import AvatarGlasses from "../avatar/AvatarGlasses";
import AvatarClothes from "../avatar/AvatarClothes";
import AvatarGender from "../avatar/AvatarGender";
import AvatarEarrings from "../avatar/AvatarEarrings";
import { AvatarCustomizationCategories, AvatarParams } from "../../types/types";
import AvatarCustomizationOption from "../avatar/AvatarOption";
import { Slider, SliderTrack,  SliderThumb, Box, Flex, Grid, SliderMark, Text } from "@chakra-ui/react";
import AvatarColor from "../avatar/AvatarColor";
import { t } from "i18next";
import { lavenderStyle, Theme } from "../../styles/styles";


interface CustomizationRowProps {
    customizationType: string;
    avatarParams: AvatarParams;
    setOptionMethod: (option: string) => void;
}

function CustomizationRow(props: CustomizationRowProps) {
    // const [selected_option, setSelectedOption] = useState(1);
    const [selectedGenderOption, setSelectedGenderOption] = useState(props.avatarParams.chosenGender === "male"?1:2);
    const [selectedAgeOption, setSelectedAgeOption] = useState(props.avatarParams.chosenAge === "young"?1:props.avatarParams.chosenAge === "middle"?50:90);
    const [selectedSkinColorOption, setSelectedSkinColorOption] = useState(props.avatarParams.chosenSkinColor === "cauca"?1:props.avatarParams.chosenSkinColor === "brown"?2:3);
    const [selectedHairTypeOption, setSelectedHairTypeOption] = useState(props.avatarParams.chosenHairType === "none"?1:props.avatarParams.chosenHairType === "1"?2:props.avatarParams.chosenHairType === "2"?3:props.avatarParams.chosenHairType==="3"?4:5);
    const [selectedHairColorOption, setSelectedHairColorOption] = useState(props.avatarParams.chosenHairColor === "black"?1:props.avatarParams.chosenHairColor === "brown"?2:props.avatarParams.chosenHairColor === "blond"?3:props.avatarParams.chosenHairColor === "gray"?4:5);
    const [selectedFacialHairTypeOption, setSelectedFacialHairTypeOption] = useState(props.avatarParams.chosenFacialHairType === "none"?1:props.avatarParams.chosenFacialHairType === "1"?2:props.avatarParams.chosenFacialHairType === "2"?3:4);
    const [selectedGlassesTypeOption, setSelectedGlassesTypeOption] = useState(props.avatarParams.chosenGlassesType === "none"?1:props.avatarParams.chosenGlassesType === "1"?2:props.avatarParams.chosenGlassesType === "2"?3:props.avatarParams.chosenGlassesType==="3"?4:5);
    const [selectedEarringsTypeOption, setSelectedEarringsTypeOption] = useState(props.avatarParams.chosenEarRingsType === "none"?1:props.avatarParams.chosenEarRingsType === "1"?2:props.avatarParams.chosenEarRingsType === "2"?3:props.avatarParams.chosenEarRingsType==="3"?4:5);
    const [selectedClothesTypeOption, setSelectedClothesTypeOption] = useState(props.avatarParams.chosenClothesType === "1"?1:props.avatarParams.chosenClothesType === "2"?2:props.avatarParams.chosenClothesType === "3"?3:props.avatarParams.chosenClothesType==="4"?4:5);
    const [theme] = useState<Theme>(lavenderStyle)

    switch (props.customizationType) {
        case "chosenGender":
            const avatarGenderOption1: AvatarCustomizationCategories = {
                name: "avatarGender",
                customizationCategory: new AvatarGender("male"),
            }

            const avatarGenderOption2: AvatarCustomizationCategories = {
                name: "avatarGender",
                customizationCategory: new AvatarGender("female"),
            }

            return (<Grid templateColumns="repeat(2, 1fr)" justifyItems="center" gridGap={1}>
                <AvatarCustomizationOption avatarOptions={avatarGenderOption1} isCurrentlySelected={selectedGenderOption === 1} onClick={() => optionSelected(1, setSelectedGenderOption, "male", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarGenderOption2}  isCurrentlySelected={selectedGenderOption === 2} onClick={() => optionSelected(2, setSelectedGenderOption, "female", props.setOptionMethod)} />
            </Grid>)

        case "chosenAge":
            return (
                <Flex flexDir={"column"} justifyContent={"center"} alignItems={"center"} width="100%" height="70%" rowGap={"20px"}>
                    <Text>{t('AvatarCustomizationRow_age_radiogroup_title')}</Text>
                    <Slider
                        colorScheme={theme.colorScheme}
                        width="70%"
                        defaultValue={selectedAgeOption}
                        min={1}
                        max={100}
                        step={1}
                        onChange={(value) => {if(value <= 33){
                                                optionSelected(value, setSelectedAgeOption, "young", props.setOptionMethod)
                                                }
                                                else if(value <= 66){
                                                    optionSelected(value, setSelectedAgeOption, "middle", props.setOptionMethod)
                                                }
                                                else if(value <= 100){
                                                    optionSelected(value, setSelectedAgeOption, "old", props.setOptionMethod)
                                                }}}
                    >
                        <SliderMark value={1} {...{
                            mt: '4',
                            ml: '-5',
                            fontSize: 'sm',
                        }}>
                            {t('AvatarCustomizationRow_age_radio_young')}
                        </SliderMark>
                   
                        <SliderMark value={100} {...{
                            mt: '4',
                            ml: '-3',
                            fontSize: 'sm',
                        }}>
                             {t('AvatarCustomizationRow_age_radio_old')}
                        </SliderMark>

                        <SliderTrack bg="#DADDD8">
                        </SliderTrack>
                        <SliderThumb boxSize={6} border="1px solid gray">


                        </SliderThumb>
                    </Slider>
                    
                </Flex>

            )


        case "chosenFacialHairType":

            const avatarFacialHairOption1: AvatarCustomizationCategories = {
                name: "avatarFacialHair",
                customizationCategory: new AvatarFacialHair(props.avatarParams.chosenGender, "none", props.avatarParams.chosenHairColor),
            }

            const avatarFacialHairOption2: AvatarCustomizationCategories = {
                name: "avatarFacialHair",
                customizationCategory: new AvatarFacialHair(props.avatarParams.chosenGender, "1", props.avatarParams.chosenHairColor),
            }

            const avatarFacialHairOption3: AvatarCustomizationCategories = {
                name: "avatarFacialHair",
                customizationCategory: new AvatarFacialHair(props.avatarParams.chosenGender, "2", props.avatarParams.chosenHairColor),
            }

            return (<Grid templateColumns="repeat(3, 1fr)" justifyItems="center" gridGap={5}>
                <AvatarCustomizationOption avatarOptions={avatarFacialHairOption1} isCurrentlySelected={selectedFacialHairTypeOption === 1} onClick={() => optionSelected(1, setSelectedFacialHairTypeOption, "none", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarFacialHairOption2} isCurrentlySelected={selectedFacialHairTypeOption === 2} onClick={() => optionSelected(2, setSelectedFacialHairTypeOption, "1", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarFacialHairOption3} isCurrentlySelected={selectedFacialHairTypeOption === 3} onClick={() => optionSelected(3, setSelectedFacialHairTypeOption, "2", props.setOptionMethod)} />
            </Grid>)


        case "chosenHairType":

            const avatarHairOption1: AvatarCustomizationCategories = {
                name: "avatarHair",
                customizationCategory: new AvatarHair(props.avatarParams.chosenGender, "none", props.avatarParams.chosenHairColor),
            }

            const avatarHairOption2: AvatarCustomizationCategories = {
                name: "avatarHair",
                customizationCategory: new AvatarHair(props.avatarParams.chosenGender, "1", props.avatarParams.chosenHairColor),
            }

            const avatarHairOption3: AvatarCustomizationCategories = {
                name: "avatarHair",
                customizationCategory: new AvatarHair(props.avatarParams.chosenGender, "2", props.avatarParams.chosenHairColor),
            }

            const avatarHairOption4: AvatarCustomizationCategories = {
                name: "avatarHair",
                customizationCategory: new AvatarHair(props.avatarParams.chosenGender, "3", props.avatarParams.chosenHairColor),
            }

            const avatarHairOption5: AvatarCustomizationCategories = {
                name: "avatarHair",
                customizationCategory: new AvatarHair(props.avatarParams.chosenGender, "4", props.avatarParams.chosenHairColor),
            }

            if (props.avatarParams.chosenGender === "male") {
                return (<Grid templateColumns="repeat(3, 1fr)" justifyItems="center" gridGap={5}>
                    <AvatarCustomizationOption avatarOptions={avatarHairOption1} isCurrentlySelected={selectedHairTypeOption === 1} onClick={() => optionSelected(1, setSelectedHairTypeOption, "none", props.setOptionMethod)} />
                    <AvatarCustomizationOption avatarOptions={avatarHairOption2} isCurrentlySelected={selectedHairTypeOption === 2} onClick={() => optionSelected(2, setSelectedHairTypeOption, "1", props.setOptionMethod)} />
                    <AvatarCustomizationOption avatarOptions={avatarHairOption3} isCurrentlySelected={selectedHairTypeOption === 3} onClick={() => optionSelected(3, setSelectedHairTypeOption, "2", props.setOptionMethod)} />
                    <AvatarCustomizationOption avatarOptions={avatarHairOption4} isCurrentlySelected={selectedHairTypeOption === 4} onClick={() => optionSelected(4, setSelectedHairTypeOption, "3", props.setOptionMethod)} />
                    <AvatarCustomizationOption avatarOptions={avatarHairOption5} isCurrentlySelected={selectedHairTypeOption === 5} onClick={() => optionSelected(5, setSelectedHairTypeOption, "4", props.setOptionMethod)} />
                </Grid>)
            }
            else {
                return (<Grid templateColumns="repeat(3, 1fr)" justifyItems="center" gridGap={5}>
                    <AvatarCustomizationOption avatarOptions={avatarHairOption2} isCurrentlySelected={selectedHairTypeOption === 1} onClick={() => optionSelected(1, setSelectedHairTypeOption, "1", props.setOptionMethod)} />
                    <AvatarCustomizationOption avatarOptions={avatarHairOption3} isCurrentlySelected={selectedHairTypeOption === 2} onClick={() => optionSelected(2, setSelectedHairTypeOption, "2", props.setOptionMethod)} />
                    <AvatarCustomizationOption avatarOptions={avatarHairOption4} isCurrentlySelected={selectedHairTypeOption === 3} onClick={() => optionSelected(3, setSelectedHairTypeOption, "3", props.setOptionMethod)} />
                    <AvatarCustomizationOption avatarOptions={avatarHairOption5} isCurrentlySelected={selectedHairTypeOption === 4} onClick={() => optionSelected(4, setSelectedHairTypeOption, "4", props.setOptionMethod)} />
                </Grid>)

            }

        case "chosenHairColor":
            const avatarHairColorOption1: AvatarCustomizationCategories = {
                name: "hairColor",
                customizationCategory: new AvatarColor("black_hair"),
            }

            const avatarHairColorOption2: AvatarCustomizationCategories = {
                name: "hairColor",
                customizationCategory: new AvatarColor("brown_hair"),
            }

            const avatarHairColorOption3: AvatarCustomizationCategories = {
                name: "hairColor",
                customizationCategory: new AvatarColor("blond_hair"),
            }

            const avatarHairColorOption4: AvatarCustomizationCategories = {
                name: "hairColor",
                customizationCategory: new AvatarColor("gray_hair"),
               
            }

            return (<Grid templateColumns="repeat(3, 1fr)" justifyItems="center" gridGap={5}>
                <AvatarCustomizationOption avatarOptions={avatarHairColorOption1} isCurrentlySelected={selectedHairColorOption === 1} onClick={() => optionSelected(1, setSelectedHairColorOption, "black", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarHairColorOption2} isCurrentlySelected={selectedHairColorOption === 2} onClick={() => optionSelected(2, setSelectedHairColorOption, "brown", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarHairColorOption3} isCurrentlySelected={selectedHairColorOption === 3} onClick={() => optionSelected(3, setSelectedHairColorOption, "blond", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarHairColorOption4} isCurrentlySelected={selectedHairColorOption === 4} onClick={() => optionSelected(4, setSelectedHairColorOption, "gray" , props.setOptionMethod)} />
            </Grid>)

        case "chosenSkinColor":
           

            const avatarSkinColorOption1: AvatarCustomizationCategories = {
                name: "skinColor",
                customizationCategory: new AvatarColor("cauca_skin"),
            }


            const avatarSkinColorOption2: AvatarCustomizationCategories = {
                name: "skinColor",
                customizationCategory: new AvatarColor("brown_skin"),
            }

            const avatarSkinColorOption3: AvatarCustomizationCategories = {
                name: "skinColor",
                customizationCategory: new AvatarColor("black_skin"),
            }

        

            return (<Grid templateColumns="repeat(3, 1fr)" justifyItems="center" gridGap={5}>
                <AvatarCustomizationOption avatarOptions={avatarSkinColorOption1} isCurrentlySelected={selectedSkinColorOption === 1} onClick={() => optionSelected(1, setSelectedSkinColorOption, "cauca", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarSkinColorOption2} isCurrentlySelected={selectedSkinColorOption === 2} onClick={() => optionSelected(2, setSelectedSkinColorOption, "brown", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarSkinColorOption3} isCurrentlySelected={selectedSkinColorOption === 3} onClick={() => optionSelected(3, setSelectedSkinColorOption, "black", props.setOptionMethod)} />
            </Grid>)

        case "chosenGlassesType":
            const avatarGlassesOption1: AvatarCustomizationCategories = {
                name: "glasses",
                customizationCategory: new AvatarGlasses(props.avatarParams.chosenGender, "none"),
            }

            const avatarGlassesOption2: AvatarCustomizationCategories = {
                name: "glasses",
                customizationCategory: new AvatarGlasses(props.avatarParams.chosenGender, "1"),
            }

            const avatarGlassesOption3: AvatarCustomizationCategories = {
                name: "glasses",
                customizationCategory: new AvatarGlasses(props.avatarParams.chosenGender, "2"),
                
            }

            return (<Grid templateColumns="repeat(3, 1fr)" justifyItems="center" gridGap={5}>
                <AvatarCustomizationOption avatarOptions={avatarGlassesOption1} isCurrentlySelected={selectedGlassesTypeOption === 1} onClick={() => optionSelected(1, setSelectedGlassesTypeOption, "none", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarGlassesOption2} isCurrentlySelected={selectedGlassesTypeOption === 2} onClick={() => optionSelected(2, setSelectedGlassesTypeOption, "1", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarGlassesOption3} isCurrentlySelected={selectedGlassesTypeOption === 3} onClick={() => optionSelected(3, setSelectedGlassesTypeOption, "2", props.setOptionMethod)} />
            </Grid>)

        case "chosenClothesType":
            const avatarClothesOption1: AvatarCustomizationCategories = {
                name: "Clothes",
                customizationCategory: new AvatarClothes(props.avatarParams.chosenGender, "1")
            }

            const avatarClothesOption2: AvatarCustomizationCategories = {
                name: "Clothes",
                customizationCategory: new AvatarClothes(props.avatarParams.chosenGender, "2")
            }

            const avatarClothesOption3: AvatarCustomizationCategories = {
                name: "Clothes",
                customizationCategory: new AvatarClothes(props.avatarParams.chosenGender, "3")
            }

            const avatarClothesOption4: AvatarCustomizationCategories = {
                name: "Clothes",
                customizationCategory: new AvatarClothes(props.avatarParams.chosenGender, "4")
            }


            return (<Grid templateColumns="repeat(3, 1fr)" justifyItems="center" gridGap={5} >
                <AvatarCustomizationOption avatarOptions={avatarClothesOption1} isCurrentlySelected={selectedClothesTypeOption === 1} onClick={() => optionSelected(1, setSelectedClothesTypeOption, "1", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarClothesOption2} isCurrentlySelected={selectedClothesTypeOption === 2} onClick={() => optionSelected(2, setSelectedClothesTypeOption, "2", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarClothesOption3} isCurrentlySelected={selectedClothesTypeOption === 3} onClick={() => optionSelected(3, setSelectedClothesTypeOption, "3", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarClothesOption4} isCurrentlySelected={selectedClothesTypeOption === 4} onClick={() => optionSelected(4, setSelectedClothesTypeOption, "4", props.setOptionMethod)} />
            </Grid>)

        case "chosenEarRingsType":
            const avatarEarringsOption1: AvatarCustomizationCategories = {
                name: "Earrings",
                customizationCategory: new AvatarEarrings("none")
            }

            const avatarEarringsOption2: AvatarCustomizationCategories = {
                name: "Earrings",
                customizationCategory: new AvatarEarrings("1")
            }

            return (<Grid templateColumns="repeat(2, 1fr)" justifyItems="center" gridGap={5}>
                <AvatarCustomizationOption avatarOptions={avatarEarringsOption1} isCurrentlySelected={selectedEarringsTypeOption === 1} onClick={() => optionSelected(1, setSelectedEarringsTypeOption, "0", props.setOptionMethod)} />
                <AvatarCustomizationOption avatarOptions={avatarEarringsOption2} isCurrentlySelected={selectedEarringsTypeOption === 2} onClick={() => optionSelected(2, setSelectedEarringsTypeOption, "1", props.setOptionMethod)} />
            </Grid>)

        default:
            return (<Box></Box>)
    }
}
function optionSelected(selected: number, setSelectedOption: (arg1: number) => void, styleOption: string, setStyleOptionMethod: (arg1: string) => void) {
    setSelectedOption(selected);
    setStyleOptionMethod(styleOption);
}

export default CustomizationRow