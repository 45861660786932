
import {
  Flex,
  Heading,
  Switch,
  Text
} from "@chakra-ui/react";

import LandingPageHeader from "../../components/landingPageScreen/LandingPageHeader";
import { lavenderStyle, Theme } from "../../styles/styles";
import { useEffect, useState } from "react";
import LandingPageFooter from "../../components/landingPageScreen/LandingPageFooter";
import GetStartedButton from "../../components/landingPageScreen/GetStartedButton";
import SubscriptionPlanSelector from "../../components/subscriptionScreens/SubscriptionPlanSelector";
import i18next, { t } from "i18next";
import useScreenWidth from "../../utils/useScreenWidth";
import { Link as Routerlink } from "react-router-dom";


function LandingPagePricingScreen() {

  const [theme] = useState<Theme>(lavenderStyle)
  const [isEuroSelected, setIsEuroSelected] = useState<boolean>(i18next.language === 'nl'); // Track the selected currency

  const screenWidth = useScreenWidth();

  const [isMobile, setIsMobile] = useState<boolean>(false)
  useEffect(() => {
  
      if (screenWidth < 1000) {
         setIsMobile(true)
         }
      else {
          setIsMobile(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth])




  return (<Flex
    flexDirection="column"
    alignItems="center"
    width="100%">

    <LandingPageHeader isMobile={isMobile} />

    <Flex width='97%' flexDirection="column"
      justifyContent={'center'}
      alignItems="center" mt='100px' height={'100%'} >
      
      <Flex width='96%' justifyContent={'center'}>
      <Heading as="h1" size="xl" mb='20px' color={theme.primaryColor}>
         {t('LandingPagePricingScreen_title')}
      </Heading>
      </Flex>
      <Flex as={Routerlink} to="/login">
      <SubscriptionPlanSelector currentPlan={'admin'} selectedPlan={'admin'} setSelectedPlan={() => { }} theme={theme} currency={isEuroSelected ? '€' : '$'} includeTrial={true} />
      </Flex>
      <Flex alignItems={'center'} mt='20px'>
       
        <Switch
          colorScheme={theme.colorScheme}
          isChecked={isEuroSelected}
          onChange={() => setIsEuroSelected(!isEuroSelected)}
        />
         <Text fontWeight={'bold'} color='blackAlpha.700' ml="2">€ {isEuroSelected}</Text>
      </Flex>

      <GetStartedButton />



      
    </Flex>
    <Flex height={'100%'} width='100%' justifyContent='flex-end' alignItems={'flex-end'}>
        <LandingPageFooter />
      </Flex>

  </Flex>
  );
}

export default LandingPagePricingScreen;
