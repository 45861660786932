import { useRef, useState } from "react";
import {
    Flex
} from '@chakra-ui/react'
import { MessageType, TypeSpeed } from "../../types/types";
import Message from "../chat/Message";
import TestChatMessage from "./TestChatMessage";

interface MessageBoxProps {
    messages: any;
    theme: any;
    isWaitAnimationVisible: boolean;
    innerRef: any;
    typeSpeed: TypeSpeed
    handleMessageSave: (message: MessageType) => void;
    handleMessageLiked: (message: MessageType) => void;
    handleMessageDisliked: (message: MessageType) => void;
    isMobile: boolean;
}

function TestMessageBox(props: MessageBoxProps) {
    const elementRef = useRef<HTMLDivElement>(null);
    const theme = props.theme;
    const [typingMessage] = useState<MessageType>({
        message: "...",
        sender: "scroll",
        animation: true,
        timestampInSeconds: 0,
        isSaved: false
    });


  


    return (
        <Flex
            ref={elementRef}
            flexDirection="column"
            width="100%"
            height="100%"
            rowGap="12px"
            overflowY="auto"
            pb="40px"
            minHeight={props.isMobile?'255px':'230px'}
        >
            {props.messages.map((message: MessageType, index: number) => (
                <Flex flexDirection="column" key={index}>
                    {message.sender === 'psychologist' && (
                        <Flex width="100%" justifyContent="flex-start" columnGap="1px" paddingLeft="2px" >
                            <TestChatMessage key={index} theme={theme} message={message} typeSpeed={props.typeSpeed} showCursor={false} />
                        </Flex>
                    )}
                    {message.sender === 'user' && (
                        <Flex width="100%"
                            justifyContent="flex-end"
                        >
                            <Message key={index} theme={theme} message={message} showCursor={false} typeSpeed={props.typeSpeed}   />
                        </Flex>
                    )}
                    {((index === (props.messages.length - 1)) && props.isWaitAnimationVisible) && (
                        <Flex width="100%" justifyContent="flex-start" columnGap="1px" paddingLeft="2px" >
                                                        <TestChatMessage key={index} theme={theme} message={typingMessage} typeSpeed={props.typeSpeed} showCursor={true}  />

                        </Flex>
                    )}
                </Flex>
            ))}

        </Flex>
    );

}

export default TestMessageBox
;
