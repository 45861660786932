import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { t } from "i18next";
import { useEffect, useState } from "react";

import example_avatar_1 from '../../assets/landingPage/example_avatar_1.png';
import example_avatar_2 from '../../assets/landingPage/example_avatar_2.png';
import example_avatar_3 from '../../assets/landingPage/example_avatar_3.png';
import example_avatar_4 from '../../assets/landingPage/example_avatar_4.png';
import example_avatar_5 from '../../assets/landingPage/example_avatar_5.png';
import example_avatar_6 from '../../assets/landingPage/example_avatar_6.png';
import example_avatar_7 from '../../assets/landingPage/example_avatar_7.png';
import { lavenderStyle, Theme } from "../../styles/styles";


interface AvatarsOverviewProps {
    isMobile: boolean
}

function AvatarsOverview(props: AvatarsOverviewProps) {
    const [theme] = useState<Theme>(lavenderStyle)
    const [currentAvatar, setCurrentAvatar] = useState(1);

    // Helper function to switch between avatars
    const toggleAvatar = () => {
        setCurrentAvatar((prevAvatar) => (prevAvatar === 7 ? 1 : prevAvatar + 1));
    };

    // Use a timer to cycle through avatars every 3 seconds (adjust as needed)
    useEffect(() => {
        const timer = setInterval(toggleAvatar, 3000);
        return () => clearInterval(timer);
    }, []);

    return (
        <Flex flexDir='column' justifyContent='center' alignItems='center' width='95%'>
            <Heading as="h1" size={props.isMobile?'md':"xl"} marginBottom='10px' marginTop='50px' color={'blackAlpha.800'}>
               {t('LandingPagePersonalizations_avatar_title')}
            </Heading>
            <Flex justifyContent='center' alignItems='center' width='100%' flexDir='column' position='relative'>
                <Flex shadow={'sm'} borderRadius={'30px'} p='3'>
                <motion.img
                    key={currentAvatar}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    style={{ position: 'relative', top: 0, left: 0, width: props.isMobile?"200px":"255px", height: props.isMobile?"230px":"300px" }}
                    src={currentAvatar === 1 ? example_avatar_1 : currentAvatar === 2 ? example_avatar_2 : currentAvatar === 3 ? example_avatar_3 : currentAvatar === 4 ? example_avatar_4 : currentAvatar === 5 ? example_avatar_5 : currentAvatar === 6 ? example_avatar_6 : example_avatar_7}
                    alt={`Avatar ${currentAvatar}`}
                />
                </Flex>

                <Text color={theme.primaryColor} fontWeight='bold' fontSize={props.isMobile?'md':'lg'} mt='30px'>
                {t('LandingPagePersonalizations_avatar_extensive_avatar_personalizations_text')}
                </Text>
                <Flex  width="100%" justifyContent="center" marginTop="2" wrap="wrap" sx={{
                    "&::-webkit-scrollbar": {
                        WebkitAppearance: "none",
                        width: "5px",
                        height: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        borderRadius: "100px",
                        backgroundColor: theme.thirdColor,
                        boxShadow: "0 0 1px rgba(255, 255, 255, 0.5)",
                    },
                }} >
                    {/* Buttons for customization options */}
                    <Button variant={"outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' > {t('AvatarCustomizationScreen_gender_btn')} </Button>
                    <Button variant={"outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm'> {t('AvatarCustomizationScreen_age_btn')} </Button>
                    <Button variant={"outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' > {t('AvatarCustomizationScreen_skincolor_btn')}</Button>
                    <Button variant={"outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm'> {t('AvatarCustomizationScreen_hairtype_btn')}</Button>
                    <Button variant={"outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' > {t('AvatarCustomizationScreen_haircolor_btn')}</Button>
                    <Button variant={"outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' > {t('AvatarCustomizationScreen_facialhair_btn')}</Button>
                    <Button variant={"outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm'> {t('AvatarCustomizationScreen_earrings_btn')}</Button>
                    <Button variant={"outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' > {t('AvatarCustomizationScreen_glasses_btn')}</Button>
                    <Button variant={"outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' > {t('AvatarCustomizationScreen_clothes_btn')} </Button>

                </Flex>
            </Flex>
        </Flex>
    );
}

export default AvatarsOverview;
