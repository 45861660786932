
import {
  Button,
  Flex, Spinner, Text
} from "@chakra-ui/react";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InsightsFlow from "../../components/insightCreator/InsightsFlow";
import UsageLimitAlert from "../../components/alerts/UsageLimitAlert";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { isAllowedToCreateInsightReport } from "../../security/rateLimitChecks";
import { lavenderStyle, Theme } from "../../styles/styles";
import { Chat, RateLimitCheck } from "../../types/types";

function InsightCreatorScreen() {
  const { chatId } = useParams<string>();
  const [insightsLoaded, setInsightsLoaded] = useState<boolean>(false);
  const [chat, setChat] = useState<Chat>();
  const [theme] = useState<Theme>(lavenderStyle)
  const navigate = useNavigate();
  const firebaseInterface = useContext(FirebaseContext);
  const { userProfile, currentUser } = useContext(UserProfileContext);
  const [uid, setUid] = useState<string>();

  const [showUsageLimitNotification, setShowUsageLimitNotification] = useState<boolean>(false);
  const [usageLimitNotificationCheckOver, setUsageLimitNotificationCheckOver] = useState<boolean>(false);
  const [currentUsageLimitNotification, setCurrentUsageLimitNotification] = useState<RateLimitCheck>();
  const path = window.location.pathname;

  const closeUsageLimitNotification = () => {
    // take the last part of the path and navigate to it
    const lastPart = path.split("/").pop();

    navigate('/insightsScreen/' + lastPart);
  }


  useEffect(() => {
    if (currentUser) {
      setUid(currentUser.uid);

    }
    else {
      throw new Error("User not logged in");
    }
  }, [currentUser]);

  useEffect(() => {
    if (!uid || !chatId) return;
    firebaseInterface.getChatData(uid, chatId).then((chat) => {
      setInsightsLoaded(true);
      setChat(chat);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, chatId]);

  useEffect(() => {
    if (!uid || !chatId) {
      return;
    }


    firebaseInterface.countTotalInsightReportsCurrentlyAvailable(uid).then((totalInightReportsAtTheSameTime: number) => {
      const rateLimitCheck = isAllowedToCreateInsightReport(userProfile.subscriptionId, totalInightReportsAtTheSameTime, userProfile.totalStats.totalInsightReportsGenerated, userProfile.dailyStats.totalInsightReportsGenerated);
      if (!rateLimitCheck.allowed) {
        setCurrentUsageLimitNotification(rateLimitCheck)
        setShowUsageLimitNotification(true)
      }
      setUsageLimitNotificationCheckOver(true);
    });




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, chatId]);

  if (usageLimitNotificationCheckOver && showUsageLimitNotification && currentUsageLimitNotification) {
    return (
      <Flex flexDir="column" width="100%" height={"99vh"} justifyContent="center" alignItems="center">
        <UsageLimitAlert onClose={closeUsageLimitNotification} rateLimitCheck={currentUsageLimitNotification} />
      </Flex>
    )
  }

  if (usageLimitNotificationCheckOver && insightsLoaded && chat && chat.insights === undefined) {
    return (
      <Flex flexDir="column" width="100%" height={"99vh"} justifyContent="center" alignItems="center">
        <Text mb="5" width="40%">{t('InsightCreatorScreen_insights_not_found_text')}</Text>
        <Button onClick={() => navigate('/insightsOverviewScreen')} colorScheme={theme.colorScheme} > {t('InsightCreatorScreen_insights_not_found_button')}</Button>
      </Flex>
    )
  }


  if (usageLimitNotificationCheckOver && insightsLoaded && chat && chat.insights && uid) {
    return (<Flex flexDirection='column' height={"99vh"} width="96%" alignItems="center" marginLeft="auto"
      marginRight="auto" >
      <InsightsFlow summary={chat.insights.summary} emotions_and_causes={chat.insights.emotions_and_causes} important_thoughts={chat.insights.important_thoughts} goals={chat.insights.goals} problems_and_complaints={chat.insights.problems_and_complaints} new_insights_and_solutions={chat.insights.new_insights_and_solutions} uid={uid} chatId={chat.databaseId} chatName={chat.name} plannedActions={chat.insights.plannedActions?chat.insights.plannedActions:[]}></InsightsFlow>

    </Flex>
    );

  }
  return (<Flex flexDir="column" width="100%" height={"99vh"} justifyContent="center" alignItems="center"><Spinner size="xl" /> </Flex>)

}

export default InsightCreatorScreen;
