
import {
    Flex,  Button, Heading
} from "@chakra-ui/react";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";



function SubscriptionBoughtScreen() {

    const [theme] = useState<Theme>(lavenderStyle)
    const navigate = useNavigate();





    return (
        <Flex flexDirection='column' maxHeight="97svh" height='100vh' width="96%" alignItems="center" justifyContent={'center'} marginLeft="auto" marginRight="auto" marginBottom="20px" >
            <Flex width={"100%"} justifyContent="center">
                <Heading size='lg' paddingTop='5px' marginLeft="5px">
                    {t('SubscriptionBoughtScreen_title')}
                </Heading>
            </Flex>
            <Flex flexDir="column" mt="5" maxWidth={"900px"} justifyContent="center" alignItems={"center"}>


                <Button colorScheme={theme.colorScheme} onClick={() => navigate('/homeScreen')} > {t('SubscriptionBoughtScreen_button')}</Button>
            </Flex>
        </Flex>

    );
}

export default SubscriptionBoughtScreen;
