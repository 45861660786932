import {
  Flex, Text, IconButton
} from '@chakra-ui/react'
import { MessageType, TypeSpeed } from '../../types/types';
import { lavenderStyle, Theme } from "../../styles/styles";
import { useEffect, useState } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import DeleteButton from '../sharedComponents/confirmationButtons/DeleteButton';
import CancelButton from '../sharedComponents/confirmationButtons/CancelButton';
interface SavedMessageProps {
  message: MessageType;
  showCursor: boolean;
  typeSpeed: TypeSpeed;
  messageTextColor: string;
  messageBackgroundColor: string;
  handleDeleteSavedMessage: (message: MessageType) => void;
}

function SavedMessage(props: SavedMessageProps) {
  const [deleteClicked, setDeleteClicked] = useState<boolean>(false)
  const [theme] = useState<Theme>(lavenderStyle)

  useEffect(() => {
    setDeleteClicked(false)
  }, [props.message]);
  
  const handleCancelClick = () => {
    setDeleteClicked(false)
  }

  const handleConfirmClick = () => {
    props.handleDeleteSavedMessage(props.message);
  }

  const handleDeleteClick = () => {

    //props.handleDeleteSavedMessage(props.message);
    setDeleteClicked(true)
  }

  return (<Flex
    width="100%"  shadow={"base"} borderRadius="2xl" p="2" mt="2">

    {deleteClicked ? (
      <Flex  width="100%" justifyContent={"center"} >

        <DeleteButton onClick={handleConfirmClick} />
        <CancelButton onClick={handleCancelClick} />
      </Flex>
    ) : (
      <Flex     width="100%"     justifyContent={"space-between"} alignItems="center">
    
        <Text
          paddingLeft="10px"
          paddingRight="10px"
          paddingBottom="5px"
          paddingTop="5px"
          backgroundColor={props.messageBackgroundColor}
          borderRadius="10px"
          color={props.messageTextColor}
        >
          {props.message.message} 
        </Text>
        <IconButton
          aria-label="send"
          icon={<DeleteIcon />}
          variant={"link"}
          size="sm"
          color={"black"}
          outline={"none"}
          paddingTop="12px"
          paddingBottom="12px"
          marginRight="8px"
          marginTop="3px"
          colorScheme={theme.colorScheme}
          onClick={handleDeleteClick}
        />
      </Flex>
    )
    }





  </Flex>)


}
export default SavedMessage