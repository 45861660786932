import { Button } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../../styles/styles";

interface CancelButtonProps {
    onClick?: any;
    size?: string
}

function CancelButton(props: CancelButtonProps) {
    const [theme] = useState<Theme>(lavenderStyle)

    return (
        <Button
            colorScheme={theme.colorScheme}
            variant="outline"
            onClick={props.onClick}
            size={props.size? props.size : "md"}
        >
            {t('CancelButton_text')}
        </Button>
    );
}

export default CancelButton;