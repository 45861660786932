import { useContext, useState } from "react";
import Avatar from "../../components/avatar/Avatar";
import CustomizationRow from "../../components/avatarCreator/CustomizationRow";
import { Button, Flex, Heading, Divider, Box } from '@chakra-ui/react'
import { lavenderStyle } from "../../styles/styles";
import { Theme } from "../../styles/styles";
import { Link as Routerlink } from "react-router-dom";
import { AvatarParams } from "../../types/types.js";
import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import {  useLocation } from 'react-router-dom';
import { FirebaseContext } from "../../contexts/firebaseProvider";

function CustomizationScreen() {

    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile, currentUser } = useContext(UserProfileContext);

    const [chosenGender, setChosenGender] = useState(userProfile.therapistSettings.avatar.chosenGender);
    const [chosenHairColor, setChosenHairColor] = useState(userProfile.therapistSettings.avatar.chosenHairColor);
    const [chosenHairType, setChosenHairType] = useState(userProfile.therapistSettings.avatar.chosenHairType);
    const [chosenSkinColor, setChosenSkincolor] = useState(userProfile.therapistSettings.avatar.chosenSkinColor);
    const [chosenAge, setChosenAge] = useState(userProfile.therapistSettings.avatar.chosenAge);
    const [chosenFacialHairType, setchosenFacialHairType] = useState(userProfile.therapistSettings.avatar.chosenFacialHairType);
    const [chosenGlassesType, setchosenGlassenType] = useState(userProfile.therapistSettings.avatar.chosenGlassesType);
    const [chosenClothesType, setchosenClothesType] = useState(userProfile.therapistSettings.avatar.chosenClothesType);
    const [chosenBrowsType] = useState("1");
    const [chosenEarRingsType, setchosenEarRingsType] = useState(userProfile.therapistSettings.avatar.chosenEarRingsType);
    const [customizationType, setCustomizationType] = useState("chosenGender")
    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/modifyPsychologistScreen"));
    const firebaseInterface = useContext(FirebaseContext);

    


    function updateAvatar(chosenGender: string, chosenHairColor: string, chosenHairType: string, chosenSkinColor: string, chosenAge: string, chosenFacialHairType: string, chosenGlassesType: string, chosenClothesType: string, chosenBrowsType: string, chosenEarRingsType: string) {
        let currentAvatarParms: AvatarParams = {
            chosenGender: chosenGender,
            chosenHairColor: chosenHairColor,
            chosenHairType: chosenHairType,
            chosenSkinColor: chosenSkinColor,
            chosenAge: chosenAge,
            chosenFacialHairType: chosenFacialHairType,
            chosenGlassesType: chosenGlassesType,
            chosenClothesType: chosenClothesType,
            chosenEarRingsType: chosenEarRingsType,
        }
        userProfile.therapistSettings.avatar = currentAvatarParms;

        if (currentUser) {
            firebaseInterface.updateAvatar(currentUser.uid, currentAvatarParms);
        }
        else {
            throw new Error("No user logged in");
        }
    }

    //let avatar_options = { head: choosen_head, hair: choosen_hair.substring(0, 5) + choosen_haircolor, brows: choosen_brows.substring(0, 6) + choosen_haircolor, facialhair: choosen_facialHair.substring(0, 11) + choosen_haircolor, glasses: choosen_glasses, clothes: choosen_clothes }
    let avatarParams: AvatarParams = {
        chosenGender: chosenGender,
        chosenHairColor: chosenHairColor,
        chosenHairType: chosenHairType,
        chosenSkinColor: chosenSkinColor,
        chosenAge: chosenAge,
        chosenFacialHairType: chosenFacialHairType,
        chosenGlassesType: chosenGlassesType,
        chosenClothesType: chosenClothesType,
        chosenEarRingsType: chosenEarRingsType
    }



    let customizationSetter;
    switch (customizationType) {
        case "chosenGender":
            customizationSetter = setChosenGender;
            break;
        case "chosenAge":
            customizationSetter = setChosenAge;
            break;
        case "chosenSkinColor":
            customizationSetter = setChosenSkincolor;
            break;
        case "chosenHairType":
            customizationSetter = setChosenHairType;
            break;
        case "chosenHairColor":
            customizationSetter = setChosenHairColor;
            break;
        case "chosenFacialHairType":
            customizationSetter = setchosenFacialHairType;
            break;
        case "chosenGlassesType":
            customizationSetter = setchosenGlassenType;
            break;
        case "chosenClothesType":
            customizationSetter = setchosenClothesType;
            break;
        case "chosenEarRingsType":
            customizationSetter = setchosenEarRingsType;
            break;
        default:
            customizationSetter = setChosenGender;
            break;
    }
   
  

    return (

        <Flex flexDirection='column'  width="96%" alignItems="center" marginLeft="auto" marginRight="auto"    >
            {isFirstTime ?  <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/gdprScreen" /> </Flex> :  <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/modifyPsychologistScreen" /> </Flex>}
           
            <Flex width={"100%"} flexDir={"column"} >

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px' >
                        {t('AvatarCustomizationScreen_title')}
                    </Heading>
                </Flex>
            </Flex>
            {isFirstTime ? 
            <Flex alignItems="flexstart" justifyContent="center" marginTop="10px" marginBottom="10px" >
                {[...Array(7)].map((_, index) => (
                    <Box
                        key={index}
                        w="2"
                        h="2"
                        borderRadius="full"
                        bg={0 === index ? theme.primaryColor : theme.secondaryColor}
                        mx="2"
                    />
                ))}
            </Flex>
            : null}


            <Divider maxWidth="460px" color={theme.thirdColor} />

            <Flex justifyContent="center" marginTop="5px" marginBottom="5px">
                <Button as={Routerlink} to={isFirstTime?"/namePsychologistSettingScreen":"/modifyPsychologistScreen"} variant='solid' colorScheme={theme.colorScheme} size='md' borderRadius={theme.borderRadius} onClick={() => updateAvatar(chosenGender, chosenHairColor, chosenHairType, chosenSkinColor, chosenAge, chosenFacialHairType, chosenGlassesType, chosenClothesType, chosenBrowsType, chosenEarRingsType)
                } >{isFirstTime?t('SettingScreen_first_time_done'): t('SettingScreen_second_time_done')}</Button>

           

            </Flex >

            <Divider maxWidth="460px" color={theme.thirdColor} />

            <Box>
                <Avatar avatarParams={avatarParams} />

            </Box>
                
            <Flex width="100%"  justifyContent={"center"} marginTop="2"  wrap="wrap"  sx={{
                "&::-webkit-scrollbar": {
                    WebkitAppearance: "none",
                    width: "5px ",
                    height: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    borderRadius: "100px",
                    backgroundColor: theme.thirdColor,
                    boxShadow: "0 0 1px rgba(255, 255, 255, 0.5)",
                },
            }} >

                    <Button variant={customizationType === "chosenGender" ? "solid" : "outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' onClick={() => setCustomizationType("chosenGender")}> {t('AvatarCustomizationScreen_gender_btn')} </Button>
                    <Button variant={customizationType === "chosenAge" ? "solid" : "outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' onClick={() => setCustomizationType("chosenAge")}> {t('AvatarCustomizationScreen_age_btn')} </Button>
                    <Button variant={customizationType === "chosenSkinColor" ? "solid" : "outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' onClick={() => setCustomizationType("chosenSkinColor")}> {t('AvatarCustomizationScreen_skincolor_btn')}</Button>
                    <Button variant={customizationType === "chosenHairType" ? "solid" : "outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' onClick={() => setCustomizationType("chosenHairType")}> {t('AvatarCustomizationScreen_hairtype_btn')}</Button>
                    <Button variant={customizationType === "chosenHairColor" ? "solid" : "outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' onClick={() => setCustomizationType("chosenHairColor")}> {t('AvatarCustomizationScreen_haircolor_btn')}</Button>
                    {chosenGender === "male" ? (
                        <Button variant={customizationType === "chosenFacialHairType" ? "solid" : "outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' onClick={() => setCustomizationType("chosenFacialHairType")}> {t('AvatarCustomizationScreen_facialhair_btn')}</Button>)
                        : (
                            <Button variant={customizationType === "chosenEarRingsType" ? "solid" : "outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' onClick={() => setCustomizationType("chosenEarRingsType")}> {t('AvatarCustomizationScreen_earrings_btn')}</Button>
                        )}
                    <Button variant={customizationType === "chosenGlassesType" ? "solid" : "outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' onClick={() => setCustomizationType("chosenGlassesType")}> {t('AvatarCustomizationScreen_glasses_btn')}</Button>
                    <Button variant={customizationType === "chosenClothesType" ? "solid" : "outline"} borderRadius={theme.borderRadius} colorScheme={theme.colorScheme} size='sm' onClick={() => setCustomizationType("chosenClothesType")}> {t('AvatarCustomizationScreen_clothes_btn')} </Button>
       
            </Flex>


            <Divider maxWidth="460px" color={theme.thirdColor} marginTop="10px" />

            <Box width={"100%"} height={"100%"} maxWidth="460px" position="relative" overflowY="auto" minHeight={"100px"} marginTop="5px">
                <CustomizationRow avatarParams={avatarParams} customizationType={customizationType} setOptionMethod={customizationSetter} />

            </Box>
            <Divider maxWidth="460px" color={theme.thirdColor} marginTop="10px" />
        </Flex>

    );
}



export default CustomizationScreen;