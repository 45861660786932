import { useContext, useEffect, useState } from 'react';
import { Alert, AlertDescription, Button, CloseButton, Flex, Text, keyframes, useBreakpointValue, AlertTitle } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../../styles/styles';
import { useLocation, Link as Routerlink, } from 'react-router-dom';
import { RateLimitCheck } from '../../types/types';
import ProgressBar from '../accountScreen/ProgressBar';
import { essentialPlan, starterPlan, trialPlan } from '../../data/subscriptions/subscriptionPlans';
import { t } from 'i18next';
import { FirebaseContext } from '../../contexts/firebaseProvider';
import { UserProfileContext } from '../../contexts/userProfileContextProvider';

interface UsageLimitAlertProps {
  onClose: () => void;
  rateLimitCheck: RateLimitCheck;
}

const UsageLimitAlert = (props: UsageLimitAlertProps) => {
  const [theme] = useState<Theme>(lavenderStyle);
  const notificationWidth = useBreakpointValue({ base: "90%", sm: "90%", md: "700px" }); // Use useBreakpointValue hook to set different widths based on screen size
  const [alertDescription, setAlertDescription] = useState<string>('');
  const [alertTitle, setAlertTitle] = useState<string>(t('UsageLimitAlert_title') as string);
  const [isUpgradable, setIsUpgradable] = useState<boolean>(true);
  const location = useLocation();
  const [stripeLoading, setStripeLoading] = useState<boolean>(false)
  const { currentUser } = useContext(UserProfileContext);

  const firebaseInterface = useContext(FirebaseContext);


  const plans = {
    'trial': trialPlan,
    'starter': starterPlan,
    'essential': essentialPlan,
    'admin': essentialPlan,
    'linkedHumanTherapist': essentialPlan
  }


  useEffect(() => {
    switch (props.rateLimitCheck.reason) {
      case 'limitTotalChatsAtTheSameTime':
        if (props.rateLimitCheck.planId === 'essential') {
          setIsUpgradable(false);
          setAlertDescription(t('UsageLimitAlert_limitTotalChatsAtTheSameTime_description_not_upgradable') as string);
        }
        else {
          setAlertDescription(t('UsageLimitAlert_limitTotalChatsAtTheSameTime_description') as string);
        }
        setAlertTitle(t('UsageLimitAlert_limitTotalChatsAtTheSameTime_title') as string);
        break;
      case 'limitDailyCreatedChats':
        setIsUpgradable(false);
        setAlertDescription(t('UsageLimitAlert_limitDailyCreatedChats_description_not_upgradable') as string);
        setAlertTitle(t('UsageLimitAlert_limitDailyCreatedChats_title') as string);
        break;
      case 'limitTotalCreatedInsightReportsAllTime':
        if (props.rateLimitCheck.planId !== 'trial') {
          setIsUpgradable(false);
          setAlertDescription(t('UsageLimitAlert_limitTotalCreatedInsightReportsAllTime_description_not_upgradable') as string);
        }
        else {
          setAlertDescription(t('UsageLimitAlert_limitTotalCreatedInsightReportsAllTime_description') as string);
          setAlertTitle(t('UsageLimitAlert_limitTotalCreatedInsightReportsAllTime_title') as string);
        }
        break;
      case 'limitDailyCreatedInsightReports':
        if (props.rateLimitCheck.planId === 'essential') {
          setIsUpgradable(false);
          setAlertDescription(t('UsageLimitAlert_limitDailyCreatedInsightReports_description_not_upgradable') as string);
        }
        else {
          setAlertDescription(t('UsageLimitAlert_limitDailyCreatedInsightReports_description') as string);
        }
        setAlertTitle(t('UsageLimitAlert_limitDailyCreatedInsightReports_title') as string);
        break;
      case 'limitTotalInsightReportsAtTheSameTime':
        setIsUpgradable(false);
        setAlertDescription(t('UsageLimitAlert_limitTotalInsightReportsAtTheSameTime_description_not_upgradable') as string);
        setAlertTitle(t('UsageLimitAlert_limitTotalInsightReportsAtTheSameTime_title') as string);
        break;
      case 'limitTotalMessagesSentAllTime':
        if (props.rateLimitCheck.planId !== 'trial') {
          setIsUpgradable(false);
          setAlertDescription(t('UsageLimitAlert_limitTotalMessagesSentAllTime_description_not_upgradable') as string);
        }
        else {
          setAlertDescription(t('UsageLimitAlert_limitTotalMessagesSentAllTime_description') as string)
        }
        setAlertTitle(t('UsageLimitAlert_limitTotalMessagesSentAllTime_title') as string);
        break;
      case 'limitDailyMessagesSent':
        if (props.rateLimitCheck.planId === 'essential') {
          setIsUpgradable(false);
          setAlertDescription(t('UsageLimitAlert_limitDailyMessagesSent_description_not_upgradable') as string);
        }
        else {
          setAlertDescription(t('UsageLimitAlert_limitDailyMessagesSent_description') as string);
        }
        setAlertTitle(t('UsageLimitAlert_limitDailyMessagesSent_title') as string);
        break;
      case 'limitTotalRememberedMessagesAtTheSameTime':
        if (props.rateLimitCheck.planId === 'essential') {
          setIsUpgradable(false);
          setAlertDescription(t('UsageLimitAlert_limitTotalRememberedMessagesAtTheSameTime_description_not_upgradable') as string);
        }
        else {
          setAlertDescription(t('UsageLimitAlert_limitTotalRememberedMessagesAtTheSameTime_description') as string);
        }
        setAlertTitle(t('UsageLimitAlert_limitTotalRememberedMessagesAtTheSameTime_title') as string);
        break;
      case 'limitDailyRememberedMessages':
        setIsUpgradable(false);
        setAlertDescription(t('UsageLimitAlert_limitDailyRememberedMessages_description_not_upgradable') as string);
        setAlertTitle(t('UsageLimitAlert_limitDailyRememberedMessages_title') as string);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rateLimitCheck.reason]);



  const shineAnimation = keyframes`
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
`;


  async function handleUpgradeClickWhenAlreadySubscribed() {
    if (!currentUser) {
      throw new Error("no current user")
    }
    setStripeLoading(true)
    firebaseInterface.getCustomerPortal()
  }

  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="9999"
      backgroundColor="rgba(0, 0, 0, 0.5)"
    >
      <Alert status={'info'} variant="subtle" width={notificationWidth} bgColor={theme.fifthColor} borderRadius="20px" borderColor={theme.secondaryColor} borderWidth={'1px'}>
        <AlertDescription width="100%">
          <Flex width="100%" justifyContent={"center"} alignItems="center" flexDir={"column"}>

            <Text casing={'uppercase'}>{plans[props.rateLimitCheck.planId].displayName}</Text>
            <AlertTitle mb='5px'>{alertTitle}</AlertTitle>

            <Flex borderWidth={'1px'} borderRadius='md' borderColor={theme.secondaryColor} pl='2' pr='2'>
              <ProgressBar current={props.rateLimitCheck.current as number} total={props.rateLimitCheck.limit as number} ></ProgressBar>
            </Flex>

            <Text fontWeight={'light'} mt='10px' color={theme.primaryColor}> {alertDescription} </Text>
            {isUpgradable && props.rateLimitCheck.planId === 'trial' &&
              <Button
                mt='15px'
                colorScheme={theme.colorScheme}
                variant="solid"
                size="md"
                as={Routerlink}
                to="/subscriptionPlanOverviewScreen"
                state={{ from: '/homeScreen' }}
                _hover={{ transform: "scale(1.05)" }}
                transition="transform 0.2s"
                animation={` ${shineAnimation} 4s infinite linear`}
                _focus={{ boxShadow: "none" }}
                style={{
                  background: `linear-gradient(to right, rgba(139, 139, 182, 1),rgba(97, 97, 153, 1), rgba(182, 182, 211, 1), rgba(139, 139, 182, 1))`,
                  backgroundSize: "200% 100%",
                  backgroundPosition: "100%",
                }}
              >
                Upgrade plan
              </Button>
            }
            {isUpgradable && props.rateLimitCheck.planId !== 'trial' &&
              <Button
                mt='15px'
                colorScheme={theme.colorScheme}
                variant="solid"
                size="md"
                isLoading={stripeLoading}
                onClick={handleUpgradeClickWhenAlreadySubscribed}
                _hover={{ transform: "scale(1.05)" }}
                transition="transform 0.2s"
                animation={` ${shineAnimation} 4s infinite linear`}
                _focus={{ boxShadow: "none" }}
                style={{
                  background: `linear-gradient(to right, rgba(139, 139, 182, 1),rgba(97, 97, 153, 1), rgba(182, 182, 211, 1), rgba(139, 139, 182, 1))`,
                  backgroundSize: "200% 100%",
                  backgroundPosition: "100%",
                }}
              >
                Upgrade plan
              </Button>
            }
            {!isUpgradable &&
              <Button
                mt='15px'
                colorScheme={theme.colorScheme}
                variant="solid"
                size="md"
                as={Routerlink}
                to="/contactInformationScreen"
                state={{ from: location.pathname }}
                _hover={{ transform: "scale(1.05)" }}
                transition="transform 0.2s"
                _focus={{ boxShadow: "none" }}
              >
                {t('UsageLimitAlert_contact_us_label')}
              </Button>
            }
          </Flex>
        </AlertDescription>
        <CloseButton color={theme.primaryColor} onClick={props.onClose} position="absolute" right="8px" top="8px" />
      </Alert>
    </Flex>
  );
};

export default UsageLimitAlert;
