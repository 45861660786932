import {
  Flex,
  Box,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { TherapyStyle } from "../../types/types";
import { Theme } from "../../styles/styles";
import { t } from "i18next";

interface TherapyStyleExplanation {
  id: TherapyStyle
  name: string;
  attributes: any[];
  recommended: boolean;
}

interface TherapyStyleProps {
  selectedStyle: TherapyStyle;
  setSelectedStyle: (value: TherapyStyle) => void;
  theme: Theme
}

function TherapyStylesSelector(props: TherapyStyleProps) {
  const bg = useColorModeValue("gray.50", "gray.900");
  const activeBg = props.theme.fourthColor
  const textColor = "black"

  const therapyStyles: TherapyStyleExplanation[] = [
    {
      id: "solution-oriented",
      name: t("TherapyStyles_SolutionOriented"),
      attributes: [
        { id: "1", name: t("TherapyStyles_SolutionOriented_attribute_1"), icon: "check" },
        { id: "2", name: t("TherapyStyles_SolutionOriented_attribute_2"), icon: "check" },
        { id: "3", name: t("TherapyStyles_SolutionOriented_attribute_3"), icon: "check" },
      ],
      recommended: true
    },
    {
      id: "supportive-listening",
      name: t("TherapyStyles_SupportiveListening"),
      attributes: [
        { id: "1", name: t("TherapyStyles_SupportiveListening_attribute_1"), icon: "check" },
        { id: "2", name: t("TherapyStyles_SupportiveListening_attribute_2"), icon: "check" },
        { id: "3", name: t("TherapyStyles_SupportiveListening_attribute_3"), icon: "check" },
      ],
      recommended: false
    },
  ];

  return (
    <>
      <Flex overflowX="auto" width="100%" justifyContent={"center"}>
        {therapyStyles.map((style: TherapyStyleExplanation) => (
          <Tooltip label={style.name} key={style.name}>
            <Flex width="100%"   maxWidth={"220px"}>
              <Box
              
                p="2"
                m="2"
                bg={props.selectedStyle === style.id ? activeBg : bg}
                color={textColor}
                borderRadius="md"
                cursor="pointer"
                transition="background-color 0.2s"

                onClick={() =>
                  props.setSelectedStyle(
                    style.id
                  )
                }
              >
                <Text fontWeight="bold" mb="2">
                  {style.name}
                </Text>
                {style.attributes.map((attribute) => (
                  <Flex key={attribute.id} alignItems="center" mt="2">
                    <Box
                      as={CheckIcon}
                      color={
                        props.selectedStyle === style.id ? props.theme.completementaryColor1 : "gray.300"
                      }
                      mr="2"
                    />
                    <Text>{attribute.name}</Text>
                  </Flex>

                ))}

              </Box>
            </Flex>

          </Tooltip>
        ))}
      </Flex>
      {props.selectedStyle && (
        <Box mt="10">
          <Text fontWeight="bold">{t("TherapyStyles_selected_therapystyle")}  {props.selectedStyle === "solution-oriented" ? t("TherapyStyles_SolutionOriented") : t("TherapyStyles_SupportiveListening")} </Text>
        </Box>
      )}
    </>
  );
}

export default TherapyStylesSelector;

