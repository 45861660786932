import { Flex, Heading,  Text, Image, Box, Grid,  Accordion, AccordionIcon, AccordionButton, AccordionItem, AccordionPanel, Divider } from "@chakra-ui/react";
import i18next, { t } from "i18next";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";
import example_avatar_1 from '../../assets/landingPage/example_avatar_1.png';
import Calendar from "react-calendar";
import MoodtrackerIcon from "../moodtracker/MoodTrackerIcon";
import MoodTrackingGraph from "../moodtracker/MoodtrackingGraph";
import conversation_report from '../../assets/landingPage/conversation-report.png';
import conversation_report_nl from '../../assets/landingPage/conversation-report-nl.png';
import InsightCard from "../insightCreator/InsightCard";
interface LandingPagePersonalizationsProps {
    isMobile: boolean
}



function LandingPageInsights(props: LandingPagePersonalizationsProps) {
    const [theme] = useState<Theme>(lavenderStyle)
    const moodColors = {
        0: "white",       // No Mood (White)
        1: "#FF6868",
        2: "#FF7A7A",
        3: "#FF8C8C",
        4: "#FF9E9E",    // Low Mood (Even Darker Red)
        5: "#FFD966",     // Moderate Mood (Light Yellow)
        6: "#FFD355",     // Moderate Mood (Slightly Darker Yellow)
        7: "#C7D88B",
        8: "#A2C971",     // High Mood (Light Green)
        9: "#7EBF57",     // High Mood (Slightly Darker Green)
        10: "#7EBF57",    // High Mood (Darker Green)
    };
    const [selectedMood, setSelectedMood] = useState(null);
    const moodsPrevious13Days = [9, 8, 9, 7, 8, 7, 7, 6, 5, 6, 3, 5, 6]



    const handleMoodClick = (mood: any) => {
        setSelectedMood(mood);
    };
    const renderMoodSquare = (mood: any) => {
        mood = mood + 1;
        const isSelected = selectedMood === mood;

        return (
            <Box
                key={mood}
                w="50px"
                h="50px"
                bg={isSelected ? (moodColors as any)[mood] : 'gray.200'}
                borderRadius="md"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => handleMoodClick(mood)}
            >
                <Text fontSize="lg" fontWeight="semibold" color={isSelected ? 'white' : 'black'}>
                    {mood}

                </Text>
            </Box>
        );
    };

    function getMoodTrackingIcon({ activeStartDate, date, view }: any) {

        if (view === 'month') {
            // count how many days in the past only if date is in the past
            const today = new Date();
            const diffTime = today.getTime() - date.getTime();
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays <= 0) {
                return <MoodtrackerIcon moodRating={0} />
            }


            // get mood from array
            if (diffDays < moodsPrevious13Days.length) {
                const mood = moodsPrevious13Days[diffDays]
                return <MoodtrackerIcon moodRating={mood} />
            }



            //random number betweeen 0 and 10
            //const randomNumber = Math.floor(Math.random() * 10);
            return <MoodtrackerIcon moodRating={0} />
        }
    }

    return (
        <Flex width={'99%'} flexDir='column' alignItems={'center'} mt='100px' shadow={'lg'} p='2'>


            <Flex width='96%' justifyContent={'center'} >
                <Heading size={props.isMobile ? 'xl' : '3xl'} color={theme.primaryColor} >
                    {t('LandingPageInsights_title')}
                </Heading>
            </Flex>



            <Heading as="h1" size={props.isMobile ? 'lg' : 'xl'} marginBottom={'20px'} marginTop={'40px'} color={'blackAlpha.800'} ml='10px' >
            {t('LandingPageInsights_daily_mood_tracking_label')}

            </Heading>
            <Flex justifyContent={'center'} maxWidth='1000px'  width={'100%'}>
            <Text mt='10px' fontSize={props.isMobile ? 'sm' : 'lg'} fontWeight={'bold'} color={"blackAlpha.700"}>
            {t('LandingPageInsights_daily_mood_tracking_description')}
            </Text>
            </Flex>

            <Flex justifyContent='center' mt='40px' >

                <Flex width="100%" justifyContent={"center"} alignItems="center" flexDir={"column"}>

                    <Image src={example_avatar_1} width={props.isMobile ? '150px' : '200px'} height={props.isMobile ? '170px' : '240px'} />
                    <Box
                        paddingLeft="10px"
                        paddingRight="10px"
                        backgroundColor="#f5f6f8"
                        borderRadius="10px"
                        paddingBottom="5px"
                        paddingTop="5px"
                        marginLeft="5px"
                        marginBottom={"5px"}
                        marginTop={"5px"}
                    >
                        <Text>{t('MoodtrackingQuestionnaire_description')}</Text>

                    </Box>

                    <Box p={4}>

                        <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                            {Array.from({ length: 10 }, (_, index) => renderMoodSquare(index))}
                        </Grid>

                    </Box>


                </Flex>

            </Flex>

            <Divider maxWidth={'400px'} mt='10px' mb='40px'></Divider>

            <MoodTrackingGraph dailyMoods={[{ "localeTimeString": "10:10:12", localeDateString: "dsa", moodRating: 5 },
            { "localeTimeString": "12:10:12", localeDateString: "dsa", moodRating: 7 },
            { "localeTimeString": "20:10:12", localeDateString: "dsa", moodRating: 7 },
            { "localeTimeString": "22:10:12", localeDateString: "dsa", moodRating: 8 },


            ]} height={200} width={320} />


      
            

            <Heading as="h1" size={props.isMobile ? 'lg' : 'xl'} marginTop={'60px'} color={'blackAlpha.800'}>
                {t('LandingPageSupportingFeatures_conversation_insights_title')}
            </Heading>

            <Flex justifyContent='center' maxWidth={'1000px'} width='100%' flexDir='column' alignItems={'center'}>


                <Text width='95%' fontSize={props.isMobile ? 'sm' : 'lg'} fontWeight={'bold'} color={"blackAlpha.700"} mt='30px' mb='50px'>

                    {t('LandingPageSupportingFeatures_conversation_insights_description')}


                </Text>

                <Flex justifyContent='center' width='350px'  >
                    <InsightCard
                        category={"insights"}
                        statement={t('LandingPageSupportingFeatures_conversation_insights_card_description')}
                        rating={0}
                        onRatingChange={() => { }}
                        onStatementChange={() => { }}
                        ratingHeader={t('InsightsFlowFlowStep_insights_rating_header')}
                        ratingLabels={[t('InsightsSummaryCategory_importance_label_1'), t('InsightsSummaryCategory_importance_label_2'), t('InsightsSummaryCategory_importance_label_3'), t('InsightsSummaryCategory_importance_label_4')]}

                    />
                </Flex>


                <Text width='95%' mt='50px' mb='50px' fontSize={props.isMobile ? 'sm' : 'lg'} fontWeight={'bold'} color={"blackAlpha.700"}>{t('LandingPageSupportingFeatures_conversation_insights_insight_report_title')}</Text>

                <Accordion allowToggle>
                    <AccordionItem borderColor={'white'} >

                        <h2>
                            <AccordionButton _hover={undefined} bgColor={theme.fifthColor} borderRadius='50px'>
                                <Flex as="span" _hover={undefined} flex='1' textAlign='left' width={'300px'} justifyContent='center'>
                                    <Text fontSize='lg' fontWeight={'bold'} color={"blackAlpha.700"} >                               {t('LandingPageSupportingFeatures_conversation_insights_insight_report_show_button')}
                                    </Text>
                                </Flex>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>

                        <AccordionPanel>
                            <Image width={'450px'} src={i18next.language === 'en' ? conversation_report : conversation_report_nl} alt="conversation report" />

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Heading as="h1" size={props.isMobile ? 'lg' : 'xl'}  marginTop={'40px'} color={'blackAlpha.800'} ml='10px' >
                {t('LandingPageInsights_progress_label')}
                </Heading>

                <Text width='95%' mt='30px' mb='50px' fontSize={props.isMobile ? 'sm' : 'lg'} fontWeight={'bold'} color={"blackAlpha.700"}> 
                {t('LandingPageInsights_progress_description')}
                </Text>

                <Flex justifyContent='center'  marginBottom={'40px'}  >



                    <Flex  flexDir={'column'} maxWidth='350px'>
                        <Calendar tileContent={getMoodTrackingIcon} />
                    </Flex>

                </Flex>

            </Flex>









        </Flex>
    )
}

export default LandingPageInsights;