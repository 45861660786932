import { useContext, useState } from "react";
import {
    Box,
    Button, Flex, Heading, Text
} from '@chakra-ui/react'
import { Link as Routerlink, useLocation } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import { TherapyStyle } from "../../types/types";
import { t } from "i18next";
import Questionnaire from "../../components/settingScreens/Questionnaire";
import TherapyStylesSelector from "../../components/settingScreens/TherapyStyles";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { FirebaseContext } from "../../contexts/firebaseProvider";

function TherapyStyleSettingScreen() {

    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile, currentUser } = useContext(UserProfileContext);
    const [selectedStyle, setSelectedStyle] = useState<TherapyStyle>(userProfile.therapistSettings.therapyStyle);
    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/modifyPsychologistScreen"));
    const firebaseInterface = useContext(FirebaseContext);



    const questions = [
        {
            id: "question1",
            text: t("Questionnaire_therapystyles_question_1"),
            options: [
                { id: "option1", text: t("Questionnaire_therapystyles_question_1_option_1") },
                { id: "option2", text: t("Questionnaire_therapystyles_question_1_option_2") },
            ],
        },
        {
            id: "question2",
            text: t("Questionnaire_therapystyles_question_2"),
            options: [
                { id: "option1", text: t("Questionnaire_therapystyles_question_2_option_1") },
                { id: "option2", text: t("Questionnaire_therapystyles_question_2_option_2") },
            ],
        },
        {
            id: "question3",
            text: t("Questionnaire_therapystyles_question_3"),
            options: [
                { id: "option1", text: t("Questionnaire_therapystyles_question_3_option_1") },
                { id: "option2", text: t("Questionnaire_therapystyles_question_3_option_2") },
            ],
        },
    ];

    const [formCompleted, setFormCompleted] = useState(!isFirstTime);
    const [recommendedTherapyStyle, setRecommendedTherapyStyle] = useState("goal-oriented");

    function updateTherapyStyle(therapyStyle: TherapyStyle) {
        userProfile.therapistSettings.therapyStyle = therapyStyle;
        if (currentUser) {
            firebaseInterface.updateTherapyStyle(currentUser.uid, therapyStyle);
        }
        else {
            throw new Error("User is not logged in");
        }
    }


    const handleSubmit = (answers: any) => {
        // check if for question 1 , question 2, question 3 option 1 or option 2 is the most selected
        // if option 1 is the most selected, set the therapy style to goal oriented
        // if option 2 is the most selected, set the therapy style to socratic
        let goalOrientedCount = 0;
        let socraticCount = 0;
        for (let key in answers) {
            if (answers[key] === "option1") {
                goalOrientedCount++;
            }
            else {
                socraticCount++;
            }
        }
        if (goalOrientedCount > socraticCount) {
            setSelectedStyle("solution-oriented")
            setRecommendedTherapyStyle("solution-oriented");
        }
        else {
            setRecommendedTherapyStyle("supportive-listening");
            setSelectedStyle("supportive-listening")
        }
        setFormCompleted(true);
    };

    return (
        <Flex flexDirection='column' width="96%"  alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            {isFirstTime ? (
                <Flex width="100%" justifyContent={"flex-start"} mt="2">
                    <PreviousScreenButton previousScreen="/personalitySettingScreen" />
                </Flex >)
                :
                <Flex mt="2" width="100%" justifyContent={"flex-start"}> <PreviousScreenButton previousScreen="/modifyPsychologistScreen" /> </Flex >
            }
            <Flex flexDir={"column"} >
                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px' marginLeft="5px">
                        {t('TherapyStyleSettingScreen_title')}
                    </Heading>
                </Flex>
            </Flex>
            <Flex flexDir="column" alignItems="center" height={"100%"} width="100%" marginTop="10px" >
                {isFirstTime ?
                    <Flex alignItems="flexstart" justifyContent="center" marginBottom={"20px"}>
                        {[...Array(7)].map((_, index) => (
                            <Box
                                key={index}
                                w="2"
                                h="2"
                                borderRadius="full"
                                bg={3 === index ? theme.primaryColor : theme.secondaryColor}
                                mx="2"
                            />
                        ))}
                    </Flex>
                    : null}
                {formCompleted ?
                    <Flex flexDir="column" alignItems={"center"}  >
                        {isFirstTime ?
                            <Flex flexDirection="column" marginBottom="40px" justifyContent={"center"} alignItems="center">
                                <Text >{t("TherapyStyleSettingScreen_recommended_therapy_text")} </Text>

                                <Text
                                    fontWeight="bold"
                                    mt='20px'
                                    fontSize="lg"
                                    color={theme.primaryColor}
                                    textTransform="uppercase"
                                >
                                    {recommendedTherapyStyle === "solution-oriented" ? t("TherapyStyleSettingScreen_recommended_therapy_goal_oriented") : t("TherapyStyleSettingScreen_recommended_therapy_socratic")}
                                </Text>
                            </Flex>
                            : null}
                        <TherapyStylesSelector selectedStyle={selectedStyle} setSelectedStyle={setSelectedStyle} theme={theme} />
                        <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' marginTop="80px" >
                            <Button as={Routerlink} to={isFirstTime ? "/typeSpeedSettingScreen" : "/modifyPsychologistScreen"} colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} onClick={() => updateTherapyStyle(selectedStyle)}>{isFirstTime ? t('SettingScreen_first_time_done') : t('SettingScreen_second_time_done')}</Button>
                        </Flex>
                    </Flex>
                    :
                    <Flex width="100%" height="100%" marginTop='20px'  justifyContent={"center"} alignItems="center" >
                        <Questionnaire questions={questions} onSubmit={handleSubmit} theme={theme} />
                    </Flex>
                }
            </Flex>
        </Flex >
    )
}

export default TherapyStyleSettingScreen