import { Box,  Divider, Flex, Heading,  Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";

interface LandingPagePrivacySectionProps {
    isMobile: boolean
}

function LandingPagePrivacySection(props: LandingPagePrivacySectionProps) {
    const [theme] = useState<Theme>(lavenderStyle)


    return (
        <Flex width={'100%'} flexDir='column' alignItems={'center'} mt='60px' bgColor={'#f6f6ff'} borderRadius={'xl'}>
            <Flex width='96%' justifyContent={'center'} >
                <Heading size={props.isMobile ? '2xl' : '3xl'} color={theme.primaryColor} mt='20px' >
                    {t('LandingPageDataStorageAndPrivacy_title')}
                </Heading>
            </Flex>


            <Flex justifyContent='center' maxWidth={'1000px'} flexDir='column' width='95%' mt='20px' >
                <Text fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}>
                {t('LandingPageDataStorageAndPrivacy_description')}                </Text>

            </Flex>


            <Divider width='80%' borderColor={theme.thirdColor} mt='20px' mb='20px'></Divider>


            <Flex justifyContent='center' maxWidth={'1000px'} flexDir='column' width='95%' mt='10px' >
                <Box textAlign="center">
                    <Text fontSize={props.isMobile ? 'lg' : 'xl'} fontWeight={'bold'} >  {t('LandingPageDataStorageAndPrivacy_point_1_title')}   </Text>

                   
                    <Text mt='20px' fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}>  
                    {t('LandingPageDataStorageAndPrivacy_point_1_description')} 
                    
                     </Text>

                </Box>
            </Flex>
            <Divider width='80%' borderColor={theme.thirdColor} mt='40px'></Divider>
            <Flex justifyContent='center' maxWidth={'1000px'} flexDir='column' width='95%' mt='20px' >
                <Box textAlign="center">
                    <Text fontSize={props.isMobile ? 'lg' : 'xl'} fontWeight={'bold'} > {t('LandingPageDataStorageAndPrivacy_point_2_title')}  </Text>
                    <Text mt='20px' fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}> {t('LandingPageDataStorageAndPrivacy_point_2_description')}</Text>
                </Box>

            </Flex>
            <Divider width='80%' borderColor={theme.thirdColor} mt='40px'></Divider>
            <Flex justifyContent='center' maxWidth={'1000px'} flexDir='column' width='95%' mt='20px' mb='40px' >
                <Box textAlign="center">
                    <Text fontSize={props.isMobile ? 'lg' : 'xl'} fontWeight={'bold'} >  {t('LandingPageDataStorageAndPrivacy_point_3_title')}</Text>
                    <Text mt='20px' fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}>{t('LandingPageDataStorageAndPrivacy_point_3_description')}</Text>
                </Box>

            </Flex>



        </Flex>
    )

}
export default LandingPagePrivacySection;