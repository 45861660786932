import { Button, Flex, HStack, IconButton, Text } from "@chakra-ui/react";
import { GB, NL } from "country-flag-icons/react/3x2";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import FirebaseInterface from "../../services/firebase";
import { lavenderStyle, Theme } from "../../styles/styles";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FirebaseContext } from "../../contexts/firebaseProvider";

function EmailNotVerifiedScreen() {
    const [theme] = useState<Theme>(lavenderStyle);
    const { currentUser } = useContext(UserProfileContext);
    const navigate = useNavigate();
    const [reloadScreen, setReloadScreen] = useState(false);
    const firebaseInterface = useContext(FirebaseContext);

    const { i18n } = useTranslation();


    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };


    const handleReload = () => {
        setReloadScreen(true);
    };

    useEffect(() => {
        if (reloadScreen) {
            window.location.reload();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadScreen]);

    const sendNewVerificationLink = () => {
        const firebaseInterface = new FirebaseInterface();
        firebaseInterface.sendEmailVerification();
    };

    useEffect(() => {
        if (currentUser) {
            if (currentUser.metadata.creationTime === currentUser.metadata.lastSignInTime) {
                if (!localStorage.getItem('verificationEmailSent')) {
                    sendNewVerificationLink();
                }
                localStorage.setItem('verificationEmailSent', 'true');
            }
        }
        if (currentUser?.emailVerified) {
            localStorage.removeItem('verificationEmailSent');
            navigate("/languageSettingScreen");

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, currentUser?.emailVerified]);

    return (
        <Flex
            flexDir="column"
            width="100%"
            height="100vh"
            alignItems="center"
        >
        <Flex width="100%" justifyContent={"flex-start"} ml='50px' mt="20px"> 
        
         <IconButton onClick={()=> firebaseInterface.logOut()}  aria-label="Go back"  icon={<ArrowBackIcon boxSize="9" />} size='lg'> Go back</IconButton>
         
          </Flex>

            <Flex  justifyContent={'flex-end'}>
                <Flex    pt={5} pb={5}>
                    <HStack spacing={3} width="100%"  borderColor={theme.fourthColor}>
                        <Button
                            colorScheme={theme.colorScheme}
                            width="100%"
                            variant={i18n.language === "en" ? "outline" : "ghost"}
                            onClick={() => setLanguage("en")}
                        >
                            <GB
                                style={{ width: "30px", height: "30px" }}
                            />
                        </Button>
                        <Button
                            colorScheme={theme.colorScheme}
                            width="100%"

                            variant={i18n.language === "nl" ? "outline" : "ghost"}
                            onClick={() => setLanguage("nl")}
                        >
                            <NL
                                style={{  width: "30px", height: "30px" }}
                            />
                        </Button>


                    </HStack>
                </Flex>
            </Flex>
            <Flex width='90%' flexDir={'column'} maxWidth='600px' justifyContent={'center'} alignItems='center'>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    {t('EmailVerification_title')}
                </Text>

                <Text >
                {t('EmailVerification_description')}
                </Text>
                <Text mt='10px' fontWeight={'bold'} color={theme.primaryColor}> {t('EmailVerification_spam_folder_instruction')}</Text>
                <Text mt='5' fontWeight={'bold'}>
                    {currentUser?.email}
                </Text>

                <Text mb={4} mt={1}>

                </Text>
                <Flex columnGap={2} flexWrap='wrap' justifyContent={'center'} rowGap={4}>
                    <Button
                        colorScheme={theme.colorScheme}
                        variant={'outline'}
                        onClick={sendNewVerificationLink}
                        disabled={currentUser?.emailVerified}
                    >
                              {t('EmailVerification_resend_email_button')}
                    </Button>
                    <Button
                        colorScheme={theme.colorScheme}

                        onClick={handleReload}
                        disabled={!reloadScreen}
                    >
                               {t('EmailVerification_check_verification_status_button')}
                    </Button>
                </Flex>

            </Flex>
        </Flex>
    );
}

export default EmailNotVerifiedScreen;
