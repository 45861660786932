import { useContext, useState } from 'react';
import { Alert, AlertDescription, Button,  Flex, Text,  useBreakpointValue, AlertTitle, Box,  Grid } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../../styles/styles';
import { Mood } from '../../types/types';
import { t } from 'i18next';
import { FirebaseContext } from '../../contexts/firebaseProvider';
import { UserProfileContext } from '../../contexts/userProfileContextProvider';
import Avatar from '../../components/avatar/Avatar';

interface UsageLimitAlertProps {
    onClose: () => void;
}

const MoodTrackingQuestionnaire = (props: UsageLimitAlertProps) => {
    const [theme] = useState<Theme>(lavenderStyle);
    const notificationWidth = useBreakpointValue({ base: "90%", sm: "90%", md: "700px" }); // Use useBreakpointValue hook to set different widths based on screen size
    const [showNoMoodSelectedError, setShowNoMoodSelectedError] = useState<boolean>(false);
    const avatarWidth = useBreakpointValue({ base: "220px", sm: "300px", md: "300px", }); // Use useBreakpointValue hook to set different widths based on screen size
    const { userProfile, setUserProfile, currentUser } = useContext(UserProfileContext);

    const firebaseInterface = useContext(FirebaseContext);

    const moodColors = {
        0: "white",       // No Mood (White)
        1: "#FF6868",
        2: "#FF7A7A",
        3: "#FF8C8C",
        4: "#FF9E9E",    // Low Mood (Even Darker Red)
        5: "#FFD966",     // Moderate Mood (Light Yellow)
        6: "#FFD355",     // Moderate Mood (Slightly Darker Yellow)
        7: "#C7D88B",
        8: "#A2C971",     // High Mood (Light Green)
        9: "#7EBF57",     // High Mood (Slightly Darker Green)
        10: "#7EBF57",    // High Mood (Darker Green)
    };


    const [selectedMood, setSelectedMood] = useState(null);

    const handleMoodClick = (mood: any) => {
        setShowNoMoodSelectedError(false);
        setSelectedMood(mood);
    };
    const renderMoodSquare = (mood: any) => {
        mood = mood + 1;
        const isSelected = selectedMood === mood;

        return (
            <Box
                key={mood}
                w="50px"
                h="50px"
                bg={isSelected ? (moodColors as any)[mood] : 'gray.200'}
                borderRadius="md"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => handleMoodClick(mood)}
            >
                <Text fontSize="lg" fontWeight="semibold" color={isSelected ? 'white' : 'black'}>
                    {mood}

                </Text>
            </Box>
        );
    };


    const trackMood = () => {
        if(!currentUser) {
            throw new Error("No current user")
        }
        if (selectedMood === null) {

            setShowNoMoodSelectedError(true);

        } else {
            const mood : Mood = {
                moodRating: selectedMood,
                localeDateString: new Date().toLocaleDateString(),
                localeTimeString: new Date().toLocaleTimeString(),
            }

            firebaseInterface.logMood(currentUser.uid, mood);
            // add to user profile

            setUserProfile((oldProfile) => {

                return {

                    ...oldProfile,
                    todayMoods: [...oldProfile.todayMoods, mood]
                }
            })
            
            props.onClose();
            setShowNoMoodSelectedError(false);
            
        }
    }

    return (
        <Flex
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="9999"
            backgroundColor="rgba(0, 0, 0, 0.5)"
        >
            <Alert status={'info'} variant="subtle" width={notificationWidth} bgColor={theme.fifthColor} borderRadius="20px" borderColor={theme.secondaryColor} borderWidth={'1px'}>
                <AlertDescription width="100%">
                    <Flex width="100%" justifyContent={"center"} alignItems="center" flexDir={"column"}>


                        <AlertTitle mb='5px'>{t('MoodtrackingQuestionnaire_title')}</AlertTitle>

                        <Flex width={avatarWidth} justifyContent={"center"} mb='2'>
                            <Avatar avatarParams={userProfile.therapistSettings.avatar} />
                        </Flex>

                       
                            

                                    <Box
                                        paddingLeft="10px"
                                        paddingRight="10px"
                                        backgroundColor="#f5f6f8"
                                        borderRadius="10px"
                                        paddingBottom="5px"
                                        paddingTop="5px"
                                        marginLeft="5px"
                                        marginBottom={"5px"}
                                        marginTop={"5px"}
                                        shadow="md" 
                                    >
                                        <Text>{t('MoodtrackingQuestionnaire_description') }</Text>
                                    
                                    </Box>

                                    <Box p={4}>

                                        <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                                            {Array.from({ length: 10 }, (_, index) => renderMoodSquare(index))}
                                        </Grid>

                                    </Box>


                                    {showNoMoodSelectedError && (
                                        <Text color="red.500" fontSize="sm">
                                           {t('MoodtrackingQuestionnaire_forgot_to_select_mood_label')}
                                        </Text>
                                    )    
                                    }
                                    <Button mt="10px" colorScheme={theme.colorScheme} onClick={trackMood}>{t('MoodtrackingQuestionnaire_confirm_button')}</Button>
                           

                      


                     


                    </Flex>
                </AlertDescription>
            </Alert>
        </Flex>
    );
};

export default MoodTrackingQuestionnaire;
