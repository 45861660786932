import { Divider, Flex, Heading, Image } from "@chakra-ui/react";
import {  useState } from "react";
import { MessageType } from "../../types/types";
import example_avatar_1 from '../../assets/landingPage/example_avatar_1.png';
import TestMessageBox from "./TestMessageBox";
import InputField from "../chat/InputField";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";


interface LandingPageHeadlineProps {
    isMobile: boolean
}
function LandingPageHeadline(props: LandingPageHeadlineProps) {
    const [exampleChatMessages, setExampleChatMessages] = useState<MessageType[]>([{ 'message': t('LandingPageHeadline_opening_msg'), 'animation': true, 'isSaved': false, 'sender': "psychologist", timestampInSeconds: 5 }]);
    const [isWaitAnimationVisible, setIsWaitAnimationVisible] = useState<boolean>(false)
    const [theme] = useState<Theme>(lavenderStyle)





    const sendExampleChatMessage = (m: string) => {
        setExampleChatMessages((old) => [...old, { 'message': m, 'animation': false, 'isSaved': false, 'sender': "user", timestampInSeconds: 5 }])
        setIsWaitAnimationVisible(true)
        setTimeout(() => {
            setIsWaitAnimationVisible(false)
            setExampleChatMessages((old) => [...old, { 'message': t('LandingPageHeadline_reponse_msg'), 'animation': true, 'isSaved': false, 'sender': "psychologist", timestampInSeconds: 5 }])
        }, 1000)
    }
    

    return (

        <Flex width='100%' mt={props.isMobile?'80px' : '100px'} justifyContent={'center'} flexDir='column' alignItems={'center'}>

            <Flex width='96%' justifyContent={'center'} color={theme.primaryColor} >
                <Heading size={props.isMobile? 'xl': '4xl'}>
                    {t('LandingPageHeadline_title')}
                </Heading>
            </Flex>



            <Flex flexDirection="column"   width="100%" overflowY="auto" maxWidth="1000px" alignItems="center" mt='20px'>

                <Image src={example_avatar_1} width={props.isMobile?'150px':'200px'} height={props.isMobile?'170px':'240px'}/>

                <Divider color="red" maxWidth="900px" />
                <TestMessageBox isMobile={props.isMobile} theme={theme} innerRef={null} messages={exampleChatMessages} isWaitAnimationVisible={isWaitAnimationVisible} typeSpeed={80} handleMessageSave={() => { }} handleMessageLiked={() => { }} handleMessageDisliked={() => { }} />
                {exampleChatMessages.length === 1 &&
                    <InputField isMobile={false} canSendMessage={true} theme={theme} handleSendMessage={(m) => { sendExampleChatMessage(m) }} />

                }
            </Flex>

        </Flex>
    )
}

export default LandingPageHeadline;