//clothes
import male_1 from '../../assets/clothes/clothes-1.svg';
import male_2 from '../../assets/clothes/clothes-2.svg';
import male_3 from '../../assets/clothes/clothes-3.svg';
import male_4 from '../../assets/clothes/clothes-4.svg';
import female_1 from '../../assets/clothes/f-clothes-1.svg';
import female_2 from '../../assets/clothes/f-clothes-2.svg';
import female_3 from '../../assets/clothes/f-clothes-3.svg';
import female_4 from '../../assets/clothes/f-clothes-4.svg';

class AvatarClothes {
    gender;
    type;
    img;
    clothesMap = {
        "female_1": female_1,
        "female_2": female_2,
        "female_3": female_3,
        "female_4": female_4,
        "male_1": male_1,
        "male_2": male_2,
        "male_3": male_3,
        "male_4": male_4,
    }
    constructor(gender, type) {
        this.gender = gender;
        this.type = type;
        this.img = new Image();
        this.img.src = this.clothesMap[this.getOptionsString()];
    }

    getOptionsString() {
        return this.gender + "_" + this.type;
    }

    // render on canvas in position of avatar
    renderOnCanvas(context, canvas_width, canvas_height) {
        return new Promise((resolve, reject) => {

            let x_scale = 10;
            let y_scale = 10;

            if (this.gender === "male") {
                switch (this.type) {
                    case "1":
                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.46, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.46, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }
                        break;
                    case "2":
                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 21, canvas_height * 0.50, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 21, canvas_height * 0.50, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }


                        break;
                    case "3":
               
                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 11, canvas_height * 0.50, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 11, canvas_height * 0.50, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }


                        break;
                    case "4":

                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 33, canvas_height * 0.46, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 33, canvas_height * 0.46, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }
                        break;


                    default:
                        resolve();
                        break;
                }
            }
            else {
                switch (this.type) {
                    case "1":
  

                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.46, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.46, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }

                        break;
                    case "2":
                     
                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 21, 1050, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 21, 1050, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }

                        break;
                    case "3":


                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 11, 1000, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 11, 1000, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }

                        break;
                    case "4":

                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 70, canvas_height * 0.48, this.img.width * x_scale + 150, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 70, canvas_height * 0.48, this.img.width * x_scale + 150, this.img.height * y_scale);
                                resolve()
                            };
                        }

                        break;

                    default:
                        resolve();
                        break;
                }
            }

        });
    }


    renderAsOption(context) {
        this.img.onload = () => {
            if (this.gender === "male") {
                switch (this.type) {
                    case "1":
                        const scaleXMale1 = 1.3; // Scale factor for width
                        const scaleYMale1 = 1.3; // Scale factor for height
                        context.drawImage(this.img, 5, 12, this.img.width * scaleXMale1, this.img.height * scaleYMale1);
                        break;
                    case "2":
                        const scaleXMale2 = 1.1; // Scale factor for width
                        const scaleYMale2 = 1.1; // Scale factor for height
                        context.drawImage(this.img, 8, 12, this.img.width * scaleXMale2, this.img.height * scaleYMale2);
                        break
                    case "3":
                        const scaleXMale3 = 1.1; // Scale factor for width
                        const scaleYMale3 = 1.1; // Scale factor for height
                        context.drawImage(this.img, 20, 12, this.img.width * scaleXMale3, this.img.height * scaleYMale3);
                        break
                    case "4":
                        const scaleXMale4 = 1.0; // Scale factor for width
                        const scaleYMale4 = 1.0; // Scale factor for height
                        context.drawImage(this.img, 20, 10, this.img.width * scaleXMale4, this.img.height * scaleYMale4);
                        break
                    default:
                        break;
                }
            }
            else {
                switch (this.type) {
                    case "1":
                        const scaleXFemale1 = 1.1; // Scale factor for width
                        const scaleYFemale1 = 1.1; // Scale factor for height
                        context.drawImage(this.img, 15, 12, this.img.width * scaleXFemale1, this.img.height * scaleYFemale1);
                        break;
                    case "2":
                        const scaleXFemale2 = 1.1; // Scale factor for width
                        const scaleYFemale2 = 1.1; // Scale factor for height
                        context.drawImage(this.img, 40, 12, this.img.width * scaleXFemale2, this.img.height * scaleYFemale2);
                        break
                    case "3":
                        const scaleXFemale3 = 1.1; // Scale factor for width
                        const scaleYFemale3 = 1.1; // Scale factor for height
                        context.drawImage(this.img, 30, 12, this.img.width * scaleXFemale3, this.img.height * scaleYFemale3);
                        break
                    case "4":
                        const scaleXFemale4 = 1.2; // Scale factor for width
                        const scaleYFemale4 = 1.2; // Scale factor for height
                        context.drawImage(this.img, 25, 20, this.img.width * scaleXFemale4, this.img.height * scaleYFemale4);
                        break
                    default:
                        break;
                }
            }
        };
    }


}

export default AvatarClothes