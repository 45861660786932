import { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../../styles/styles';
import { t } from 'i18next';

interface ProgressBarProps {
    current: number;
    total: number;
}

const ProgressBar = (props: ProgressBarProps) => {
    const progress = (props.current / props.total) * 100;
    const progressBarWidth = '100px'; // Adjust the width as per your requirement
    const [theme] = useState<Theme>(lavenderStyle)

    return (
        <Flex justifyContent={'center'} alignItems='center'  >
            <Box
                width={progressBarWidth}
                height="10px"
                background="#f1f1f1"
                borderRadius="sm"
                position="relative"
                overflow="hidden"
            >
                <Box
                    width={`${progress}%`}
                    height="100%"
                    background={theme.thirdColor}
                    position="absolute"
                    top={0}
                    left={0}
                />
            </Box>

            <Text ml='5px' fontSize={'sm'} fontWeight='thin'>
                {props.current}/{props.total <300 ? props.total: "     "+ t('SubscriptionPlanCard_unlimited_label')}
            </Text>
        </Flex>
    );
};

export default ProgressBar;
