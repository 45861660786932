import  { useState } from 'react';
import { VStack, Text, Divider, Flex,  Button, Heading, IconButton } from '@chakra-ui/react';
import InsightsSummaryCategory from './InsightsSummaryCategory';
import { t} from 'i18next';
import { lavenderStyle, Theme } from '../../styles/styles';
import { ArrowBackIcon } from '@chakra-ui/icons';

interface RatingSummaryProps {
  feelingsRatings: { text: string; rating: number }[];
  thoughtsRatings: { text: string; rating: number }[];
  goalRatings: { text: string; rating: number }[];
  problemRatings: { text: string; rating: number }[];
  insightsRatings: { text: string; rating: number }[];

  conversationDescription: string;
  goPreviousFlowStep: () => void;
  goNextFlowStep: () => void;
}

function InsightsSummary({ feelingsRatings, thoughtsRatings, goalRatings, problemRatings, insightsRatings, conversationDescription, goPreviousFlowStep, goNextFlowStep }: RatingSummaryProps) {
  // Sort feelings and thoughts by rating in descending order
  const [theme] = useState<Theme>(lavenderStyle)

  const sortedFeelings = [...feelingsRatings]
    .filter((item) => item.rating > 25)
    .sort((a, b) => b.rating - a.rating);

  const sortedThoughts = [...thoughtsRatings]
    .filter((item) => item.rating > 25)
    .sort((a, b) => b.rating - a.rating);

  const sortedGoals = [...goalRatings]
    .filter((item) => item.rating > 25)
    .sort((a, b) => b.rating - a.rating);

  const sortedProblems = [...problemRatings]
    .filter((item) => item.rating > 25)
    .sort((a, b) => b.rating - a.rating);

  const sortedInsights = [...insightsRatings]
    .filter((item) => item.rating > 25)
    .sort((a, b) => b.rating - a.rating);






  return (
    <VStack spacing={4} align="stretch">
      <Flex width="100%" alignItems="center" flexDir="column">
        <Heading size="lg" paddingTop="5px" marginLeft="5px" >
        {t('InsightsFlow_InsightsSummary_title')}
        </Heading>
        <IconButton
          aria-label="Go back"
          variant="outline"
          colorScheme={theme.colorScheme}
          icon={<ArrowBackIcon boxSize="7" />}
          position="absolute"
          top={4}
          left={4}
          onClick={goPreviousFlowStep}
        />

      </Flex>
      <Text fontSize="xl" fontWeight="bold" color="teal.500"></Text>

      {/* Display conversation description */}
      <Flex width="100%" justifyContent={"center"} >
        <Text borderRadius={"20"} p="3" bgColor="#f5f6f8" fontWeight={"medium"} fontStyle={"oblique"} width="80%">{conversationDescription}</Text>
      </Flex>


      <Divider />
      <InsightsSummaryCategory insights={sortedProblems} title={t('InsightsSummaryCategory_problems_title')} ratingLabels={[t('InsightsSummaryCategory_prevalence_label_1'), t('InsightsSummaryCategory_prevalence_label_2'), t('InsightsSummaryCategory_prevalence_label_3'), t('InsightsSummaryCategory_prevalence_label_4')]} />


      <InsightsSummaryCategory insights={sortedFeelings} title={t('InsightsSummaryCategory_feelings_title')} ratingLabels={[t('InsightsSummaryCategory_prevalence_label_1'), t('InsightsSummaryCategory_prevalence_label_2'), t('InsightsSummaryCategory_prevalence_label_3'), t('InsightsSummaryCategory_prevalence_label_4')]} />

      {/* Display thoughts ratings */}
      <InsightsSummaryCategory insights={sortedThoughts} title={t('InsightsSummaryCategory_thoughts_title')} ratingLabels={[t('InsightsSummaryCategory_prevalence_label_1'), t('InsightsSummaryCategory_prevalence_label_2'), t('InsightsSummaryCategory_prevalence_label_3'), t('InsightsSummaryCategory_prevalence_label_4')]} />

      <InsightsSummaryCategory insights={sortedInsights} title={t('InsightsSummaryCategory_insights_title')} ratingLabels={[t('InsightsSummaryCategory_importance_label_1'), t('InsightsSummaryCategory_importance_label_2'), t('InsightsSummaryCategory_importance_label_3'), t('InsightsSummaryCategory_importance_label_4')]} />

      <InsightsSummaryCategory insights={sortedGoals} title={t('InsightsSummaryCategory_goals_title')} ratingLabels={[t('InsightsSummaryCategory_importance_label_1'), t('InsightsSummaryCategory_importance_label_2'), t('InsightsSummaryCategory_importance_label_3'), t('InsightsSummaryCategory_importance_label_4')]} />


      <Divider />

      {/* Export button */}
      <Flex columnGap="2" pb="5">

        <Button flex={1} onClick={goNextFlowStep} colorScheme={theme.colorScheme} variant="outline">
          {t('InsightsFlow_InsightsSummary_next_button')}
        </Button>
      </Flex>
    </VStack>
  );
}

export default InsightsSummary;
