import { useRef, useState } from 'react';
import { VStack, Text, Divider, Flex, Button } from '@chakra-ui/react';
import InsightsSummaryCategory from '../insightCreator/InsightsSummaryCategory';
import { lavenderStyle, Theme } from '../../styles/styles';
import { firebaseStoredInsightReport } from '../../types/types';
import InsightsSummaryPlannedActionsCategory from '../insightCreator/InsightsSummaryPlannedActionsCategory';
import { useNavigate } from 'react-router-dom';
import { t } from "i18next";


interface InsightReportProps {
    insightReport: firebaseStoredInsightReport
    closeInsightReport: () => void
    newInsightsAvailable: boolean
}

function InsightReportViewer({ insightReport, closeInsightReport, newInsightsAvailable }: InsightReportProps) {
    // Sort feelings and thoughts by rating in descending order
    const [theme] = useState<Theme>(lavenderStyle)
    const feelingsRatings = insightReport.report.report.emotions_and_causes
    const thoughtsRatings = insightReport.report.report.important_thoughts
    const goalRatings = insightReport.report.report.goals
    const problemRatings = insightReport.report.report.problems_and_complaints
    const insightsRatings = insightReport.report.report.new_insights_and_solutions
    const conversationDescription = insightReport.report.report.summary
    const plannedActions = insightReport.report.report.plannedActions
    const navigate = useNavigate();
    const sortedFeelings = [...feelingsRatings]
        .filter((item) => item.rating > 25)
        .sort((a, b) => b.rating - a.rating);

    const sortedThoughts = [...thoughtsRatings]
        .filter((item) => item.rating > 25)
        .sort((a, b) => b.rating - a.rating);

    const sortedGoals = [...goalRatings]
        .filter((item) => item.rating > 25)
        .sort((a, b) => b.rating - a.rating);

    const sortedProblems = [...problemRatings]
        .filter((item) => item.rating > 25)
        .sort((a, b) => b.rating - a.rating);

    const sortedInsights = [...insightsRatings]
        .filter((item) => item.rating > 25)
        .sort((a, b) => b.rating - a.rating);


    const handleChatClick = () => {
        //props.handleSelectInsightReport(props.insightReport);
        navigate(`/insightsCreatorScreen/${insightReport.report.chatId}`);
    };


    const componentRef = useRef(null);



    return (
        <VStack spacing={4} align="stretch" ref={componentRef}>
       


            <Flex width="100%" alignItems={"center"} justifyContent="center" flexDir={"column"}>
                
                {newInsightsAvailable &&
                    <Text mb="2" fontSize="xl" fontWeight="bold" color={"green"}>{t('InsightsOverviewScreen_InsightReportViewer_new_insights_available_text')}</Text>
                }
                <Button variant="solid" colorScheme={theme.colorScheme} onClick={handleChatClick}>{t('InsightsOverviewScreen_InsightReportViewer_update_insights_button')}</Button>

            </Flex>



            {/* Display conversation description */}
            <Flex width="100%" justifyContent={"center"} >
                <Text borderRadius={"20"} p="3" bgColor="#f5f6f8" fontWeight={"medium"} fontStyle={"oblique"} width="97%">{conversationDescription}</Text>
            </Flex>

            <Divider />

            <InsightsSummaryCategory insights={sortedProblems} title={t('InsightsSummaryCategory_problems_title')} ratingLabels={[t('InsightsSummaryCategory_prevalence_label_1'), t('InsightsSummaryCategory_prevalence_label_2'), t('InsightsSummaryCategory_prevalence_label_3'), t('InsightsSummaryCategory_prevalence_label_4')]} />

            <InsightsSummaryCategory insights={sortedFeelings} title={t('InsightsSummaryCategory_feelings_title')} ratingLabels={[t('InsightsSummaryCategory_prevalence_label_1'), t('InsightsSummaryCategory_prevalence_label_2'), t('InsightsSummaryCategory_prevalence_label_3'), t('InsightsSummaryCategory_prevalence_label_4')]} />

            {/* Display thoughts ratings */}
            <InsightsSummaryCategory insights={sortedThoughts} title={t('InsightsSummaryCategory_thoughts_title')} ratingLabels={[t('InsightsSummaryCategory_prevalence_label_1'), t('InsightsSummaryCategory_prevalence_label_2'), t('InsightsSummaryCategory_prevalence_label_3'), t('InsightsSummaryCategory_prevalence_label_4')]} />

            <InsightsSummaryCategory insights={sortedInsights} title={t('InsightsSummaryCategory_insights_title')} ratingLabels={[t('InsightsSummaryCategory_importance_label_1'), t('InsightsSummaryCategory_importance_label_2'), t('InsightsSummaryCategory_importance_label_3'), t('InsightsSummaryCategory_importance_label_4')]} />

            <InsightsSummaryCategory insights={sortedGoals} title={t('InsightsSummaryCategory_goals_title')} ratingLabels={[t('InsightsSummaryCategory_importance_label_1'), t('InsightsSummaryCategory_importance_label_2'), t('InsightsSummaryCategory_importance_label_3'), t('InsightsSummaryCategory_importance_label_4')]} />

            <InsightsSummaryPlannedActionsCategory plannedActions={plannedActions ? plannedActions : []} title={t('InsightsSummaryCategory_planned_actions_title')} />
            <Divider />


        </VStack>
    );
}

export default InsightReportViewer;
