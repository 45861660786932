import { useContext, useState } from "react";
import {
    Button, Divider, Flex, Heading, Text, IconButton, Image, useBreakpointValue
} from '@chakra-ui/react'
import { Link as Routerlink, useLocation } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import { faBrain, faExclamationTriangle, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowBackIcon, ChatIcon } from "@chakra-ui/icons";
import instructionGif from '../../assets/general/remembermessage.gif'
import { t } from "i18next";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";

function ManualScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/infoOverviewScreen"));
    const buttonSize = useBreakpointValue({ base: "md", sm: "lg", md: "lg" });
    const { userProfile} = useContext(UserProfileContext);

    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            {isFirstTime ? <Flex width="100%" justifyContent={"flex-start"} mt="2">    <IconButton aria-label="Go back"
                variant="outline"
                colorScheme={theme.colorScheme}
                icon={<ArrowBackIcon boxSize="7" />}
                as={Routerlink}
                size={buttonSize}
                to={"/rememberedMessagesScreen"} state={{ from: location.pathname }}
                borderRadius={theme.borderRadius} > </IconButton>

            </Flex> : <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/infoOverviewScreen" /> </Flex>}


            <Flex maxWidth={"800px"} width={"100%"} flexDir={"column"} >
                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px' marginLeft="5px">
                        {t('ManualScreen_title')}
                    </Heading>

                </Flex>
            </Flex>
            <Divider pt="1"></Divider>


            <Flex maxWidth={"1000px"} flexDir="column" alignItems="center" mt="2" pb='10'>

                <Flex width="90%" flexDir="column">


                    <Text> {t('ManualScreen_chatbot_description')} </Text>
                    <Flex mt="2">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        <Text fontSize="sm" color="gray.500" ml="2" mt="-0.5">{t('ManualScreen_chatbot_warning_1')} </Text>
                    </Flex>
                    <Flex mt="2">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        <Text fontSize="sm" color="gray.500" ml="2" mt="-0.5"> {t('ManualScreen_chatbot_warning_2')}  </Text>
                    </Flex>

                    <Divider mt="5" mb="5" />
                    <Heading size='md' paddingTop='5px' color={theme.text1} marginLeft="5px">
                        {t('ManualScreen_daily_conversation_title')}

                    </Heading>



                    <Flex flexDir={'column'} mt='5' alignItems={'center'} justifyContent='center'>

                        <Flex>
                            <Button
                                width={"200px"}
                                type="submit"
                                variant="solid"
                                colorScheme={theme.colorScheme}

                                leftIcon={<ChatIcon />}
                            >
                                {t('HomeScreen_new_chat_button_text')}
                            </Button>
                        </Flex>

                        <Flex flexDir={"column"} mt='20px' >
                            <Text fontStyle={"italic"}>
                            {t('ManualScreen_daily_conversation_description')}

                            </Text>
                        </Flex>

                    </Flex>
                    <Divider mt="5" mb="5" />
                    <Heading size='md' paddingTop='5px' color={theme.text1} marginLeft="5px">
                    {t('ManualScreen_daily_insights_title')}

                    </Heading>

                    <Flex flexDir={'column'} mt='5' alignItems={'center'} justifyContent='center'>
                        <Button mb='20px' type="submit" leftIcon={<FontAwesomeIcon icon={faLightbulb} />} variant={"outline"} colorScheme={theme.colorScheme} >
                            {t('HomeScreen_insights_button_text')}
                        </Button>

                        <Flex flexDir={"column"} >
                            <Text fontStyle={"italic"}>
                            {t('ManualScreen_daily_insights_description')}
                            </Text>
                        </Flex>

                    </Flex>





                    <Divider mt="5" mb="5"></Divider>
                    <Heading size='md' paddingTop='5px' color={theme.text1} marginLeft="5px">
                        {t('ManualScreen_longterm_memory_header')}
                    </Heading>

                    <Flex flexDir="column" width={"100%"}  >
                   
                            <Text fontStyle={"italic"} mt='20px'>
                            {t('ManualScreen_longterm_memory_description')}
                            </Text>
            


                        <Flex flexDir={"column"}>


                            <Flex mt="5">


                                <IconButton aria-label="Go back"
                                    variant="outline"
                                    colorScheme={theme.colorScheme}
                                    icon={<FontAwesomeIcon icon={faBrain} size="lg" />}
                                    mt='2'
                                    size={"md"}
                                    mr="5"
                                    borderRadius={theme.borderRadius} > </IconButton>

                                <Flex flexDir={"column"} >
                                    <Text fontStyle={"italic"}>{t('ManualScreen_remembered_messages_icon_description')}</Text>

                                </Flex>

                            </Flex>
                            <Flex flexDir={'column'} width="100%" alignItems={"center"} marginTop="30px " marginBottom="10px"  >
                                <Image flex="2" src={instructionGif} alt="loading..." width={"400px"} height={'100px'} fit={"cover"} borderRadius="10" />
                                <Text fontStyle={"italic"} mt="1" flex="2"> {t('ManualScreen_hamburger_message_remember_description')}</Text>
                            </Flex>
                        </Flex>


                    </Flex>
                </Flex>
            </Flex>
            {isFirstTime ?
                <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' marginTop={"10"} mb="5" >
                    <Button mb="5" colorScheme={theme.colorScheme} size="lg" as={Routerlink} state={{ from: location.pathname, isFirstTime: true }} to= {userProfile.linkedHumanTherapist? "/homeScreen": "/subscriptionPlanOverviewScreen"  }> {t('ManualScreen_understood_button')}</Button>
                </Flex>
                : null}

        </Flex >);

}



export default ManualScreen