import { useContext, useState } from "react";
import {
    Button,
    Flex,
    useBreakpointValue
} from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { FirebaseContext } from "../../contexts/firebaseProvider";


function InsightsButton() {
    const [theme] = useState<Theme>(lavenderStyle) 
    const navigate = useNavigate();
    const buttonWidth = useBreakpointValue({ base: "100%", sm: "100%", md:"500px"}); // Use useBreakpointValue hook to set different widths based on screen size
    const firebaseInterface = useContext(FirebaseContext);

    return (
        <Flex  width="100%" justifyContent={"center"}>
            
            <Button width={buttonWidth} type="submit"  leftIcon={<FontAwesomeIcon icon={faLightbulb} />} variant={"outline"} colorScheme={theme.colorScheme} onClick={() => navigate("/insightsScreen/"+firebaseInterface.getCurrentDate())}>
                {t('HomeScreen_insights_button_text')}
            </Button>
        </Flex>
    );
}


export default InsightsButton;
