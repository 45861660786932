import { Routes, Route } from "react-router-dom";
import AvatarCustomizationScreen from "../pages/settingsScreens/AvatarCustomizationScreen";
import NameUserSettingScreen from "../pages/settingsScreens/NameUserSettingScreen";
import TypeSpeedSettingScreen from "../pages/settingsScreens/TypeSpeedSettingScreen";
import NamePsychologistSettingScreen from "../pages/settingsScreens/NamePsychologistSettingScreen";
import ChatbotScreen from "../pages/chatScreens/ChatbotScreen";
import PersonalitySettingScreen from "../pages/settingsScreens/PersonalitySettingScreen";
import TherapyStyleSettingScreen from "../pages/settingsScreens/TherapyStyleSettingScreen";
import LanguageSettingScreen from "../pages/settingsScreens/LanguageSettingScreen";
import LoginScreen from "../pages/loginScreens/LoginScreen";
import ManualScreen from "../pages/infoScreens/ManualScreen";
import HomeScreen from "../pages/homeScreens/HomeScreen";
import ProtectedAuthAndProfileRoute from "./ProtectedAuthAndProfileRoute";
import RememberedMessagesScreen from "../pages/rememberedMessagesScreens/RememberedMessagesScreen";
import InsightCreatorScreen from "../pages/insightsScreens/InsightCreatorScreen";
import MindfulnessSettingScreen from "../pages/settingsScreens/MindfulnessSettingScreen";
import ModifyPsychologistScreen from "../pages/modifyScreens/ModifyPsychologistScreen";
import SubscriptionPlanOverviewScreen from "../pages/subscriptionScreens/SubscriptionPlanOverviewScreen";
import UsageRisksScreen from "../pages/infoScreens/UsageRisksScreen";
import InfoOverviewScreen from "../pages/infoScreens/InfoOverviewScreen";
import ContactInformationScreen from "../pages/infoScreens/ContactInformationScreen";
import SubscriptionBoughtScreen from "../pages/subscriptionScreens/SubscriptionBoughtScreen";
import AccountScreen from "../pages/accountScreens/AccountScreen";
import ErrorScreen from "../pages/errorScreens/ErrorScreen";
import ManageSubscriptionScreen from "../pages/subscriptionScreens/ManageSubscriptionScreen";
import EmailNotVerifiedScreen from "../pages/loginScreens/EmailNotVerifiedScreen";
import UserDataFetchinScreen from "../pages/loginScreens/UserDataFetchingSceen";
import LandingPageScreen from "../pages/landingPageScreens/LandingPageScreen";
import LandingPagePricingScreen from "../pages/landingPageScreens/LandingPagePricingScreen";
import LandingPageContactScreen from "../pages/landingPageScreens/LandingPageContactScreen";
import LanguageRoute from "./LanguageRoute";
import ChatbotServerFullScreen from "../pages/chatScreens/ChatbotServerFullScreen";
import RegionSettingScreen from "../pages/settingsScreens/RegionSettingScreen";
import AddTherapistScreen from "../pages/addTherapistScreens/AddTherapistScreen";
import CalendarScreen from "../pages/calenderScreens/CalendarScreen";
import InsightsScreen from "../pages/insightsScreens/InsightsScreen";
import PromoCodeActivatedScreen from "../pages/subscriptionScreens/PromoCodeActivatedScreen";
import GdprScreen from "../pages/infoScreens/GdprScreen";

const AppRoutes = () => (
    <Routes>
        <Route path="/login" element={<LanguageRoute > <LoginScreen /> </LanguageRoute>  } />
        <Route path="/" element={  <LanguageRoute > <LandingPageScreen/>  </LanguageRoute>    } />
        <Route path="/pricing" element={ <LanguageRoute > <LandingPagePricingScreen/></LanguageRoute>  } />
        <Route path="/contact" element={ <LanguageRoute >   <LandingPageContactScreen/> </LanguageRoute> } />

        <Route path="/languageSettingScreen" element={<ProtectedAuthAndProfileRoute> <LanguageSettingScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/avatarCustomizationScreen" element={<ProtectedAuthAndProfileRoute> <AvatarCustomizationScreen /> </ProtectedAuthAndProfileRoute>} />
        <Route path="/namePsychologistSettingScreen" element={<ProtectedAuthAndProfileRoute><NamePsychologistSettingScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/nameUserSettingScreen" element={<ProtectedAuthAndProfileRoute><NameUserSettingScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/personalitySettingScreen" element={<ProtectedAuthAndProfileRoute><PersonalitySettingScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/typeSpeedSettingScreen" element={<ProtectedAuthAndProfileRoute><TypeSpeedSettingScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/therapyStyleSettingScreen" element={<ProtectedAuthAndProfileRoute><TherapyStyleSettingScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/chatbotScreen/:chatId" element={<ProtectedAuthAndProfileRoute><ChatbotScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/modifyPsychologistScreen" element={<ProtectedAuthAndProfileRoute><ModifyPsychologistScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/homeScreen" element={<ProtectedAuthAndProfileRoute><HomeScreen/></ProtectedAuthAndProfileRoute> } />
        <Route path="/infoOverviewScreen" element={<ProtectedAuthAndProfileRoute><InfoOverviewScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/manualScreen" element={<ProtectedAuthAndProfileRoute><ManualScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/usageRisksScreen" element={<ProtectedAuthAndProfileRoute><UsageRisksScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/gdprScreen" element={<ProtectedAuthAndProfileRoute><GdprScreen /></ProtectedAuthAndProfileRoute>} />
        <Route path="/rememberedMessagesScreen" element={<ProtectedAuthAndProfileRoute><RememberedMessagesScreen />  </ProtectedAuthAndProfileRoute>} />
        <Route path="/insightsCreatorScreen/:chatId" element={<ProtectedAuthAndProfileRoute> <InsightCreatorScreen />  </ProtectedAuthAndProfileRoute>} />
        <Route path="/mindfulnessSettingScreen" element={<ProtectedAuthAndProfileRoute>         <MindfulnessSettingScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/subscriptionPlanOverviewScreen" element={<ProtectedAuthAndProfileRoute>         <SubscriptionPlanOverviewScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/contactInformationScreen" element={<ProtectedAuthAndProfileRoute>         <ContactInformationScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/subscriptionBoughtScreen" element={<ProtectedAuthAndProfileRoute>         <SubscriptionBoughtScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/accountScreen" element={<ProtectedAuthAndProfileRoute>         <AccountScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/manageSubscriptionScreen" element={<ProtectedAuthAndProfileRoute>         <ManageSubscriptionScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/emailNotVerifiedScreen" element={<ProtectedAuthAndProfileRoute>         <EmailNotVerifiedScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/userDataFetchinScreen" element={<ProtectedAuthAndProfileRoute>         <UserDataFetchinScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/chatbotServerFullScreen" element={<ProtectedAuthAndProfileRoute>         <ChatbotServerFullScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/regionSettingScreen" element={<ProtectedAuthAndProfileRoute>         <RegionSettingScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/addTherapistScreen" element={     <ProtectedAuthAndProfileRoute>       <AddTherapistScreen/> </ProtectedAuthAndProfileRoute> } />
        <Route path="/calendarScreen" element={    <ProtectedAuthAndProfileRoute>       <CalendarScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/insightsScreen/:firebaseDateId" element={  <ProtectedAuthAndProfileRoute>      <InsightsScreen/>  </ProtectedAuthAndProfileRoute> } />

        <Route path="/promoCodeActivatedScreen" element={<ProtectedAuthAndProfileRoute>         <PromoCodeActivatedScreen/> </ProtectedAuthAndProfileRoute>} />




    
        <Route path="*" element={<ProtectedAuthAndProfileRoute><ErrorScreen /></ProtectedAuthAndProfileRoute>} />
    </Routes>
);

export default AppRoutes;
