
// hair
import male_1_black from '../../assets/hair/hair-1-black.svg';
import male_2_black from '../../assets/hair/hair-2-black.svg';
import male_3_black from '../../assets/hair/hair-3-black.svg';
import male_4_black from '../../assets/hair/hair-4-black.svg';
import male_1_blond from '../../assets/hair/hair-1-blond.svg';
import male_2_blond from '../../assets/hair/hair-2-blond.svg';
import male_3_blond from '../../assets/hair/hair-3-blond.svg';
import male_4_blond from '../../assets/hair/hair-4-blond.svg';
import male_1_brown from '../../assets/hair/hair-1-brown.svg';
import male_2_brown from '../../assets/hair/hair-2-brown.svg';
import male_3_brown from '../../assets/hair/hair-3-brown.svg';
import male_4_brown from '../../assets/hair/hair-4-brown.svg';
import male_1_gray from '../../assets/hair/hair-1-gray.svg';
import male_2_gray from '../../assets/hair/hair-2-gray.svg';
import male_3_gray from '../../assets/hair/hair-3-gray.svg';
import male_4_gray from '../../assets/hair/hair-4-gray.svg';
import female_1_black from '../../assets/hair/woman-hair1-black.svg';
import female_2_black from '../../assets/hair/woman-hair2-black.svg';
import female_3_black from '../../assets/hair/woman-hair3-black.svg';
import female_4_black from '../../assets/hair/woman-hair4-black.svg';
import female_1_blond from '../../assets/hair/woman-hair1-blond.svg';
import female_2_blond from '../../assets/hair/woman-hair2-blond.svg';
import female_3_blond from '../../assets/hair/woman-hair3-blond.svg';
import female_4_blond from '../../assets/hair/woman-hair4-blond.svg';
import female_1_brown from '../../assets/hair/woman-hair1-brown.svg';
import female_2_brown from '../../assets/hair/woman-hair2-brown.svg';
import female_3_brown from '../../assets/hair/woman-hair3-brown.svg';
import female_4_brown from '../../assets/hair/woman-hair4-brown.svg';
import female_1_gray from '../../assets/hair/woman-hair1-gray.svg';
import female_2_gray from '../../assets/hair/woman-hair2-gray.svg';
import female_3_gray from '../../assets/hair/woman-hair3-gray.svg';
import female_4_gray from '../../assets/hair/woman-hair4-gray.svg';

class AvatarHair {

    gender;
    type;
    color;
    img;

    hairMap = {
        "female_1_black": female_1_black,
        "female_2_black": female_2_black,
        "female_3_black": female_3_black,
        "female_4_black": female_4_black,
        "female_1_blond": female_1_blond,
        "female_2_blond": female_2_blond,
        "female_3_blond": female_3_blond,
        "female_4_blond": female_4_blond,
        "female_1_brown": female_1_brown,
        "female_2_brown": female_2_brown,
        "female_3_brown": female_3_brown,
        "female_4_brown": female_4_brown,
        "female_1_gray": female_1_gray,
        "female_2_gray": female_2_gray,
        "female_3_gray": female_3_gray,
        "female_4_gray": female_4_gray,
        "male_1_black": male_1_black,
        "male_2_black": male_2_black,
        "male_3_black": male_3_black,
        "male_4_black": male_4_black,
        "male_1_blond": male_1_blond,
        "male_2_blond": male_2_blond,
        "male_3_blond": male_3_blond,
        "male_4_blond": male_4_blond,
        "male_1_brown": male_1_brown,
        "male_2_brown": male_2_brown,
        "male_3_brown": male_3_brown,
        "male_4_brown": male_4_brown,
        "male_1_gray": male_1_gray,
        "male_2_gray": male_2_gray,
        "male_3_gray": male_3_gray,
        "male_4_gray": male_4_gray,
    }

    constructor(gender, type, color) {
        this.gender = gender;
        this.type = type;
        this.color = color;
        this.img = new Image();
        this.img.src = this.hairMap[this.getOptionsString()];
    }

    getOptionsString() {

        return this.gender + "_" + this.type + "_" + this.color;
    }


    renderOnCanvas(context, canvas_width, canvas_height) {
        return new Promise((resolve, reject) => {
            let x_scale = 10;
            let y_scale = 10;

            if (this.gender === "male") {

                switch (this.type) {
                    case "1":

                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 13, canvas_height * 0.07, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 13, canvas_height * 0.07, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }

                        break;
                    case "2":

                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 4, canvas_height * 0.08, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 4, canvas_height * 0.08, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }
                        break;

                    case "3":

                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 15, canvas_height * 0.07, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 15, canvas_height * 0.07, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }
                        break;

                    case "4":

                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 33, canvas_height * 0.07, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) + 33, canvas_height * 0.07, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }

                        break;
                    default:
                        resolve();
                        break;
                }
            }
            else {
                switch (this.type) {
                    case "1":

                        if (this.img.complete) {
                            context.drawImage(this.img, 805, 236, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, 805, 236, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }
                        break;
                    case "2":

                        if (this.img.complete) {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 4, -90, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 4, -90, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }

                        break;

                    case "3":

                        if (this.img.complete) {
                            context.drawImage(this.img, 595, 192, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, 595, 192, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }

                        break;

                    case "4":

                        if (this.img.complete) {
                            context.drawImage(this.img, 810, 220, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        }
                        else {
                            this.img.onload = () => {
                                context.drawImage(this.img, 810, 220, this.img.width * x_scale, this.img.height * y_scale);
                                resolve()
                            };
                        }

                        break;
                    default:
                        resolve();
                        break;
                }
            }

        });

    }



    renderAsOption(context, canvas_width, canvas_height) {

        if (this.type === "none") {
            context.strokeStyle = "gray";
            context.lineWidth = 5

            let margin = 50;
            // draw the first diagonal line
            context.beginPath();
            context.moveTo(margin, margin);
            context.lineTo(canvas_width - margin, canvas_height - margin);
            context.stroke();

            // draw the second diagonal line
            context.beginPath();
            context.moveTo(canvas_width - margin, margin);
            context.lineTo(margin, canvas_height - margin);
            context.stroke();

        }
        else {
            this.img.onload = () => {
                if (this.gender === "male") {
                    const scaleXMale = 2.5; // Scale factor for width
                    const scaleYMale = 2.5; // Scale factor for height
                    context.drawImage(this.img, 10, 40, this.img.width * scaleXMale, this.img.height * scaleYMale);
                }
                else {
                    switch (this.type) {
                        case "1":
                            const scaleXFem = 2; // Scale factor for width
                            const scaleYFem = 2; // Scale factor for height
                            context.drawImage(this.img, 40, 5, this.img.width * scaleXFem, this.img.height * scaleYFem);
                            break;
                        case "2":
                            const scaleXFem2 = 2.2; // Scale factor for width
                            const scaleYFem2 = 2.2; // Scale factor for height
                            context.drawImage(this.img, 40, 10, this.img.width * scaleXFem2, this.img.height * scaleYFem2);
                            break;
                        case "3":
                            const scaleXFem3 = 1.8; // Scale factor for width
                            const scaleYFem3 = 1.8; // Scale factor for height
                            context.drawImage(this.img, 10, 20, this.img.width * scaleXFem3, this.img.height * scaleYFem3);
                            break;
                        case "4":
                            const scaleXFem4 = 2.4; // Scale factor for width
                            const scaleYFem4 = 2.4; // Scale factor for height
                            context.drawImage(this.img, 25, 40, this.img.width * scaleXFem4, this.img.height * scaleYFem4);
                            break;

                        default:
                            break;


                    }
                }
            }
        };
    }


}

export default AvatarHair