import { useContext, useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  CloseButton,
  Flex,
  Text,
  useBreakpointValue,
  AlertTitle,
  Textarea,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../../styles/styles';
import { FirebaseContext } from '../../contexts/firebaseProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { FeedbackReport, MessageType, TherapistSettings } from '../../types/types';
import { t } from 'i18next';

interface UsageLimitNotificationProps {
  onClose: () => void;
  message: string; // The message to display in the alert
  category: "like" | "dislike"; // The category of the feedback
  previousMessages: MessageType[];
  chatID: string,
  userID: string,
  therapistSettings: TherapistSettings // The previous messages that the user has sent
}

const UserFeedbackAlert = (props: UsageLimitNotificationProps) => {
  const [theme] = useState<Theme>(lavenderStyle);
  const notificationWidth = useBreakpointValue({ base: '90%', sm: '90%', md: '700px' }); // Use useBreakpointValue hook to set different widths based on screen size
  const [feedback, setFeedback] = useState('');
  const [isExceedingLimit, setIsExceedingLimit] = useState(false);
  const firebaseInterface = useContext(FirebaseContext);
  const toast = useToast()

  const maxCharacterLimit = 200; // Set your desired character limit here

  useEffect(() => { }, []);

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = event.target.value;
    setFeedback(input);
    setIsExceedingLimit(input.length > maxCharacterLimit);
  };

  const handleFeedbackSubmit = () => {
    if (isExceedingLimit || feedback.length === 0) {
      // Handle the case where the user has exceeded the character limit
      return;
    }
    // You can handle the feedback submission here
    // For example, you can send it to a server or store it in a database
    const feedbackRepport: FeedbackReport = {
      chatID: props.chatID,
      userID: props.userID,
      category: props.category,
      reportedMessage: props.message,
      previousMessages: props.previousMessages,
      timestampInSeconds: Date.now() / 1000,
      therapistSettings: props.therapistSettings, 
    }
    firebaseInterface.addFeedbackReport(feedbackRepport);
    toast({
      position: 'top',
      render: () => (
          <Flex justifyContent={"center"} color='white' p={5} bg={theme.completementaryColor1} borderRadius="10" >
              {t('FeedbackSuccesSendToast')}
          </Flex>
      ),
  })

    setFeedback('');
    props.onClose();
  };

  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="9999"
      backgroundColor="rgba(0, 0, 0, 0.5)"
    >
      <Alert
        status={'info'}
        variant="subtle"
        width={notificationWidth}
        bgColor={'white'}
        borderRadius="20px"
        borderColor={theme.secondaryColor}
        borderWidth={'1px'}
      >
        <AlertDescription width="100%">
          <Flex width="100%" justifyContent={'center'} alignItems="center" flexDir="column">

            <Flex ml='10' width={'100%'} justifyContent='flex-start'>
              <FontAwesomeIcon color={theme.primaryColor} icon={props.category === 'dislike' ? faThumbsDown : faThumbsUp} size='2x' />
            </Flex>

            <AlertTitle fontSize='lg' mt='-30px' mb="5px">{t('UserFeedbackAlert_title')}</AlertTitle>
            <Flex>

            </Flex>
            <Box
              paddingLeft="10px"
              paddingRight="10px"
              backgroundColor="#f5f6f8"
              borderRadius="10px"
              paddingBottom="5px"
              paddingTop="5px"
              marginLeft="5px"
              marginBottom={'5px'}
              marginTop={'5px'}
            >
              <Text>{props.message}</Text>
            </Box>
            <Textarea
              value={feedback}
              onChange={handleFeedbackChange}
              placeholder={props.category === 'dislike' ?  t('UserFeedbackAlert_place_holder_dislike') as string: t('UserFeedbackAlert_place_holder_like') as string}
              width="100%"
              mt="5px"
              resize="none"
              minHeight="100px"
              marginBottom="10px"
              isInvalid={isExceedingLimit} // Add isInvalid prop to show the red warning
            />
            {isExceedingLimit && (
              <Text color="red" textAlign="center" mb="10px">
                {t('UserFeedbackAlert_character_limit_exceeded')} ({feedback.length}/{maxCharacterLimit})
              </Text>
            )}
            <Button colorScheme={theme.colorScheme} onClick={handleFeedbackSubmit}>
              {t('UserFeedbackAlert_character_limit_submit')}
            </Button>
          </Flex>
        </AlertDescription>
        <CloseButton color={theme.primaryColor} onClick={props.onClose} position="absolute" right="8px" top="8px" />
      </Alert>
    </Flex>
  );
};

export default UserFeedbackAlert;
