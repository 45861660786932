import { useState } from 'react';
import { Button, Flex, Heading, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../../styles/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import CloseInsightCreatorButton from './CloseInsightCreatorButton';
import PreviousScreenButton from '../sharedComponents/navigation/PreviousScreenButton';
import { t } from 'i18next';


interface DescriptionFlowStepProps {
  goNextFlowStep: () => void;
}

function InstructionScreen(props: DescriptionFlowStepProps) {


  const [theme] = useState<Theme>(lavenderStyle)
  return (


    <Flex width="100%" flexDir="column" alignItems={"center"} >
        <Flex width="100%" justifyContent={"flex-end"} mt="2" >
            


<CloseInsightCreatorButton />
                  </Flex>
      <Flex width="80%" justifyContent={"center"}>
        <Heading size="lg"  color={theme.primaryColor}  >
          {t('InsightsCreator_instructionScreen_title')}
        </Heading>
      </Flex>
 

      <Flex width="90%" pb="10" pt="10" flexDir="column" maxWidth={"800px"}>
        <UnorderedList>
          <ListItem> {t('InsightsCreator_instructionScreen_description_list_item_1')}</ListItem>
          <ListItem>{t('InsightsCreator_instructionScreen_description_list_item_2')}</ListItem>
          <ListItem>{t('InsightsCreator_instructionScreen_description_list_item_3')} </ListItem>
          <ListItem>{t('InsightsCreator_instructionScreen_description_list_item_4')} </ListItem>
        </UnorderedList>


        <Flex mt="5">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <Text fontSize="sm" color="gray.500" ml="2" mt="-2">{t('InsightsCreator_instructionScreen_description_warning')}</Text>
        </Flex>

      </Flex>

      <Flex columnGap="2" mt="5" pb="5">
        <Button flex={1} onClick={props.goNextFlowStep} colorScheme={theme.colorScheme}>
        {t('InsightsCreator_instructionScreen_next_button')}
        </Button>
      </Flex>


    </Flex>


  );
}

export default InstructionScreen;
