import { useContext, useState } from "react";
import {
    Box,
    Button, Flex, FormControl, Heading, Input, Text
} from '@chakra-ui/react'

import { Link as Routerlink, useLocation } from "react-router-dom";

import { lavenderStyle, Theme } from "../../styles/styles";
import Avatar from "../../components/avatar/Avatar";
import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { FirebaseContext } from "../../contexts/firebaseProvider";

function NamePsychologistSettingScreen() {

    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile, currentUser } = useContext(UserProfileContext);
    const [isError, setIsError] = useState(false);
    const [placeHolderText] = useState(t('NamePsychologistSettingScreen_inputfield_placeholder'))
    const [psychologistName, setPsychologistName] = useState(userProfile.therapistSettings.psychologistName);
    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/modifyPsychologistScreen"));
    const firebaseInterface = useContext(FirebaseContext);
    const [nameTooLongError, setNameTooLongError] = useState(false);


    const handleGenerateRandomName = () => {
        if (userProfile.therapistSettings.avatar.chosenGender === "male") {
            const maleNames = ["John", "Michael", "David", "William", "James", "Andrew", "Jacob", "Ethan", "Daniel", "Matthew", "Noah", "Lucas", "Mason", "Elijah", "Oliver", "Benjamin", "Liam", "Aiden", "Logan", "Hendrik", "Thomas", "Johannes", "Jeroen", "Pieter", "Willem", "Jelle", "Daan", "Sander", "Bram", "Luc", "Diego", "Gabriel", "Rafael", "Eduardo", "Richard", "Mark", "Anthony"];
            const randomIndex = Math.floor(Math.random() * maleNames.length);
            const randomName = maleNames[randomIndex];
            setPsychologistName(randomName);
        } else {
            const femaleNames = ["Emily", "Sarah", "Jessica", "Elizabeth", "Jennifer", "Emma", "Sophia", "Olivia", "Isabella", "Mia", "Ava", "Charlotte", "Amelia", "Harper", "Evelien", "Anna", "Lotte", "Femke", "Lisanne", "Laura", "Marieke", "Sanne", "Anne", "Eline", "Lisa", "Maartje", "Roos", "Julia", "Isabel", "Valentina", "Camila", "Clara", "Elena", "Mary", "Susan", "Ashley", "Linda"];
            const randomIndex = Math.floor(Math.random() * femaleNames.length);
            const randomName = femaleNames[randomIndex];
            setPsychologistName(randomName);
        }
    }




    function updatePsychologistName(psychologistName: string) {
        userProfile.therapistSettings.psychologistName = psychologistName;
        if (currentUser) {
            firebaseInterface.updatePsychologistName(currentUser.uid, userProfile.therapistSettings.psychologistName);
        }
        else {
            throw new Error("No user logged in");
        }

    }

    const handleNameChange = (event: any) => {
        const input = event.target.value;
        if (input.length <= 40) {
            setPsychologistName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
            
            setNameTooLongError(false);
        } else {
            setNameTooLongError(true);
        }
    };

    return (
        <Flex flexDirection='column' maxHeight="99svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto"    >
            {isFirstTime ? <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/avatarCustomizationScreen" /> </Flex> : <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/modifyPsychologistScreen" /> </Flex>}

            <Flex width={"100%"} flexDir={"column"} >
                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px' marginLeft="5px">
                        {t('NamePsychologistSettingScreen_title')}
                    </Heading>
                </Flex>
            </Flex>
            <Flex flexDir="column" alignItems="center" height={"100%"} width="100%" marginTop="10px">
                {isFirstTime ?
                    <Flex alignItems="flexstart" justifyContent="center" >
                        {[...Array(7)].map((_, index) => (
                            <Box
                                key={index}
                                w="2"
                                h="2"
                                borderRadius="full"
                                bg={1 === index ? theme.primaryColor : theme.secondaryColor}
                                mx="2"
                            />
                        ))}
                    </Flex>
                    : null}
                <Flex width="60%" justifyContent={"center"} mt="10px" >
                    <Avatar avatarParams={userProfile.therapistSettings.avatar} />
                </Flex>
                <Flex flexDirection={"column"} justifyContent="flex-start" width="100%" >
                    <FormControl id="username">

                        <Flex flexDir="column" width="100%" alignItems={"center"} marginTop="20px">
                            <Input maxWidth={"300px"} placeholder={placeHolderText?.toString()}
                                _placeholder={{ opacity: 0.6, color: 'inherit' }} type="text" value={psychologistName} onChange={handleNameChange} isRequired />
                            {!nameTooLongError ? (
                                <Flex></Flex>
                            ) : (
                                <Text fontSize="sm" >  {t('NamePsychologistSettingScreen_nameTooLongError')}</Text>)}

                            {!isError ? (
                                <Flex></Flex>
                            ) : (
                                <Text fontSize="sm" >  {t('NamePsychologistSettingScreen_forgotnametext')}</Text>)}
                        </Flex>

                    </FormControl>

                    <Flex justifyContent={"center"} marginTop="10px" marginBottom="10px">
                        <Text >{t('NamePsychologistSettingScreen_or')}</Text>
                    </Flex>

                    <Flex width="100%" justifyContent={"center"} >
                        <Button onClick={() => handleGenerateRandomName()}> {t('NamePsychologistSettingScreen_namegenerater_btn')}</Button>
                    </Flex>
                </Flex>

                <Flex justifyContent='center' alignContent={"flex-end"} height="100%" width="100%" marginTop="20px" flexDirection='row' >
                    {!(psychologistName === '') ? (
                        <Button as={Routerlink} to={isFirstTime ? "/personalitySettingScreen" : "/modifyPsychologistScreen"} colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} onClick={() => updatePsychologistName(psychologistName)}> {isFirstTime ? t('SettingScreen_first_time_done') : t('SettingScreen_second_time_done')} </Button>
                    ) : (
                        <Button colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} onClick={() => setIsError(true)}>{isFirstTime ? t('SettingScreen_first_time_done') : t('SettingScreen_second_time_done')} </Button>
                    )}
                </Flex>
            </Flex>
        </Flex >
    )

}

export default NamePsychologistSettingScreen