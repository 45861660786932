
class PineconeInterface {
    async addMessageToPinecone(userId: string, message: string) {
        console.log('addMessageToPinecone', userId, message)
        const url = 'https://us-central1-psychobot-90921.cloudfunctions.net/handle_remember_message'
        const data = {
            user_id: userId,
            message: message
        }
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            response.text().then(text => console.log(text))
            if (!response.ok) {
                throw new Error('Error adding message to Pinecone.');
            }
            return response;
        })
    }

    async removeMessageFromPinecone(userId: string, message: string) {
        const url = 'https://us-central1-psychobot-90921.cloudfunctions.net/handle_forget_message'
        const data = {
            user_id: userId,
            message: message
        }
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            response.text().then(text => console.log(text))
            if (!response.ok) {
                throw new Error('Error removing message from Pinecone.');
            }
            return response;
        })
    }
}

export default PineconeInterface;