import {
  Flex, Text, Box, IconButton, Tooltip,
} from '@chakra-ui/react'
import { TypeAnimation } from 'react-type-animation';
import { MessageType, TypeSpeed } from '../../types/types';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from "../../styles/styles";
import { t} from 'i18next';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
interface MessageProps {
  message: MessageType;
  theme: Theme;
  showCursor: boolean;
  typeSpeed: TypeSpeed;
  bounce?: boolean;
  handleMessageSave?: (message: MessageType) => void;
  handleMessageLiked?: (message: MessageType) => void;
  handleMessageDisliked?: (message: MessageType) => void;
}

function Message(props: MessageProps) {
  let theme = props.theme;

  const handleBrainClick = () => {
    if (props.handleMessageSave) {
      props.handleMessageSave(props.message);
    }
  }

  const handleLikeClick = () => {
    if (props.handleMessageLiked) {
      props.handleMessageLiked(props.message);
    }
  }

  const handleDislikeClick = () => {
    if (props.handleMessageDisliked) {
      props.handleMessageDisliked(props.message);
    }
  }






  if (props.message.sender !== 'user')
    return (
      <Flex
        maxWidth="95%"
        alignItems={'center'}
      >
        <Box
          paddingLeft="10px"
          paddingRight="10px"
          backgroundColor="#f5f6f8"
          borderRadius="10px"
          paddingBottom="5px"
          paddingTop="5px"
          marginLeft="5px"
          marginBottom={"5px"}
          marginTop={"5px"}
        >
          {props.message.animation ? (
            <TypeAnimation
              cursor={props.showCursor}
              sequence={[props.message.message]}
              wrapper="span"
              speed={props.typeSpeed}
              style={{ color: "#000" }}
            />
          ) : (
            <Text color="#000">{props.message.message}</Text>
          )}

        </Box>
        <Flex pl='1'>
          {!props.message.isDisliked &&
          <IconButton
            aria-label="send"
            icon={<FontAwesomeIcon icon={faThumbsUp}  size='1x' />}
            colorScheme={'blackAlpha'}
            variant={props.message.isLiked?'outline':"ghost"}
            size='sm'
            onClick={handleLikeClick}
          />
        }
        {!props.message.isLiked  && 
          <IconButton
            aria-label="send"
            icon={<FontAwesomeIcon icon={faThumbsDown}  size='1x' />}
            colorScheme={'blackAlpha'}
            variant={props.message.isDisliked?'outline':"ghost"}
            size='sm'
            onClick={handleDislikeClick}
          />
          }

        </Flex>

      </Flex>
    );
  else {
    return (<Flex
      maxWidth="95%"
    >
      {props.handleMessageSave && (

        <Tooltip hasArrow={true} label={!props.message.isSaved ? t('Message_tooltip_remember') : t('Message_tooltip_forget')} maxWidth='150px' aria-label="Guardar mensaje" placement="left" bgColor={!props.message.isSaved ? theme.primaryColor : theme.completementaryColor1} p='2' borderRadius={'15px'} >
          <IconButton
            aria-label="send"
            icon={<FontAwesomeIcon icon={faBrain} beatFade={props.message.isSaved} size="lg" bounce={props.bounce && !props.message.isSaved} />}
            variant={props.message.isSaved ? "link" : "link"}
            boxSize="18px"
            fontSize="12px"
            color={!props.message.isSaved ? theme.brainButtonColor : theme.completementaryColor1}
            outline={!props.message.isSaved ? "none" : "none"}
            paddingTop="12px"
            paddingBottom="12px"
            marginRight="8px"
            marginTop="6px"
            onClick={handleBrainClick}
          />
        </Tooltip>
      )

      }

      <Text
        paddingLeft="10px"
        paddingRight="10px"
        paddingBottom="5px"
        paddingTop="5px"
        backgroundColor={props.message.isSaved ? theme.completementaryColor1 : theme.messageBackgroundColor}
        borderRadius="10px"
        marginRight={"10px"}
        color={theme.messageTextColor}
      >
        {props.message.message}
      </Text>

    </Flex>)
  }

}
export default Message