
import {
    Box,
    Button,
    Flex,
    Heading, Text, useToast
} from "@chakra-ui/react";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import SavedMessage from "../../components/chat/SavedMessage";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { lavenderStyle, Theme } from "../../styles/styles";
import { MessageType, RateLimitCheck } from "../../types/types";
import { t } from "i18next";
import PineconeInterface from "../../services/pinecone";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { Link as Routerlink, useLocation, } from "react-router-dom";
import AddRememberedMessageAlertDialogButton from "../../components/rememberedMessagesScreen/AddRememberedMessageButton";
import { isAllowedToRememberMessage } from "../../security/rateLimitChecks";
import UsageLimitAlert from "../../components/alerts/UsageLimitAlert";

function RememberedMessagesScreen() {

    const { userProfile, setUserProfile,  currentUser } = useContext(UserProfileContext);
    const [theme] = useState<Theme>(lavenderStyle)
    const firebaseInterface = useContext(FirebaseContext);
    const [showUsageLimitNotification, setShowUsageLimitNotification] = useState<boolean>(false);
    const [currentUsageLimitNotification, setCurrentUsageLimitNotification] = useState<RateLimitCheck>();


    const location = useLocation();
    const [isFirstTime] = useState((location.state && ((location.state.from === "/nameUserSettingScreen") || (location.state.from === "/manualScreen") )));
    const toast = useToast()


    async function logMessageRemembered() {
        if (!currentUser) {
            throw new Error("User is not logged in")
        }

        setUserProfile((oldProfile) => {
            return {
                ...oldProfile,
                dailyStats: {
                    ...oldProfile.dailyStats,
                    totalMessagesRemembered: oldProfile.dailyStats.totalMessagesRemembered + 1
                },
                totalStats: {
                    ...oldProfile.totalStats,
                    totalMessagesRemembered: oldProfile.totalStats.totalMessagesRemembered + 1

                }
            }
        })
        firebaseInterface.logMessageRemembered(currentUser.uid)
    }


    const updateSavedMessages = (newSavedMessages: MessageType[]) => {
        console.log("updating saved messages", newSavedMessages)
        setUserProfile((oldProfile) => {
            return {
                ...oldProfile,
                therapistSettings: {
                    ...oldProfile.therapistSettings,
                    rememberedMessages: newSavedMessages
                }
            }
        })
    }

    const handleDeleteSavedMessage = (message: MessageType) => {
        if (!currentUser) {
            throw new Error("No user logged in")
        }
        const newSavedMessages = userProfile.therapistSettings.rememberedMessages.filter((savedMessage) => savedMessage.timestampInSeconds !== message.timestampInSeconds)
        updateSavedMessages(newSavedMessages)
        firebaseInterface.deleteRememberedMessage(currentUser.uid, message)
        const pineconeInterface = new PineconeInterface();
        pineconeInterface.removeMessageFromPinecone(currentUser.uid, message.message)

    }

    const handleAddMessage = (messageText: string) => {
        if (!currentUser) {
            throw new Error("No user logged in")
        }

        const rateLimitCheck = isAllowedToRememberMessage(userProfile.subscriptionId, userProfile.dailyStats.totalMessagesRemembered, userProfile.therapistSettings.rememberedMessages.length)
        if (!rateLimitCheck.allowed) {
            setShowUsageLimitNotification(true)
            setCurrentUsageLimitNotification(rateLimitCheck)
            return;
        }

        let message: MessageType = {
            message: messageText,
            timestampInSeconds: Date.now(),
            isSaved: true,
            animation: false,
            sender: "user"
        }
        
        const newSavedMessages = [...userProfile.therapistSettings.rememberedMessages, message]
        updateSavedMessages(newSavedMessages)
        firebaseInterface.addRememberedMessage(currentUser.uid, message)
        const pineconeInterface = new PineconeInterface();
        pineconeInterface.addMessageToPinecone(currentUser.uid, message.message)
        // toast
        toast({
            position: 'top',
            duration: 3000,
            render: () => (
                <Flex justifyContent={"center"} color='white' p={3} bg={theme.completementaryColor1} borderRadius="10" >
                    {t('MessageSaved_toast')}
                </Flex>
            ),
        })

        logMessageRemembered()
    }
    const closeUsageLimitNotification = () => {
        setShowUsageLimitNotification(false)
    }


    return (<Flex flexDirection='column' height={"99vh"} width="96%" alignItems="center" marginLeft="auto"
        marginRight="auto" >
         {showUsageLimitNotification && currentUsageLimitNotification && (
            <UsageLimitAlert onClose={closeUsageLimitNotification} rateLimitCheck={currentUsageLimitNotification} />
        )}
        <Flex width="100%" justifyContent={"flex-start"} mt="2">
            {isFirstTime ? <PreviousScreenButton previousScreen="/nameUserSettingScreen" /> : <PreviousScreenButton previousScreen={location.state.from}  />}
        </Flex>

        <Heading  size='lg' paddingTop="5px"  >  {isFirstTime ? t('RememberedMessagesScreen_description_title_first_time') : t('RememberedMessagesScreen_description_title')}</Heading>
        {isFirstTime ?
            <Flex alignItems="flexstart" justifyContent="center" marginTop="10px">
                {[...Array(7)].map((_, index) => (
                    <Box
                        key={index}
                        w="2"
                        h="2"
                        borderRadius="full"
                        bg={6 === index ? theme.primaryColor : theme.secondaryColor}
                        mx="2"
                    />
                ))}
            </Flex>
            : null}

        <Flex flexDir="column" width="95%" marginTop="10px" justifyContent={"center"} alignItems="center" >
            {isFirstTime ?
                <Flex p="3" borderRadius="20" flexDir="column">
                    <Text fontWeight={'bold'}> {t('RememberedMessagesScreen_description_first_time')}
                    </Text>
                    <Text mt='5px' fontWeight={'light'} fontStyle={'italic'}> {t('RememberedMessagesScreen_description_examples_first_time')}
                    </Text>
                </Flex>
                :
                <Flex p="3" bgColor={'#f6f6ff'} borderRadius="20" flexDir="column">
                    <Text color='blackAlpha.700' fontWeight={'semibold'}> {t('RememberedMessagesScreen_description_part_1')} 
                    </Text>
                    <Text mt='5px' fontWeight={'light'} fontStyle={'italic'}> {t('RememberedMessagesScreen_description_examples_first_time')}
                    </Text>
                    <Text color='blackAlpha.700'  mt='5px' >  {t('RememberedMessagesScreen_description_part_2')} <FontAwesomeIcon border={true} color={theme.primaryColor} icon={faBrain} beatFade={true} size='1x' /> {t('RememberedMessagesScreen_description_part_3')}
                    </Text>
                </Flex>
            }
            <AddRememberedMessageAlertDialogButton handleAddMessage={handleAddMessage} />
        </Flex>

        <Flex width="90%" height={'100%'} mb="5" borderWidth={"1px"} borderColor={theme.thirdColor} padding="4" borderRadius={"2xl"} >
            {userProfile.therapistSettings.rememberedMessages.length !== 0 ?
                <Flex flexDir="column" width="100%" height={"100%"}   >
                    {userProfile.therapistSettings.rememberedMessages.map((message: MessageType, index: number) =>
                        <Flex flexDir="column" width="100%" key={index} >
                            <SavedMessage message={message} messageBackgroundColor={"#f5f6f8"} messageTextColor={"#000"} showCursor={false} typeSpeed={55} handleDeleteSavedMessage={handleDeleteSavedMessage} />
                        </Flex>
                    )}
                </Flex>
                :
                <Flex flexDir="column" justifyContent="center" alignItems={"center"} width="100%">
                        <Text color={theme.primaryColor}>{t('RememberedMessagesScreen_no_remembered_messages_text')}</Text>
                </Flex>
            }
        </Flex>
        {isFirstTime &&
            <Flex justifyContent='center' mt='20px' height="100%" width="100%" flexDirection='row' >
                <Button as={Routerlink} to={"/manualScreen"} colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} > {t('SettingScreen_first_time_done')}</Button>
            </Flex>
        }
    </Flex>
    );
}

export default RememberedMessagesScreen;
