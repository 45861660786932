import { useState } from 'react';
import { IconButton, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useBreakpointValue } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { lavenderStyle, Theme } from '../../styles/styles';
import { t } from 'i18next';


const CloseInsightCreatorButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [theme] = useState<Theme>(lavenderStyle)
  const buttonSize = useBreakpointValue({ base: "md", sm: "lg", md:"lg"}); // Use useBreakpointValue hook to set different widths based on screen size

  const path = window.location.pathname;


  const handleConfirmClose = () => {
    // Close the tool or perform any other necessary action
    const lastPart = path.split("/").pop();

    navigate('/insightsScreen/' + lastPart);

    onClose();
  };

  return (
    <>
      <IconButton
        aria-label="Go back"
        variant="outline"
        colorScheme={theme.colorScheme}// Update with your desired color scheme
        icon={<CloseIcon boxSize="5"/>}
        size={buttonSize}
    
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('CloseInsightCreator_popover_header')}</ModalHeader>
          <ModalBody>
          {t('CloseInsightCreator_popover_body')}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme={theme.colorScheme} mr={3} onClick={handleConfirmClose}>
            {t('CloseInsightCreator_popover_confirm')}
            </Button>
            <Button colorScheme={theme.colorScheme} variant="ghost" onClick={onClose}>
            {t('CloseInsightCreator_popover_reject')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CloseInsightCreatorButton;
