import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, CircularProgress, CircularProgressLabel, Divider, Flex, Heading, Link, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";

interface LandingPageFeaturesProps {
    isMobile: boolean
}

function LandingPageScienceSection(props: LandingPageFeaturesProps) {
    const [theme] = useState<Theme>(lavenderStyle)

    return (
        <Flex width={'100%'} flexDir='column' alignItems={'center'} mt='40px' bgColor={'#f6f6ff'} borderRadius={'xl'}>
            <Flex width='96%' justifyContent={'center'} >
                <Heading size={props.isMobile ? '2xl' : '3xl'} color={theme.primaryColor} mt='20px' >
                    {t('LandingPageScienceSection_title')}
                </Heading>
            </Flex>


            <Flex justifyContent='center' maxWidth={'1000px'} flexDir='column' width='95%' mt='20px' >
                <Text fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}>
                {t('LandingPageScienceSection_description')} <Link fontWeight={'extrabold'} href='https://storage.googleapis.com/psyscribe_paper/paper_psyscribe.pdf' isExternal>
                {t('LandingPageScienceSection_link_paper_label')} <ExternalLinkIcon mx='2px' />
                    </Link>.

                </Text>

            </Flex>


            <Divider width='80%' borderColor={theme.thirdColor} mt='20px' mb='20px'></Divider>
        

            <Flex justifyContent='center' maxWidth={'1000px'} flexDir='column' width='95%' mt='10px' >
                <Box textAlign="center">
                <Text fontSize={props.isMobile ? 'lg' : 'xl'} fontWeight={'bold'} >     {t('LandingPageScienceSection_result_1_title')} </Text>

                    <CircularProgress mt='20px' value={91} color={theme.completementaryColor4} size="120px">
                        <CircularProgressLabel fontWeight={'bold'} color={theme.primaryColor}>{91}%</CircularProgressLabel>
                    </CircularProgress>
                    <Text  mt='20px' fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}>    {t('LandingPageScienceSection_result_1_description')} </Text>

                </Box>
            </Flex>
            <Divider width='80%' borderColor={theme.thirdColor} mt='40px'></Divider>
            <Flex justifyContent='center' maxWidth={'1000px'} flexDir='column' width='95%' mt='20px' >
                <Box textAlign="center">
                    <Text fontSize={props.isMobile ? 'lg' : 'xl'} fontWeight={'bold'} >   {t('LandingPageScienceSection_result_2_title')} </Text>
                    <Text  mt='20px' fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}> {t('LandingPageScienceSection_result_2_description')} </Text>


                </Box>

            </Flex>
            <Divider width='80%' borderColor={theme.thirdColor} mt='40px'></Divider>
            <Flex justifyContent='center' maxWidth={'1000px'} flexDir='column' width='95%' mt='20px'  mb='40px' >
                <Box textAlign="center">
                    <Text fontSize={props.isMobile ? 'lg' : 'xl'} fontWeight={'bold'} > {t('LandingPageScienceSection_result_3_title')}</Text>
                    <Text  mt='20px' fontSize={props.isMobile ? 'sm' : 'md'} fontWeight={'bold'} color={"blackAlpha.700"}> {t('LandingPageScienceSection_result_3_description')} </Text>


                </Box>

            </Flex>



        </Flex>
    )

}
export default LandingPageScienceSection;