import { useState } from "react";
import { Box, Portal, Popover, PopoverTrigger, PopoverContent, PopoverBody, List, ListItem, Button, PopoverArrow } from "@chakra-ui/react";
import { lavenderStyle, Theme } from "../../styles/styles";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";



const FeaturesDrowDownMenu = () => {
  const [theme] = useState<Theme>(lavenderStyle);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();

  const location = window.location.pathname;




  const handleButtonClicked = (headerToScrollTo: string) => {

    if (location !== '/') {
      navigate('/');
      return
    }
 


    const headingElement = document.getElementById(headerToScrollTo);

    if (headingElement) {
      headingElement.scrollIntoView({ behavior: 'smooth' });
    }


    setTimeout(() => {

      setIsOpen(false);
    }
      , 900);
    //setIsOpen(false);


  };

  return (
    <Box as="header">
      <Popover isOpen={isOpen} onClose={handleClose}>
        <PopoverTrigger>
          <Button rightIcon={<ChevronDownIcon />} color={theme.primaryColor} variant='link' cursor="pointer" onClick={handleOpen}>{t('LandingPageHeader_features_button')}</Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            zIndex={1001}
            backgroundColor="white"
          >
            <PopoverArrow />

            <PopoverBody p="4px" width='100%'>
              <List >
              <ListItem p='2'>
                  <Button color={'blackAlpha.700'} alignItems={'center'} justifyContent={'flex-start'} width='100%' colorScheme={theme.colorScheme} variant="ghost" onClick={() => handleButtonClicked("aiTherapist")}>{t('LandingPageHeader_features_ai_therapist_button')}</Button>
                </ListItem>
              <ListItem p='2' >
                  <Button  color={'blackAlpha.700'} alignItems={'center'} justifyContent={'flex-start'} width='100%' colorScheme={theme.colorScheme} variant="ghost" onClick={() => handleButtonClicked('conversationInsights')}>{t('LandingPageHeader_features_conversation_insights_button')}</Button>
                </ListItem>
             
                <ListItem p='2'>
                  <Button color={'blackAlpha.700'} alignItems={'center'} justifyContent={'flex-start'} width='100%' colorScheme={theme.colorScheme} variant="ghost" onClick={() => handleButtonClicked("landingPagePersonalizations")}>{t('LandingPageHeader_features_personalizable_button')}</Button>
                </ListItem>
                <ListItem p='2'>
                  <Button color={'blackAlpha.700'} alignItems={'center'} justifyContent={'flex-start'} width='100%' colorScheme={theme.colorScheme} variant="ghost" onClick={() => handleButtonClicked('longTermMemory')}>{t('LandingPageHeader_features_longtermmemmory_button')}</Button>
                </ListItem>
              
                {/* Add more feature items as needed */}
              </List>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};

export default FeaturesDrowDownMenu;
