import {
    Flex, Text, Box, IconButton, Tooltip,
  } from '@chakra-ui/react'
  import { TypeAnimation } from 'react-type-animation';
  import { MessageType, TypeSpeed } from '../../types/types';
  import { faBrain } from '@fortawesome/free-solid-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { Theme } from "../../styles/styles";
  import { t} from 'i18next';
import { useState } from 'react';
  interface MessageProps {
    message: MessageType;
    theme: Theme;
    typeSpeed: TypeSpeed;
    bounce?: boolean;
    showCursor: boolean;
  }
  
  function TestChatMessage(props: MessageProps) {
    let theme = props.theme;


    const [isBouncing, setIsBouncing] = useState<boolean>(false)
  
    const handleBrainClick = () => {
        setIsBouncing(!isBouncing)

    }

  
  
  
  
    if (props.message.sender !== 'user')
      return (
        <Flex
          maxWidth="90%"
          alignItems={'center'}
        >
          <Box
            paddingLeft="10px"
            paddingRight="10px"
            backgroundColor="#f5f6f8"
            borderRadius="10px"
            paddingBottom="5px"
            paddingTop="5px"
            marginLeft="5px"
            marginBottom={"5px"}
            marginTop={"5px"}
          >
            {props.message.animation ? (
              <TypeAnimation
                cursor={props.showCursor}
                sequence={[props.message.message]}
                wrapper="span"
                speed={props.typeSpeed}
                style={{ color: "#000" }}
              />
            ) : (
              <Text color="#000">{props.message.message}</Text>
            )}
  
          </Box>
      
  
        </Flex>
      );
    else {
      return (<Flex
        maxWidth="90%"
      >
      
  
          <Tooltip hasArrow={true} label={!props.message.isSaved ? t('Message_tooltip_remember') : t('Message_tooltip_forget')} maxWidth='150px' aria-label="Guardar mensaje" placement="left" bgColor={!isBouncing ? theme.primaryColor : theme.completementaryColor1} p='2' borderRadius={'15px'} >
            <IconButton
              aria-label="send"
              icon={<FontAwesomeIcon icon={faBrain} beatFade={isBouncing} size="lg" bounce={ !isBouncing} />}
              variant={props.message.isSaved ? "link" : "link"}
              boxSize="18px"
              fontSize="12px"
              color={!isBouncing ? theme.brainButtonColor : theme.completementaryColor1}
              outline={!isBouncing ? "none" : "none"}
              paddingTop="12px"
              paddingBottom="12px"
              marginRight="8px"
              marginTop="6px"
              onClick={handleBrainClick}
            />
          </Tooltip>
        
  
   
  
        <Text
          paddingLeft="10px"
          paddingRight="10px"
          paddingBottom="5px"
          paddingTop="5px"
          backgroundColor={isBouncing ? theme.completementaryColor1 : theme.messageBackgroundColor}
          borderRadius="10px"
          marginRight={"10px"}
          color={theme.messageTextColor}
        >
          {props.message.message}
        </Text>
  
      </Flex>)
    }
  
  }
  export default TestChatMessage