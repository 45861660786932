import { useRef, useEffect, useState } from "react";
import {
    Flex
} from '@chakra-ui/react'
import Message from "./Message";
import { MessageType, TypeSpeed } from "../../types/types";

interface MessageBoxProps {
    messages: any;
    theme: any;
    isWaitAnimationVisible: boolean;
    innerRef: any;
    typeSpeed: TypeSpeed
    handleMessageSave: (message: MessageType) => void;
    handleMessageLiked: (message: MessageType) => void;
    handleMessageDisliked: (message: MessageType) => void;
}

function MessageBox(props: MessageBoxProps) {
    const elementRef = useRef<HTMLDivElement>(null);
    const theme = props.theme;
    const [typingMessage] = useState<MessageType>({
        message: "...",
        sender: "scroll",
        animation: true,
        timestampInSeconds: 0,
        isSaved: false
    });


    useEffect(() => {
        if (elementRef && elementRef.current) {
            elementRef.current.addEventListener('DOMNodeInserted', event => {
                // @ts-ignore
             
                const { relatedNode } = event;
                const className = relatedNode.classList[0];
                if(className === 'css-whrynk'){
                    return
                }
           
                    
                const { currentTarget: target } = event;
                // @ts-ignore


                // @ts-ignore
                target.scroll({ top: target.scrollHeight });
            });
        }
    }, [props.messages]);


    return (
        <Flex
            ref={elementRef}
            flexDirection="column"
            width="100%"
            height="100%"
            rowGap="12px"
            overflowY="auto"
            pb="40px"
        >
            {props.messages.map((message: MessageType, index: number) => (
                <Flex flexDirection="column" key={index}>
                    {message.sender === 'psychologist' && (
                        <Flex width="100%" justifyContent="flex-start" columnGap="1px" paddingLeft="2px" >
                            <Message key={index} theme={theme} message={message} showCursor={false} typeSpeed={props.typeSpeed} handleMessageSave={props.handleMessageSave} handleMessageLiked={props.handleMessageLiked} handleMessageDisliked={props.handleMessageDisliked} />
                        </Flex>
                    )}
                    {message.sender === 'user' && (
                        <Flex width="100%"
                            justifyContent="flex-end"
                        >
                            <Message key={index} theme={theme} message={message} showCursor={false} typeSpeed={props.typeSpeed} handleMessageSave={props.handleMessageSave} bounce={index%10===0||index%10===1} />
                        </Flex>
                    )}
                    {((index === (props.messages.length - 1)) && props.isWaitAnimationVisible) && (
                        <Flex width="100%" justifyContent="flex-start" columnGap="1px" paddingLeft="2px" >
                            <Message theme={theme} message={typingMessage} showCursor={true} typeSpeed={55} handleMessageSave={props.handleMessageSave} />
                        </Flex>
                    )}
                </Flex>
            ))}

        </Flex>
    );

}

export default MessageBox;
