// heads
import male_1_black_young from '../../assets/heads/avatar-black-young.svg';
import male_1_black_middle from '../../assets/heads/avatar-black-middle.svg';
import male_1_black_old from '../../assets/heads/avatar-black-old.svg';
import male_1_brown_young from '../../assets/heads/avatar-brown-young.svg';
import male_1_brown_middle from '../../assets/heads/avatar-brown-middle.svg';
import male_1_brown_old from '../../assets/heads/avatar-brown-old.svg';
import male_1_cauca_young from '../../assets/heads/avatar-cauc-young.svg';
import male_1_cauca_middle from '../../assets/heads/avatar-cauc-middle.svg';
import male_1_cauca_old from '../../assets/heads/avatar-cauc-old.svg';
import female_1_black_young from '../../assets/heads/avatar-black-young-f.svg';
import female_1_black_middle from '../../assets/heads/avatar-black-middle-f.svg';
import female_1_black_old from '../../assets/heads/avatar-black-old-f.svg';
import female_1_brown_young from '../../assets/heads/avatar-brown-young-f.svg';
import female_1_brown_middle from '../../assets/heads/avatar-brown-middle-f.svg';
import female_1_brown_old from '../../assets/heads/avatar-brown-old-f.svg';
import female_1_cauca_young from '../../assets/heads/avatar-cauc-young-f.svg';
import female_1_cauca_middle from '../../assets/heads/avatar-cauc-middle-f.svg';
import female_1_cauca_old from '../../assets/heads/avatar-cauc-old-f.svg';

class AvatarHead {
    gender;
    skin;
    age;
    img;

    headMap = {
        "female_1_black_young": female_1_black_young,
        "female_1_black_middle": female_1_black_middle,
        "female_1_black_old": female_1_black_old,
        "female_1_brown_young": female_1_brown_young,
        "female_1_brown_middle": female_1_brown_middle,
        "female_1_brown_old": female_1_brown_old,
        "female_1_cauca_young": female_1_cauca_young,
        "female_1_cauca_middle": female_1_cauca_middle,
        "female_1_cauca_old": female_1_cauca_old,
        "male_1_black_young": male_1_black_young,
        "male_1_black_middle": male_1_black_middle,
        "male_1_black_old": male_1_black_old,
        "male_1_brown_young": male_1_brown_young,
        "male_1_brown_middle": male_1_brown_middle,
        "male_1_brown_old": male_1_brown_old,
        "male_1_cauca_young": male_1_cauca_young,
        "male_1_cauca_middle": male_1_cauca_middle,
        "male_1_cauca_old": male_1_cauca_old
    }

    constructor(gender, skin, age) {
        this.gender = gender;
        this.skin = skin;
        this.age = age;
        this.img = new Image();
        this.img.src = this.headMap[this.getOptionsString()];
    }

    getOptionsString() {

        // eslint-disable-next-line
        return this.gender + "_" + "1" + "_" + this.skin + "_" + this.age;
    }


    renderOnCanvas(context, canvas_width, canvas_height) {
        return new Promise((resolve, reject) => {
            let x_scale = 10;
            let y_scale = 10;

            if (this.img.complete) {
                context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.11, this.img.width * x_scale, this.img.height * y_scale);
                resolve()
            }
            else {
                this.img.onload = () => {
                    context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.11, this.img.width * x_scale, this.img.height * y_scale);
                    resolve()
                };
            }

           
        })
    }

    renderAsOption(context, canvas_width, canvas_height) {
        // fil context with black
        this.img.onload = () => {
            context.fillStyle = "#FFDAD4";
            context.fillRect(0, 0, canvas_width, canvas_height);
        };
      
        // #EDB399
        // #90554B

    }


}


export default AvatarHead