import React, { useState } from 'react';
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, Box, Text, Flex } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../../styles/styles';

interface ImportanceSliderProps {
    onRatingChange: (rating: number) => void;
    ratingHeader: string;
    ratingLabels: string[];
    startRating: number;
}

const ImportanceSlider: React.FC<ImportanceSliderProps> = ({ onRatingChange, ratingHeader, ratingLabels, startRating }) => {
    const [rating, setRating] = useState(startRating);
    const [theme] = useState<Theme>(lavenderStyle)

    const handleRatingChange = (value: number) => {
        setRating(value);
        onRatingChange(value);
    };

    const labelStyles = {
        mt: '2',
        size: 'sm',
        ml: '-0.35rem',
    }


    return (
        <Box width="100%" mb={5}>
            <Flex flex={1} justifyContent="center">
                <Text fontSize="sm" mb={2}>
                    {ratingHeader}
                </Text>
            </Flex>
            <Slider value={rating} min={0} max={100} step={1} onChange={handleRatingChange} size="lg" colorScheme={theme.colorScheme}>

                <SliderTrack >
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb fontSize="lg" bgColor={theme.fifthColor} />
            </Slider>

            <Flex flex={1} justifyContent="center">
                {rating <= 25 && <Text {...labelStyles}>{ratingLabels[0]}</Text>}
                {rating > 25 && rating <= 50 && <Text {...labelStyles}>{ratingLabels[1]}</Text>}
                {rating > 50 && rating <= 75 && <Text {...labelStyles}>{ratingLabels[2]}</Text>}
                {rating > 75 && <Text {...labelStyles}>{ratingLabels[3]}</Text>}

            </Flex>
        </Box>
    );
};

export default ImportanceSlider;
