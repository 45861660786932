import { Divider, Flex, Text, Image } from "@chakra-ui/react";
import { email } from "../../data/contactInfo/contactInfo";
import LanguageMenu from "./LanguageMenu";
import { Theme, lavenderStyle } from "../../styles/styles";
import { useState } from "react";
import logo from '../../assets/general/PsyScribeLogo.svg';
function LandingPageFooter() {
    const [theme] = useState<Theme>(lavenderStyle)

    return (
        <Flex width='100%' marginTop='100px'  paddingBottom='20px' alignItems={'center'}  flexDir={'column'} bgColor={"#f2f2ff"}>
            <Divider></Divider> 
             <Flex width={'100%'} justifyContent='flex-end' alignItems={'flex-end'} >
                    <LanguageMenu />
                </Flex>

            <Image   mb='10px' width='150px' height='50px' src={logo} alt="My Logo" />
            <Text  mb='10px' color={theme.primaryColor} fontWeight={'semibold'}>Your AI therapist: www.psyscribe.com  </Text>



  

                <Text cursor="pointer"  onClick={(e) => {
                window.location.href = "mailto:" + email;
                e.preventDefault();
            }}  color={theme.primaryColor} fontWeight={'normal'} >Contact: {email}</Text>
            


    

        </Flex>

    )
}

export default LandingPageFooter;