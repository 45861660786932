
//glasses
import male_1 from '../../assets/glasses/glasses-1.svg';
import male_2 from '../../assets/glasses/glasses-2.svg';
import female_1 from '../../assets/glasses/f-glasses-1.svg';
import female_2 from '../../assets/glasses/f-glasses-2.svg';

class AvatarGlasses {

    gender;
    type;
    img

    glassesMap = {
        "female_1": female_1,
        "female_2": female_2,
        "male_1": male_1,
        "male_2": male_2,
    }

    constructor(gender, type) {
        this.gender = gender;
        this.type = type;
        this.img = new Image();
        this.img.src = this.glassesMap[this.getOptionsString()];
    }

    getOptionsString() {
        return this.gender + "_" + this.type;
    }


    renderOnCanvas(context, canvas_width, canvas_height) {
        return new Promise((resolve, reject) => {
            let x_scale = 10;
            let y_scale = 10;

            switch (this.type) {
                case "1":
                    if (this.img.complete) {
                        context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.26, this.img.width * x_scale, this.img.height * y_scale);
                        resolve()
                    }
                    else {
                        this.img.onload = () => {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.26, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        };
                    }



                    break;
                case "2":

                    if (this.img.complete) {
                        context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 2, canvas_height * 0.25, this.img.width * x_scale, this.img.height * y_scale);
                        resolve()
                    }
                    else {
                        this.img.onload = () => {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2) - 2, canvas_height * 0.25, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        };
                    }
                    break;
                default:
                    resolve();
                    break;
            }

        }
        );
    }


    renderAsOption(context, canvas_width, canvas_height) {
        if (this.type === "none") {
            context.strokeStyle = "gray";
            context.lineWidth = 5

            let margin = 50;
            // draw the first diagonal line
            context.beginPath();
            context.moveTo(margin, margin);
            context.lineTo(canvas_width - margin, canvas_height - margin);
            context.stroke();

            // draw the second diagonal line
            context.beginPath();
            context.moveTo(canvas_width - margin, margin);
            context.lineTo(margin, canvas_height - margin);
            context.stroke();

        }
        else {
            this.img.onload = () => {

                switch (this.type) {
                    case "1":
                        const scaleXMale = 3.5; // Scale factor for width
                        const scaleYMale = 3.5; // Scale factor for height
                        context.drawImage(this.img, 2, 70, this.img.width * scaleXMale, this.img.height * scaleYMale);
                        break;
                    case "2":
                        const scaleXMale2 = 3.5; // Scale factor for width
                        const scaleYMale2 = 3.5; // Scale factor for height
                        context.drawImage(this.img, 3, 60, this.img.width * scaleXMale2, this.img.height * scaleYMale2);
                        break;
                    default:
                        break;
                }

            }
        };
    }
}



export default AvatarGlasses