import { useContext, useState } from "react";
import {
    Box,
    Button, Flex, FormControl, Heading, Input, Text
} from '@chakra-ui/react'

import { Link as Routerlink, useLocation } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import Avatar from "../../components/avatar/Avatar";
import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { FirebaseContext } from "../../contexts/firebaseProvider";
function NameUserSettingScreen() {

    const [isError, setIsError] = useState(false);
    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile, currentUser } = useContext(UserProfileContext);
    const [userName, setUsername] = useState(userProfile.username && userProfile.username !== "not_complete" ? userProfile.username : "");

    const [placeHolderText] = useState(t('NameUserSettingScreen_input_placeholder'))
    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/modifyPsychologistScreen"));
    const firebaseInterface = useContext(FirebaseContext);
    const [nameTooLongError, setNameTooLongError] = useState(false);


    function updateUserName(userName: string) {
        userProfile.username = userName
        if (currentUser) {
            firebaseInterface.updateUserName(currentUser.uid, userName);
        }
        else {
            throw new Error("User not logged in");
        }
    }

    const handleNameChange = (event: any) => {
        const input = event.target.value;
        if (input.length <= 40) {
            setUsername(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
            
            setNameTooLongError(false);
        } else {
            setNameTooLongError(true);
        }
    };


    return <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
        {isFirstTime ? <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/typeSpeedSettingScreen" /> </Flex> : <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/modifyPsychologistScreen" /> </Flex>}

        <Flex width={"100%"} flexDir={"column"} >

            <Flex width={"100%"} justifyContent="center">
                <Heading size='lg' paddingTop='5px'  marginLeft="5px">
                    {t('NameUserSettingScreen_title')}
                </Heading>
            </Flex>
        </Flex>


        <Flex flexDir="column" alignItems="center" height={"100%"} width="100%" marginTop="10px">
            {isFirstTime ?
                <Flex alignItems="flexstart" justifyContent="center" >
                    {[...Array(7)].map((_, index) => (
                        <Box
                            key={index}
                            w="2"
                            h="2"
                            borderRadius="full"
                            bg={5 === index ? theme.primaryColor : theme.secondaryColor}
                            mx="2"
                        />
                    ))}
                </Flex>
                : null}
            <Flex width="60%" justifyContent={"center"} mt="10px">
                <Avatar avatarParams={userProfile.therapistSettings.avatar} />

            </Flex>

            <Flex flexDirection={"column"} justifyContent="flex-start" width="100%" maxWidth={"600px"} marginTop="20px" marginBottom="40px">
                <Text fontStyle={"italic"} fontSize="md" textAlign="center" marginBottom="20px">
                    {t('NameUserSettingScreen_description')}
                </Text>
                <>
                    <FormControl id="username">

                        <Flex flexDir="column" width="100%" alignItems={"center"} marginTop="20px">
                            <Input maxWidth={"300px"} placeholder={placeHolderText?.toString()}
                                _placeholder={{ opacity: 0.6, color: 'inherit' }} type="text" value={userName} onChange={handleNameChange} isRequired />
                            {!nameTooLongError ? (
                                <Flex></Flex>
                            ) : (
                                <Text fontSize="sm" >  {t('NamePsychologistSettingScreen_nameTooLongError')}</Text>)}
                            {!isError ? (
                                <Flex></Flex>
                            ) : (
                                <Text fontSize="sm" >{t('NameUserSettingScreen_forgotnametext')} </Text>)}
                        </Flex>

                    </FormControl>
                </>

            </Flex>

            <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' >
                {!(userName === '') ? (
                    <Button as={Routerlink} to={isFirstTime ? "/rememberedMessagesScreen" : "/modifyPsychologistScreen"} state={{ from: location.pathname }} colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} onClick={() => updateUserName(userName)}> {isFirstTime ? t('SettingScreen_first_time_done') : t('SettingScreen_second_time_done')}</Button>
                ) : (
                    <Button colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} onClick={() => setIsError(true)}> {isFirstTime ? t('SettingScreen_first_time_done') : t('SettingScreen_second_time_done')}</Button>
                )}
            </Flex>
        </Flex>


    </Flex >
}


export default NameUserSettingScreen