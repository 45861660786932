import { useContext, useEffect, useState } from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Divider,
    Flex, Heading, keyframes, Text
} from '@chakra-ui/react'
import { Spinner, Image } from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import { Chat, insightReportOverview, Mood } from "../../types/types";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import RememberedMessagesButton from "../../components/sharedComponents/topNavigationRow/RememberedMessageButton";
import { t } from "i18next";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import NotificationsButton from "../../components/sharedComponents/topNavigationRow/NotificationsButton";
import ProfileButton from "../../components/sharedComponents/topNavigationRow/ProfileButton";
import logo from '../../assets/general/PsyScribeLogo.svg';
import Moodtracker from "../../components/moodtracker/MoodTracker";
import { useLocation } from "react-router-dom";
import ChatWithoutInsightButton from "../../components/insightsOverviewScreen/ChatWithoutInsightButton";
import InsightReportViewer from "../../components/insightsOverviewScreen/InsightReportViewer";
import { ChatIcon } from "@chakra-ui/icons";
import MainNavigationRow from "../../components/sharedComponents/mainNavigationRow/MainNavigationRow";
import MoodTrackingGraph from "../../components/moodtracker/MoodtrackingGraph";



function InsightsScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile, currentUser } = useContext(UserProfileContext);
    const navigate = useNavigate();
    const firebaseInterface = useContext(FirebaseContext);
    const [loadingChat] = useState<boolean>(false);
    const [isSummaryLoaded, setIsSummaryLoaded] = useState<boolean>(false)
    const [chatSummary, setChatSummary] = useState<string>("")
    const [isChatLoaded, setIsChatLoaded] = useState<boolean>(false)
    const [chat, setChat] = useState<Chat>()
    const [todayMoods, setTodayMoods] = useState<Mood[]>([])
    const [insightReport, setInsightReport] = useState<insightReportOverview>();
    const [insightReportsLoaded, setInsightReportsLoaded] = useState<boolean>(false);
    const [chatInsightsAvailable, setChatInsightsAvailable] = useState<boolean>(false);
    const { firebaseDateId } = useParams<string>();
    const [insightsDate] = useState<Date>(firebaseInterface.dateFromFirebaseDateId(firebaseDateId!))
    const location = useLocation();
    const [totalMessagesWithoutInsights, setTotalMessagesWithoutInsights] = useState<number>(0)
    const insightsAfterNumberOfMessages = 10
    const [showNewButton, setShowNewButton] = useState<boolean>(false)

    useEffect(() => {

        if(!insightReportsLoaded){
            return
        }
        if (insightReport && insightReport.newInsightsAvailable) {
            setShowNewButton(true)
        }
        else if (chatInsightsAvailable && !insightReport) {
            setShowNewButton(true)
        }
        else{
            setShowNewButton(false)
        }

    }, [insightReport, chatInsightsAvailable,insightReportsLoaded])


    useEffect(() => {
        if (!currentUser || !firebaseDateId) {
            return;
        }
        firebaseInterface.getTotalMessagesWithoutInsights(currentUser.uid, firebaseDateId).then((totalMessagesWithoutInsights) => {
            setTotalMessagesWithoutInsights(totalMessagesWithoutInsights)
        }
        )
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [])


    useEffect(() => {
        if (!currentUser || !firebaseDateId) {
            throw new Error("User is not logged in")
        }
        firebaseInterface.fetchMoodsFromDate(currentUser.uid, firebaseInterface.dateFromFirebaseDateId(firebaseDateId)).then((moods) => {
            setTodayMoods(moods)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, firebaseDateId, userProfile.todayMoods.length])

    useEffect(() => {
        if (!currentUser || !firebaseDateId) {
            throw new Error("User is not logged in")
        }

        if (firebaseInterface) {
            firebaseInterface.getChatSummary(currentUser.uid, firebaseDateId).then((summary) => {
                setChatSummary(summary)


                setIsSummaryLoaded(true)
            })

            firebaseInterface.getChatData(currentUser.uid, firebaseDateId).then((chat) => {
                setChatInsightsAvailable(completeInsightsAvailable(chat))

                setChat(chat)
                setIsChatLoaded(true)
            }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, firebaseDateId]);


    const checkIfUnratedInsightsAvailable = (chat: Chat): boolean => {
        if (!chat.insights) {
            return false
        }
        // check if there exists a 0 rating in goals
        if (chat.insights.goals && chat.insights.goals.length > 0) {
            const unratedGoals = chat.insights.goals.filter((g) => g.rating === 0)
            if (unratedGoals.length > 0) {
                return true
            }
        }
        // check if there exists a 0 rating in emotions_and_causes
        if (chat.insights.emotions_and_causes && chat.insights.emotions_and_causes.length > 0) {
            const unratedEmotions_and_causes = chat.insights.emotions_and_causes.filter((g) => g.rating === 0)
            if (unratedEmotions_and_causes.length > 0) {
                return true
            }
        }
        // check if there exists a 0 rating in important_thoughts
        if (chat.insights.important_thoughts && chat.insights.important_thoughts.length > 0) {
            const unratedImportant_thoughts = chat.insights.important_thoughts.filter((g) => g.rating === 0)
            if (unratedImportant_thoughts.length > 0) {
                return true
            }
        }
        // check if there exists a 0 rating in problems_and_complaints
        if (chat.insights.problems_and_complaints && chat.insights.problems_and_complaints.length > 0) {
            const unratedProblems_and_complaints = chat.insights.problems_and_complaints.filter((g) => g.rating === 0)
            if (unratedProblems_and_complaints.length > 0) {
                return true
            }
        }
        return false
    }

    // TODO toon chat alleen als dit er is
    const completeInsightsAvailable = (chat: Chat): boolean => {
        if (chat.insights && chat.insights.summary && chat.insights.goals && chat.insights.emotions_and_causes && chat.insights.important_thoughts && chat.insights.problems_and_complaints) {
            return true
        }
        return false
    }

    // load chats from firebase
    useEffect(() => {
        if (!currentUser || !chat || !firebaseDateId) {
            return
        }
        firebaseInterface.getInsightReportByChatId(currentUser.uid, firebaseDateId).then((ir) => {
            if (ir) {
                setInsightReport({ insightReport: ir, newInsightsAvailable: checkIfUnratedInsightsAvailable(chat) })
            }
            setInsightReportsLoaded(true)
        })

        // eslint-disable-next-line
    }, [currentUser, chat]);

    const shineAnimation = keyframes`
    0% {
      background-position: -200%;
    }
    100% {
      background-position: 200%;
    }
  `;



    const startChat = async () => {
        if (!currentUser || !firebaseDateId) {
            throw new Error("no current user")
        }
        

       
        const chatExists = await firebaseInterface.checkIfChatExists(currentUser.uid, firebaseDateId)
        if (!chatExists) {
            await firebaseInterface.createDailyChat(currentUser.uid, firebaseDateId)
        }
        navigate(`/chatbotScreen/${firebaseDateId}`, { state: { from: location.pathname } });


        // navigate to chat
        //navigate(`/chatbotScreen/${id}`);
    }



    if (!isSummaryLoaded || !isChatLoaded || !insightReportsLoaded) {
        return (
            <Flex flexDirection='column' height={isMobile ? "88vh" : "99vh"} width="96%" alignItems="center" marginLeft="auto"
                marginRight="auto"   >
                <Flex width="100%" mt="2" zIndex={'overlay'}   >
                    <Image ml='2' width='150px' height='50px' src={logo} alt="My Logo" />
                    <Flex width="100%" justifyContent={"flex-end"} columnGap='1' >
                        <RememberedMessagesButton />
                        <NotificationsButton />
                        <ProfileButton />
                    </Flex>
                </Flex>
                <Spinner color={theme.thirdColor} size="xl" />
                <MainNavigationRow /></Flex>
        );
    }








    return <Flex flexDirection='column' height={"100%"} width="96%" alignItems="center" marginLeft="auto"
        marginRight="auto"   >



        <Flex width="100%" mt="2" zIndex={'overlay'}   >
            <Image ml='2' width='150px' height='50px' src={logo} alt="My Logo" />
            <Flex width="100%" justifyContent={"flex-end"} columnGap='1' >
                <RememberedMessagesButton />
                <NotificationsButton />
                <ProfileButton />
            </Flex>
        </Flex>

        <Heading color={theme.primaryColor}> {insightsDate.toLocaleDateString()}</Heading>
        <Divider borderColor={theme.thirdColor} maxWidth='800px' mt='5' ></Divider>


        {todayMoods.length > 0 &&

            <Box >
                <Moodtracker moods={todayMoods} />
                <MoodTrackingGraph dailyMoods={todayMoods} height={200} width={320} />
            </Box>
        }

        <Divider borderColor={theme.thirdColor} maxWidth='800px' mt='5' mb='5' ></Divider>

        <Text fontSize={'xl'} color={theme.primaryColor} fontWeight={'bold'} mb='20px'>{t('InsightsScreen_daily_conversation_label')}</Text>

        <Button
            borderRadius='10px'
            width={'350px'}
            type="submit"
            variant="outline"
            colorScheme={theme.colorScheme}
            onClick={startChat}

            leftIcon={<ChatIcon />}
            isLoading={loadingChat}
        >
            {t('InsightsScreen_continue_conversation_button')}
        </Button>

        <Accordion allowToggle>
            <AccordionItem borderColor={'white'} >

                <h2>
                    <AccordionButton _hover={undefined} bgColor={theme.fifthColor} borderRadius='50px' mt='20px'>
                        <Flex as="span" _hover={undefined} flex='1' textAlign='left' width={'300px'} justifyContent='center'>
                            <Text fontSize='md' fontWeight={'bold'} color={"blackAlpha.700"} >                             {t('InsightsScreen_show_summary_button')}
                            </Text>
                        </Flex>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>

                <AccordionPanel>
                    <Flex width={'100%'} alignItems={'center'} flexDir={'column'} mt='20px'>
                        <Text fontSize={'md'} fontWeight={'bold'} mb='10px'>{t('InsightsScreen_summary_title')}</Text>
                        <Text fontStyle={'italic'}>{chatSummary && chatSummary.length > 0 ? chatSummary : t('InsightsScreen_summary_not_enough_messages')}</Text>
                    </Flex>

                </AccordionPanel>
            </AccordionItem>
        </Accordion>




        <Flex width={'100%'} alignItems={'center'} flexDir={'column'} mt='20px' mb='100px'>



            <Flex>


                <Accordion allowToggle>
                    <AccordionItem borderColor={'white'} >

                        <h2>

                            <AccordionButton _hover={undefined} color={showNewButton?'white':'black'} borderRadius='50px' animation={showNewButton?` ${shineAnimation} 10s infinite linear`:''}
                                style={showNewButton? {
                                    background: `linear-gradient(to right, rgba(139, 139, 182, 1),rgba(97, 97, 153, 1), rgba(182, 182, 211, 1), rgba(139, 139, 182, 1))`,
                                    backgroundSize: "200% 100%",
                                    backgroundPosition: "100%",
                                } : {
                                    background: theme.fifthColor,
                                    backgroundSize: "200% 100%",
                                    backgroundPosition: "100%",
                                }}>

                                <Flex as="span" _hover={undefined} flex='1' textAlign='left' width={'300px'} justifyContent='center' >
                                    {showNewButton &&
                                        <Box
                                            position='relative'
                                            top='-15px'
                                            left='-40px'
                                            backgroundColor='red'
                                            color='white'
                                            borderRadius='full'
                                            padding='2px 6px'
                                            fontSize='xs'
                                            fontWeight='bold'
                                        >
                                            new
                                        </Box>

                                    }
                                    <Text fontSize='md' color={showNewButton?'white':"blackAlpha.700"} fontWeight={'bold'} >
                                        {t('InsightsScreen_show_conv_insights_button')}
                                    </Text>

                                </Flex>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>

                        <AccordionPanel>
                            {chatInsightsAvailable ?
                                <>
                                    {insightReport ? (
                                        <InsightReportViewer insightReport={insightReport.insightReport} closeInsightReport={() => { }} newInsightsAvailable={insightReport.newInsightsAvailable} />
                                    ) :
                                        <ChatWithoutInsightButton chat={chat!} />
                                    }
                                </>
                                :
                                <Flex>
                                    {(insightsAfterNumberOfMessages - totalMessagesWithoutInsights) >= 0 ?
                                        <Flex>
                                            <Text fontWeight={'bold'} mr='1'>{insightsAfterNumberOfMessages - totalMessagesWithoutInsights}</Text>
                                            <Text fontStyle={'italic'}>{t('InsightsScreen_insights_not_available')}  </Text>
                                        </Flex>
                                        :
                                        <Text fontStyle={'italic'}>{t('InsightsScreen_requesting_insights')}</Text>
                                    }
                                </Flex>
                            }
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>


            </Flex>


        </Flex>





        <MainNavigationRow />



    </Flex>
}


export default InsightsScreen