import { useContext, useState } from "react";
import {
    Button, Flex, Heading, Text
} from '@chakra-ui/react'
import { Link as Routerlink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../components/settingScreens/LanguageSelector";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { Language } from "../../types/types";
import { FirebaseContext } from "../../contexts/firebaseProvider";

function LanguageSettingScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile, setUserProfile , currentUser } = useContext(UserProfileContext);
    const { i18n } = useTranslation();
    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/accountScreen"));
    const firebaseInterface = useContext(FirebaseContext);


    function updateLanguage(language: string) {
        //userProfile.language = language as Language
        if (currentUser) {
            firebaseInterface.updateLanguage(currentUser.uid, language as Language);
            setUserProfile({ ...userProfile, language: language as Language })
        }
        else {
            throw new Error("User not logged in");
        }
    }
    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >

            <Flex width={"100%"} justifyContent="center">
                <Heading size='lg' paddingTop='65px' marginLeft="5px">
                    {t('LanguageSettingScreen_title')}
                </Heading>
            </Flex>
          
            <Flex width={"60%"} justifyContent="center">
                <Text fontStyle={"italic"} fontSize="md" textAlign="center" marginTop="20px">
                    {t('LanguageSettingScreen_description')}
                </Text>
            </Flex>


            <Flex justifyContent="center" alignItems={"center"} height="100%" marginTop="40px">
                <LanguageSelector />
            </Flex>
            <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' marginTop={"40px"} >
                <Button variant={"outline"} colorScheme={theme.colorScheme} size="lg" as={Routerlink} to= {isFirstTime ? "/regionSettingScreen": "/accountScreen" } onClick={() => updateLanguage(i18n.language)}>{isFirstTime ? t('LanguageSettingScreen_first_time_done'):  t('LanguageSettingScreen_second_time_done')}  </Button>
            </Flex>
        </Flex >);

}


export default LanguageSettingScreen