import {
    Flex,
} from "@chakra-ui/react";
import { SubscriptionID, SubscriptionPlan } from "../../types/types";
import { Theme } from "../../styles/styles";
import { essentialPlan, starterPlan, trialPlan } from "../../data/subscriptions/subscriptionPlans";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import { useEffect, useState } from "react";
import useScreenWidth from "../../utils/useScreenWidth";



interface TherapyStyleProps {
    currentPlan: SubscriptionID;
    selectedPlan: SubscriptionID;
    setSelectedPlan: (value: SubscriptionID) => void;
    theme: Theme
    currency: '$' | '€'

    includeTrial: boolean;
}

function SubscriptionPlanSelector(props: TherapyStyleProps) {
    const [therapyStyles, setTherapyStyles] = useState<SubscriptionPlan[]>([])


    const screenWidth = useScreenWidth();

    const [isMobile, setIsMobile] = useState<boolean>(false)
    useEffect(() => {
    
        if (screenWidth < 1000) {
           setIsMobile(true)
           }
        else {
            setIsMobile(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenWidth])
  
   useEffect(() => {
     if (props.includeTrial){
        setTherapyStyles([trialPlan, starterPlan, essentialPlan])
        } else {
        setTherapyStyles([starterPlan, essentialPlan])
        


     }
// eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   
    return (
        <>
            <Flex justifyContent={'center'} flexDir={isMobile?'column':'row'} columnGap={'10px'} rowGap={'10px'} mt='20px'>
                {therapyStyles.map((plan) => (
                    <SubscriptionPlanCard
                        key={plan.id}
                        plan={plan}
                        isSelected={props.selectedPlan === plan.id}
                        setSelectedPlan={props.setSelectedPlan}
                        isCurrentPlan={props.currentPlan === plan.id}
                        currency={props.currency}
                    />
                ))}
            </Flex>

        </>
    );
}

export default SubscriptionPlanSelector;

