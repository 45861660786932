
//@ts-ignore
import male_1_black from '../../assets/brows/brows-1-black.svg';

import male_1_blond from '../../assets/brows/brows-1-blond.svg';
import male_1_brown from '../../assets/brows/brows-1-brown.svg';
import male_1_gray from '../../assets/brows/brows-1-gray.svg';
import female_1_black from '../../assets/brows/f-brows-1-black.svg';
import female_1_blond from '../../assets/brows/f-brows-1-blond.svg';
import female_1_brown from '../../assets/brows/f-brows-1-brown.svg';
import female_1_gray from '../../assets/brows/f-brows-1-gray.svg';


class AvatarBrows {

    gender;
    type;
    color;
    img;

    browsMap = {
        "male_1_black": male_1_black,
        "male_1_blond": male_1_blond,
        "male_1_brown": male_1_brown,
        "male_1_gray": male_1_gray,
        "female_1_black":female_1_black,
        "female_1_blond":female_1_blond,
        "female_1_brown":female_1_brown,
        "female_1_gray":female_1_gray
    }

    constructor(gender, type, color) {
        this.gender = gender;
        this.type = type;
        this.color = color;
        this.img = new Image();
        this.img.src = this.browsMap[this.getOptionsString()];
    }

    getOptionsString() {
        return this.gender + "_" + this.type + "_" + this.color;
    }


    renderOnCanvas(context, canvas_width, canvas_height) {

        return new Promise((resolve, reject) => {
            let x_scale = 10;
            let y_scale = 10;

            switch (this.type) {
                case "1":
                    if (this.img.complete) {
                        context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.23, this.img.width * x_scale, this.img.height * y_scale);
                        resolve()
                    }
                    else {
                        this.img.onload = () => {
                            context.drawImage(this.img, (canvas_width / 2) - (this.img.width * x_scale / 2), canvas_height * 0.23, this.img.width * x_scale, this.img.height * y_scale);
                            resolve()
                        };
                    }
                    break;
                default:
                    resolve();
                    break;
            }

        });
    }



    renderAsOption(context, canvas_width, canvas_height) {
       
        this.img.onload = () => {
            context.drawImage(this.img, 0, 0);
        };
    }
}



export default AvatarBrows