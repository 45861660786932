import { useContext, useState } from "react";
import {
    Button, Flex, Input, Text
} from '@chakra-ui/react'

import { useNavigate } from "react-router-dom";

import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { PromoCode } from "../../types/types";

function PromoCodeInput() {

    const { userProfile, currentUser } = useContext(UserProfileContext);
    const [isError] = useState(false);
    const [promoCodeInput, setPromoCodeInput] = useState("");
    const firebaseInterface = useContext(FirebaseContext);
    const [promoCodeInvalid, setPromoCodeInvalid] = useState(false);

    const navigate = useNavigate();


    async function submitPromoCode() {
        if (!currentUser) {
            return;
        }
        if (promoCodeInput === "therapie") {
            const promoCode: PromoCode = {
                code: "therapie",
                // current date + 1 month
                endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
            }
            await firebaseInterface.updatePromoCode(currentUser.uid, promoCode)

            userProfile.promoCode = promoCode;
            userProfile.subscriptionId = 'essential';
            //setUserProfile({ ...userProfile, promoCode: promoCode });
            //setUserProfile({ ...userProfile, subscriptionId: 'essential' });
            navigate('/promoCodeActivatedScreen');

            // valid
        }
        else {
            setPromoCodeInvalid(true);
        }
    }

    const handleNameChange = (event: any) => {
        const input = event.target.value;

        setPromoCodeInput(input);
        setPromoCodeInvalid(false);

    };

    return (
        <Flex width={'100%'} justifyContent='center' alignItems={'center'} flexDir={'column'} >
            <Text fontWeight={'bold'} mb='5px'> {t('PromoCodeInput_label')}</Text>
            <Flex>
                <Flex flexDir='column' justifyContent={'center'} alignItems='center'>
                    <Input placeholder={"Enter promo code"}
                        _placeholder={{ opacity: 0.6, color: 'inherit' }} type="text" value={promoCodeInput} onChange={handleNameChange} isRequired />
                   
                    {!isError ? (
                        <Flex></Flex>
                    ) : (
                        <Text fontSize="sm" >  {t('PromoCodeInput_invalid_code')}</Text>)}

                    {!promoCodeInvalid ? (
                        <Flex></Flex>
                    ) : (
                        <Text color='red.300' fontSize="sm" >  {t('PromoCodeInput_invalid_code')}</Text>)}
                </Flex>



                <Button onClick={() => submitPromoCode()}>  {t('PromoCodeInput_button')}</Button>
            </Flex>




        </Flex >
    )

}

export default PromoCodeInput