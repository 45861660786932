
import {
    Flex,
    Image
} from "@chakra-ui/react";
import LoginButton from "../../components/loginscreen/LoginButton";
import logo from '../../assets/general/PsyScribeLogo.svg';

import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";


function LoginScreen() {


    return (<Flex
            flexDirection="column"
            alignItems="center"
            width="96%"
            marginX="auto"
            height="100vh"
        >
         <Flex width="100%" justifyContent={"flex-start"} mt='2'>   <PreviousScreenButton previousScreen="/" /></Flex>

              <Image width='500px' height='100px' src={logo} alt="My Logo" />

         
            <Flex mt='50px' height={'100%'} justifyContent='center'  >
            
            <LoginButton />
            </Flex>

        </Flex>
    );
}

export default LoginScreen;
