import { useState } from "react";
import {
    Button, Flex, Heading, Text
} from '@chakra-ui/react'
import { Link as Routerlink, useLocation, } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
function UsageRisksScreen() {
    const [theme] = useState<Theme>(lavenderStyle)

    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/infoOverviewScreen"));

    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            {isFirstTime ? <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/addTherapistScreen" /> </Flex> : <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/infoOverviewScreen" /> </Flex>}


            <Flex width={"100%"} flexDir={"column"} maxWidth={"900px"}>

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px' marginLeft="5px">
                        {t('DataPrivacyScreen_title')}
                    </Heading>

                </Flex>
            </Flex>
            <Flex flexDir="column" mt="5" width='95%' maxWidth={"900px"}>
                <Flex flexDir="column">

                    <Text>
                        {t('DataPrivacyScreen_description')}


                    </Text>

                </Flex>


                <Flex flexDir="column" mt="5">
                    <Heading size='sm' paddingTop='5px' color={theme.text1} >        {t('DataPrivacyScreen_anonymity_title')} </Heading>
                    <Text> {t('DataPrivacyScreen_anonymity_description')}</Text>
                </Flex>
                <Flex flexDir="column" mt="5">
                    <Heading size='sm' paddingTop='5px' color={theme.text1} >{t('DataPrivacyScreen_security_title')}</Heading>
                    <Text> {t('DataPrivacyScreen_security_description')}</Text>
                </Flex>

                <Flex flexDir="column" mt="5">
                    <Heading size='sm' paddingTop='5px' color={theme.text1} >{t('DataPrivacyScreen_confidentiality_title')} </Heading>
                    <Text>{t('DataPrivacyScreen_confidentiality_description')} </Text>
                </Flex>



            </Flex>

            {isFirstTime ?
            <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' marginTop={"30px"} >

                <Button mb="10" colorScheme={theme.colorScheme} size="lg" as={Routerlink} to={"/gdprScreen"} > {t('DataPrivacyScreen_understood_button')} </Button>
            </Flex>
            : null}

        </Flex >);

}



export default UsageRisksScreen