import { Flex, IconButton, Textarea, Text } from "@chakra-ui/react";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { lavenderStyle, Theme } from "../../styles/styles";


interface InputFieldProps {
    theme: Theme;
    canSendMessage: boolean;
    handleSendMessage: (inputMessage: string) => void;
    isMobile: boolean;
}

function InputField(props: InputFieldProps) {
    const [inputMessage, setInputMessage] = useState("");
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [theme] = useState<Theme>(lavenderStyle)
    const [showWarning, setShowWarning] = useState(false);

    const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const input = event.target.value;
        if (input.length <= 500) {
            setInputMessage(event.target.value);
            adjustTextareaHeight();
          setShowWarning(false);
        } else {
          setShowWarning(true);
        }
       
    };

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (props.isMobile) {
            return;
        }

        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
   
            handleSendMessage();
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            adjustTextareaHeight();
        }
    }, []);
    //


    //handle send message
    const handleSendMessage = () => {
        if (!props.canSendMessage || inputMessage === "") {
            return;
        }
        props.handleSendMessage(inputMessage);
        // Below is the code to reset the input field both input message and textarea value need to be reset
        setInputMessage("");
        if (textareaRef.current) {
            textareaRef.current.value = ""; // Reset the textarea value to an empty string
        }
        adjustTextareaHeight();
        setShowWarning(false);


    }

    return (
        <Flex width={'95%'} flexDir='column' >
            {showWarning && <Flex width={'100%'} justifyContent='center'> <Text color="red" fontSize="sm">{t('ChatbotScreen_input_character_limit_warning')}</Text></Flex>}
            <Flex flexDirection={"row"} width="100%" marginBottom='1' p="1" borderColor={theme.fourthColor} borderWidth="1px" borderRadius={"10px"} alignItems={"flex-end"}
            >
                <Textarea
                    value={inputMessage}
                    onKeyPress={handleKeyPress}
                    onChange={handleDescriptionChange}
                    placeholder={t('ChatbotScreen_input_placeholder')?.toString()}
                    aria-label="input"
                    resize="none"
                    shadow="none"
                    overflow="auto"
                    maxHeight={"400px"}
                    border="none"
                    // Adjusted the minimum height to 1 row (can be adjusted based on your desired row height)
                    rows={1}
                    _focus={{
                        outline: 'none',
                        borderColor: 'none',
                        boxShadow: 'none'
                    }}

                    ref={textareaRef}

                >
                </Textarea>

                {props.canSendMessage ? (
                    <IconButton aria-label='Send Message' icon={<FontAwesomeIcon icon={faPaperPlane} />} colorScheme={props.theme.colorScheme}
                        borderRadius={props.theme.borderRadius} variant='solid' size="sm" mb="1" mr="1"
                        onClick={handleSendMessage} />

                ) :
                    (
                        <IconButton aria-label='Send Message' icon={<TypeAnimation
                            sequence={[
                                '.', // Types 'One'
                                250, // Waits 1s
                                '..', // Deletes 'One' and types 'Two'
                                250, // Waits 2s
                                '...', // Types 'Three' without deleting 'Two'
                                250, // Waits 2s
                            ]}
                            wrapper="span"
                            cursor={false}
                            repeat={Infinity} />}
                            colorScheme={props.theme.colorScheme}
                            isDisabled={true}
                            borderRadius={props.theme.borderRadius} variant='solid' size='sm' fontSize="sm" mb="1" mr="1" />

                    )
                }

            </Flex>

        </Flex>);

}

export default InputField