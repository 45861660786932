import { useContext, useEffect, useState } from "react";
import {
  IconButton,
  Box,
  useBreakpointValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  PopoverHeader,
  Flex,
  PopoverArrow,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { lavenderStyle, Theme } from "../../../styles/styles";
import { NotificationFetchedFromDatabase } from "../../../types/types";
import Notification from "./Notification";
import { UserProfileContext } from "../../../contexts/userProfileContextProvider";
import { FirebaseContext } from "../../../contexts/firebaseProvider";
import { t } from "i18next";


function NotificationsButton() {
  const [theme] = useState<Theme>(lavenderStyle);
  const buttonSize = useBreakpointValue({ base: "md", sm: "lg", md: "lg" });
  const [newNotificationsCount, setNewNotificationsCount] = useState<number>(0);
  const { userProfile, setUserProfile, currentUser } = useContext(UserProfileContext);

  const firebaseInterface = useContext(FirebaseContext);



  const handleClick = () => {
    // set every notification to not new
    if (!currentUser) return;
    userProfile.notifications.forEach((notification) => {
      if (notification.notification.isNew) {
        firebaseInterface.setNotificationAsRead(currentUser.uid, notification.firebaseDocumentId);
      }
    }
    );
    // set the new notifications count to 0
    setNewNotificationsCount(0);
    // update  the user profile
    setUserProfile((oldProfile) => {
      return {
        ...oldProfile,
        notifications: oldProfile.notifications.map((notification) => {
          return {
            ...notification,
            notification: {
              ...notification.notification,
              isNew: false
            }
          }
        })
      }
    }
    )
  };

  useEffect(() => {
    if (!currentUser) return;
    //firebaseInterface.addNotication(currentUser.uid,props.notifications[0])
    // count how many notifications are new
    let count = 0;
    userProfile.notifications.forEach((notification) => {
      if (notification.notification.isNew) {
        count++;
      }
    });
    setNewNotificationsCount(count);
    // eslint-disable-next-line
  }, [userProfile.notifications.length]);


  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <IconButton
          aria-label="Go back"
          variant="ghost"
          colorScheme={newNotificationsCount > 0 ? theme.colorScheme2 : theme.colorScheme}
          onClick={handleClick}
          icon={
            <Box position="relative">
              <FontAwesomeIcon
                icon={faBell}
                color={newNotificationsCount > 0 ? theme.completementaryColor1 : ""}
                size="lg"
                fade={newNotificationsCount > 0}
              />
              {newNotificationsCount > 0 && (
                <Box
                  position="absolute"
                  top="-4px"
                  right="-4px"
                  borderRadius="full"
                  bg="red"
                  color="white"
                  fontSize="xs"
                  fontWeight="bold"
                  lineHeight="shorter"
                  width="16px"
                  height="16px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {newNotificationsCount}
                </Box>
              )}
            </Box>
          }
          size={buttonSize}
          borderRadius={theme.borderRadius}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent width='320px'>
          <PopoverArrow />
          <PopoverHeader fontWeight="bold">{t('NotificationsButton_title')}</PopoverHeader>
          <PopoverBody>
            {userProfile.notifications.length > 0 ? (
              <Flex width={'100%'} maxHeight='400px' overflowY={'scroll'} flexWrap={'wrap'}>
                {userProfile.notifications.map((notification: NotificationFetchedFromDatabase, index: any) => (
                  <Notification key={index} notification={notification} />
                ))}
              
              </Flex>
            ) : (
              <Box p={4}>{t('NotificationsButton_no_notifications_label')}</Box>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default NotificationsButton;
