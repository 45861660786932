import { useContext, useEffect, useState } from "react";
import {
    Button,
    Divider,
    Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure
} from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { Link as Routerlink, useLocation } from "react-router-dom";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { EU, GB, NL, US } from "country-flag-icons/react/3x2";
import { useTranslation } from "react-i18next";
function AccountScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    // const [userSubscriptionPlan, setUserSubscriptionPlan] = useState<SubscriptionID>()
    const { userProfile, currentUser } = useContext(UserProfileContext);
    const location = useLocation();
    const firebaseInterface = useContext(FirebaseContext);
    const { i18n } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isDeleting, setIsDeleting] = useState(false);

    const handleSignOut = () => {
        firebaseInterface.signOut();
    };

    useEffect(() => {
        // not really necessary but I somtimes get a glitch where the language is not set correctly
        if (userProfile.language === 'nl' && i18n.language !== 'nl') {
            i18n.changeLanguage("nl");
        }
    }, [userProfile.language, i18n])

    const handleDeleteAccount = async () => {
        setIsDeleting(true);
        try {
          // Call your Firebase delete account method here
          await firebaseInterface.deleteAccount();
          // Optionally, you can perform any additional actions after deletion
          // ...
          onClose();
        } catch (error) {
          console.error('Error deleting account:', error);
        } finally {
          setIsDeleting(false);
        }
      };
    

    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" mt='20px' >


            <Flex width={"100%"} flexDir={"column"} maxWidth={"900px"}>

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px' marginLeft="5px">
                        {t('AccountScreen_title')}
                    </Heading>
                </Flex>

            </Flex>
            <Flex flexDir="column" maxWidth={"900px"} rowGap="10px" justifyContent="center" alignItems={"flex-start"} width="100%">

                <Divider width="100%" mt='2' mb='2' />
                <Flex flexDir="row" width='80%' columnGap={'10px'} justifyContent={'flex-start'}>
                    <Text fontWeight={'bold'}>
                        {t('AccountScreen_email_label')}
                    </Text>
                    <Text>
                        {currentUser?.email}
                    </Text>
                </Flex>


                <Divider width="100%" mt='2' mb='2' />
                <Flex flexDir="row" width='80%' columnGap={'10px'} justifyContent={'flex-start'} alignItems='center'>
                    <Text fontWeight={'bold'}>
                        {t('AccountScreen_language_label')}
                    </Text>
                    <Text>
                        {userProfile?.language === 'en' ? <Flex><Text>English</Text> <GB
                            style={{ marginLeft: "10px", marginRight: '10px', width: "24px", height: "24px" }}
                        /> </Flex> : <Flex><Text>Nederlands</Text> <NL
                            style={{ marginLeft: "10px", marginRight: '10px', width: "24px", height: "24px" }}
                        /> </Flex>}
                    </Text>
                    <Button colorScheme={theme.colorScheme} variant='outline' size='sm' as={Routerlink} to="/languageSettingScreen" state={{ from: location.pathname }}> Update</Button>
                </Flex>

                <Divider width="100%" mt='2' mb='2' />
                <Flex flexDir="row" width='80%' columnGap={'10px'} justifyContent={'flex-start'} alignItems='center'>
                    <Text fontWeight={'bold'}>
                        {t('AccountScreen_region_label')}
                    </Text>
                    <Text>
                        {userProfile?.region === 'eu' ? <Flex><Text>  {t('RegionSettingScreen_region_eu')}</Text> <EU
                            style={{ marginLeft: "10px", marginRight: '10px', width: "24px", height: "24px" }}
                        /> </Flex> : <Flex><Text>  {t('RegionSettingScreen_region_us')}</Text> <US
                            style={{ marginLeft: "10px", marginRight: '10px', width: "24px", height: "24px" }}
                        /> </Flex>}
                    </Text>
                    <Button colorScheme={theme.colorScheme} variant='outline' size='sm' as={Routerlink} to="/regionSettingScreen" state={{ from: location.pathname }}> Update</Button>
                </Flex>

                <Divider width="100%" mt='2' mb='2' />


                <Flex flexDir="row" width='80%' columnGap={'10px'} justifyContent={'flex-start'} alignItems='center'>
                    <Text fontWeight={'bold'}>
                        {t('AccountScreen_linked_therapist_label')}
                    </Text>
                    <Text>
                        {userProfile?.linkedHumanTherapist?.name ? userProfile?.linkedHumanTherapist?.name : t('ModifyScreen_no_linked_therapist_label')}
                    </Text>
                    <Button colorScheme={theme.colorScheme} variant='outline' size='sm' as={Routerlink} to="/addTherapistScreen" state={{ from: location.pathname }}> Update</Button>
                </Flex>


                <Divider width="100%" mt='2' mb='2' />
                <Button variant={'outline'} colorScheme={'red'} size='md' onClick={handleSignOut}> Log out</Button>
                <Divider width="100%" mt='2' mb='2' />
                <Button variant={'solid'} colorScheme={'red'} size='md' onClick={onOpen}>{t('DeleteAccount_button')}</Button>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{t('DeleteAccountModal_title')}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                        {t('DeleteAccountModal_description')}
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={onClose} isDisabled={isDeleting}>
                            {t('DeleteAccountModal_cancel_button')}
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={handleDeleteAccount}
                                isLoading={isDeleting}
                                loadingText="Deleting..."
                                isDisabled={isDeleting}
                            >
                                       {t('DeleteAccountModal_confirm_button')}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Divider width="100%" mt='2' mb='2' />


            </Flex>
            <Button colorScheme={theme.colorScheme} as={Routerlink} to={"/homeScreen"} size={'lg'} mt='40px'>{t('ModifyScreen_done_button')}</Button>


        </Flex >);
}



export default AccountScreen