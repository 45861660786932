import {  useEffect, useState } from 'react'
import { Button, Flex, Spinner, VStack, Text, Image } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../styles/styles';
import { useTranslation } from 'react-i18next';
import { GB, NL } from 'country-flag-icons/react/3x2';
import logo from '../assets/general/PsyScribeLogo.svg';
import { useAuthState } from "react-firebase-hooks/auth";
import FirebaseInterface from '../services/firebase';
import { useNavigate } from 'react-router-dom';
const LanguageRoute = ({ children }: { children: any }) => {
    const [theme] = useState<Theme>(lavenderStyle)
    const [userState, setUserState] = useState<"loading" | "notLoggedInLanguageSet" | "notLoggedInLanguageNotSet" | "loggedIn">('loading')
    const [firebase] = useState<FirebaseInterface>(new FirebaseInterface());
    const [user, isUserProfileProviderCheckingCurrentUser] = useAuthState(firebase.auth);
    const navigate = useNavigate();

    const { i18n } = useTranslation();


    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language)
        setUserState('notLoggedInLanguageSet')
    };




    const getUserState = (isUserProfileProviderCheckingCurrentUser :  boolean, currentUser :any) => {
        if (isUserProfileProviderCheckingCurrentUser) {
            return 'loading'
        }

        else {
            if (currentUser) {
                return 'loggedIn'
            }
            else{

                const preferredLanguage = localStorage.getItem('language')

                if (!preferredLanguage) {
                    return 'notLoggedInLanguageNotSet'
                }
                else {
                    i18n.changeLanguage(preferredLanguage); // set the language to the preferred language
                    return 'notLoggedInLanguageSet'
                }

            }
        }
  


    }

    useEffect(() => {
        const newUserState = getUserState(isUserProfileProviderCheckingCurrentUser, user);
     
        switch (newUserState) {
            case 'loading':
                break
            case 'loggedIn':
                navigate('/userDataFetchinScreen')
                break
            default:
                //navigate('/');
        }



        // !!! IMPORTANT THIS NEEDS TO BE SET LAST SO THAT WHEN WE GO AWAY FROM LOADING STATE
        /// WE DONT SHOW THE  <div> {children} </div> BEFORE WE NAVIGATE AWAY
        if (userState !== newUserState) {
            setUserState(newUserState);
        }


        // DONT INCLUDE USER PROFILE IN DEPENDENCIES WE ONLY WANT TO FETCH IT ONCE WE REFRESH
        // SOMETIMES IN THE APP WE UPDATE THE USER PROFILE AND WE DONT WANT TO REDIRECT THAN
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserProfileProviderCheckingCurrentUser, user]);




    if (userState === 'loading' || userState === 'loggedIn') {
        return <Flex flexDir="column" width="100%" height={"99vh"} justifyContent="center" alignItems="center"><Spinner color={theme.thirdColor} size="xl" /> </Flex>;
    }
    if (userState === 'notLoggedInLanguageNotSet') {
        return <Flex flexDir="column" width="100%" height={"99vh"} alignItems="center">
            <Image mt='50px' mb='20px' width='500px' height='100px'  src={logo} alt="My Logo" cursor="pointer" />
            <Text fontWeight={'semibold'} fontStyle={'italic'} fontSize={'sm'} color='blackAlpha.700' mb='30px' mt='10px' pl='5' pr='5'width='100%' maxWidth={'1000px'}>
                
                Psyscribe is a fully personalizable AI assistant designed to help you talk about and reflect on your mental health.
                    
                    We understand the personal nature of mental health which is why we made our AI fully personalizable. 
                    You can create an avatar for your mental health assistant, choose between multiple conversation styles, personalities and more.
                    After creating your own AI mental health assisant you can chat in an anonymous and safe environment.
                    Therapists can also link with their patients and monitor their progress.
                    Our mission is to make with the help of AI, quality mental health care available to everyone, everywhere.
                </Text>

    
            <VStack spacing={7} width="100%" maxWidth={'600px'} padding="7" borderRadius={"5"} borderColor={theme.fourthColor}>
                <Text  fontSize={'l'} fontWeight='bold' > Which language do you prefer?</Text>
                <Text   fontSize={'l'} fontWeight='bold' > Welke taal verkies je?</Text>


                <Button
                    colorScheme={theme.colorScheme}
                    width="100%"
                    variant={"outline"}
                    onClick={() => setLanguage("en")}
                >
                    English
                    <GB
                        style={{ marginLeft: "10px", width: "24px", height: "24px" }}
                    />
                </Button>
                <Button
                    colorScheme={theme.colorScheme}
                    width="100%"
                    variant={"outline"}
                    onClick={() => setLanguage("nl")}
                >
                    Nederlands
                    <NL
                        style={{ marginLeft: "10px", width: "24px", height: "24px" }}
                    />
                </Button>


            </VStack>


      
        </Flex>;
    }
    return (
        <div>
            {children}
        </div>
    )
}

export default LanguageRoute