import { useState } from "react";
import { Button, Flex, keyframes, useBreakpointValue } from "@chakra-ui/react";
import { ChatIcon } from "@chakra-ui/icons";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";

interface NewChatButtonProps {
  createNewChatHandler: () => void;
  animation?: boolean;
  isLoading?: boolean;
}

function NewChatButton(props: NewChatButtonProps) {
  const [theme] = useState<Theme>(lavenderStyle);
  const buttonWidth = useBreakpointValue({ base: "100%", sm: "100%", md: "500px" }); // Use useBreakpointValue hook to set different widths based on screen size

  const shineAnimation = keyframes`
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
`;

    return (
      <Flex width="100%" justifyContent="center">
        <Button
          width={buttonWidth}
          type="submit"
          isLoading={props.isLoading}
          variant="solid"
          colorScheme={theme.colorScheme}
          onClick={props.createNewChatHandler}
          animation={` ${shineAnimation} 20s infinite linear`}
          style={{
            background: `linear-gradient(to right, rgba(139, 139, 182, 1),rgba(97, 97, 153, 1), rgba(182, 182, 211, 1), rgba(139, 139, 182, 1))`,
            backgroundSize: "200% 100%",
            backgroundPosition: "100%",
          }}
          leftIcon={<ChatIcon />}
        >
           {t('HomeScreen_new_chat_button_text')}
        </Button>
      </Flex>
    );
  

 
}

export default NewChatButton;
