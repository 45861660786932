import { useContext, useState } from "react";
import {
    Box, Button, Flex, Heading, 
} from '@chakra-ui/react'

import { Link as Routerlink, useLocation, } from "react-router-dom";
import CustomizationRadioGroup from "../../components/settingScreens/CustomizationRadioGroup";

import { lavenderStyle, Theme, } from "../../styles/styles";
import { TypeAnimation } from "react-type-animation";
import Avatar from "../../components/avatar/Avatar";
import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { FirebaseContext } from "../../contexts/firebaseProvider";

function TypeSnelheidSettingScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile,currentUser } = useContext(UserProfileContext);
    const [typeSpeedOption, setTypeSpeedOption] = useState(userProfile.therapistSettings.typeSpeed === 55 ? "0" : "1")
    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/modifyPsychologistScreen"));
    const [slowTypeSpeedText] = useState(t('TypeSpeedSettingScreen_slowtypespeed_text'))
    const [fastTypeSpeedText] = useState(t('TypeSpeedSettingScreen_fasttypespeed_text'))
    const firebaseInterface = useContext(FirebaseContext);


    function updateTypeSpeed(typeSpeedOption: string) {
        if (typeSpeedOption === "0") {
            userProfile.therapistSettings.typeSpeed = 55
        }
        else {
            userProfile.therapistSettings.typeSpeed = 80
        }
        if (currentUser) {
            firebaseInterface.updateTypeSpeed(currentUser.uid, userProfile.therapistSettings.typeSpeed);
        }
        else {
            throw new Error("No user logged in");
        }

    }

    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
                    {isFirstTime ? <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/therapyStyleSettingScreen" /> </Flex> : <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/modifyPsychologistScreen" /> </Flex>}

            <Flex width={"100%"} flexDir={"column"} >
         
                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px'  marginLeft="5px">
                        {t('TypeSpeedSettingScreen_title')}
                    </Heading>
                </Flex>
            </Flex>


            <Flex flexDir="column" alignItems="center" height={"100%"} width="100%" marginTop="10px">
                {isFirstTime ?
                    <Flex alignItems="flexstart" justifyContent="center" >
                        {[...Array(7)].map((_, index) => (
                            <Box
                                key={index}
                                w="2"
                                h="2"
                                borderRadius="full"
                                bg={4 === index ? theme.primaryColor : theme.secondaryColor}
                                mx="2"
                            />
                        ))}
                    </Flex>
                    : null}

                <Flex width="60%" justifyContent={"center"} mt="10px">
                    <Avatar avatarParams={userProfile.therapistSettings.avatar} />

                </Flex>

                <Flex flexDirection={"column"} alignItems="center" width="100%" maxWidth={"600px"} marginTop="20px">
                    <Flex flexDirection={"column"} alignItems="center" width="90%" >

                        <TypeAnimation
                            cursor={true}
                            sequence={[
                                slowTypeSpeedText ? slowTypeSpeedText : "This is a calm typing speed",
                                3000, // Waits 1s
                                "",
                            ]}
                            wrapper="span"
                            repeat={Infinity}
                            speed={50}
                            style={{ color: "black" }}
                        />

                        <TypeAnimation
                            cursor={true}
                            sequence={[
                                fastTypeSpeedText ? fastTypeSpeedText : "This is a fast typing speed",
                                3000, // Waits 1s
                                "",
                            ]}
                            wrapper="span"
                            repeat={Infinity}
                            speed={80}
                            style={{ color: "black" }}
                        />
                    </Flex>
                    <Flex paddingTop="20px" paddingBottom="50px">
                        <CustomizationRadioGroup theme={theme} heading={t('TypeSpeedSettingScreen_radiogroup_title')} radioValues={[t('TypeSpeedSettingScreen_radiobutton1'), t('TypeSpeedSettingScreen_radiobutton2')]}
                            onChange={setTypeSpeedOption} value={typeSpeedOption}
                        />
                    </Flex>
                </Flex>


                <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' >
                    <Button as={Routerlink} to={isFirstTime ?"/nameUserSettingScreen":"/modifyPsychologistScreen"} colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} onClick={() => updateTypeSpeed(typeSpeedOption)}> {isFirstTime?t('SettingScreen_first_time_done'): t('SettingScreen_second_time_done')}</Button>
                </Flex>
            </Flex>
        </Flex >
    )
}


export default TypeSnelheidSettingScreen