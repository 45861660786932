import { useState } from "react";
import {
  Button,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import { Chat } from "../../types/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

interface ChatWithoutInsightButtonProps {
  chat: Chat;
}

function ChatWithoutInsightButton(props: ChatWithoutInsightButtonProps) {
  const navigate = useNavigate();
  const [theme] = useState<Theme>(lavenderStyle);
  const buttonWidth = useBreakpointValue({ base: "100%", sm: "100%", md: "500px" }); // Use useBreakpointValue hook to set different widths based on screen size
  const handleChatClick = () => {
    //props.handleSelectInsightReport(props.insightReport);
    navigate(`/insightsCreatorScreen/${props.chat.databaseId}`);
  };

  return (
    <Flex>
     
        <Button
          width={buttonWidth}
          justifyContent="center"
          padding="10px"
          onClick={handleChatClick}
          variant="outline"
          leftIcon={<FontAwesomeIcon icon={faLightbulb} />} 
          colorScheme={theme.colorScheme}
          overflow="hidden" textOverflow="ellipsis"
        >
         {t('CreateInsightReport_label')}
        </Button>
 
    </Flex>
  );
}

export default ChatWithoutInsightButton;
