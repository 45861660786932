
import { useState, useRef, useEffect, useContext } from "react";
import {
    Text,
    Divider,
    useToast,
    Flex,
    Spinner,
} from '@chakra-ui/react'
import MessageBox from "../../components/chat/MessageBox";
import { lavenderStyle } from "../../styles/styles";
import Avatar from "../../components/avatar/Avatar";
import { Theme } from "../../styles/styles";
import { t } from "i18next";
import InputField from "../../components/chat/InputField";
import { Language, MessageType, NotificationInferface, Personality, RateLimitCheck, TherapyStyle } from "../../types/types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import RememberedMessagesButton from "../../components/sharedComponents/topNavigationRow/RememberedMessageButton";
import PineconeInterface from "../../services/pinecone";
import { isMobileCheck } from "../../utils/mobileCheck";
import InsightServer from "../../services/insightServer";
import UsageLimitAlert from "../../components/alerts/UsageLimitAlert";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { isAllowedToRememberMessage, isAllowedToSendMessage } from "../../security/rateLimitChecks";
import NotificationsButton from "../../components/sharedComponents/topNavigationRow/NotificationsButton";
import ProfileButton from "../../components/sharedComponents/topNavigationRow/ProfileButton";
import UserFeedbackAlert from "../../components/alerts/UserFeedbackAlert";
import TryDifferentSettingsAlert from "../../components/alerts/TryDifferentSettingsAlert";

/* eslint-disable no-useless-concat */
// TODO laad chats van  gebruiker zelf niet meer  in.

// Chats zijn niet op to date
function ChatbotScreen() {
    const { chatId } = useParams<string>();
    const [chatIdString] = useState<string>(chatId as string);
    //const [socket, setSocket] = useState<WebSocket | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const [totalMessagesWithoutBotResponse, setTotalMessagesWithoutBotResponse] = useState(0);
    const { userProfile, setUserProfile, currentUser } = useContext(UserProfileContext);
    const [chatSummary, setChatSummary] = useState("")
    const [chatSummaryLoaded, setChatSummaryLoaded] = useState(false)
    const [theme] = useState<Theme>(lavenderStyle)
    const [messageQueue, setMessageQueue] = useState<MessageType[]>([]);
    const [isBotTyping, setIsBotTyping] = useState(false);
    const [messages, setMessages] = useState<MessageType[]>([]);
    const elementRef = useRef();
    const toast = useToast()
    const location = useLocation();
    const [messagesLoaded, setMessagesLoaded] = useState(false)
    const [botInitialized, setBotInitialized] = useState(false)
    const [isMobile] = useState(isMobileCheck())
    const firebaseInterface = useContext(FirebaseContext);
    const [rememberedMessageButtonNotification, setRememberedMessageButtonNotification] = useState(false)
    const socketChatbotRef = useRef<WebSocket | null>(null);
    const [waitingToReconnect, setWaitingToReconnect] = useState(false);
    const [showUsageLimitAlert, setShowUsageLimitAlert] = useState<boolean>(false);
    const [currentUsageLimitAlert, setCurrentUsageLimitAlert] = useState<RateLimitCheck>();
    const [totalMessagesWithoutInsights, setTotalMessagesWithoutInsights] = useState<number>(0)
    const [showUserFeedbackAlert, setShowUserFeedbackAlert] = useState<boolean>(false);
    const [showTryDifferentSettingsAlert, setShowTryDifferentSettingsAlert] = useState<boolean>(false);
    const [userFeedbackMessage, setUserFeedbackMessage] = useState<string>("")
    const [userFeedbackCategory, setUserFeedbackCategory] = useState<"like"|"dislike">("like")
    const [messagesBeforeFeedbackMessage, setMessagesBeforeFeedbackMessage] = useState<MessageType[]>([])
    const navigate = useNavigate()

    const closeUserFeedbackLikeAlert = () => {
        setShowUserFeedbackAlert(false)
    }


    const closeUsageLimitNotification = () => {
        setShowUsageLimitAlert(false)
    }
    const getChatbotURL = () => {
        
        if (userProfile.region === 'eu') {
            if (serverNumber === 1) {
                return 'wss://chateu.psyscribe.com/'
            }
            else{
                return 'wss://chateu.psyscribe.com/'
            }
        }
        if (userProfile.region === 'us') {
            if (serverNumber === 1) {
                return 'wss://chateu.psyscribe.com/'
            }
            else{
                return 'wss://chateu.psyscribe.com/'
            }
        }
        return 'wss://chateu.psyscribe.com/'
       // userProfile.region === 'eu'? 'wss://server.psyscribe.com/' : 'wss://chatusa.psyscribe.com/' 
    }
    
    //const CHATBOT_URL = "ws://localhost:5005/"
    //const CHATBOT_URL = 'ws://192.168.0.233:5005/'
    //const CHATBOT_URL = 'wss://server.psyscribe.com/'
    const [serverNumber] = useState<number> (Math.floor(Math.random() * 2) + 1)
    const [CHATBOT_URL] = useState<string>(getChatbotURL() )
    //const CHATBOT_URL = userProfile.region === 'eu'? 'wss://server.psyscribe.com/' : 'wss://chatusa.psyscribe.com/'
    
   
    // Connection to socket
    useEffect(() => {
        if (waitingToReconnect) {
            return;
        }

        if (!socketChatbotRef.current) {
            const client = new WebSocket(CHATBOT_URL);
            socketChatbotRef.current = client;
            client.onerror = handleError;
            client.onopen = handleConnectionOpen;
            client.onclose = handleConnectionClose;
            client.onmessage = handleMessageReceived;

            return () => {
                // Dereference, so it will set up next time
                socketChatbotRef.current = null;
                client.close();
            }
        }
        // eslint-disable-next-line
    }, [waitingToReconnect]);


    const handleConnectionOpen = () => {
        setIsConnected(true);
    }

    const handleError = (error: any) => {
        setTotalMessagesWithoutBotResponse(0)
        setIsBotTyping(false)
    }



    async function addNotificationInsightReportReceived() {
        if (!currentUser) { return };
        const chatName = await firebaseInterface.getChatName(currentUser.uid, chatIdString)

        
        const testNotification: NotificationInferface = {
            category: 'insights',
            title: t('InsightsAvailableNotification_title'),
            message: t('InsightsAvailableNotification_description_1') + chatName + t('InsightsAvailableNotification_description_2'),
            isNew: true,
            onClickNavigateTo: "/insightsCreatorScreen/" + chatIdString
        }

        // we need  to store them to firebase first because the notifications  need  to be assigned an id so the user  can delete them
        await firebaseInterface.addNotication(currentUser.uid, testNotification)
        firebaseInterface.fetchNotifications(currentUser.uid).then((notifications) => {
            setUserProfile((oldProfile) => {
                return {
                    ...oldProfile,
                    notifications: notifications
                }
            })
        })
        toast({
            position: 'top',
            render: () => (
                <Flex justifyContent={"center"} color='white' p={3} bg={theme.completementaryColor1} borderRadius="10" >
                    {t('InsightsAvailableToast')}
                </Flex>
            ),
        })
    }

    const handleConnectionClose = () => {
        setTotalMessagesWithoutBotResponse(0)
        setIsBotTyping(false)
        if (socketChatbotRef.current) {
            // Connection failed
            // ws closed by server
        } else {
            // Cleanup initiated from app side, can return here, to not attempt a reconnect
            const insightServer = new InsightServer(userProfile.region)
            if (!currentUser) {
                throw new Error("User is not logged in");
            }
            if (totalMessagesWithoutInsights >= 3) {

               
                insightServer.requestInsights(currentUser.uid, chatIdString, userProfile.language).then((response) => {
                    if (response.success) {
                        addNotificationInsightReportReceived()

                    }
                })
                setTotalMessagesWithoutInsights(0)
            }
            return;
        }

        if (waitingToReconnect) {
            return;
        };

        // Parse event code and log
        setIsConnected(false);
        setBotInitialized(false)
        setMessagesLoaded(false)

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(true);

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(false), 3000);
    };

    const handleMessageReceived = ({ data }: any) => {
        if (!currentUser) {
            throw new Error("User is not logged in");
        }

        const serverMessage = JSON.parse(data);
        switch (serverMessage.type) {
            case "message":
                const message: MessageType = {
                    message: serverMessage.text,
                    sender: "psychologist",
                    animation: true,
                    timestampInSeconds: new Date().getTime() / 1000,
                    isSaved: false
                };
                firebaseInterface.addMessage(currentUser.uid, chatIdString, message)
                setMessageQueue((prevQueue) => [...prevQueue, message]);
                break
            case "initialized":
                setBotInitialized(true)
                break
            case "summary":
                // strip the "/user_description_updated:" part
                const chatSummary = serverMessage.text
                // return if description has no length
                if (chatSummary.length === 0) {
                    return
                }
                firebaseInterface.updateChatSummary(currentUser.uid, chatSummary, chatIdString)
                setChatSummary(chatSummary)
                break
            case "server_full":
                // strip the "/user_description_updated:" part
                // return if description has no length
                // navigate to home screen
                navigate("/chatbotServerFullScreen")
                break
            // unknown message type
            default:
                setTotalMessagesWithoutBotResponse(0)
                break
        }
    }


    // The bot can send multiple messages and these get added to a queue 
    // we want the type animation to start after the animation of the previous message is done
    useEffect(() => {
        if (messageQueue.length > 0) {
            let delay = (messageQueue[0]?.message?.length || 0) * userProfile.therapistSettings.typeSpeed * 0.28;
            if (userProfile.therapistSettings.typeSpeed === 55) {
                delay = (messageQueue[0]?.message?.length || 0) * userProfile.therapistSettings.typeSpeed * 0.92;
            }
            if (!isBotTyping) {
                setMessages(old => [...old, messageQueue[0]]);
                setIsBotTyping(true);
            }
            // Remove the first message from the queue after a delay
            const timeoutId = setTimeout(() => {
                if (totalMessagesWithoutBotResponse > 0) {
                    setTotalMessagesWithoutBotResponse(totalMessagesWithoutBotResponse - 1);
                }
                setMessageQueue((prevQueue) => prevQueue.slice(1));
                setIsBotTyping(false);
            }, delay); // Wait 5 seconds before showing the next message
            // Cleanup the timeout when the component unmounts or when the message changes
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [messageQueue, isBotTyping, userProfile.therapistSettings.typeSpeed, totalMessagesWithoutBotResponse]);


    // load messages from firebase
    useEffect(() => {
        if (!currentUser) {
            return;
        }
        getFirebaseStoredChatMessages().then((messages) => {
            if (messages && messages.length > 0) {
                if (userProfile.therapistSettings.rememberedMessages.length > 0) {
                    const savedMessagesTexts: string[] = userProfile.therapistSettings.rememberedMessages.map((message) => message.message)
                    // set remembered messages to true if they are message
                    messages.forEach((message) => {
                        if (savedMessagesTexts.includes(message.message)) {
                            message.isSaved = true;
                        }
                    }
                    )
                }
                setMessages(messages)
            }
            setMessagesLoaded(true)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, messagesLoaded]);

    //  load chatSummary from local storage or firebase
    useEffect(() => {
        if (!currentUser) {
            return;
        }
        getFirebaseStoredChatSummary().then((chatSummary) => {
            setChatSummary(chatSummary as string)
            setChatSummaryLoaded(true)
        })
        // eslint-disable-next-line 
    }, [currentUser]);


    // initialize the bot with the settings chosen by the user
    useEffect(() => {
        if (messagesLoaded && chatSummaryLoaded && isConnected && currentUser) {
            let lastMessages = messages.slice(Math.max(messages.length - 5, 0))
            const lastMessagesString = (JSON.stringify(lastMessages))
            // Else the slots won't be filled in RASA
            let chat_summary_without_new_lines = ""
            if (chatSummary) {
                chat_summary_without_new_lines = chatSummary;
            }
            currentUser.getIdToken().then((id_token) => {

                const initialize_message = constructInializeChatbotMessage(currentUser.uid, userProfile.therapistSettings.personality, userProfile.therapistSettings.therapyStyle, userProfile.username, chat_summary_without_new_lines, lastMessagesString, userProfile.therapistSettings.psychologistName, userProfile.language, id_token)
             
                socketChatbotRef.current?.send(initialize_message)
            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, messagesLoaded, isConnected, chatSummaryLoaded])

    // possibly send opening message
    useEffect(() => {
        if (botInitialized && currentUser) {
            let startMessageNeedsToBeSent = messages?.length === 0;
            if (startMessageNeedsToBeSent) {
                sendOpeningMessage()
            }
            setMessages((old) => [...old, { sender: "scroll", message: "", animation: false, timestampInSeconds: new Date().getTime() / 1000, isSaved: false }]);
        }
        // eslint-disable-next-line 
    }, [currentUser, botInitialized])

    // requesting insights
    useEffect(() => {
        if (totalMessagesWithoutInsights >= 10 && messages.length > 0 && currentUser) {
            const insightServer = new InsightServer(userProfile.region)
            insightServer.requestInsights(currentUser.uid, chatIdString, userProfile.language).then((response) => {
                if (response.success) {
                    addNotificationInsightReportReceived()

                }
            })
            setTotalMessagesWithoutInsights(0)
        }
        // eslint-disable-next-line
    }, [messages, currentUser, chatIdString, userProfile.language])


    useEffect(() => {
        if (!currentUser) {
            return;
        }
        firebaseInterface.getTotalMessagesWithoutInsights(currentUser.uid, chatIdString).then((totalMessagesWithoutInsights) => {
            setTotalMessagesWithoutInsights(totalMessagesWithoutInsights)
        }
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[])


    const canSendMessage = () => {
        return totalMessagesWithoutBotResponse === 0 && !isBotTyping && botInitialized
    }

    async function logMessageSent() {
        if (!currentUser) {
            throw new Error("User is not logged in")
        }
        // paid plans each have a different number of messages they can send daily so get rate limited on their daily stats
        // trial users get 50 free messages (not daily) so get rate limited on their total stats
        // when trail users sent 50 messages and afterwards upgrade to a paid plan I don't want those 50 messagegs to have counted towards their daily limit.
        // so I only increment the daily stats for paid users
        if (userProfile.subscriptionId === 'trial') {
            setUserProfile((oldProfile) => {
                return {
                    ...oldProfile,
                    totalStats: {
                        ...oldProfile.totalStats,
                        totalMessagesSent: oldProfile.totalStats.totalMessagesSent + 1
                    }
                }
            })
        }
        else {

            setUserProfile((oldProfile) => {
                return {
                    ...oldProfile,
                    dailyStats: {
                        ...oldProfile.dailyStats,
                        totalMessagesSent: oldProfile.dailyStats.totalMessagesSent + 1
                    },
                    totalStats: {
                        ...oldProfile.totalStats,
                        totalMessagesSent: oldProfile.totalStats.totalMessagesSent + 1

                    }
                }
            })
        }
        firebaseInterface.logMessageSent(currentUser.uid, userProfile.subscriptionId)
    }

    async function logMessageRemembered() {
        if (!currentUser) {
            throw new Error("User is not logged in")
        }

        setUserProfile((oldProfile) => {
            return {
                ...oldProfile,
                dailyStats: {
                    ...oldProfile.dailyStats,
                    totalMessagesRemembered: oldProfile.dailyStats.totalMessagesRemembered + 1
                },
                totalStats: {
                    ...oldProfile.totalStats,
                    totalMessagesRemembered: oldProfile.totalStats.totalMessagesRemembered + 1

                }
            }
        })

        firebaseInterface.logMessageRemembered(currentUser.uid)
    }




    const handleSendMessage = async (inputMessage: string) => {
        if (currentUser) {
            const rateLimitCheck = isAllowedToSendMessage(userProfile.subscriptionId, userProfile.dailyStats.totalMessagesSent, userProfile.totalStats.totalMessagesSent)
            if (!rateLimitCheck.allowed) {
                setShowUsageLimitAlert(true)
                setCurrentUsageLimitAlert(rateLimitCheck)
                return;
            }

            socketChatbotRef.current?.send(JSON.stringify({ type: "message", text: inputMessage }));
            const message: MessageType = { sender: "user", message: inputMessage, animation: false, timestampInSeconds: new Date().getTime() / 1000, isSaved: false }
            setMessages((old) => [...old, message]);
            firebaseInterface.addMessage(currentUser.uid, chatIdString, message)
            firebaseInterface.updateChatServerTimestamp(currentUser.uid, chatIdString)
            setTotalMessagesWithoutBotResponse(totalMessagesWithoutBotResponse + 1);
            setTotalMessagesWithoutInsights(totalMessagesWithoutInsights + 1);
            logMessageSent()
            if (userProfile.totalStats.totalMessagesSent === 14) {
                setShowTryDifferentSettingsAlert(true)
            }
        } else {
            throw new Error("User is not logged in");
        }
    };



    const getFirebaseStoredChatMessages = async () => {
        if (!currentUser) {
            throw new Error("User is not logged in");
        }
        if (firebaseInterface) {
            const messages = await firebaseInterface.getMessages(currentUser.uid, chatIdString)
            let messageArray: MessageType[] = [];
            messages.forEach((savedMessage) => {
                let messageObject: MessageType = {
                    message: savedMessage.text,
                    sender: savedMessage.sender,
                    animation: false,
                    //@ts-ignore
                    timestampInSeconds: savedMessage.timestamp.seconds,
                    isSaved: false
                }
                messageArray.push(messageObject);
            })
            return messageArray;
        }
    }




    const getFirebaseStoredChatSummary = async () => {
        if (!currentUser) {
            throw new Error("User is not logged in");
        }
        if (firebaseInterface) {
            const chatSummary: string = await firebaseInterface.getChatSummary(currentUser.uid, chatIdString)
            return chatSummary;
        }
    }


    const sendOpeningMessage = () => {
        if (!currentUser) {
            throw new Error("User is not logged in");
        }
        const startMessage = `${t('ChatbotScreen_opening_start')}${" " + userProfile.username + ","} ${t('ChatbotScreen_opening_im')} ${userProfile.therapistSettings.psychologistName} ${t('ChatbotScreen_opening_uitleg')}`;
        const message: MessageType = {
            message: startMessage,
            sender: "psychologist",
            animation: true,
            timestampInSeconds: new Date().getTime() / 1000,
            isSaved: false
        };
        firebaseInterface.addMessage(currentUser.uid, chatIdString, message)
        setMessageQueue((prevQueue) => [...prevQueue, message]);
    }



    const constructInializeChatbotMessage = (uid: string, personality: Personality, therapyStyle: TherapyStyle, userName: string,
        chatSummary: string, lastMessages: string, botName: string, language: Language, id_token: any) => {
        let intializeMessage = JSON.stringify({ "type": "initialize", "uid": uid, "id_token": id_token, "settings": { "personality": personality, "therapy_style": therapyStyle, "user_name": userName, "bot_name": botName, "chat_summary": chatSummary, "message_history": lastMessages, "language": language } })
        return intializeMessage;
    }




    const updateSavedMessages = (newSavedMessages: MessageType[]) => {
        setUserProfile((oldProfile) => {
            return {
                ...oldProfile,
                therapistSettings: {
                    ...oldProfile.therapistSettings,
                    rememberedMessages: newSavedMessages
                }
            }
        })
    }

    const handleMessageLikeClicked = async (message: MessageType) => {
        if(message.isLiked){
            return;
        }
        message.isLiked = true
        setMessages((oldMessages) => {
            let newMessages = [...oldMessages]
            const index = newMessages.findIndex((oldMessage) => oldMessage.timestampInSeconds === message.timestampInSeconds)
            newMessages[index] = message
            return newMessages
        }
        )
        const previousMessages = messages.filter((oldMessage) => oldMessage.timestampInSeconds < message.timestampInSeconds).slice(-5)

        setUserFeedbackMessage(message.message)
        setUserFeedbackCategory("like")
        setMessagesBeforeFeedbackMessage(previousMessages)

        
        setShowUserFeedbackAlert(true)
    }

    const handleMessageDislikeClicked = async (message: MessageType) => {
        if(message.isDisliked){
            return;
        }
        message.isDisliked = true
        setMessages((oldMessages) => {
            let newMessages = [...oldMessages]
            const index = newMessages.findIndex((oldMessage) => oldMessage.timestampInSeconds === message.timestampInSeconds)
            newMessages[index] = message
            return newMessages
        }
        )

        // find the 3 previous messages
        const previousMessages = messages.filter((oldMessage) => oldMessage.timestampInSeconds < message.timestampInSeconds).slice(-5)
        setUserFeedbackMessage(message.message)
        setUserFeedbackCategory("dislike")
        setMessagesBeforeFeedbackMessage(previousMessages)
        setShowUserFeedbackAlert(true)
    
    }

    const handleRememberMessageButtonClicked = async (message: MessageType) => {
        if (!currentUser) {
            throw new Error("User is not logged in");
        }
        const pineconeInterface = new PineconeInterface();
        if (message.isSaved) {
            // remove from saved messages
            const newSavedMessages = userProfile.therapistSettings.rememberedMessages.filter((savedMessage) => savedMessage.timestampInSeconds !== message.timestampInSeconds)
            updateSavedMessages(newSavedMessages)
            message.isSaved = false
            firebaseInterface.deleteRememberedMessage(currentUser.uid, message)
            pineconeInterface.removeMessageFromPinecone(currentUser.uid, message.message)
            toast({
                position: 'top',
                render: () => (
                    <Flex justifyContent={"center"} color={theme.primaryColor} p={3} bg={"white"} borderRadius="10" borderColor={"gray"} borderWidth="1px">
                        {t('MessageDeleted_toast')}
                    </Flex>
                ),
            })
            return
        }


        const rateLimitCheck = isAllowedToRememberMessage(userProfile.subscriptionId, userProfile.dailyStats.totalMessagesRemembered, userProfile.therapistSettings.rememberedMessages.length)
        if (!rateLimitCheck.allowed) {
            setShowUsageLimitAlert(true)
            setCurrentUsageLimitAlert(rateLimitCheck)
            return;
        }


        // add to saved messages
        const newSavedMessages = [...userProfile.therapistSettings.rememberedMessages, message]
        updateSavedMessages(newSavedMessages)
        message.isSaved = true
        firebaseInterface.addRememberedMessage(currentUser.uid, message)
        pineconeInterface.addMessageToPinecone(currentUser.uid, message.message)
        if (newSavedMessages.length === 1) {
            setRememberedMessageButtonNotification(true)
        }

        logMessageRemembered()
        // toast
        toast({
            position: 'top',
            render: () => (
                <Flex justifyContent={"center"} color='white' p={3} bg={theme.completementaryColor1} borderRadius="10" >
                    {t('MessageSaved_toast')}
                </Flex>
            ),
        })
    }

    return (<Flex flexDirection='column' height={isMobile ? "88vh" : "99vh"} width="96%" alignItems="center" marginLeft="auto" overflowY="auto"
        marginRight="auto"  >

        {showUsageLimitAlert && currentUsageLimitAlert && (
            <UsageLimitAlert onClose={closeUsageLimitNotification} rateLimitCheck={currentUsageLimitAlert} />
        )}

        {showUserFeedbackAlert && (
            <UserFeedbackAlert onClose={closeUserFeedbackLikeAlert} message={userFeedbackMessage} category={userFeedbackCategory} previousMessages={messagesBeforeFeedbackMessage} userID={currentUser!.uid} chatID={chatIdString} therapistSettings={userProfile.therapistSettings}/>
        )}

        {
            showTryDifferentSettingsAlert && (
                <TryDifferentSettingsAlert onClose={() =>setShowTryDifferentSettingsAlert(false)} />
            )


        }



        <Flex width="100%" justifyContent={"space-between"} mt="2" zIndex={'overlay'}>

            <PreviousScreenButton previousScreen={location.state ? location.state.from:"/homeScreen"} />
            <Flex>

                <RememberedMessagesButton isNotificationOn={rememberedMessageButtonNotification} />

                <NotificationsButton />
                <ProfileButton />

            </Flex>

        </Flex>

        <Flex width="60%" justifyContent={"center"} maxHeight="30vh" maxWidth="30vh" mt="-10">
            <Avatar avatarParams={userProfile.therapistSettings.avatar} />
        </Flex>

        <Divider color="red" maxWidth="900px" />
        {!botInitialized ?
            <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%" width="100%" maxWidth="900px">
                <Text color={theme.primaryColor} mb="5"> {t('ChatbotScreen_connection_text')}</Text>
                <Spinner color={theme.thirdColor} size="xl" />
            </Flex>
            : <Flex flexDirection="column" height="100%" width="100%" overflowY="auto" maxWidth="1000px" alignItems="center">
                <MessageBox theme={theme} innerRef={elementRef} messages={messages} isWaitAnimationVisible={!canSendMessage() && (totalMessagesWithoutBotResponse === 1 && !isBotTyping)} typeSpeed={userProfile.therapistSettings.typeSpeed} handleMessageSave={handleRememberMessageButtonClicked} handleMessageLiked={handleMessageLikeClicked} handleMessageDisliked={handleMessageDislikeClicked} />
                <InputField isMobile={isMobile} canSendMessage={canSendMessage()} theme={theme} handleSendMessage={handleSendMessage} />
            </Flex>}
    </Flex>
    )
}

export default ChatbotScreen
