import { useRef, useEffect } from "react";
import { AvatarParams } from "../../types/types";

import "./Avatar.css";
import AvatarBrows from "./AvatarBrows";
import AvatarClothes from "./AvatarClothes";
import AvatarEarrings from "./AvatarEarrings";
import AvatarFacialHair from "./AvatarFacialHair";
import AvatarGlasses from "./AvatarGlasses";
import AvatarHair from "./AvatarHair";
import AvatarHead from "./AvatarHead";


interface ChoosenAvatar {
    head: AvatarHead;
    hair: AvatarHair;
    brows: AvatarBrows;
    facialhair: AvatarFacialHair;
    glasses: AvatarGlasses;
    clothes: AvatarClothes;
    earrings: AvatarEarrings;
}




interface AvatarProps {
    avatarParams: AvatarParams;
}

/**
 * Renders the avatar based on the chosen avatar parameters
 */

function Avatar(props: AvatarProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        const avatarOptions: ChoosenAvatar = {
            head: new AvatarHead(props.avatarParams.chosenGender, props.avatarParams.chosenSkinColor, props.avatarParams.chosenAge),
            hair: new AvatarHair(props.avatarParams.chosenGender, props.avatarParams.chosenHairType, props.avatarParams.chosenHairColor),
            brows: new AvatarBrows(props.avatarParams.chosenGender, "1", props.avatarParams.chosenHairColor),
            facialhair: new AvatarFacialHair(props.avatarParams.chosenGender, props.avatarParams.chosenFacialHairType, props.avatarParams.chosenHairColor),
            glasses: new AvatarGlasses(props.avatarParams.chosenGender, props.avatarParams.chosenGlassesType),
            clothes: new AvatarClothes(props.avatarParams.chosenGender, props.avatarParams.chosenClothesType),
            earrings: new AvatarEarrings(props.avatarParams.chosenEarRingsType)
        }
        const canvas = canvasRef.current
        if (!canvas) return;
        const context = canvas.getContext('2d')
        let dpi = 2.2
        canvas.width = 1001 * dpi
        canvas.height = 1001 * dpi
        if (avatarOptions.head.gender === "male") {
            context?.scale(1.16, 1.2)
            context?.translate(-170, 20)
        }
        else {
            context?.scale(1.16, 1.2)
            context?.translate(-170, 20)
        }
        if (context != null) {
            drawAvatar(context, avatarOptions, canvas.width, canvas.height)
        }
    }, [props.avatarParams.chosenGender, props.avatarParams.chosenSkinColor, props.avatarParams.chosenAge, props.avatarParams.chosenHairType, props.avatarParams.chosenHairColor, props.avatarParams.chosenFacialHairType, props.avatarParams.chosenGlassesType, props.avatarParams.chosenClothesType, props.avatarParams.chosenEarRingsType])
    return <canvas ref={canvasRef} className="avatar_choosen" />;
}

async function drawAvatar(context: CanvasRenderingContext2D, avatarOptions: ChoosenAvatar, canvasWidth: number, canvasHeight: number) {
    await avatarOptions.head.renderOnCanvas(context, canvasWidth, canvasHeight)
    await avatarOptions.brows.renderOnCanvas(context, canvasWidth, canvasHeight)
    await avatarOptions.glasses.renderOnCanvas(context, canvasWidth, canvasHeight)
    await avatarOptions.hair.renderOnCanvas(context, canvasWidth, canvasHeight)
    if (avatarOptions.head.gender === "female") {
        await avatarOptions.earrings.renderOnCanvas(context, canvasWidth, canvasHeight)
    }
    if (avatarOptions.head.gender === "male") {
        await avatarOptions.facialhair.renderOnCanvas(context, canvasWidth, canvasHeight)
    }
    await avatarOptions.clothes.renderOnCanvas(context, canvasWidth, canvasHeight)
}



export default Avatar