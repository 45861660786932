import { useContext, useState } from "react";
import {
    Box,
    Button, Flex, Heading, Text
} from '@chakra-ui/react'
import { Link as Routerlink, useLocation } from "react-router-dom";
import CustomizationRadioGroup from "../../components/settingScreens/CustomizationRadioGroup";
import { lavenderStyle, Theme } from "../../styles/styles";
import Avatar from "../../components/avatar/Avatar";
import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { FirebaseContext } from "../../contexts/firebaseProvider";

function PersonalitySettingScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile, currentUser } = useContext(UserProfileContext);
    const [personality] = useState(userProfile.therapistSettings.personality)
    const [personalityRadioValue, setPersonalityRadioValue] = useState(personality ? personality === "motivational" ? '0' : personality === "compassionate" ? '1' : personality === "lighthearted" ? '2' : '0' : '0')
    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/modifyPsychologistScreen"));
    const firebaseInterface = useContext(FirebaseContext);


    function updatePersonality(personalityRadioValue: string) {
        switch (personalityRadioValue) {
            case '0':
                userProfile.therapistSettings.personality = 'motivational'
                break;
            case '1':
                userProfile.therapistSettings.personality = 'compassionate'
                break;
            case '2':
                userProfile.therapistSettings.personality = 'lighthearted'
                break;
            default:
                userProfile.therapistSettings.personality = 'compassionate'
                break;
        }
        if (currentUser) {
            firebaseInterface.updatePersonality(currentUser.uid, userProfile.therapistSettings.personality);
        }
        else {
            throw new Error("User not logged in");
        }


    }


    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            {isFirstTime ?
                    <Flex width="100%" justifyContent={"flex-start"} mt="2">  <PreviousScreenButton previousScreen="/namePsychologistSettingScreen" /> </Flex>
                    : <Flex width="100%" justifyContent={"flex-start"} mt="2">  <PreviousScreenButton previousScreen="/modifyPsychologistScreen" /> </Flex>}
            <Flex width={"100%"} flexDir={"column"} >
                

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px'  marginLeft="5px">
                        {t('PersonalitySettingScreen_title')}
                    </Heading>
                </Flex>
            </Flex>


            <Flex flexDir="column" alignItems="center" height={"100%"} width="100%" marginTop="10px">
                {isFirstTime ?
                    <Flex alignItems="flexstart" justifyContent="center" >
                        {[...Array(7)].map((_, index) => (
                            <Box
                                key={index}
                                w="2"
                                h="2"
                                borderRadius="full"
                                bg={2 === index ? theme.primaryColor : theme.secondaryColor}
                                mx="2"
                            />
                        ))}
                    </Flex>
                    : null}


                <Flex width="60%" justifyContent={"center"} mt="10px">
                    <Avatar avatarParams={userProfile.therapistSettings.avatar} />

                </Flex>


                <Flex flexDirection={"column"} justifyContent="flex-start" width="100%" maxWidth={"600px"} marginTop="20px">
                    <CustomizationRadioGroup theme={theme} heading={t('PersonalitySettingScreen_radiogroup_title')} radioValues={[t('PersonalitySettingScreen_radiobutton1'), t('PersonalitySettingScreen_radiobutton2'), t('PersonalitySettingScreen_radiobutton3')]}
                        onChange={setPersonalityRadioValue} value={personalityRadioValue}
                    />
                </Flex>

                <Flex width="100%" justifyContent="center" height="300px" maxHeight="100px" maxWidth={"600px"} marginTop="10px" >
                    {personalityRadioValue === '0' && <Text width="90%" as='em' marginLeft="5px">{t('PersonalitySettingScreen_radiobutton1_explanation')}</Text>}
                    {personalityRadioValue === '1' && <Text width="90%" as='em' marginLeft="5px">{t('PersonalitySettingScreen_radiobutton2_explanation')}</Text>}
                    {personalityRadioValue === '2' && <Text width="90%" as='em' marginLeft="5px">{t('PersonalitySettingScreen_radiobutton3_explanation')}</Text>}

                </Flex>


                <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' mt='40px' >
                    <Button as={Routerlink} to={isFirstTime?"/therapyStyleSettingScreen":"/modifyPsychologistScreen"} colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} onClick={() => updatePersonality(personalityRadioValue)}> {isFirstTime?t('SettingScreen_first_time_done'): t('SettingScreen_second_time_done')}</Button>
                </Flex>
            </Flex>
        </Flex >
    )
}



export default PersonalitySettingScreen