import { useState } from "react";
import {
    Button, Flex, Heading, Text
} from '@chakra-ui/react'
import { Link as Routerlink, useLocation, } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
function GdprScreen() {
    const [theme] = useState<Theme>(lavenderStyle)

    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/infoOverviewScreen"));

    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            {isFirstTime ? <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/usageRisksScreen" /> </Flex> : <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen="/infoOverviewScreen" /> </Flex>}


            <Flex width={"100%"} flexDir={"column"} maxWidth={"900px"}>

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px' marginLeft="5px">
                    {t('GdprScreen_title')}
                    </Heading>

                </Flex>
            </Flex>
            <Flex flexDir="column" mt="5" width='95%' maxWidth={"900px"}>
                <Flex flexDir="column">

                    <Text>
                    {t('GdprScreen_description')}


                    </Text>

                </Flex>


                <Flex flexDir="column" mt="5">
                    <Heading size='sm' paddingTop='5px' color={theme.text1} >            {t('GdprScreen_collected_info_title')} </Heading>
                    <Text>    {t('GdprScreen_collected_info_description')} </Text>
                </Flex>
                <Flex flexDir="column" mt="5">
                    <Heading size='sm' paddingTop='5px' color={theme.text1} > {t('GdprScreen_sharing_info_title')}</Heading>
                    <Text> {t('GdprScreen_sharing_info_description')} </Text>
                </Flex>

                <Flex flexDir="column" mt="5">
                    <Heading size='sm' paddingTop='5px' color={theme.text1} >{t('GdprScreen_security_title')} </Heading>
                    <Text>{t('GdprScreen_security_description')} </Text>
                </Flex>



                <Flex flexDir="column" mt="5">
                    <Heading size='sm' paddingTop='5px' color={theme.text1} > {t('GdprScreen_data_removable_title')}</Heading>
                    <Text> {t('GdprScreen_data_removable_description')} </Text>
                </Flex>

                <Flex flexDir="column" mt="5">
                    <Heading size='sm' paddingTop='5px' color={theme.text1} >{t('GdprScreen_questions_title')} </Heading>
                    <Text>{t('GdprScreen_questions_description')} </Text>
                </Flex>
            </Flex>

            {isFirstTime ?
            <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' marginTop={"30px"} >

                <Button mb="10" colorScheme={theme.colorScheme} size="lg" as={Routerlink} to={"/avatarCustomizationScreen"} >{t('GdprScreen_agree_button')}</Button>
            </Flex>
            : null}

        </Flex >);

}



export default GdprScreen