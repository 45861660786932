import React, { useContext, useEffect, useState } from 'react';
import { VStack, Fade } from '@chakra-ui/react';
import InsightsSummary from './InsightsSummary';
import FlowStep from './FlowStep';
import DescriptionFlowStep from './DesciptionFlowStep';
import InstructionScreen from './InstructionScreen';
import PlannedActionsStep from './PlannedActionsStep';
import { InsightRating, InsightReport, Insights } from '../../types/types';
import { t } from 'i18next';
import { UserProfileContext } from '../../contexts/userProfileContextProvider';
import { FirebaseContext } from '../../contexts/firebaseProvider';

interface InsightsFlowProps {
    summary: string;
    emotions_and_causes: InsightRating[];
    important_thoughts: InsightRating[];
    goals: InsightRating[];
    problems_and_complaints: InsightRating[]
    new_insights_and_solutions: InsightRating[];
    plannedActions: string[];
    chatId: string;
    uid: string;
    chatName: string;
}

const InsightsFlow: React.FC<InsightsFlowProps> = (props) => {
    const [currentStep, setCurrentStep] = useState<'instructions' | 'description' | 'feelings' | 'thoughts' | 'goals' | 'problems' | 'insights' | 'summary' | 'plannedActions'>('instructions');
    const [description, setDescription] = useState(props.summary);
    const { userProfile, setUserProfile, currentUser } = useContext(UserProfileContext);
    const firebaseInterface = useContext(FirebaseContext);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep]);

    const [feelings, setFeelings] = useState(
        props.emotions_and_causes
    );
    const [thoughts, setThoughts] = useState(
        props.important_thoughts
    );
    const [goals, setGoals] = useState(
        props.goals
    );
    const [problems, setProblems] = useState(
        props.problems_and_complaints
    );
    const [insights, setInsights] = useState(
        props.new_insights_and_solutions
    );

    const [plannedActions, setPlannedActions] = useState(
        props.plannedActions
    );



    const handleRatingChange = (category: string, index: number, rating: number) => {
        if (category === 'feelings') {
            setFeelings((prevFeelings) => {
                const updatedFeelings = [...prevFeelings];
                updatedFeelings[index] = { ...updatedFeelings[index], rating };
                return updatedFeelings;
            });
        } else if (category === 'thoughts') {
            setThoughts((prevThoughts) => {
                const updatedThoughts = [...prevThoughts];
                updatedThoughts[index] = { ...updatedThoughts[index], rating };
                return updatedThoughts;
            });
        }
        else if (category === 'goals') {
            setGoals((prevGoals) => {
                const updatedGoals = [...prevGoals];
                updatedGoals[index] = { ...updatedGoals[index], rating };
                return updatedGoals;
            });
        }
        else if (category === 'problems') {
            setProblems((prevProblems) => {
                const updatedProblems = [...prevProblems];
                updatedProblems[index] = { ...updatedProblems[index], rating };
                return updatedProblems;
            });
        }
        else if (category === 'insights') {
            setInsights((prevInsights) => {
                const updatedInsights = [...prevInsights];
                updatedInsights[index] = { ...updatedInsights[index], rating };
                return updatedInsights;
            });
        }

    };

    const handleStatementChange = (category: string, index: number, statement: string) => {
        if (category === 'feelings') {
            setFeelings((prevFeelings) => {
                const updatedFeelings = [...prevFeelings];
                updatedFeelings[index] = { ...updatedFeelings[index], text: statement };
                return updatedFeelings;
            }
            );
        } else if (category === 'thoughts') {
            setThoughts((prevThoughts) => {
                const updatedThoughts = [...prevThoughts];
                updatedThoughts[index] = { ...updatedThoughts[index], text: statement };
                return updatedThoughts;
            }
            );
        }
        else if (category === 'goals') {
            setGoals((prevGoals) => {
                const updatedGoals = [...prevGoals];
                updatedGoals[index] = { ...updatedGoals[index], text: statement };
                return updatedGoals;
            }
            );
        }
        else if (category === 'problems') {
            setProblems((prevProblems) => {
                const updatedProblems = [...prevProblems];
                updatedProblems[index] = { ...updatedProblems[index], text: statement };
                return updatedProblems;
            }
            );
        }
        else if (category === 'insights') {
            setInsights((prevInsights) => {
                const updatedInsights = [...prevInsights];
                updatedInsights[index] = { ...updatedInsights[index], text: statement };
                return updatedInsights;
            }
            );
        }
        else if (category === 'plannedActions') {
            setPlannedActions((prevPlannedActions) => {
                const updatedPlannedActions = [...prevPlannedActions];
                //@ts-ignore   
                updatedPlannedActions[index] = statement ;
                return updatedPlannedActions;
            }
            );
        }

    };

    const addNewStatement = (category: string) => {
        if (category === 'feelings') {
            setFeelings((prevFeelings) => {
                return [...prevFeelings, { text: '', rating: 0 }];
            }
            );
        } else if (category === 'thoughts') {
            setThoughts((prevThoughts) => {
                return [...prevThoughts, { text: '', rating: 0 }];
            }
            );
        }
        else if (category === 'goals') {
            setGoals((prevGoals) => {
                return [...prevGoals, { text: '', rating: 0 }];
            }
            );
        }
        else if (category === 'problems') {
            setProblems((prevProblems) => {
                return [...prevProblems, { text: '', rating: 0 }];
            }
            );
        }
        else if (category === 'insights') {
            setInsights((prevInsights) => {
                return [...prevInsights, { text: '', rating: 0 }];
            }
            );
        }
        else if (category === 'plannedActions') {
            //@ts-ignore   
            setPlannedActions((prevPlannedActions) => {
                return [...prevPlannedActions,  '' ];
            }
            );


        }

        setTimeout(() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        }, 100);
    };



    const handleDescriptionChange = (description: string) => {
        setDescription(description);
    }

    async function logInsightReportCreated() {
        if (!currentUser) {
            throw new Error("User is not logged in")
        }
        // paid plans each have a different number of insights they can generate daily so get rate limited on their daily stats
        // trial users get 1 free insight report (not daily) so get rate limited on their total stats
        // when trail users generate an insight report and afterwards upgrade to a paid plan  I don't want that insight report to have counted towards their daily limit.
        // so I only increment the daily stats for paid users
        if (userProfile.subscriptionId === 'trial') {
            setUserProfile((oldProfile) => {
                return {
                    ...oldProfile,
                    totalStats: {
                        ...oldProfile.totalStats,
                        totalInsightReportsGenerated: oldProfile.totalStats.totalInsightReportsGenerated + 1,
                    }
                }
            })
        }
        else {
            setUserProfile((oldProfile) => {
                return {
                    ...oldProfile,
                    dailyStats: {
                        ...oldProfile.dailyStats,
                        totalInsightReportsGenerated: oldProfile.dailyStats.totalInsightReportsGenerated + 1,


                    },
                    totalStats: {
                        ...oldProfile.totalStats,
                        totalInsightReportsGenerated: oldProfile.totalStats.totalInsightReportsGenerated + 1,
                    }
                }
            })
        }
        firebaseInterface.logInsightReportGenerated(currentUser.uid, userProfile.subscriptionId)

    }

    const handleSaveReport = async () => {
        const report: Insights = {
            summary: description,
            emotions_and_causes: feelings.filter((item) => item.rating > 25),
            important_thoughts: thoughts.filter((item) => item.rating > 25),
            goals: goals.filter((item) => item.rating > 25),
            problems_and_complaints: problems.filter((item) => item.rating > 25),
            new_insights_and_solutions: insights.filter((item) => item.rating > 25),
            //@ts-ignore
            plannedActions: plannedActions.filter((item) => item !== ''),
        };
        const insightReport: InsightReport = {
            report: report,
            chatId: props.chatId,
            chatName: props.chatName,
        }

        await firebaseInterface.addInsightReport(props.uid, props.chatId, insightReport);
        await firebaseInterface.updateInsights(props.uid, props.chatId, report);
        logInsightReportCreated();
        //props.onSaveReport(report);
    };

    const goNextFlowStep = () => {
        if (currentStep === 'instructions') {
            setCurrentStep('description');
        } else if (currentStep === 'description') {
            setCurrentStep('problems');
        } else if (currentStep === 'problems') {
            setCurrentStep('feelings');
        } else if (currentStep === 'feelings') {
            setCurrentStep('thoughts');
        } else if (currentStep === 'thoughts') {
            setCurrentStep('insights');
        } else if (currentStep === 'insights') {
            setCurrentStep('goals');
        }
        else if (currentStep === 'goals') {
            setCurrentStep('summary');
        }
        else if (currentStep === 'summary') {
            setCurrentStep('plannedActions');
        }

    };

    const goPreviousFlowStep = () => {
        if (currentStep === 'problems') {
            setCurrentStep('description');
        } else if (currentStep === 'feelings') {
            setCurrentStep('problems');
        } else if (currentStep === 'thoughts') {
            setCurrentStep('feelings');
        } else if (currentStep === 'insights') {
            setCurrentStep('thoughts');
        } else if (currentStep === 'goals') {
            setCurrentStep('insights');
        }
        else if (currentStep === 'summary') {
            setCurrentStep('goals');
        }
        else if (currentStep === 'plannedActions') {
            setCurrentStep('summary');
        }
    };
    // TODO Voeg bolletjes met stappen toe
    return (
        <VStack spacing={4} align="stretch" width={"100%"} height="100%">

            {currentStep === 'instructions' && (
                <Fade in={currentStep === 'instructions'}>
                    <InstructionScreen
                        goNextFlowStep={goNextFlowStep} />
                </Fade>
            )}


            {currentStep === 'description' && (
                <Fade in={currentStep === 'description'}>
                    <DescriptionFlowStep
                        goNextFlowStep={goNextFlowStep}
                        handleDescriptionChange={handleDescriptionChange}
                        initialDescription={description} />
                </Fade>
            )}

            {currentStep === 'problems' && (
                <Fade in={currentStep === 'problems'}>
                    <FlowStep
                        heading={t('InsightsFlowFlowStep_problems_title')}
                        text={t('InsightsFlowFlowStep_problems_description')}
                        feelings={problems}
                        handleRatingChange={handleRatingChange}
                        handleStatementChange={handleStatementChange}
                        addNewStatement={addNewStatement}
                        goNextFlowStep={goNextFlowStep}
                        goPreviousFlowStep={goPreviousFlowStep}
                        ratingHeader={t('InsightsFlowFlowStep_problems_rating_header')}
                        category={"problems"}
                        ratingLabels={[t('InsightsSummaryCategory_prevalence_label_1'), t('InsightsSummaryCategory_prevalence_label_2'), t('InsightsSummaryCategory_prevalence_label_3'), t('InsightsSummaryCategory_prevalence_label_4')]}
                    />
                </Fade>
            )}


            {currentStep === 'feelings' && (
                <Fade in={currentStep === 'feelings'}>
                    <FlowStep
                        heading={t('InsightsFlowFlowStep_feelings_title')}
                        text={t('InsightsFlowFlowStep_feelings_description')}
                        feelings={feelings}
                        handleRatingChange={handleRatingChange}
                        handleStatementChange={handleStatementChange}
                        addNewStatement={addNewStatement}
                        goNextFlowStep={goNextFlowStep}
                        goPreviousFlowStep={goPreviousFlowStep}
                        ratingHeader={t('InsightsFlowFlowStep_feelings_rating_header')}
                        category={"feelings"}
                        ratingLabels={[t('InsightsSummaryCategory_prevalence_label_1'), t('InsightsSummaryCategory_prevalence_label_2'), t('InsightsSummaryCategory_prevalence_label_3'), t('InsightsSummaryCategory_prevalence_label_4')]}
                    />
                </Fade>
            )}


            {currentStep === 'thoughts' && (
                <Fade in={currentStep === 'thoughts'}>
                    <FlowStep
                        heading={t('InsightsFlowFlowStep_thoughts_title')}
                        text={t('InsightsFlowFlowStep_thoughts_description')}
                        feelings={thoughts}
                        handleRatingChange={handleRatingChange}
                        handleStatementChange={handleStatementChange}
                        addNewStatement={addNewStatement}
                        goNextFlowStep={goNextFlowStep}
                        goPreviousFlowStep={goPreviousFlowStep}
                        ratingHeader={t('InsightsFlowFlowStep_thoughts_rating_header')}
                        category={"thoughts"}
                        ratingLabels={[t('InsightsSummaryCategory_prevalence_label_1'), t('InsightsSummaryCategory_prevalence_label_2'), t('InsightsSummaryCategory_prevalence_label_3'), t('InsightsSummaryCategory_prevalence_label_4')]}
                    />

                </Fade>
            )}


            {currentStep === 'insights' && (
                <Fade in={currentStep === 'insights'}>
                    <FlowStep
                        heading={t('InsightsFlowFlowStep_insights_title')}
                        text={t('InsightsFlowFlowStep_insights_description')}
                        feelings={insights}
                        handleRatingChange={handleRatingChange}
                        handleStatementChange={handleStatementChange}
                        addNewStatement={addNewStatement}
                        goNextFlowStep={goNextFlowStep}
                        goPreviousFlowStep={goPreviousFlowStep}
                        ratingHeader={t('InsightsFlowFlowStep_insights_rating_header')}
                        category={"insights"}
                        ratingLabels={[t('InsightsSummaryCategory_importance_label_1'), t('InsightsSummaryCategory_importance_label_2'), t('InsightsSummaryCategory_importance_label_3'), t('InsightsSummaryCategory_importance_label_4')]}
                    />
                </Fade>
            )}


            {currentStep === 'goals' && (
                <Fade in={currentStep === 'goals'}>
                    <FlowStep
                        heading={t('InsightsFlowFlowStep_goals_title')}
                        text={t('InsightsFlowFlowStep_goals_description')}
                        feelings={goals}
                        handleRatingChange={handleRatingChange}
                        handleStatementChange={handleStatementChange}
                        addNewStatement={addNewStatement}
                        goNextFlowStep={goNextFlowStep}
                        goPreviousFlowStep={goPreviousFlowStep}
                        ratingHeader={t('InsightsFlowFlowStep_goals_rating_header')}
                        category={"goals"}
                        ratingLabels={[t('InsightsSummaryCategory_importance_label_1'), t('InsightsSummaryCategory_importance_label_2'), t('InsightsSummaryCategory_importance_label_3'), t('InsightsSummaryCategory_importance_label_4')]}
                    />
                </Fade>
            )}

            {currentStep === 'summary' && (
                <Fade in={currentStep === 'summary'}>
                    <VStack spacing={4} align="stretch" alignItems={"center"} >
                        <InsightsSummary
                            feelingsRatings={feelings}
                            thoughtsRatings={thoughts}
                            problemRatings={problems}
                            insightsRatings={insights}
                            goalRatings={goals}
                            conversationDescription={description}
                            goPreviousFlowStep={goPreviousFlowStep}
                            goNextFlowStep={goNextFlowStep}
                        />
                    </VStack>
                </Fade>
            )}

            {currentStep === 'plannedActions' && (
                <Fade in={currentStep === 'plannedActions'}>
                    <VStack spacing={4} align="stretch" alignItems={"center"} >
                        <PlannedActionsStep
                            heading={t('InsightsFlowFlowStep_plannedActions_title')}
                            text={t('InsightsFlowFlowStep_plannedActions_description')}
                            actions={plannedActions}
                            handleStatementChange={handleStatementChange}
                            addNewStatement={addNewStatement}
                            saveReport={handleSaveReport}
                            goPreviousFlowStep={goPreviousFlowStep}
                            category={"plannedActions"}
                        />
                    </VStack>
                </Fade>
            )}


        </VStack>
    );
};

export default InsightsFlow;
