import { useState, ChangeEvent, useEffect, useRef } from 'react';
import { Box, Textarea, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../../styles/styles';
import CloseInsightCreatorButton from './CloseInsightCreatorButton';
import { t } from 'i18next';

interface DescriptionFlowStepProps {
  initialDescription: string;
  goNextFlowStep: () => void;
  handleDescriptionChange: (description: string) => void;
}

function DescriptionFlowStep(props: DescriptionFlowStepProps) {
  const [description, setDescription] = useState<string>(props.initialDescription || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight();
    }
  }, []);

  const handleSaveClick = () => {
    // Perform save operation or any other logic here
    props.handleDescriptionChange(description);
    props.goNextFlowStep();
  };

  const [theme] = useState<Theme>(lavenderStyle)
  return (
    <Flex width="100%" flexDir="column">
         <Flex width="100%" justifyContent={"flex-end"}  mt="2"   >            <CloseInsightCreatorButton />
        </Flex>
      <Flex width="100%" alignItems="center" flexDir="column">
     
        <Heading size="lg" marginLeft="5px" >
          {t('DescriptionFlowFlowStep_title')}
        </Heading>
        <Flex alignItems="flexstart" justifyContent="center" p="2" >
          {[...Array(6)].map((_, index) => (
            <Box
              key={index}
              w="2"
              h="2"
              borderRadius="full"
              bg={0 === index ? theme.primaryColor : theme.secondaryColor}
              mx="2"
            />
          ))}
        </Flex>

        <Flex width="90%" maxWidth="800px" pb="5" pt="5" >
          <Text fontStyle="italic" fontSize="md" >
            {t('DescriptionFlowStep_description')}
          </Text>
        </Flex>
      </Flex>
      <Box
        bg="white"
        border="1px solid #ccc"
        borderRadius="md"
        p={3}
        boxShadow="md"
        mx="auto"
        width="90%" maxWidth="1000px"
        mb="5"
      >
        <Textarea
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Enter description"
          aria-label="Description input"
          resize="none"
          overflow="hidden"
          minH="20px" // Adjusted the minimum height to 1 row (can be adjusted based on your desired row height)

          _focus={{
            outline: 'none',
            borderColor: theme.thirdColor,
            boxShadow: '0 0 0 2px ' + theme.thirdColor,
          }}
          ref={textareaRef}
        />

      </Box>
      <Flex width="100%" justifyContent={"center"}>
        <Button width="60%"
          colorScheme={theme.colorScheme}
          onClick={handleSaveClick}
          variant="outline"
        >
          {t('DescriptionFlowFlowStep_next_button')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default DescriptionFlowStep;
