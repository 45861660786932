import { useState, } from "react";
import { Button, VStack } from "@chakra-ui/react";
import { GB, NL } from 'country-flag-icons/react/3x2'
import { useTranslation } from "react-i18next";
import { lavenderStyle, Theme } from "../../styles/styles";

const LanguageSelectScreen = () => {
  const [theme] = useState<Theme>(lavenderStyle)

  const { i18n } = useTranslation();


  const setLanguage = (language: string) => {
    localStorage.setItem('language', language)
    i18n.changeLanguage(language);
  };



  return (
    <VStack spacing={5} width="100%" borderWidth={"1px"} padding="4" borderRadius={"5"} borderColor={theme.fourthColor}>
      <Button
      colorScheme={theme.colorScheme}
        width="100%"
        variant={i18n.language === "en" ? "solid" : "outline"}
        onClick={() => setLanguage("en")}
      >
        English
        <GB
          style={{ marginLeft: "10px", width: "24px", height: "24px" }}
        />
      </Button>
      <Button
         colorScheme={theme.colorScheme}
         width="100%"
        variant={i18n.language === "nl" ? "solid" : "outline"}
        onClick={() => setLanguage("nl")}
      >
        Nederlands  
        <NL
          style={{ marginLeft: "10px", width: "24px", height: "24px" }}
        />
      </Button>
      

    </VStack>
  );
};

export default LanguageSelectScreen;
