
import male from '../../assets/gender/male2.png';
import female from '../../assets/gender/female2.png';

class AvatarGender {
    gender;
    img;

    browsMap = {
        "male": male,
        "female": female,
    }

    constructor(gender) {
        this.gender = gender;
        this.img = new Image();
        this.img.src = this.browsMap[this.getOptionsString()];
    }

    getOptionsString() {
        return this.gender;
    }


    renderOnCanvas(context, canvas_width, canvas_height) {
        return new Promise((resolve, reject) => {
            switch (this.gender) {
                case "male":
                    this.img.onload = () => {

                        context.drawImage(this.img, 400, 400, this.img.width * 1.8, this.img.height * 1.8);
                        resolve()
                    };

                    this.img.onerror = () => {
                        reject()
                    };

                    if (this.img.complete) {
                        context.drawImage(this.img, 400, 400, this.img.width * 1.8, this.img.height * 1.8);
                        resolve()
                    }
                    break;
                case "female":
                    this.img.onload = () => {
                        context.drawImage(this.img, (canvas_width / 2) - 500, 200, this.img.width * 1.9, this.img.height * 1.9);
                        resolve()
                    };

                    this.img.onerror = () => {
                        reject()
                    };

                    if (this.img.complete) {
                        context.drawImage(this.img, (canvas_width / 2) - 500, 200, this.img.width * 1.9, this.img.height * 1.9);
                        resolve()
                    }
                    break;
                default:
                    resolve();
                    break;
            }

        });
    }


    renderAsOption(context, canvas_width, canvas_height) {

        this.img.onload = () => {

            switch (this.gender) {
                case "male":
                    const scaleXMale = 0.2; // Scale factor for width
                    const scaleYMale = 0.2; // Scale factor for height
                    context.drawImage(this.img, 12, 20, this.img.width * scaleXMale, this.img.height * scaleYMale);
                    break
                case "female":
                    const scaleXFemale = 0.19; // Scale factor for width
                    const scaleYFemale = 0.19; // Scale factor for height
                    context.drawImage(this.img, 45, 10, this.img.width * scaleXFemale, this.img.height * scaleYFemale);
                    break
                default:
                    break;
            }
        };

    }
}



export default AvatarGender