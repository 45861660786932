import { useContext, useEffect, useState } from "react";
import {
    Divider,
    Flex, Heading, useBreakpointValue
} from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import Avatar from "../../components/avatar/Avatar";
import { isMobile } from "react-device-detect";
import NewChatButton from "../../components/homescreen/NewChatButton";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import RememberedMessagesButton from "../../components/sharedComponents/topNavigationRow/RememberedMessageButton";
import InsightsButton from "../../components/homescreen/InsightsButton";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import NotificationsButton from "../../components/sharedComponents/topNavigationRow/NotificationsButton";
import ProfileButton from "../../components/sharedComponents/topNavigationRow/ProfileButton";
import logo from '../../assets/general/PsyScribeLogo.svg';
import MoodTrackingQuestionnaire from "../moodtrackerScreens/MoodTrackingQuestionnaire";
import MainNavigationRow from "../../components/sharedComponents/mainNavigationRow/MainNavigationRow";
import { useTranslation } from "react-i18next";

function HomeScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const { userProfile, currentUser } = useContext(UserProfileContext);
    const navigate = useNavigate();
    const avatarWidth = useBreakpointValue({ base: "220px", sm: "350px", md: "400px", }); // Use useBreakpointValue hook to set different widths based on screen size
    const firebaseInterface = useContext(FirebaseContext);
    const [showDailyMoodTracker, setShowDailyMoodTracker] = useState<boolean>(false);
    const [creatingChat, setCreatingChat] = useState<boolean>(false);
    const [today] = useState<Date>(new Date())
    const { i18n } = useTranslation();

    const closeDailyMoodTracker = () => {
        setShowDailyMoodTracker(false)
    }


    const startChat = async () => {
        if (!currentUser) {
            throw new Error("no current user")
        }
        setCreatingChat(true)
        const todayChatId = firebaseInterface.getCurrentDate()
        const chatExists = await firebaseInterface.checkIfChatExists(currentUser.uid, firebaseInterface.getCurrentDate())
        if (!chatExists) {
            await firebaseInterface.createDailyChat(currentUser.uid, todayChatId)
        }
        navigate(`/chatbotScreen/${todayChatId}`);


        // navigate to chat
        //navigate(`/chatbotScreen/${id}`);
    }


    useEffect(() => {
     
    
        if (userProfile.language === 'nl' && i18n.language !== 'nl') {

            i18n.changeLanguage("nl");
        }

        if (userProfile.todayMoods.length === 0) {
            setShowDailyMoodTracker(true)
        }
    }, [userProfile.language,i18n,userProfile.todayMoods.length])

    return <Flex flexDirection='column' height={isMobile ? "88vh" : "99vh"} width="96%" alignItems="center" marginLeft="auto"
        marginRight="auto"   >

        {showDailyMoodTracker && <MoodTrackingQuestionnaire onClose={closeDailyMoodTracker} />}


        <Flex width="100%" mt="2" zIndex={'overlay'}   >
            <Image ml='2' width='150px' height='50px' src={logo} alt="My Logo" />
            <Flex width="100%" justifyContent={"flex-end"} columnGap='1' >
                <RememberedMessagesButton />
                <NotificationsButton />
                <ProfileButton />
            </Flex>
        </Flex>

        <Heading color={theme.primaryColor} fontFamily={'heading'}> {today.toLocaleDateString()}</Heading>
        <Divider borderColor={theme.thirdColor} maxWidth='800px' mt='5' ></Divider>

        <Flex width={avatarWidth} justifyContent={"center"} mb='2'>
            <Avatar avatarParams={userProfile.therapistSettings.avatar} />
        </Flex>
        <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            rowGap="5px"
            mt='10px'
        >


            <NewChatButton createNewChatHandler={startChat} animation={true} isLoading={creatingChat} />

            <InsightsButton />

            <MainNavigationRow />

        </Flex>

    </Flex>
}


export default HomeScreen