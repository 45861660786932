import { essentialPlan, starterPlan, trialPlan } from "../data/subscriptions/subscriptionPlans";
import { SubscriptionID, RateLimitCheck } from "../types/types";

export function isAllowedToCreateChat(subscriptionID: SubscriptionID, totalChatsAtTheSameTime: number, dailyCreatedChats: number): RateLimitCheck {
   
    switch (subscriptionID) {
        case "trial":
            if (totalChatsAtTheSameTime >= trialPlan.limitTotalChatsAtTheSameTime) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitTotalChatsAtTheSameTime",
                    current: totalChatsAtTheSameTime,
                    limit: trialPlan.limitTotalChatsAtTheSameTime
                };
            }
            if (dailyCreatedChats >= trialPlan.limitDailyCreatedChats) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitDailyCreatedChats",
                    current: dailyCreatedChats,
                    limit: trialPlan.limitDailyCreatedChats
                };
            }
            break;
        case "starter":
            if (totalChatsAtTheSameTime >= starterPlan.limitTotalChatsAtTheSameTime) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitTotalChatsAtTheSameTime",
                    current: totalChatsAtTheSameTime,
                    limit: starterPlan.limitTotalChatsAtTheSameTime
                };
            }
            if (dailyCreatedChats >= starterPlan.limitDailyCreatedChats) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitDailyCreatedChats",
                    current: dailyCreatedChats,
                    limit: starterPlan.limitDailyCreatedChats
                };
            }

            break;
        case "essential":
            
            if (totalChatsAtTheSameTime >= essentialPlan.limitTotalChatsAtTheSameTime) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitTotalChatsAtTheSameTime",
                    current: totalChatsAtTheSameTime,
                    limit: essentialPlan.limitTotalChatsAtTheSameTime

                };
            }
            if (dailyCreatedChats >= essentialPlan.limitDailyCreatedChats) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitDailyCreatedChats",
                    current: dailyCreatedChats,
                    limit: essentialPlan.limitDailyCreatedChats
                };
            }
            break;
    
        case "admin":
            return {
                allowed: true,
                planId: subscriptionID,
            }
        default:
            return {
                allowed: false,
                planId: subscriptionID,
                reason: "planNotRecognized",
            }
    }
    return {
        allowed: true,
        planId: subscriptionID,
    }
}


export function isAllowedToCreateInsightReport(subscriptionID: SubscriptionID, totalInightReportsAtTheSameTime: number, totalInsightReportsCreatedAllTime: number, dailyCreatedInsightReports: number): RateLimitCheck {
    switch (subscriptionID) {
        case "trial":
            if (totalInsightReportsCreatedAllTime >= trialPlan.limitTotalCreatedInsightReportsAllTime) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitTotalCreatedInsightReportsAllTime",
                    current: totalInsightReportsCreatedAllTime,
                    limit: trialPlan.limitTotalCreatedInsightReportsAllTime
                };
            }
            break;
        case "starter":
            if (totalInightReportsAtTheSameTime >= starterPlan.limitTotalInsightReportsAtTheSameTime) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitTotalInsightReportsAtTheSameTime",
                    current: totalInightReportsAtTheSameTime,
                    limit: starterPlan.limitTotalInsightReportsAtTheSameTime
                };

            }
            if (dailyCreatedInsightReports >= starterPlan.limitDailyCreatedInsightReports) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitDailyCreatedInsightReports",
                    current: dailyCreatedInsightReports,
                    limit: starterPlan.limitDailyCreatedInsightReports
                };
            }
            break;
        case "essential":
            if (totalInightReportsAtTheSameTime >= essentialPlan.limitTotalInsightReportsAtTheSameTime) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitTotalInsightReportsAtTheSameTime",
                    current: totalInightReportsAtTheSameTime,
                    limit: essentialPlan.limitTotalInsightReportsAtTheSameTime
                };
            }
            if (dailyCreatedInsightReports >= essentialPlan.limitDailyCreatedInsightReports) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitDailyCreatedInsightReports",
                    current: dailyCreatedInsightReports,
                    limit: essentialPlan.limitDailyCreatedInsightReports
                };
            }
            break;
       
        case "admin":
            return {
                allowed: true,
                planId: subscriptionID,
            }
        default:
            return {
                allowed: false,
                planId: subscriptionID,
                reason: "planNotRecognized",
            };
    }
    return {
        allowed: true,
        planId: subscriptionID,
    };
}


export function isAllowedToSendMessage(subscriptionID: SubscriptionID, dailyMessagesSent: number, totalMessagesSentAllTime: number): RateLimitCheck {
    switch (subscriptionID) {
        case "trial":
            if (totalMessagesSentAllTime >= trialPlan.limitTotalMessagesSentAllTime) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitTotalMessagesSentAllTime",
                    current: totalMessagesSentAllTime,
                    limit: trialPlan.limitTotalMessagesSentAllTime
                };
            }
            break;
        case "starter":
            if (dailyMessagesSent >= starterPlan.limitDailyMessagesSent) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitDailyMessagesSent",
                    current: dailyMessagesSent,
                    limit: starterPlan.limitDailyMessagesSent
                };

            }
            break;
        case "essential":
            if (dailyMessagesSent >= essentialPlan.limitDailyMessagesSent) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitDailyMessagesSent",
                    current: dailyMessagesSent,
                    limit: essentialPlan.limitDailyMessagesSent
                };
            }
            break;
     
        case "admin":
            return {
                allowed: true,
                planId: subscriptionID,
            };
        default:
            return {
                allowed: false,
                planId: subscriptionID,
                reason: "planNotRecognized",
            };
    }
    return {
        allowed: true,
        planId: subscriptionID,
    };
}

export function isAllowedToRememberMessage(subscriptionID: SubscriptionID, dailyRememberedMessages: number, totalRememberedMessagesAtTheSameTime: number) : RateLimitCheck {
    switch (subscriptionID) {
        case "trial":
            if (totalRememberedMessagesAtTheSameTime >= trialPlan.limitTotalRememberedMessagesAtTheSameTime) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitTotalRememberedMessagesAtTheSameTime",
                    current: totalRememberedMessagesAtTheSameTime,
                    limit: trialPlan.limitTotalRememberedMessagesAtTheSameTime
                };
            }
            if (dailyRememberedMessages >= trialPlan.limitDailyRememberedMessages) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitDailyRememberedMessages",
                    current: dailyRememberedMessages,
                    limit: trialPlan.limitDailyRememberedMessages
                };
            }
            break;
        case "starter":
            if (totalRememberedMessagesAtTheSameTime >= starterPlan.limitTotalRememberedMessagesAtTheSameTime) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitTotalRememberedMessagesAtTheSameTime",
                    current: totalRememberedMessagesAtTheSameTime,
                    limit: starterPlan.limitTotalRememberedMessagesAtTheSameTime
                };
            }
            if (dailyRememberedMessages >= starterPlan.limitDailyRememberedMessages) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitDailyRememberedMessages",
                    current: dailyRememberedMessages,
                    limit: starterPlan.limitDailyRememberedMessages
                };
            }
            break;
        case "essential":
            if (totalRememberedMessagesAtTheSameTime >= essentialPlan.limitTotalRememberedMessagesAtTheSameTime) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitTotalRememberedMessagesAtTheSameTime",
                    current: totalRememberedMessagesAtTheSameTime,
                    limit: essentialPlan.limitTotalRememberedMessagesAtTheSameTime
                };
            }
            if (dailyRememberedMessages >= essentialPlan.limitDailyRememberedMessages) {
                return {
                    allowed: false,
                    planId: subscriptionID,
                    reason: "limitDailyRememberedMessages",
                    current: dailyRememberedMessages,
                    limit: essentialPlan.limitDailyRememberedMessages
                };
            }
            break;
       
        case "admin":
            return {
                allowed: true,
                planId: subscriptionID,
            };
        default:
            return {
                allowed: false,
                planId: subscriptionID,
                reason: "planNotRecognized",
            };
    }
    return {
        allowed: true,
        planId: subscriptionID,

    };
}