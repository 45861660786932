import { useEffect, useState } from 'react';
import { Flex, Heading, Text, VStack, Button, IconButton, Box } from '@chakra-ui/react';
import InsightCard from './InsightCard';
import { AddIcon } from "@chakra-ui/icons";
import { lavenderStyle, Theme } from '../../styles/styles';
import CloseInsightCreatorButton from './CloseInsightCreatorButton';
import PreviousScreenButton from '../sharedComponents/navigation/PreviousScreenButton';
import { t } from 'i18next';

interface FlowStepProps {
  heading: string;
  ratingHeader: string;
  ratingLabels: string[];
  category: string;
  text: string;
  feelings: {
    text: string;
    rating: number;
  }[];
  handleRatingChange: (category: string, index: number, rating: number) => void;
  handleStatementChange: (category: string, index: number, statement: string) => void;
  addNewStatement: (category: string) => void;
  goNextFlowStep: () => void;
  goPreviousFlowStep: () => void;
}

function FlowStep({
  heading,
  ratingHeader,
  ratingLabels,
  category,
  text,
  feelings,
  handleRatingChange,
  handleStatementChange,
  addNewStatement,
  goNextFlowStep,
  goPreviousFlowStep,
}: FlowStepProps) {
  const [theme] = useState<Theme>(lavenderStyle)
  const [sortedFeelings, setSortedFeelings] = useState<{ text: string; rating: number }[]>([]);

  const getStepIndex = () => {
    switch (category) {
      case 'problems':
        return 1;
      case 'feelings':
        return 2;
      case 'thoughts':
        return 3;
      case 'insights':
        return 4;
      case 'goals':
        return 5;
      default:
        return 0;
    }
  };

  useEffect(() => {

    // sort feelings by rating
    feelings.sort((a, b) =>  - b.rating + a.rating);
    setSortedFeelings(feelings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // sort feelings by rating
    setSortedFeelings(feelings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feelings.length])

  return (
    <Flex width="100%" flexDir="column">
      <Flex width="100%" justifyContent={"space-between"} mt="2" >

        <PreviousScreenButton  onClick={goPreviousFlowStep} />
        <CloseInsightCreatorButton />

      </Flex>
      <Flex width="100%" alignItems="center" flexDir="column">




        <Heading size="lg" paddingTop="5px" marginLeft="5px" >
          {heading}
        </Heading>
        <Flex alignItems="flexstart" justifyContent="center" p="2" >
          {[...Array(6)].map((_, index) => (
            <Box
              key={index}
              w="2"
              h="2"
              borderRadius="full"
              bg={getStepIndex() === index ? theme.primaryColor : theme.secondaryColor}
              mx="2"
            />
          ))}
        </Flex>
        <Flex width="90%" maxWidth="800px" pb="5" pt="5">
          <Text fontStyle="italic" fontSize="md">
            {text}
          </Text>
        </Flex>
      </Flex>
      <Flex width="100%" justifyContent="center" align='stretch'>
        <VStack spacing={5} align="stretch" width="95%" maxWidth="800px">
          {sortedFeelings.length > 0 ? sortedFeelings.map((feeling, index) => (
            <InsightCard
              key={index}
              category={category}
              statement={feeling.text}
              rating={feeling.rating}
              onRatingChange={(rating) => handleRatingChange(category, index, rating)}
              onStatementChange={(statement) => handleStatementChange(category, index, statement)}
              ratingHeader={ratingHeader}
              ratingLabels={ratingLabels}
            />
          )) :
            <Flex width="100%" justifyContent="center" alignItems="center" flexDir="column" mt="10" mb="10">
              <Text fontWeight={"bold"}>{t('InsightsFlowFlowStep_no_insights_text')}</Text>
            </Flex>
          }
          <Flex width="100%" justifyContent="center" alignItems="center" flexDir="column">
            <Text>{t('InsightsFlowFlowStep_add_insight_text')}</Text>
          </Flex>
          <IconButton
            width="100%"
            aria-label="Add"
            variant="ghost"
            colorScheme={theme.colorScheme}
            icon={<AddIcon />}
            onClick={() => addNewStatement(category)}
          />
          <Flex columnGap="2" pb="5">

            <Button flex={1} onClick={goNextFlowStep} colorScheme={theme.colorScheme} variant="outline">
              {t('InsightsFlowFlowStep_next_step_button')}
            </Button>
          </Flex>
        </VStack>
      </Flex>
    </Flex>
  );
}

export default FlowStep;
