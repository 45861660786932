import { useContext, useState } from "react";
import {
    Button,
    Flex, Heading, IconButton, Spinner, Text, useBreakpointValue
} from '@chakra-ui/react'
import { Link, Link as Routerlink, useLocation } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import Avatar from "../../components/avatar/Avatar";
import { t } from "i18next";
import { EditIcon } from '@chakra-ui/icons'
import { UserProfileContext } from "../../contexts/userProfileContextProvider";

function ModifyPsychologistScreen() {
    const { userProfile } = useContext(UserProfileContext);
    const [theme] = useState<Theme>(lavenderStyle)
    const location = useLocation();
    const avatarWidth = useBreakpointValue({ base: "250px", sm: "250px", md: "300px", }); // Use useBreakpointValue hook to set different widths based on screen size

    if (userProfile.username === "not_complete") {
        return <Flex flexDir="column" width="100%" height={"88vh"} justifyContent="center" alignItems="center" >
            <Spinner size="xl" />
        </Flex>
    }

    return (
        <Flex flexDirection='column' height={"99vh"} width="96%" alignItems="center" marginLeft="auto"
            marginRight="auto" >

            <Heading size="lg" marginTop='20px'>
                {t('ModifyPsychologistScreen_title')}
            </Heading>

            <Flex marginTop="10px" flexDir="column" marginBottom="10px">
                <IconButton
                    colorScheme={theme.colorScheme}
                    as={Link}
                    to='/avatarCustomizationScreen'
                    state={{ from: location.pathname }}
                    icon={<EditIcon boxSize={5} />}
                    variant={"ghost"}
                    onClick={() => { }}
                    aria-label={""}
                    marginTop="10px"
                />
                <Flex width={avatarWidth}>
                    <Avatar avatarParams={userProfile.therapistSettings.avatar} />
                </Flex>
            </Flex>
            <Flex flexDir="column" width="100%" maxWidth={'300px'} justifyContent={"center"} alignItems="center"  >
                <Flex width="100%" flexDirection="column" alignItems="center">
                    <Flex width='100%' justifyContent={'space-between'} alignItems="center" mb="10px"  >
                        <Flex >
                            <Text width='130px' fontSize="md" fontWeight="bold">
                                {t('ModifyPsychologistScreen_name_text')}:
                            </Text>
                            <Text fontSize="md">{userProfile.therapistSettings.psychologistName}</Text>
                        </Flex>
                        <IconButton
                            colorScheme={theme.colorScheme}
                            as={Routerlink}
                            to="/namePsychologistSettingScreen"
                            state={{ from: location.pathname }}
                            icon={<EditIcon />}
                            size="sm"
                            variant={"ghost"}
                            ml="1"
                            onClick={() => { }}
                            aria-label={""} />
                    </Flex>
                    <Flex width='100%' justifyContent={'space-between'} alignItems="center" mb="10px">
                        <Flex >
                            <Text width='130px'  fontSize="md" fontWeight="bold" >
                                {t('ModifyPsychologistScreen_therapystyle_text')}:
                            </Text>
                            <Text fontSize="md">{userProfile.therapistSettings.therapyStyle === "supportive-listening" ? t('ModifyPsychologistScreen_supportive_therapy_style') : t('ModifyPsychologistScreen_goaloriented_therapy_style')}</Text>

                        </Flex>
                        <IconButton
                            colorScheme={theme.colorScheme}
                            as={Routerlink}
                            to="/therapyStyleSettingScreen"
                            state={{ from: location.pathname }}
                            icon={<EditIcon />}
                            size="sm"
                            ml="1"
                            variant={"ghost"}
                            onClick={() => { }}
                            aria-label={""} />
                    </Flex>
                    <Flex width='100%' justifyContent={'space-between'} alignItems="center" mb="10px">
                    <Flex >
                        <Text fontSize="md" fontWeight="bold" width='130px'>
                            {t('ModifyPsychologistScreen_personality_text')}:
                        </Text>
                        <Text fontSize="md">{userProfile.therapistSettings.personality === "compassionate" ? t('ModifyPsychologistScreen_compassionate_personality') : (userProfile.therapistSettings.personality === "motivational" ? t('ModifyPsychologistScreen_motivational_personality') : t('ModifyPsychologistScreen_humorous_personality'))}</Text>
                        </Flex >
                        
                        <IconButton
                            colorScheme={theme.colorScheme}
                            as={Routerlink}
                            to="/personalitySettingScreen"
                            state={{ from: location.pathname }}
                            icon={<EditIcon />}
                            size="sm"
                            ml="1"
                            variant={"ghost"}
                            onClick={() => { }}
                            aria-label={""} />
                    </Flex>
                    <Flex width='100%' justifyContent={'space-between'} alignItems="center" mb="10px">
                    <Flex >
                        <Text fontSize="md" fontWeight="bold" width='130px'>
                            {t('ModifyPsychologistScreen_typespeed_text')}:
                        </Text>
                        <Text fontSize="md">{userProfile.therapistSettings.typeSpeed === 80 ? t('ModifyPsychologistScreen_typespeed_fast') : t('ModifyPsychologistScreen_typespeed_slow')}</Text>
                        </Flex >
                        <IconButton
                            colorScheme={theme.colorScheme}
                            as={Routerlink}
                            to="/typeSpeedSettingScreen"
                            state={{ from: location.pathname }}
                            icon={<EditIcon />}
                            size="sm"
                            ml="1"
                            variant={"ghost"}
                            onClick={() => { }}
                            aria-label={""} />
                    </Flex>
                    <Flex width='100%' justifyContent={'space-between'} alignItems="center" >
                    <Flex >
                        <Text fontSize="md" fontWeight="bold" width='130px'>
                            {t('ModifyPsychologistScreen_username_text')}:
                        </Text>
                        <Text fontSize="md">{userProfile.username === null ? t("ModifyPsychologistScreen_unknown_name") : userProfile.username}</Text>
                        </Flex >
                        <IconButton
                            colorScheme={theme.colorScheme}
                            icon={<EditIcon />}
                            as={Routerlink}
                            to="/nameUserSettingScreen"
                            state={{ from: location.pathname }}
                            size="sm"
                            ml="1"
                            variant={"ghost"}
                            onClick={() => { }}
                            aria-label={""} />
                    </Flex>
                </Flex>


            </Flex>
            <Button colorScheme={theme.colorScheme} as={Routerlink} to={"/homeScreen"} size={'lg'} mt='40px'>{t('ModifyScreen_done_button')}</Button>
        </Flex>
    );
}

export default ModifyPsychologistScreen