import { useContext, useEffect, useState } from "react";
import { Box, Button, Divider, Flex,  Text } from "@chakra-ui/react";
import { lavenderStyle, Theme } from "../../styles/styles";
import { Mood } from "../../types/types";
import { AddIcon } from "@chakra-ui/icons";
import MoodTrackingQuestionnaire from "../../pages/moodtrackerScreens/MoodTrackingQuestionnaire";
import { t } from "i18next";
import { FirebaseContext } from "../../contexts/firebaseProvider";

interface MoodTrackerProps {
    moods: Mood[];
}

function Moodtracker(props: MoodTrackerProps) {
    const [theme] = useState<Theme>(lavenderStyle);
    const [todayDate] = useState<Date>(new Date())
    const [avgMoodRating, setAvgMoodRating] = useState<number>(0)
    const [isMoodTrackerOpen, setIsMoodTrackerOpen] = useState<boolean>(false)
    const firebaseInterface = useContext(FirebaseContext);

    useEffect(() => {
        // sort moods by date
        // calculate average mood rating
        let sum = 0;
        props.moods.forEach((mood) => {
            sum += mood.moodRating
        })
        const avg = Number((sum / props.moods.length).toFixed(1))
        setAvgMoodRating(avg)
    }, [props.moods])

    // Define a color mapping based on mood ratings
    const moodColors = {
        0: "white",       // No Mood (White)
        1: "#FF6868",
        2: "#FF7A7A",
        3: "#FF8C8C",
        4: "#FF9E9E",    // Low Mood (Even Darker Red)
        5: "#FFD966",     // Moderate Mood (Light Yellow)
        6: "#FFD355",     // Moderate Mood (Slightly Darker Yellow)
        7: "#C7D88B",
        8: "#A2C971",     // High Mood (Light Green)
        9: "#7EBF57",     // High Mood (Slightly Darker Green)
        10: "#7EBF57",    // High Mood (Darker Green)
    };


    const color = (moodColors as any)[Math.round(avgMoodRating)] || "gray.300";
    const closeDailyMoodTracker = () => {
        setIsMoodTrackerOpen(false)
    }

    const latestMood = props.moods.length > 0 ? props.moods[props.moods.length - 1] : null;

   
    return (
        <Flex
            bg="white"
            p="4"
            borderRadius="md"
            display="flex"
            alignItems="center"
            justifyContent="space-between"

            flexDir={'column'}
        >

            {isMoodTrackerOpen && <MoodTrackingQuestionnaire onClose={closeDailyMoodTracker} />}


            <Box
                width="50px"
                height="50px"
                bg={color}
                borderRadius="full"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Text fontSize="xl" fontWeight="bold" color="white">
                    {avgMoodRating}
                </Text>
            </Box>

            <Text fontWeight={'bold'} fontSize={'smaller'} mt="2">{t('MoodTracker_title')} {latestMood?.localeDateString}</Text>
            <Text fontSize={'smaller'}>
                {latestMood ? `${t('Moodtracker_last_measure_label')} ${latestMood.localeTimeString}` : t('Moodtracker_no_measure_label')}
            </Text>
            <Divider mt="2" mb="2" />
            {(firebaseInterface.dateToFirebaseDateId(todayDate) === latestMood?.localeDateString  || todayDate.toLocaleDateString() === latestMood?.localeDateString )&&
                <Button
                    leftIcon={<AddIcon />}
                    size='sm'
                    aria-label="Add Mood"
                    variant="outline"
                    width={'100%'}
                    onClick={() => setIsMoodTrackerOpen(true)}
                    colorScheme={theme.colorScheme}>
                    {t('Moodtracker_measure_mood_button')}
                </Button>

            }
        </Flex>

    );

}

export default Moodtracker;
