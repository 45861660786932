
import {
    Flex,
    IconButton,
} from "@chakra-ui/react";

import { Link as Routerlink, } from "react-router-dom";
import { useContext, useState } from "react";
import { lavenderStyle, Theme } from "../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faHouse, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FirebaseContext } from "../../../contexts/firebaseProvider";


function MainNavigationRow() {
    const [theme] = useState<Theme>(lavenderStyle)
    const firebaseInterface = useContext(FirebaseContext);
    const path = window.location.pathname;

    return (<Flex
        width={'100%'}
        borderBottomColor={theme.thirdColor}
        borderBottomWidth='1px'
        position={'fixed'}
        bg='white'
        zIndex={10}
        p={3}
        pl={7}
        pr={7}
        alignItems='center'
        bottom="0"  // Position at the bottom of the screen
        left="0"    // Align to the left
        right="0"   // Align to the right

        justifyContent={'center'}

        border='1px solid'
        borderColor={theme.thirdColor}
        columnGap={5}

    >

        <IconButton aria-label="Go back"
            variant={path ==="/homeScreen"?"solid" : "ghost"}
            colorScheme={theme.colorScheme}
            icon={<FontAwesomeIcon icon={faHouse} size="xl" />}



            width='100px'
            as={Routerlink} to={"/homeScreen"} borderRadius={theme.borderRadius} > </IconButton>


        <IconButton aria-label="Go back"
            variant={path.includes("/insightsScreen")?"solid" : "ghost"}
            colorScheme={theme.colorScheme}
            icon={<FontAwesomeIcon icon={faLightbulb} size="lg" />}

    
            width='100px'
            as={Routerlink} to={"/insightsScreen/"+firebaseInterface.getCurrentDate()} borderRadius={theme.borderRadius} > </IconButton>



        <IconButton aria-label="Go back"
            variant={path ==="/calendarScreen"?"solid" : "ghost"}
            colorScheme={theme.colorScheme}
            icon={<FontAwesomeIcon icon={faCalendarDays} size="lg" />}

            
            width='100px'
            as={Routerlink} to={"/calendarScreen"} borderRadius={theme.borderRadius} > </IconButton>



    </Flex>
    );
}

export default MainNavigationRow
    ;
