
import {
  Box,
  Flex,
} from "@chakra-ui/react";

import LandingPageHeader from "../../components/landingPageScreen/LandingPageHeader";
import LandingPageHeadline from "../../components/landingPageScreen/LandingPageHeadline";
import LandingPageFeatures from "../../components/landingPageScreen/LandingPageFeatures";
import LandingPagePersonalizations from "../../components/landingPageScreen/LandingPagePersonalizations";
import LandingPageSupportingFeaturesExplained from "../../components/landingPageScreen/LandingPageSupportingFeaturesExplained";
import LandingPageFooter from "../../components/landingPageScreen/LandingPageFooter";
import GetStartedButton from "../../components/landingPageScreen/GetStartedButton";
import {  useEffect, useState } from "react";
import useScreenWidth from "../../utils/useScreenWidth";
import LandingPageScienceSection from "../../components/landingPageScreen/LandingPageScienceSection";
import LandingPageInsights from "../../components/landingPageScreen/LandingPageInsights";
import LandingPagePrivacySection from "../../components/landingPageScreen/LandingPagePrivacySection";


function LandingPageScreen() {

  const screenWidth = useScreenWidth();


  const [isMobile, setIsMobile] = useState<boolean>(false)
  useEffect(() => {
  
      if (screenWidth < 1000) {
         setIsMobile(true)
         }
      else {
          setIsMobile(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth])






  return (<Flex
    flexDirection="column"
    alignItems="center"
    width="100%">

    <LandingPageHeader isMobile={isMobile} />

    <Flex width='97%' flexDirection="column"
      justifyContent={'center'}
      alignItems="center">

      <LandingPageHeadline  isMobile={isMobile} />
     
      <GetStartedButton />
      <Box id='aiTherapist'></Box>
      <LandingPageFeatures isMobile={isMobile} />

      <Box id='conversationInsights' ></Box>
      <LandingPageInsights isMobile={isMobile} />

      <LandingPagePrivacySection isMobile={isMobile} />
      <Box id='landingPagePersonalizations'></Box>

      <LandingPagePersonalizations isMobile={isMobile} />
      <LandingPageScienceSection isMobile={isMobile} />

      <Box id='longTermMemory'></Box>
      <LandingPageSupportingFeaturesExplained  isMobile={isMobile} />
      <GetStartedButton />

   
    </Flex>
    <LandingPageFooter />

  </Flex>
  );
}

export default LandingPageScreen;