
import {
    Flex,  Button, Heading, Text
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";



function PromoCodeActivatedScreen() {

    const [theme] = useState<Theme>(lavenderStyle)
    const navigate = useNavigate();
    const { userProfile } = useContext(UserProfileContext);

    



    return (
        <Flex flexDirection='column' maxHeight="97svh" height='100vh' width="96%" alignItems="center" justifyContent={'center'} marginLeft="auto" marginRight="auto" marginBottom="20px" >
            <Flex width={"100%"} justifyContent="center">
                <Heading size='lg' paddingTop='5px' marginLeft="5px">
                    {t('PromoCodeActivatedScreen_title')}
                </Heading>
            </Flex>
            <Text>     {t('PromoCodeActivatedScreen_description')} {userProfile.promoCode?.endDate.toLocaleDateString()}</Text>
            <Flex flexDir="column" mt="5" maxWidth={"900px"} justifyContent="center" alignItems={"center"}>


                <Button colorScheme={theme.colorScheme} onClick={() => navigate('/homeScreen')} > {t('SubscriptionBoughtScreen_button')}</Button>
            </Flex>
        </Flex>

    );
}

export default PromoCodeActivatedScreen;
