
import {
    Flex,
    Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import { lavenderStyle, Theme } from "../../styles/styles";


/**
 * The routing after the data is loaded is handled in contexts/authenticationProvider.tsx
 */
function UserDataFetchinScreen() {
    const [theme] = useState<Theme>(lavenderStyle)

    return ( <Flex flexDir="column" width="100%" height={"99vh"} justifyContent="center" alignItems="center"><Spinner color={theme.thirdColor} size="xl" /> </Flex>);

    
}

export default UserDataFetchinScreen;